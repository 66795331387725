import React, { useEffect, useState } from 'react';
import { useHistory , Link,useLocation} from 'react-router-dom';
import Navbar from './global-components/navbar';
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';
import Footer from './global-components/footer';
import {Helmet} from "react-helmet";
import { API_URL } from './Api'
import Counter from './section-components/counter';
import DialogBox from './global-components/enquiry-modal';
import axios from "axios";


 
const APIGET = `${API_URL}/Searching`;

// blog
const API_MarketPlace = `${API_URL}/MarketPlace`;
const API_View = `${API_URL}/MarketPlace_image`;
 

// Blog 
const URL = `${API_URL}/Blog`;
const urlimg = `${API_URL}/Blog_image`;

// Resources

const api = `${API_URL}/Resources`
const apiimage = `${API_URL}/Resources_image`

// Tech-Talk
const apitech = `${API_URL}/TechTalk`;
const techurlimg = `${API_URL}/TechTalk_image`;

function Search () {
    const location = useLocation();
    const [Finalsearch, setFinalsearch] = useState([])
    const [totalcount, settotalcount] = useState()
    const [market, setMarket] = useState([])
    const [Product, SetProduct] = useState("")
	const [Productlink, SetProductlink] = useState("")
    const [dialog, setDialog] = useState(false)
    const history = useHistory()
    // blog
    const [Blogdata, setBlogdata] = useState([]);
    // resource
    const [report, setreport] = useState([]);
    const [Resource, setResource] = useState(false);
    const [Blogs, setBlogs] = useState(false);
    const [News, setNews] = useState(false);
    const [MarketsPlace, setMarketsPlace] = useState(false);
    const [TechTalks, setTechTalks] = useState(false);
// tech talk
const [TechTalkData, SetTechTalkData] = useState([]);
const queryParameters = new URLSearchParams(window.location.search)
const parmsdata = queryParameters.get("search")
const  keywords =  parmsdata.replace(/\\|\//g,'')
//const keywords = location.state.detail;
    useEffect(()=> {
       
        
        FinalResult();
        
    },[location])

    const FinalResult =async()=>{
        const Result = await axios.post(`${APIGET}`,{keyword:keywords})
        setFinalsearch(Result.data)
        settotalcount(Result.data.Resources.length+Result.data.blogs.length+Result.data.markets.length+Result.data.TechTalk.length+Result.data.News.length)
        var totalsval= Result.data.Resources.length+Result.data.blogs.length+Result.data.markets.length+Result.data.TechTalk.length+Result.data.News.length
if(Result.data.Resources.length >0){
    setResource(true)
}
if(Result.data.blogs.length >0){
    setBlogs(true)
}
if(Result.data.markets.length >0){
    setMarketsPlace(true)
}
if(Result.data.TechTalk.length >0){
    setTechTalks(true)
}
if(Result.data.News.length >0){
    setNews(true)
}
    }

     //Get Category from Backend
     const GetData = async () => {
        const cate = await axios.get(`${API_MarketPlace}`)
        setMarket(cate.data)
    }

    const Getcompanyname = data => {
        SetProduct(data)
         if(data =="Deel"){
            window.open('https://www.deel.com/partners/hrtech-sg', '_blank');
        }else{
            
            setDialog(true)
        }
      }

   const dialogClose=()=>{
        setDialog(false)
      }

    //   blog
    const GetDatablog = async () => {
		// const res = await axios.get(URL)
		// setBlogdata(res.data)
		const { data } = await axios.get(URL)
		let sort = data.sort((a, b) => {
			return (
				new Date(a.BlogDate) -
				new Date(b.BlogDate)
			)
		}).reverse()
		setBlogdata(sort)
		console.log(data,"12345678")
	}

    // resource
    const GetAllResource = () => {
        axios.get(`${api}`).then((res) => {
            let sort = res.data.sort((a, b) => {
                return (
                    new Date(a.ResourceDate) -
                    new Date(b.ResourceDate)
                )
            }).reverse()
            
            setreport(sort)
             
        })
    }

   

        return (
            <div>
              
                <Navbar />
<DialogBox show={dialog} title={Product} links={Productlink} dialogClose={dialogClose} />
                <section className='py-md-5 py-4 title_bg jumbotron jumbotron-fluid text-center m-0 border-bottom'>
                    <div className="container-fluid " style={{ marginTop: '4%' }}>
                        <h1 className="text-center f-28 fw-600" >Showing {totalcount} results for "{keywords}"</h1>
                    </div>

                </section>

                {/* {Finalsearch.map((Items)=>{ */}

               <>
              
                <section className='bg-white pt-4 pb-0'>
                    <div className='container-fluid pb-md-4 px-0'>
                        {Finalsearch.markets && Finalsearch.markets.length > 0 ? 
                        <div className='row mx-auto'>
                            <div className='col-md-12'>
                                <div className='testimonial-head'>
                                    <h2 className='mb-md-5 fw-600 mt-md-3 mt-3 mb-4'>MarketPlaces</h2>
                                </div>
                            </div>
                        </div>: ""}
                        <div className='row mx-0'>
                                                {Finalsearch.markets?.map((item, i) => {
                                                    return (
                                                        <div key={i} className='col-12 col-sm-6 col-md-3 col-lg-3 slide-in-bottom feature_card px-2 mb-3'>
                                                            <div className='content mp_card'>
                                                                <a href={`/${item.MarketURL}/`}>
                                                                    <div className='content-overlay'></div>
                                                                    <div className='new_card content-image bg-transparent border-lightgrey'>
                                                                        <div className='h-50 d-flex' style={{ justifyContent: 'center', position: 'relative' }}>
                                                                            <div className="card-img-top" style={{ width: '100%', height: 'auto', backgroundImage: ` url(${API_View}/${item.ProductLogo})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center' }}></div>
                                                                        </div>
                                                                        <div className='h-50 d-flex align-items-center bg_grey justify-content-center' style={{ padding: '0px 10px 0px 10px' }}>
                                                                            <div className='d-block'>
                                                                                <p className='f-12 w-100 text-grey fixed_content_3'>
                                                                                    {item.ProductSummary}
                                                                                </p>
                                                                                {/* <div className='stars w-100 pt-3 rating' style={{rating: 4.0 }}></div> */}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </a>
                                                                <div className='content-details px-0'>
                                                                    <a href={`/${item.MarketURL}/`}>
                                                                        <div className='h-50 d-block text-center pt-5 px-3'>
                                                                            <h3 className='text-white content-title f-20 fw-600 mt-0 mb-2 word_wrap'>{item.ProductName}</h3>
                                                                            <p className='content-text f-12 fixed_content pr-0 word_wrap'>
                                                                                {item.ProductTagLine}
                                                                            </p>
                                                                        </div>
                                                                    </a>

                                                                    <div className='container row m-0 h-50 pt-md-4 pt-2 d-flex' style={{ justifyContent: 'center', position: 'relative', alignItems:'center' }}>
                                                                        <div className='col-md-6 col-6 pr-1 mb-0' style={{ backgroudColor: '#fff' }}>
                                                                        <a href={`/${item.MarketURL}/`}>
                                                                                <button className='card_view btn btn-block f-16 border-radius-none enquiry_mobile waves-effect waves-light' >
                                                                                    View
                                                                                </button>
                                                                            </a>
                                                                        </div>
                                                                        <div className='col-md-6 col-6 pr-1 mb-0' style={{ backgroudColor: '#fff' }}>
                                                                            <button type="button" className="card_view btn btn-block f-16 border-radius-none enquiry_mobile waves-effect waves-light"
                                                                                onClick={() => {
                                                                                    Getcompanyname(item.ProductName)
                                                                                    SetProduct(item.ProductName)
                                                                                 }} 
                                                                                //  data-toggle="modal" data-target="#exampleModalLong"
                                                                                >
                                                                                Enquire
                                                                            </button>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                    </div>
                </section><br></br>
                <section className='bg-white pb-4 pt-0'>
                    <div className='container-fluid pb-md-4 px-0'>
                        {Finalsearch.blogs && Finalsearch.blogs.length > 0 ? 
                        <div className='row mx-auto'>
                            <div className='col-md-12'>
                                <div className='testimonial-head'>
                                    <h2 className='mb-md-5 fw-600 mt-md-3 mt-3 mb-4'>Blogs</h2>
                                </div>
                            </div>
                        </div>: "" }
                        <div className='our_team'>
                            <div className='wrapper-large align-center'>
                                <div>
                                <div className='row slide-in-bottom tech_talk_data mx-0'>
						<div className='col-lg-12 col-xl-12 pr-md-3 pl-md-0 tech_talk_view'>
							<div className='row mx-0 mb-4'>
								{Finalsearch.blogs?.map((item, i) => {
									let date = new Date(item.BlogDate).toDateString().slice(4)
									return (
										<div className='col-lg-4 col-md-4 mt-4 px-md-3 px-0 pb-0 tech_talk_view tech_talkList-count'>
											<a href={`/blog/${item.Blogurlname.replace(/ /g, '-').toLowerCase()}/`}>

											 

												<div className='card resource_card'>
													<div className='card-img-top w-100 m-0 ' style={{ backgroundImage: `url(${urlimg}/${item.BlogImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
													</div>
													<div className='card-body bg-lightgrey pb-0 p-3' style={{ overFlow: 'hodden' }}>
														<p className='text-grey f-14 blog_date'>{date}</p>
														<p className='card-title text-black f-18 fw-600 fixed_content mb-2'>
															{item.BlogHeadline}
														</p>
														<p className='card-text text-grey-shade-2 f-14 fixed_content'>
															{item.BlogShortDescription}
														</p>
													</div>
													<div className='card-footer bg-lightgrey border-0 pt-0 p-3'>
														<div className='media align-items-center'>
															<img src={`${urlimg}/${item.AuthorProfileImage}`} className="author_image" />
															<div className='media-body blog_f14 ml-2'>
																<span className="text-grey">by</span>&nbsp;
																<span className="text-black fw-400">{item.AuthorName},</span>&nbsp;&nbsp;
																<span class="text-grey">{item.AuthorDesignation},</span>
																&nbsp;
																<span class="text-grey">{item.AuthorCompanyName}</span>
															</div>

														</div>
													</div>
												</div>
											</a>
										</div>
									)
								})
								}
							</div>
						</div>
						 
					</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <section className='bg-white pb-4 pt-0'>
                    <div className='container-fluid pb-md-4 px-0'>
                        {Finalsearch.Resources && Finalsearch.Resources.length > 0 ? 
                        <div className='row mx-auto'>
                            <div className='col-md-12'>
                                <div className='testimonial-head'>
                                    <h2 className='mb-md-5 fw-600 mt-md-3 mt-3 mb-4'>Resources</h2>
                                </div>
                            </div>
                        </div>:""}
                        <div className='our_team'>
                            <div className='wrapper-large align-center'>
                                <div>
                                

<div className=" active" id="" >
    {/* {console.log("start",report.length<0)} */}
    <div className='row mx-auto home_blog'>
        {Finalsearch.Resources?.map((item, i) => {
             
            return (
                <div key={i} className='col-md-3 mb-4'>
                    <a href={`/resources-details/${item.Resourceurlname.replace(/ /g, '-').toLowerCase()}/`} >
                        <div className='card resource_card_height border border-radius-none'>
                            <div className='w-100 border_lightgrey' style={{
                                backgroundImage: `url(${apiimage}/${item.ResourceImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', backgroundColor: '#000', height: '200px'
                            }}>  </div>
                            <div className='card-body bg-lightgrey p-3'>
                                <p className='card-text f-16 fw-600 text-dark'>
                                    {item.ResourceHeadline}
                                </p>
                            </div>
                        </div>
                    </a>
                </div>
            )

        })}


    </div>
</div>  
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <section className='bg-white pb-4 pt-0'>
                    <div className='container-fluid pb-md-4 px-0'>
                        {Finalsearch.TechTalk && Finalsearch.TechTalk.length > 0 ? 
                        <div className='row mx-auto'>
                            <div className='col-md-12'>
                                <div className='testimonial-head'>
                                    <h2 className='mb-md-5 fw-600 mt-md-3 mt-3 mb-4'>Tech Talks</h2>
                                </div>
                            </div>
                        </div>:""}
                        <div className='our_team'>
                            <div className='wrapper-large align-center'>
                                <div>
                                <div className='row slide-in-bottom tech_talk_data mx-0'>
                        <div className='col-lg-8 col-xl-8 pr-md-3 pl-md-0 tech_talk_view'>
                            <div className='row mx-0 mb-4' >
                                 {
                                    Finalsearch.TechTalk?.map((item, id) => {
                                    let date = new Date(item.TechTalkDate).toDateString().slice(4);
                                        // console.log(item, 'dtata');
                                        return (
                                            <div className='col-lg-6 col-md-6 mt-4 px-md-3 px-0 pb-0  tech_talkList-count' key={id}>
                                                <a href={`/tech-talks/${item?.TechTalkurlname.replace(/ /g, '-').toLowerCase()}/`}>
                                                    <div className='card resource_card'>
                                                        <div className='card-img-top w-100 m-0 ' style={{ backgroundImage: `url(${techurlimg}/${item.TechTalkImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}> </div>
                                                        <div className='card-body bg-lightgrey pb-0 p-3' style={{ overFlow: 'hodden' }}>
                                                            <p className='text-grey f-14'>{date}</p>
                                                            <p className='card-title text-black f-18 fw-600 fixed_content mb-2 techtalk-lineheight'>
                                                                {item.TechTalkHeadline}
                                                            </p>
                                                            <p className='card-text text-grey-shade-2 f-14 fixed_content techtalk-lineheight'>
                                                                {item.TechTalkHeadline}
                                                            </p>
                                                        </div>
                                                        <div className='card-footer bg-lightgrey border-0 pt-0 p-3'>
                                                            <div className='media align-items-center'>
                                                                <img src={`${techurlimg}/${item.AuthorProfileImage}`} className="author_image" />
                                                                <div className='media-body f-14 ml-2'>
                                                                    <span className="text-grey">by</span>&nbsp;
                                                                    <span className="text-black fw-400">{item.AuthorName},</span>&nbsp;&nbsp;
                                                                    <span class="text-grey">{item.AuthorDesignation}</span>&nbsp;&nbsp;
                                                                    <span class="text-grey">{item.AuthorCompanyName}</span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        
                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                {/* news */}
                {
                    Finalsearch.News && Finalsearch.News.length > 0 ? <>
                     <div className='row mx-auto'>
                            <div className='col-md-12'>
                                <div className='testimonial-head'>
                                    <h2 className='mb-md-5 fw-600 mt-md-3 mt-3 mb-4'>News</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0">
           
           {
                                               Finalsearch.News?.map((item, id) => {
                                               let date = new Date(item.NewsDate).toDateString().slice(4);
                                                   
                                                   return (
                                                       <div className='col-lg-4 col-md-4 mt-4 px-md-3 px-0 pb-0  tech_talkList-count' key={id}>
                                                           <a href={`/news/${item?.Newsurlname.replace(/ /g, '-').toLowerCase()}/`}>
                                                               <div className='card resource_card'>
                                                                   <div className='card-img-top w-100 m-0 ' style={{ backgroundImage: `url(${urlimg}/${item.NewsImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center' }}> </div>
                                                                   <div className='card-body bg-lightgrey pb-0 p-3' style={{ overFlow: 'hodden' }}>
                                                                       <p className='text-grey f-14'>{date}</p>
                                                                       <p className='card-title text-black f-18 fw-600 fixed_content mb-2 techtalk-lineheight'>
                                                                           {item.NewsHeadline}
                                                                       </p>
                                                                   
                                                                   </div>
                                                             
                                                               </div>
                                                           </a>
                                                       </div>
                                                   )
                                               })
                                           }
           </div>
                    </>:null
                }
                {/* news end */}
                </>
 {/* }) } */}
                <Testimonial />
                <Team />
                <Footer />

            </div>



        )
}

export default Search;
