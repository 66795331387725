import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Model from './Model';
import axios from "axios";
import DialogBox from './global-components/enquiry-modal';

import { API_URL } from './Api'

const api = `${API_URL}/News`;
const urlimg = `${API_URL}/News_image`;

export default function NewsHomepage(props) {

  // initializing state to store data
  const [Product, SetProduct] = useState("")
  const [Productlink, SetProductlink] = useState("")
  const [NewsData, SetNewsData] = useState([]);
  const [dialog, setDialog] = useState(false)

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 769 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet1: {
      breakpoint: { max: 768, min: 520 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 520, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  //Function call on page mount
  // useEffect(() => {
  //   fetch()
  // }, [])

  
 

// const fetch = async () => {
//     const { data } = await axios.get(api);
//     let sort = data.sort((a, b) => {
//         return (
//             new Date(a.NewsDate) -
//             new Date(b.NewsDate)
//         )
//     }).reverse()
//     SetNewsData(sort.slice(0, 6));
     
   
// }

 
  return (
    <div className="container-flex m-auto">
       
     
       
        
            <div className="row mx-0">
           
{
                                    props.Newsdatas?.map((item, id) => {
                                    let date = new Date(item.NewsDate).toDateString().slice(4);
                                        
                                        return (
                                            <div className='col-lg-4 col-md-4 mt-4 px-md-3 px-0 pb-0  tech_talkList-count' key={id}>
                                                <Link to={`/news/${item?.Newsurlname.replace(/ /g, '-').toLowerCase()}/`}>
                                                    <div className='card resource_card'>
                                                        <div className='card-img-top w-100 m-0 ' style={{ backgroundImage: `url(${urlimg}/${item.NewsImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center' }}> </div>
                                                        <div className='card-body bg-lightgrey pb-0 p-3' style={{ overFlow: 'hodden' }}>
                                                            <p className='text-grey f-14'>{date}</p>
                                                            <p className='card-title text-black f-18 fw-600 fixed_content mb-2 techtalk-lineheight'>
                                                                {item.NewsHeadline}
                                                            </p>
                                                        
                                                        </div>
                                                  
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })
                                }
</div>
<br></br>
<div className="row mx-0">
<div className="col-md-12 mb-md-5 mx-auto d-flex text-center justify-content-center">
          <Link to={"/news/"} className="btn btn-red-primary fw-500 f-20 f-mb-16 border-radius-none px-5 py-3 waves-effect waves-light">View More</Link>
        </div>
</div>
         
    
       
    </div>
  );
}
