import React,{useState,useEffect,useRef}from "react";
import { Link, NavLink, useParams,useHistory } from 'react-router-dom';
import "./index.css";
import { API_URL } from '../../Api'
import axios from "axios";

const Api_View = `${API_URL}/Categories/`;
const Api_subcate = `${API_URL}/SubCategory/cat/`;
const APIGET = `${API_URL}/Searching/`;

export default function Marketplace_Home_Section(props) {
  let history = useHistory();
  const ulref = useRef();
  const inputref = useRef();
  const sub_ulref = useRef();
  const sub_inputref = useRef();
const [forms,setforms]=useState({
  category:"",
  sub_categories:"",
  keyword:""
})
const [categorydatas,setcategorydatas]=useState([])
const [Subcategory,setSubcategory]=useState([])
const [Errormsg,setErrormsg]=useState("")

 

const onchange_forms =async(e)=>{
const {name,value}=e.target;
setforms({
  ...forms,
  [name]:value
})
if(name=="category"){
  setErrormsg("")
  const datss= props.Categorydats.filter((items,index)=>items.CategoryName.toLowerCase().includes(value.toLowerCase()))
  if(datss[0]._id){
    const {data} = await axios.get(`${Api_subcate}/${datss[0]._id}`);
    console.log("llllll",data)
    setSubcategory(data);
  }
}
}


const onSubmit_forms =async(event)=>{
  event.preventDefault();
 
  if(forms.category || forms.keyword){
    const main_category =forms.category?.toLowerCase().replace(/ /g, '-');
    const sub_category =forms.sub_categories?.toLowerCase().replace(/ /g, '-');
    const keyword =forms.keyword;
    if(sub_category){
      window.location.pathname = `marketplace/${main_category}/${sub_category}/`
      
    }else if(main_category){
      setErrormsg("")
      window.location.pathname = `marketplace/${main_category}/`

    }else{
      const Result = await axios.post(`${APIGET}`,{keyword:keyword})
      if(Result.data){
       history.push({
         pathname: `/search`,
         search: `?search=${keyword}/`,
         state: { detail: `${keyword}` }
     });
     // history.push(`/search?search=${Searching.keywords}`)
        
      }
console.log("cat 11",keyword)
    }
    
   
  }else{
    setErrormsg("Please select category ")
  }
}
  return (
    <div className="main-content-marketplace-sec">
      <span className="double-dots"><img src="/assets/img/homepage/marketplace/market_dot.png" width={60} className="img-fluid" alt="the" /></span>      <div className="">
        <div className="container">
          <div className="row contents">
            <div className="col-md-12">
              <h1 className="dark-color">Browse Marketplace</h1>
            </div>
          </div>
          <div className="row mt-5 mb-3 d-flex">
            <div className="col-md-left ">
              <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                <img
                  src="/assets/img/homepage/marketplace/marketplace.png"
                  className="img-fluid w-75"
                  alt="image"
                />
              </div>
            </div>


            <div className="col-md-right">
              <div className="row right-side">
              {props.Categorydats.length > 0 ? <>
              {props.Categorydats.map(items =>{
                return(
                  <div className="colum-div" key={items._id}>
                  <a href={ `/marketplace/${items.CategoryName.replace(/ /g, '-').toLowerCase()}/`} className="text-dark">
                  <div className="card-sec-market">
                    <img
                      src={`${Api_View}/${items.CategoryIcon}`}
                      width={50}
                      alt=""
                    />
                  </div>
                  <p className="text-center mt-2">{items.CategoryName}</p>
                  </a>
                </div>
                )
              })}
              </>:null}
              
             
                <div className="colum-div">
                  <div className="card-sec-market-last">
                   <a href="/marketplace/" className="text-light text-center">EXPLORE <span className="d-block">MARKETPLACE</span></a>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>


          <div className="row mt-5 d-flex align-items-center">
      <div className="col-md-7">
<div className="left-side">
  <h1>Transforming HR.  <br></br> One Trusted Solution at a Time</h1>
</div>
      </div>
      <div className="col-md-5">
    <div className="forms-sec">
      <form onSubmit={onSubmit_forms}>
        {!forms.keyword ? <>
        <div className="row mt-2">
          <div className="col-md-5"> <label>Primary Category</label></div>
          <div className="col-md-7"> 
          <div className="input-ul-sec">
            <select value={forms.category} name="category" onChange={onchange_forms}
            
             >
              <option>Select</option>
              {
              props.Categorydats.map((items,index)=>{
                return(
                   <option value={items.CategoryName}>{items.CategoryName}</option>
                )
              })
            }
            </select>
            <p className="text-danger">{Errormsg}</p>
          {/* <input type="text" value={forms.category} name="category" onChange={onchange_forms} ref={inputref} placeholder="HRIS,HRMS and HCMS" ></input> */}
          {/* dropdown */}
          {/* <div className="dropcatagory">
          <ul id="ul-results" className="list-group" ref={ulref}>
            {
              categorydatas.map((items,index)=>{
                return(
                  <button
                  type="button"
                  key={index}
                  onClick={(e)=>{dropdown_select(items.CategoryName,items._id)}}
                  className="list-group-item list-group-item-action">
                    <span className="button-name-text-trans">{items.CategoryName}</span>
                  </button>
                )
              })
            }

          </ul>
          </div> */}
          {/* dropdown */}
          </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-5"> <label>Sub Categories</label></div>
          <div className="col-md-7"> 
          <div className="input-ul-sec">
          <select value={forms.sub_categories} name="sub_categories" onChange={onchange_forms}
             >
              <option>Select</option>
              {
              Subcategory.map((items,index)=>{
                return(
                   <option value={items.SubCategoryURL} className="">{items.SubCategoryName.toLowerCase()}</option>
                )
              })
            }
            </select>
          {/* <input type="text" value={forms.sub_categories} name="sub_categories" onChange={onchange_forms} ref={sub_inputref} placeholder=""></input> */}
          {/* dropdown */}
          {/* <div className="dropcatagory">
          <ul id="ul-results" className="list-group" ref={sub_ulref}>
            {
              Subcategory.map((items,index)=>{
                return(
                  <button
                  type="button"
                  key={index}
                  onClick={(e)=>{setforms({...forms,sub_categories:items.SubCategoryName.toLowerCase()})}}
                  className="list-group-item list-group-item-action">
                    <span className="button-name-text-trans">{items.SubCategoryName.toLowerCase()}</span>
                  </button>
                )
              })
            }

          </ul>
          </div> */}
          {/* dropdown */}
          </div>
          </div>
        </div></>:null}
        {forms.category || forms.sub_categories ? <>
       
        </>:<> <div className="row mt-2">
          <div className="col-md-5"> <label>Search by Keyword</label></div>
          <div className="col-md-7"> <input type="text" value={forms.keyword} name="keyword" onChange={onchange_forms} placeholder="Name or Keyword"></input></div>
        </div></>}
        <div className="row mt-2">
          <div className="col-md-5"> </div>
          <div className="col-md-7 button-sec"> <button type="submit" className="btn1">SEARCH NOW</button></div>
        </div>
      </form>
    </div>
      </div>
          </div>
        </div>
      </div>
    </div>
  );
}
