import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class HowToWork extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <section className="subscribe_div1 py-1">
  <div className="container-fluid px-0">
    <div className="row d-md-flex align-items-center looking-section">
      <div className="col-sm-8 col-md-8 mb-md-0 mb-3">
        <h2 className="text-black f-28 f-mb-20 fw-bold text_center_mob">
          Looking to list your HRTech product on the Marketplace?
        </h2>
      </div>
      <div className="col-sm-4 col-md-4 text-center">
        <a href='https://docs.google.com/forms/d/1h8o6qFgxRnp9xHjNPDTHxjw8Q5_9988574bDOWj6JLE' >
          <button className="btn btn-red-primary fw-500 f-20 f-mb-16 border-radius-none px-5 py-3 waves-effect waves-light" type="submit" id="mc-embedded-subscribe">
          LIST YOUR PRODUCT
          </button>
        </a>
      </div>
    </div>
  </div>
</section>

        }
}

export default HowToWork