import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NavbarV4 extends Component {

    componentDidMount() {

     const $ = window.$;

     $( 'body' ).removeClass( 'home-3' );

   }
   
    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
			<div className="navbar-area">
        <div className="navbar-top">
          <div className="container">
            <div className="row">
              <div className="col-md-8 text-md-left text-center">
                <ul>
                  <li><p><i className="fa fa-map-marker" /> 2072 Pinnickinick Street, WA 98370</p></li>
                  <li><p><i className="fa fa-envelope-o" />  info@website.com</p></li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="topbar-right text-md-right text-center">
                  <li className="social-area">
                    <a href="/"><i className="fa fa-facebook" aria-hidden="true" /></a>
                    <a href="/"><i className="fa fa-twitter" aria-hidden="true" /></a>
                    <a href="/"><i className="fa fa-instagram" aria-hidden="true" /></a>
                    <a href="/"><i className="fa fa-pinterest" aria-hidden="true" /></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar bg-white navbar-area-1 navbar-area navbar-expand-lg go-top">
          <div className="container nav-container">
            <div className="responsive-mobile-menu">
              <button className="menu toggle-btn d-block d-lg-none" data-target="#MedNExT_main_menu" aria-expanded="false" aria-label="Toggle navigation">
                <span className="icon-left" />
                <span className="icon-right" />
              </button>
            </div>
            <div className="logo">
              <Link to="/"><img src={publicUrl+"assets/img/LOGO.png"} alt="img" /></Link>
            </div>
            <div className="nav-right-part nav-right-part-mobile">
              <Link className="signin-btn" to="/sign-in">Sign In</Link>
              <Link className="btn btn-base" to="/sign-up">Sign Up</Link>
              <a className="search-bar" href="/"><i className="fa fa-search" /></a>
            </div>
            <div className="collapse navbar-collapse" id="MedNExT_main_menu">
            <ul className="navbar-nav menu-open">
                    <li className="menu-item-has-children current-menu-item">
                      <Link to="/">Home</Link>
                      {/* <ul className="sub-menu">
                        <li><Link to="/">Home 01</Link></li>
                        <li><Link to="/home-v2">Home 02</Link></li>
                        <li><Link to="/home-v3">Home 03</Link></li>
                      </ul> */}
                    </li>
                    {/* <li> <Link to="/course">Course</Link></li> */}
                  
                    <li className="menu-item-has-children">
                    <Link to="/course">Course</Link>
                      <ul className="sub-menu">
                        <li className="menu-item-has-children-sub"><Link to="/Knowledge">Anatomy</Link> 
                        <ul className="sub-sub-menu">
                        <li><Link to="/Knowledge">Sub menu11</Link> </li>
                        <li><Link to="/Knowledge">Myocardial infraction</Link></li>
                        <li><Link to="/Knowledge">Diabetes</Link></li>
                        <li><Link to="/Knowledge">Medical Neuroscience</Link></li>
                        <li><Link to="/Knowledge">Dermatology</Link></li>
                        <li><Link to="/Knowledge">Stories Of Infection</Link></li>
                        <li className="menu-item-has-children-sub"><Link>Science Matter</Link>
                        <ul className="sub-sub-menu">
                        <li  ><Link to="/Knowledge">Sub menu11</Link> </li>
                        <li><Link to="/Knowledge">Myocardial infraction</Link></li>
                        <li><Link>Diabetes</Link></li>
                        <li><Link>Medical Neuroscience</Link></li>
                        <li><Link>Dermatology</Link></li>
                        <li><Link>Stories Of Infection</Link></li>
                        <li><Link>Science Matter</Link></li>
                        <li><Link>BioMedical Visualisation</Link></li>
                        <li><Link>Thorocic oncology</Link></li>
                        <li><Link>Antibiotic</Link>
                        
                        </li>
                      </ul></li>
                        <li><Link to="/Knowledge">BioMedical Visualisation</Link></li>
                        <li><Link to="/Knowledge">Thorocic oncology</Link></li>
                        <li><Link to="/Knowledge"> Antibiotic</Link>
                        
                        </li>
                      </ul>
                      </li>

                        <li><Link>Myocardial infraction</Link></li>
                        {/* <li><Link>Diabetes</Link></li>
                        <li><Link>Medical Neuroscience</Link></li>
                        <li><Link>Dermatology</Link></li>
                        <li><Link>Stories Of Infection</Link></li>
                        <li><Link>Science Matter</Link></li>
                        <li><Link>BioMedical Visualisation</Link></li>
                        <li><Link>Thorocic oncology</Link></li>
                        <li><Link>Antibiotic</Link></li> */}
                      </ul>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to="/Knowledge">Knowledge base</Link>
                      <ul className="sub-menu">
                        {/* <li><Link to="/blog">Blog</Link></li>  */}
                        {/* <li><Link to="/blog-details">Blog Details</Link></li> */}
                        <li><Link to="/Discussion">Discussion Forum</Link></li>
                      </ul>
                    </li>
                    <li><Link to="/blog">Blog</Link></li>
                    {/* <li><Link to="/Discussion">Discussion Forum</Link></li> */}
                    <li><Link to="/contact">Contact Us</Link></li>
                  </ul>
            </div>
            <div className="nav-right-part nav-right-part-desktop">
              <Link className="signin-btn" to="/sign-in">Sign In</Link>
              <Link className="btn btn-base" to="/sign-up">Sign Up</Link>
              <a className="search-bar" href="/"><i className="fa fa-search" /></a>
            </div>
          </div>
        </nav>
      </div>

        )
    }
}


export default NavbarV4