import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Navbar from './global-components/navbar';
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';
import Footer from './global-components/footer';
import Counter from './section-components/counter';
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import ReactDatatable from '@ashvin27/react-datatable';
import { API_URL } from './Api'
import { Modal } from 'react-bootstrap'
import {Helmet} from "react-helmet";

const API_Jobs = `${API_URL}/Jobs`;
const API_View = `${API_URL}/Company_image`;

function Jobs() {

    const history = useHistory()
    const [jobs, setJobs] = useState([])
    const [loading, setLoading] = useState(true)
    const [jobData, setJobData] = useState([])
    const [search, setSearch] = useState({
        Company: '',
        Job: '',
        Location: '',
        Type: ''
    })
    const [applyJob, setApplyJob] = useState([])
    const [Email, setEmail] = useState('')
    const [load, setLoad] = useState('')
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false)
        setLoad('')
    }
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (document.location.pathname[document.location.pathname.length - 1] !== '/') {
            history.replace(document.location.pathname + '/');
        }
        GetData()
	GetData11()
    }, [])

    //Get Category from Backend
    const GetData = async () => {
        const Res = await axios.get(`${API_Jobs}/list`)
        
        setLoading(false)
        setJobData(Res.data)
console.log("Res",Res.data)
    }

    const GetData11 = async () => {
        const Res = await axios.get(`${API_Jobs}/list`)
        let sort = Res.data.sort((a, b) => {
            return (
                new Date(a.JobDate) -
                new Date(b.JobDate)
            )
        }).reverse()
        setJobs(sort)
        
    }

    const handleChange = ({ target: { name, value } }) => {
        setSearch({ ...search, [name]: value });
    }

    const handleSearch = async () => {
        const Res = await axios.post(`${API_Jobs}/Search`, search)
        setJobs(Res.data)
    }

    const handleRefresh = () => {
        setSearch({
            Company: '',
            Job: '',
            Location: '',
            Type: ''
        })
    }

    const ApplyJob = async (event) => {
        event.preventDefault()
        setLoad('Processing...')
        const data = await axios.post(`${API_Jobs}/Apply`, 
        { Email: Email,
            JobId:applyJob._id,
            Company:applyJob.CompanyName,
            JobTitle:applyJob.JobTitle,
	    Location:applyJob.Location,
            CompanyUrl:applyJob.CompanyUrl
        })
        if (data) {
            setEmail('')
            setLoad('')
            window.open(`${applyJob.CompanyUrl}`,'_blank');
            handleClose()
        }
    }

    const columns = [
        { key: 'CompanyName', text: 'Company Name', sortable: true  },
        {
            key: "CompanyLogo",
            text: "Logo",
            sortable: true ,
            cell: (record) => {
                return (
                    <div>
                        <img src={`${API_View}/${record.CompanyLogo}`} className='jobtbleimg' alt={record.CompanyName} />
                    </div>
                );
            }
        },
        { key: 'JobTitle', text: 'Job Title', sortable: true },
        { key: 'Location', text: 'Location', sortable: true },
        { key: 'EmploymentType', text: 'Employment Type', sortable: true },
        { key: 'JobDate', text: 'Job Date',sortable: true  },
        {
            key: "action",
            text: "Apply Now",
            sortable: true ,
            cell: (record, index) => {
                return (
                    <div>
                        <button className='btn btncolor' onClick={() => { setApplyJob(record); handleShow() }}>
                            Apply
                        </button>
                    </div>
                );
            }
        }
    ];
    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        language: {
            loading_text: "Please be patient while data loads..."
        }
    }


    return (
        <div>
 
            <Navbar />

            <div className='row '>
    <div className='col-md-12  mt-5'>
<div className='jobs_iframe'>
    <iframe className='mt-4' src="https://jobs.hrtech.sg/" height='900' width="100%"  title="Iframe Example"></iframe>
</div>

    </div>

 </div>
 
            {/* <section className='py-md-5 py-4 title_bg jumbotron jumbotron-fluid text-center m-0 border-bottom ' >
                <div className="container-fluid title-text" >
                    <h1 className="text-center portal fw-700 jobtitle" style={{ wordSpacing: '8px' }} >hrtech.sg Job Portal</h1>
                    <p className='mt-1 text-muted f-13 '>Find the Right HR Technology Job You Deserve!</p>
                    <div className='row text-center'>
                        <div className='col-md-12 col-sm-12'>
                            <form className=''>
                                <div className='justify-content-center row mt-3'>

                                    <div className='col-lg-2 col-md-2 col-sm-6 '>
                                        <div className="form-group" id="exampleFormControlSelect1">
                                            <label htmlFor="exampleFormControlSelect1"></label>
                                            <input className="form-control formFieldInput" type='select' list='comp' placeholder="Select Company" 
                                                style={{fontSize: '15px'}} name="Company" value={search.Company} onChange={handleChange} />
                                            <datalist id='comp'>
                                                {jobData ? <>
                                                    {Array.from(new Set(jobData.sort((a, b) => a.CompanyName.localeCompare(b.CompanyName)).map(item => item.CompanyName))).map((comp, i) => (<option key={i} value={comp}>{comp}</option>))}

                                                </> :
                                                    <option value="">No Data</option>}
                                            </datalist>
                                        </div>

                                    </div>
                                    <div className='col-lg-2 col-md-2 col-sm-6 '>
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlSelect1"></label>
                                            <input className="form-control formFieldInput" type='select' list='job' placeholder="Select Job" 
                                                style={{fontSize: '15px'}} name='Job' value={search.Job} onChange={handleChange} />
                                            <datalist id='job'>
                                                {jobData ? <>
                                                    {Array.from(new Set(jobData.sort((a, b) => a.JobTitle.localeCompare(b.JobTitle)).map(item => item.JobTitle))).map((job, i) => (<option key={i} value={job}>{job}</option>))}

                                                </> :
                                                    <option value="">No Data</option>}
                                            </datalist>
                                        </div>

                                    </div>
                                    <div className='col-lg-2 col-md-2 col-sm-6 '>
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlSelect1"></label>
                                            <input className="form-control formFieldInput" type='select' list='loca' placeholder="Select Location" 
                                                style={{fontSize: '15px'}} name='Location' value={search.Location} onChange={handleChange} />
                                            <datalist id='loca'>
                                                {jobData ? <>
                                                    {Array.from(new Set(jobData.sort((a, b) => a.Location.localeCompare(b.Location)).map(item => item.Location))).map((loc, i) => (<option key={i} value={loc}>{loc}</option>))}

                                                </> :
                                                    <option value="">No Data</option>}
                                            </datalist>
                                        </div>

                                    </div>
                                    <div className='col-lg-2 col-md-2 col-sm-6 '>
                                        <div className="form-group">
                                            <label htmlFor="exampleFormControlSelect1"></label>
                                            <input className="form-control formFieldInput"  placeholder="Select Emp. Type" list='type' name='Type' 
                                                style={{fontSize: '15px'}}value={search.Type} onChange={handleChange}/>
                                                <datalist id='type'>
                                                <option value="Full Time">Full Time</option>
                                                <option value="Part Time">Part Time</option>
                                            </datalist>
                                        </div>

                                    </div>
                                    <div className='col-lg-2 col-md-2 col-sm-6 pb-4'>
                                        <button className="btn btn-success waves-effect mr-2  waves-light m-0 jobsearchbtn" type="button" onClick={handleSearch} style={{ marginRight: '15px' }}><FaSearch className='mt-1' /></button>&nbsp;&nbsp;&nbsp;
                                        <button className="btn btn-success waves-effect mr-2 ml-2 waves-light m-0 jobrefreshbtn" type="button" onClick={handleRefresh}>Refresh</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </section> */}
  {/* <section>
 	<nav>
		<ol className="cd-breadcrumb custom-separator">
			<li><a href="/">Home</a></li>
			 
			<li className="current"><em>Jobs</em></li>
		</ol>
	</nav>
</section>
            <section className='mt-5 mt-5 pb-5'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                          
                            <p><b>Current Openings : {jobs.length}</b></p>
                            <br></br>
                            <div className='card aligntable p-2'>
                                <ReactDatatable
                                    columns={columns}
                                    records={jobs}
                                    config={config}
                                    loading={loading}
                                    style={{overflow:'auto'}}
                                />
                            </div>
                          

                        </div>
                    </div>
                </div>
            </section>
            <hr></hr>
            <section>
                <div className='container pb-3'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <p className='f-14'>
                                Disclaimer: hrtech.sg’s jobs portal is a curated aggregation of relevant best-in-class HR Technology jobs. We constantly review various job boards and company career sites for jobs that might be of keen interest to the users of our platform. These jobs have been published on our jobs portal with or without relevant consent from the respective hiring organizations that have posted these roles. While we will ensure that our jobs portal only hosts active roles, if any of these openings are not active at the time you’re viewing them, please do inform us at maya@hrtech.sg.
                            </p>
                        </div>
                    </div>
                </div>
            </section> */}


            {/* <Counter /> */}
            {/* <Testimonial />
            <Team />
            <Footer />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Apply Job</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={ApplyJob}>
                    <input type="email" className="form-control" placeholder='Enter your Email' value={Email} onChange={(e) => setEmail(e.target.value)} required/>
                    <div className='b-1 p-2 mt-3  ml-auto float-right d-flex align-items-center'>
                        <p className='mr-3'>{load}</p>
                    <button type="button" onClick={handleClose} className="btn btn-outline-danger m-1">Close</button>
                    <button type="submit" value="Submit" className="btn jobcardbutton  m-1">Submit</button>
                    </div>
                    </form>
                </Modal.Body>
            </Modal> */}
        </div>
    )
}

export default Jobs;

