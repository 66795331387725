import React, {useState} from 'react';
import '../news.css';
import { FaAngleUp } from "react-icons/fa";
 
// import { Button } from './Styles';
  
const ScrollButton = () =>{
  
  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    //   alert("> 300")
    } 
    else if (scrolled <= 300){
      setVisible(false)
    //   alert("<= 300")
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  
  window.addEventListener('scroll', toggleVisible);
  
  return (
    <span  className="Topscroll"  style={{display: visible ? 'inline' : 'none', 
     
}} onClick={scrollToTop}  >
   <FaAngleUp />
  </span>
   
  );
}
  
export default ScrollButton;