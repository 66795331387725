import React, { useState, useEffect,useReducer } from 'react';
import { Link ,useParams} from 'react-router-dom';
import { MdMail } from 'react-icons/md';
import Navbar from './global-components/navbar';
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';
import Footer from './global-components/footer';
import TechtalkEditor from './techtalkEditor';
import axios from 'axios';
import { API_URL } from './Api';
import { FaChevronLeft, FaChevronRight, FaLinkedinIn,FaWhatsapp,FaFacebookF,} from "react-icons/fa";
import './news.css';
import { Reducer } from './Reducer';
import {Helmet} from "react-helmet";
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    LinkedinIcon
  } from "react-share";



const api = `${API_URL}/TechTalk`;
const urlimg = `${API_URL}/TechTalk_image`;




const TechtalkView = () => {

    // const Scrolltop =()=>{
    //     window.scrollTo({
    //         top:0,
    //         behavior:"smooth"
    //     })
    // }
    const inital=()=>{}

    const params = useParams();
    const [Scroll, dispatch] = useReducer(Reducer,inital )
    const [result, setresult] = useState([]);
    const [nextResult, setNextResult] = useState([]);
    const [prevResult, setPrevResult] = useState([]);
    const [getdata, setgetdata] = useState(false);
    const [Alldata, setAlldata] = useState([]);
    const [view, setview] = useState(false);
    const [Blogmetadata, setBlogmetadata] = useState([])
	const [Blogmetaname, setBlogmetname] = useState([])

    


    useEffect( () => {

        const product = params.tech.replace(/-/g, ' ')

        const  fetch= async()=>{
       try{
           dispatch({type:"SCROLLTO"})
        const { data } = await axios.get(api)
        setAlldata(data)
        let filter = data?.filter((headline) => headline?.TechTalkurlname?.toLowerCase().includes(product.toLowerCase()))
        setresult(filter[0])
        setgetdata(true)
        const next =data?.filter(dd => dd?.PublishDate > filter[0]?.PublishDate)
        setNextResult(next)
        const prev =  data?.filter(dd => dd?.PublishDate < filter[0]?.PublishDate)
        setPrevResult(prev)
        setview(true)
        GetBlogdata()

       } catch(err){
           console.log(err," error in the fetch data");
       }
        }
        fetch()
        Scroll()

    }, [params.tech, params.product]);

    const GetBlogdata = async () =>{
        debugger
		let Heading = params.tech?.replace(/-/g, ' ')
        debugger
		const blog = await axios.get(api)
        debugger
		console.log(blog,'blog')
		const filter = blog.data.find((person) => person.TechTalkurlname.toLowerCase().includes(Heading.toLowerCase()))
		console.log(filter,"filter")
        debugger
		const Result = await axios.get(`${api}/${filter._id}`)
        debugger
		setBlogmetadata(Result.data)
        debugger
		setBlogmetname(Result.data._id)
		console.log(Result.data,'meta')
	}


    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    if(result.TechTalkTags){
        var str = result.TechTalkTags;
        var Tags = str.split(',');
         
    }
    const datefor=()=>{
        let date= ''
        if(getdata){
          date=  result?.TechTalkDate.split('T')[0]
            date=new Date(date).toDateString().slice(4)
        }
        return date
    }
    if(!getdata){
        return <></>
    }
    else{
        return (
            <div>
   
                <div className='row'>
                <Navbar/>
                </div>
                <br></br>
              
                <section className='blog-page-content mt-5 top_M'>
  <section>
					<nav>
		<ol className="cd-breadcrumb custom-separator" style={{backgroundColor:"#ffffff"}}>
			<li><a href="/">Home</a></li>
			<li><a href="/tech_talks/">Tech Talks</a></li>
			 
			<li className="current"><em>{result?.TechTalkHeadline}</em></li>
		</ol>
	</nav></section>
                    <div className='container-fluid px-md-2  mb-5' >
                        <div className='row px-0 flexbox-wrapper'>
                            <div className='col-sm-8 col-md-8 co-lg-8'>
                                <div className='blog-detail pr-md-3'>
                                    <div className='text-grey f-16 f-mb-16'>{datefor()}</div>
                                    <h1 className='pb-2 text-black fw-600 resource_detail_page_title fr-2'>
                                       <a href={`/tech-talks/${result?.TechTalkurlname.replace(/ /g, '-').toLowerCase()}/`} style={{color:"black"}} >{result?.TechTalkHeadline} </a>
                                    </h1>
                                    <p className='f-blog3-16 short_desc text-grey'>
                                       {result?.TechTalkShortDescription}
                                    </p>
                                    <div className='media align-items-center py-3 px-md-1'>
                                        <div className='author_image author_border border talkimg' style={{ backgroundImage: `url(${urlimg}/${result.AuthorProfileImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} > </div>
                                        <div className="media-body f-12 ml-2">
                                            <span className="text-grey fw-600">by</span>&nbsp;&nbsp;<span className="text-grey fw-600">{result.AuthorName}</span>, <span className="text-grey fw-600">{result.AuthorDesignation}</span>,  <a href={result.AuthorCompanyLink} target={'_blank'} className="fw-600">{result.AuthorCompanyName}</a>
                                        </div>
    
                                    </div>
                                    <div className='style_summernote pt-3'>
                                        
                                          {renderHTML(result.TechtalkStory)}
                                       
                                      
                                    </div>
                                    
                                    <div className='w-100 py-4 px-3 bg-lightgrey mt-md-4 mt-3'>
                                        <div className='media py-0 px-0'>
                                            <div className="footer_author_image author_border border talkimg"  style={{ backgroundImage: `url(${urlimg}/${result.AuthorProfileImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center' }} >
                                            </div>
                                            <div className='media-body f-12 ml-2 ml-md-3'>
                                                <div className='owner_description style_summernote'>
                                                    <p style={{ lineHeight: '1.5rem' }} className='style_summernote pt-3'>
                                                    {renderHTML(result.AuthorDescription)}
                                                    </p>
                                                    <br></br>
                                                </div>
                                            </div>
    
                                        </div>
                                    </div>
                                    <div className='owner_description row mx-0 mt-3'>
                                        <div className='col-md-12 px-0'>
                                        {Tags?.map(item =>{
                                                return(
<p className='ml-0 badge about_services my-1 mr-2 py-1 px-3 border-radius-primary'> {item}</p>
                                                )
                                            })}
                                           
                                        </div>
                                    </div>
                                    <div className="row mx-0">
                                        <div className="col-md-12 px-0 px-md-1 text-md-right d-flex align-items-center">
                                            <p className="text-grey py-3 mr-0 ">Share: </p>
                                            &nbsp; &nbsp;
                                            <div>
                          <WhatsappShareButton url={window.location.href} title={result.TechTalkHeadline} className="share-btn m-1">
                            <WhatsappIcon size={30} round={true} />
                          </WhatsappShareButton>
                          <FacebookShareButton url={window.location.href} title={result.TechTalkHeadline} className="share-btn m-1">
                            <FacebookIcon size={30} round={true} />
                          </FacebookShareButton>
                          <LinkedinShareButton url={window.location.href} title={result.TechTalkHeadline} className="share-btn m-1">
                            <LinkedinIcon size={30} round={true} />
                          </LinkedinShareButton>
                          <EmailShareButton url={window.location.href} title={result.TechTalkHeadline} className="share-btn m-1">
                            <EmailIcon size={30} round={true} />
                          </EmailShareButton>

                        </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12 col-lg-12 col-12 px-0 border_top_table'>
                                            <div className='row m-0 py-4 resource_detail_footer_nav d-md-flex w-100 px-2 px-md-0 border_top_table mb-4 mb-md-0'>
                                        {
                                            prevResult.length>0 ?<div className='col-5 p-0 d-flex align-items-center'>
                                            <a href={`/tech-talks/${prevResult[prevResult.length - 1].TechTalkurlname.replace(/ /g, '-').toLowerCase()}/`} className="d-flex justify-content-start align-items-center w-100">
                                                <div className="media align-items-center">
                                                    <div>
                                                        <FaChevronLeft className='text-grey f-18' />
                                                    </div>
                                                    <div className="media-body ml-4">
                                                        <p className="text-grey f-14 f-mb-12">Previous Post</p>
                                                        <p className="text-black f-16 f-mb-14"> {prevResult[prevResult.length - 1]?.TechTalkHeadline} </p>
                                                    </div>
                                                </div>
                                            </a>

                                        </div>:<></>
                                        }
                                                <div className='col-2 d-flex m-auto justify-content-center'>
                                                    <a href={"/tech-talks/"}>
                                                        <img src='/assets/img/Event/Group1.svg' alt=''></img>
                                                    </a>
                                                </div>

                                                {
                                                    nextResult.length>0? <div className='col-5 p-0 float-right text-right d-flex align-items-center justify-content-end'>
                                                    <a href={`/tech-talks/${nextResult[0].TechTalkurlname.replace(/ /g, '-').toLowerCase()}/`} className="d-block">
                                                        <div className="media align-items-center">
                                                            <div >
                                                                <p className="text-grey f-14 f-mb-12 text-right">Next Post</p>
                                                                <p className="text-black f-16 f-mb-14 text-right">{nextResult[0]?.TechTalkHeadline}</p>
                                                            </div>
                                                            <div className="media-body ml-4">
                                                                <FaChevronRight className='text-grey f-18' />
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>:<></>
                                                }
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-4 col-md-4 col-lg-4 side_bar ec-sidebarWrap '>
                              {
                                  Alldata.length>0?  <TechtalkEditor  TechTalkData={Alldata} view={view} />:<>nodata</>
                              }
                            </div>
                        </div>
                    </div>
                </section>
    
    
                <Testimonial />
                <Team />
                <Footer />
            </div>
        )
    }



    
}


export default TechtalkView