import React, { useState, useEffect, } from 'react';
import { Link, useHistory, useParams, NavLink } from 'react-router-dom';
import Navbar from './global-components/navbar';
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';
import Footer from './global-components/footer';
import { API_URL } from './Api';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import './news.css'



const api = `${API_URL}/Resources/`
const apiimage = `${API_URL}/Resources_image/`
const apicato = `${API_URL}/Categories/`

function SHRM() {

    const [data, setdata] = useState([]);
    const [report, setreport] = useState([]);
    const [getdata, setgetdata] = useState(false);
    const [Category, setCategory] = useState([]);
    const [subcato, setsubcato] = useState(false);
    const [Footerload, SetFooterload] = useState(false)

    const $ = window.$;
    const history = useHistory()
    const params = useParams()


    useEffect(() => {
        setTimeout(() => {
            SetFooterload(true)
          }, 500)
        
    }, []);



 


    const shrm = [{
        image:"https://www.hrtech.sg/assets/img/Bridging.png",
        title:"Bridging of IHRP Certification to SHRM Certification",
        link:"https://www.shrm.org/shrm-india/Pages/IHRP-SHRM-Partnership.aspx"
    },
{
    image:"https://www.hrtech.sg/assets/img/Membershihp.png",
    title:"SHRM Membership",
    link:"https://www.shrm.org/about-shrm/Pages/Membership.aspx"
},{
    image:"https://www.hrtech.sg/assets/img/Technology.png",
    title:"Technology Resources",
    link:"https://www.shrm.org/ResourcesAndTools/hr-topics/technology/Pages/default.aspx"
},{
    image:"https://www.hrtech.sg/assets/img/Certification.png",
    title:'SHRM Certification',
    link:"https://www.shrm.org/certification/Pages/default.aspx",
},{
    image:"hhttps://www.hrtech.sg/assets/img/Education.png",
    title:"SHRM Educational Programs",
    link:"https://www.shrm.org/LearningAndCareer/learning/Pages/EducationalPrograms.aspx"
},{
    image:"https://www.hrtech.sg/assets/img/SHRM.png",
    title:"The 3C's of a Connected Workplace" ,
    link:"https://us02web.zoom.us/webinar/register/5016354017349/WN_QcfHhV8HRVmTtQj2aYLdhw"
                                
}
]

    // render() {


    return (<div>
          
        <Navbar />

        <section className='py-md-5 py-4  jumbotron jumbotron-fluid text-center m-0 border-bottom'>
            <div className="container-fluid market title_bg resource-width" style={{marginTop:" 1%"}}>
                <h1 className="text-center f-28 fw-600" >SHRM</h1>
            </div>

        </section>
        <section>
            <div className='bg-white'>
                <div className='container-fluid pt-5 pb-md-3 px-0'>
                    <div >

                        {/* TABS for filter data  */}
                    

                        {/* selete bar after maxwitdh of 990px   */}
                     
                       

                            <div className=" active" id="" >
                                {/* {console.log("start",report.length<0)} */}
                                <div className='row mx-auto home_blog'>
                                    {shrm?.map((item, i) => {
                                        return (
                                            <div key={i} className='col-md-3 mb-4'>
                                                <a href={item.link} target="_blank" >
                                                    <div className='card resource_card_height border border-radius-none'>
                                                        <div className='w-100 border_lightgrey' style={{
                                                            backgroundImage: `url(${item.image})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', backgroundColor: '#000', height: '200px'
                                                        }}>  </div>
                                                        <div className='card-body bg-lightgrey p-3'>
                                                            <p className='card-text f-16 fw-600 text-dark'>
                                                                {item.title}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    </a>
                                            </div>
                                        )

                                    })}


                                </div>
                            </div>  


                    </div>

                </div>
            </div>
        </section>

        {Footerload ? <>
            <Testimonial />
            <Team />
            <Footer /></>:null}
    </div>
    )

    // }
}

export default SHRM
