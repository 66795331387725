import React from "react";
import "./index.css";
import { API_URL } from '../../Api'
const apiimage = `${API_URL}/Resources_image/`

export default function Resource_Home_pages(props) {
  console.log("nnnnncccmmm",props.Resourcesdatas)
  return (
    <div className="main-content-resource-sec">
       <div className="">
        <div className="container">
          <div className="row contents ">
            <div className="col-md-12">
              <h1 className="dark-color">Resources</h1>
            </div>
          </div>
          <div className="row mt-5 card-sec">
            {props.Resourcesdatas.slice(0,3).map((item,i) =>(
              <div className="col-md-4" key={item._id}>
                <a href={`/resources-details/${item.Resourceurlname.replace(/ /g, '-').toLowerCase()
                                                    }/`}>
              <div className="card p-3 ">
                  <div className="main-content-resource ">
                  {/* <div className="resource-left-side ">
                  <div className="feature-img ">
  <img src={`${apiimage}/${item.ResourceImage}`} className="img-fluid"  />
</div>
                  </div> */}
                     <div className="img-sec mb-3">
                  <div className="img-1">
                    <img
                      src={`${apiimage}/${item.ResourceImage}`}
                      className="img-fluid"
                    ></img>                   
                  </div>
                </div>
                <h4 className="mb-2">{item.ResourceHeadline}</h4>
               

                  {/* <div className="resource-right-side">
                      <h4>{item.ResourceHeadline}</h4>

                  </div> */}
                  </div>

              </div></a>

          </div>
            ))}
                        {/* <div className="col-md-4">
                <div className="card p-4 ">
                    <div className="main-content-resource d-flex">
                    <div className="resource-left-side ">
                    <div className="feature-img ">
    <img src="/assets/img/homepage/marketplace/hrms.png" className="img-fluid"  />
</div>
                    </div>
                    <div className="resource-right-side">
                        <h4>OUTLOOK REPORT</h4>
                    <p>Award winning, all-in-one cloud & mobile- based HR software. Simplify and manage.</p>

                    </div>
                    </div>

                </div>

            </div> */}
                       
           
           
          </div>
          <div className="row mt-3">
            <div className="col-md-12 text-right">
                <div className="btn-sec">
                    <a href="/resources/" className="btn-1">VIEW MORE</a>
                </div>

            </div>

          </div>
          
        </div>
      </div>
    </div>
  );
}
