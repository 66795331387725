import React, { useState, useEffect, useReducer } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { MdMail } from 'react-icons/md';
import Navbar from './global-components/navbar';
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';
import Footer from './global-components/footer';
import Resourceeditorchoice from './Resourceeditorchoice';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { API_URL } from './Api';
import axios from 'axios';
// import Resourceeditorchoice from './Resourceeditorchoice';
import Resourcedownload, { Sucess } from './resource-model';
import { Helmet } from 'react-helmet';
import { Reducer } from './Reducer';
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    LinkedinIcon
  } from "react-share";



const api = `${API_URL}/Resources/`
const apiimage = `${API_URL}/Resources_image/`
function ResourceView() {

    const inital = () => { }

    let publicUrl = process.env.PUBLIC_URL + '/'

    const history = useHistory()
    const params = useParams();

    const [Scroll, dispatch] = useReducer(Reducer, inital)

    const [result, setresult] = useState([]);
    const [nextResult, setNextResult] = useState([]);
    const [prevResult, setPrevResult] = useState([]);
    const [show, setshow] = useState(false);
    const [getdata, setgetdata] = useState(false);
    const [form, setform] = useState(false);
    const [Footerload, SetFooterload] = useState(false)

    useEffect(() => {

        setTimeout(() => {
            SetFooterload(true)
          }, 500)

        try {
            dispatch({ type: "SCROLLTO" })
            const product = params.head.replace(/-/g, ' ')
            const fetch = async () => {
                const  {data}  = await axios.get(api)
                console.log("Getting Resources in ResourceView.js")
                const filter = data.find((headline) => headline.Resourceurlname.toLowerCase().includes(product.toLowerCase()))
	            setresult(filter)
                console.log(filter,'filter')
                setgetdata(true)
                const next = data?.filter(dd => dd?.PublishDate > filter?.PublishDate)
                setNextResult(next)
                const prev = data?.filter(dd => dd?.PublishDate < filter?.PublishDate)
                setPrevResult(prev)
            }
            fetch()
            Scroll()
        }
        catch (err) {
            console.log(err, 'error in the ResourseView')
        }



    }, [params.head]);

    const showmodel = () => {
        setshow(true)
    }
    const showoff = () => {
        setshow(false)
    }
    const datefor = () => {
        let date = ''
        if (getdata) {
            date = result?.ResourceDate?.split('T')[0]
            date = new Date(date).toDateString().slice(4)
        }
        return date
    }
    const sucessform = () => {
        setform(true)
    }
    const unsucessform = () => {
        setform(false)
    }

    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    return (
        <div>
            <div className='row'>
                <div className='col-md-12'>
 
                    <Navbar />
                </div>
            </div>
            <br></br>
            <Resourcedownload show={show} hide={showoff} sucess={sucessform} productid={result?._id} />

            <Sucess onshow={form} onHide={unsucessform} />

            <section className='blog-page-content mt-5'>
 <section>
					<nav>
		<ol className="cd-breadcrumb custom-separator" style={{backgroundColor:"#ffffff"}}>
			<li><a href="/">Home</a></li>
			<li><a href="/resources/">Resources</a></li>
			 
			<li className="current"><em>{result?.ResourceHeadline}</em></li>
		</ol>
	</nav></section>

                <div className='container-fluid px-md-2 blog_detail_container mt-3 '>

                    <div className='row px-0 flexbox-wrapper'>
                        <div className='col-sm-8 col-md-8 co-lg-8'>
                            <div className='blog-detail pr-md-3'>
                                <div className='text-grey f-16 f-mb-16'>{datefor()}</div>
                                <h1 className='pb-2 text-black fw-600 resource_detail_page_title'>
                                    {result?.ResourceHeadline}
                                       {console.log(result?.ResourceHeadline,"result?.ResourceHeadline")}
                                </h1>
                                <div className='style_summernote pt-3'>
									{renderHTML(result?.ResourceDescription)}
									
									</div>
                                <div >
                                    {Footerload ?
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='loader_div d-none'>
                                                <img src='/assests/img/resources/loder.gif' className='loader_gif'></img>
                                            </div>
                                            <div className='resource_detail_page_image my-3 border'>
                                                <div className='broucher_div d-flex flex-column text-center'>
                                                    <img src={`${apiimage}/${result?.ResourceImage}`} className='broucher_view'></img>

                                                </div>
                                                <div className="p-3 d-md-flex justify-content-between align-items-center mt-2">
                                                    <div className="mb-3 mb-md-0">
                                                        <p className="font-weight-bold f-16 mb-0"> {result?.ResourceHeadline}</p>
                                                    </div>
                                                    <div>
                                                        <a href="javascript:void(0)" className="btn btn-red-primary-outline border-radius-none  download_brochure waves-effect waves-light" id="download_brochure" onClick={() =>
                                                            showmodel()
                                                        }>Download Resource</a>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                     :null}
                                    {Footerload ? <>
                                    <div className="row mx-0">
                                        <div className="col-md-12 px-0 px-md-1 text-md-right d-flex mt-5">
                                            <p className="text-grey py-3 mr-0 ">Share: </p>
                                            <div className="pl-3 text-right share_event mt-1 text-grey">
                                            
                                            <div>
                          <WhatsappShareButton url={window.location.href} title={result.ResourceHeadline} className="share-btn m-1">
                            <WhatsappIcon size={30} round={true} />
                          </WhatsappShareButton>
                          <FacebookShareButton url={window.location.href} title={result.ResourceHeadline} className="share-btn m-1">
                            <FacebookIcon size={30} round={true} />
                          </FacebookShareButton>
                          <LinkedinShareButton url={window.location.href} title={result.ResourceHeadline} className="share-btn m-1">
                            <LinkedinIcon size={30} round={true} />
                          </LinkedinShareButton>
                          <EmailShareButton url={window.location.href} title={result.ResourceHeadline} className="share-btn m-1">
                            <EmailIcon size={30} round={true} />
                          </EmailShareButton>

                        </div>
                                            </div>
                                        </div>

                                        <div className='row col-12'>
                                            <div className='col-md-12 col-lg-12 col-12 px-0 border_top_table'>
                                                <div className='row m-0 py-4 resource_detail_footer_nav d-md-flex w-100 px-2 px-md-0 border_top_table mb-4 mb-md-0'>


                                                    {
                                                        prevResult.length > 0 ? <div className='col-5 p-0 d-flex align-items-center'>
                                                            <a href={`/resources-details/${prevResult[prevResult.length - 1].Resourceurlname.replace(/ /g, '-').toLowerCase()}/`}
                                                                className="d-flex justify-content-start align-items-center w-100">
                                                                <div className="media align-items-center">
                                                                    <div>
                                                                        <FaChevronLeft className='text-grey f-18' />
                                                                    </div>
                                                                    <div className="media-body ml-4">                                                                <p className="text-grey f-14 f-mb-12">Previous Post</p>
                                                                        <p className="text-black f-16 f-mb-14">{prevResult[prevResult.length - 1]?.ResourceHeadline}</p>
                                                                    </div>
                                                                </div>
                                                            </a>

                                                        </div> : <div className="col-5"></div>
                                                    }


                                                    <div className='col-2 d-flex m-auto justify-content-center'>
                                                        <a href={"/resources/"}>
                                                            <img src='/assets/img/Event/Group1.svg' alt=''></img>
                                                        </a>
                                                    </div>
                                                    {
                                                        nextResult.length > 0 ? <div className='col-5 p-0 float-right text-right d-flex align-items-center justify-content-end'>
                                                            <a href={`/resources-details/${nextResult[0].Resourceurlname.replace(/ /g, '-').toLowerCase()}/`} className="d-block">
                                                                <div className="media align-items-center">
                                                                    <div >
                                                                        <p className="text-grey f-14 f-mb-12 text-right">Next Post</p>
                                                                        <p className="text-black f-16 f-mb-14 text-right">{nextResult[0].ResourceHeadline}</p>
                                                                    </div>
                                                                    <div className="media-body ml-4">
                                                                        <FaChevronRight className='text-grey f-18' />
                                                                    </div>
                                                                </div>
                                                            </a>

                                                        </div> : <div className="col-5"></div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div></>:null}
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-4 col-md-4 col-lg-4 side_bar ec-sidebarWrap'>

                            <Resourceeditorchoice />

                        </div>
                    </div> </div>
            </section>
            <div className='marbottom'></div>
            {Footerload ? <>
            <Testimonial />
            <Team />
            <Footer /></>:null}

        </div>

    )

    // }
}

export default ResourceView
