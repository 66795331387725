import React from "react";
import "./index.css";
import { API_URL } from '../../Api';
const API_View = `${API_URL}/MarketPlace_image`;

export default function Feature_Product(props) {
  // Sort Productdata by ProductName
  const sortedProductData = [...props.Productdata].sort((a, b) => a.ProductName.localeCompare(b.ProductName));

  return (
    <div className="main-content-feature-product-sec">
       <div className="">
        <div className="container">
          <div className="row contents ">
            <div className="col-md-12">
              <h1 className="dark-color">Featured Products</h1>
            </div>
          </div>
          <div className="row mt-5 card-sec">
            {sortedProductData.map((item, i) => {
                return (
                     <div className="col-md-3" key={i}>
                        <a href={`/${item.MarketURL}/`}>
                <div className="card p-4">
                    
<div className="feature-img mb-4">
    <img src={`${API_View}/${item.ProductLogo}`} className="img-fluid" />
</div>
<p>{item.ProductSummary}</p>
                </div></a>

            </div>
                )
            })}
           
           
          </div>
          
        </div>
      </div>
    </div>
  );
}

