import React, { useEffect, useState } from 'react';
import Navbar from '../global-components/navbar';
import Articles from './Articles';
import Footer from '../global-components/footer';
import Testimonial from '../section-components/testimonial';
import Team from '../section-components/team';

const SingUpPage = () => {
    const [Footerload, SetFooterload] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            SetFooterload(true)
          }, 500)
         
         
    }, [])
    return (
        <div>
            <Navbar />
            <Articles />
            {Footerload ? <>
            <Testimonial />
            <Team />
            <Footer />
            </>:null}
        </div>
    )
}

export default SingUpPage