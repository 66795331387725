import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar';
import Banner from './section-components/banner';
import Intro from './section-components/intro';
import About from './section-components/about';
import Resource from './ResourceHomepage';
import Blog from './BlogHomepage';
import Event from './EventHomepage';
import NewsHome from './NewsHomepage';
import Techtalk from './TechTalksHome';
import CourseFilter from './section-components/course-filter';
import FunFact from './section-components/fun-fact';
import HowToWork from './section-components/how-to-work';
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';
import Footer from './global-components/footer';
import Counter from './section-components/counter';
import {Helmet} from "react-helmet";
import axios from "axios";
import FeaturedCarosel from './FeaturedCarosel';
import { API_URL } from './Api'


const api = `${API_URL}/News/alldata/website`;
const Homepageapi = `${API_URL}/homepagewebsite`;
const Home_V1 = () => {
  // const [NewsData, SetNewsData] = useState([]);
  const [Homedata, SetHomedata] = useState([]);
  useEffect(() => {
    // fetch();
    fetchHome();
  }, [])

  
// const fetch = async () => {
//   const { data } = await axios.get(api);
  
//   SetNewsData(data);
   
 
// }
const fetchHome = async () => {
  const { data } = await axios.get(Homepageapi);
  console.log("tuuuu",data)
  SetHomedata(data);
   
 
}
    return <div>
            {console.log("gggg",Homedata)}
        <Navbar />
       
        {typeof Homedata.Banner !== "undefined" && Homedata.Banner.length > 0 ? <>
        <Banner Bannerdatas={Homedata.Banner} />
        </>:null} 
        
        <HowToWork />
        {typeof Homedata.Category !== "undefined" && Homedata.Category.length > 0 ? <>
        
        <CourseFilter Categorydats={Homedata.Category}/>
        </>:null} 

     <div className='carosel-web'>
     {typeof Homedata.FeaturedProd !== "undefined" && Homedata.FeaturedProd.length > 0 ? <>

      <About Productdata={Homedata.FeaturedProd} />
      </>:null} 

      </div>
      {typeof Homedata.FeaturedProd !== "undefined" && Homedata.FeaturedProd.length > 0 ? <>
<div className='carosel-mob'>
        <div class="row mx-auto">
            <div class="col-md-12">
              <div class="testimonial-head my-md-5 my-4">
                <h2 class="wow flipInY f-mb-20 f-28 fw-600 testimonial-st">
                  Featured Products
                </h2>
              </div>
            </div>
          </div>
     

            <FeaturedCarosel Productdata={Homedata.FeaturedProd} />
    

        </div>  </>:null} 

        {/* resources Start */}
     {typeof Homedata.Resourcesdata !== "undefined" && Homedata.Resourcesdata.length > 0 ? <>

        <section
        class="" 
        id="marketplace"
        style={{backgroundColor:"#edeff0"}}
      >
        <div class="container px-0">
          <div class="row mx-auto">
            <div class="col-md-12">
              <div class="testimonial-head my-md-5 my-4">
                <h2 class="wow flipInY f-mb-20 f-28 fw-600 testimonial-st">
                  Resources
                  </h2>
              </div>
            </div>
          </div>

          <Resource Resourcesdatas={Homedata.Resourcesdata} />
     
        </div>
        {/* <Model Product={this.state.ProductName} /> */}
      </section>
      </>:null} 

        {/* resources end */}

        {/* Blog start */}
     {typeof Homedata.Blog !== "undefined" && Homedata.Blog.length > 0 ? <>

        <section
        class=""
        id="marketplace"
        
      >
        <div class="container px-0">
          <div class="row mx-auto">
            <div class="col-md-12">
              <div class="testimonial-head my-md-5 my-4">
                <h2 class="wow flipInY f-mb-20 f-28 fw-600 testimonial-st">
                Blogs
                  </h2>
              </div>
            </div>
          </div>

          <Blog Blogdatas={Homedata.Blog}/>

        </div>
      
      </section>
      </>:null} 

        {/* Blog end */}

        {/* Event Start */}
     {typeof Homedata.Event !== "undefined" && Homedata.Event.length > 0 ? <>

        <section
        class=""
        id="marketplace"
        style={{backgroundColor:"#edeff0"}}
      >
        <div class="container px-0">
          <div class="row mx-auto">
            <div class="col-md-12">
              <div class="testimonial-head my-md-5 my-4">
                <h2 class="wow flipInY f-mb-20 f-28 fw-600 testimonial-st">
               Events
                  </h2>
              </div>
            </div>
          </div>

          <Event Eventdatas={Homedata.Event}/>

        </div>
      </section>
      </>:null} 

        {/* Event End */}

        {/* Tech talks start */}
     {typeof Homedata.Techtalk !== "undefined" && Homedata.Techtalk.length > 0 ? <>

        <section
        class=""
        id="marketplace"
        
      >
        <div class="container px-0">
          <div class="row mx-auto">
            <div class="col-md-12">
              <div class="testimonial-head my-md-5 my-4">
                <h2 class="wow flipInY f-mb-20 f-28 fw-600 testimonial-st">
                Tech Talks
                  </h2>
              </div>
            </div>
          </div>

          <Techtalk Techtalkdatas={Homedata.Techtalk} />

        </div>
      
      </section>
      </>:null} 

        {/* Tech talks end */}
        {/* News Start */}
        {typeof Homedata.Newsdata !== "undefined" && Homedata.Newsdata.length > 0 ? <>

          <section
        class=" "
        id=" "
        style={{backgroundColor:"#edeff0"}}
      >
        <div class="container px-0">
          <div class="row mx-auto">
            <div class="col-md-12">
              <div class="testimonial-head my-md-5 my-4">
                <h2 class="wow flipInY f-mb-20 f-28 fw-600 testimonial-st">
               News
                  </h2>
              </div>
            </div>
          </div>
          <NewsHome Newsdatas={Homedata.Newsdata} />
        </div>
      </section>
        </>:null}
     
        {/* News end */}
 
        
        {/* <Counter /> */}
        <Testimonial />
        <Team />
        <Footer />
    </div>
}

export default Home_V1

