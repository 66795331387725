import React, { Component } from 'react';
class EventDetails extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="course-single-area pd-top-100 pd-bottom-20s">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-12 order-lg-12">
		        <div className="event-detaila-inner">
					<div className='row'>
					<div className='col-lg-6'>
					<div className="thumb mb-4">
		            <img src={publicUrl+"assets/img/other/5.jpg"} alt="img" />
		          </div>
					</div>
		          <div className='col-lg-6'>
		        
		          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea</p>
		          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem</p>
				  </div>
				  </div>
		          <div className="row pt-4 pb-4">
		            <div className="col-lg-5">
		              <div className="thumb mb-3 mb-lg-0">
		                <img src={publicUrl+"assets/img/course/8.jpg"} alt="img" />
		              </div>
		            </div>
		            <div className="col-lg-7 align-self-center">
		              <h6>Quality Control System</h6>
		              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod</p>
		              <h6>Highly Professional Staff</h6>
		              <p className="mb-0">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod</p>
		            </div>
		          </div>
		          <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea</p>
		        </div>
		      </div>
		   
		    </div>
		  </div>
		</div>

        }
}

export default EventDetails