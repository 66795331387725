import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
// import TechtalkEditor from './techtalkEditor';
import '../../components/news.css';
import './certificate.css'
import axios from 'axios';
import { API_URL } from '../Api';
import { FaRegStar } from "react-icons/fa";
import {Link} from "react-router-dom"
import {Helmet} from "react-helmet";
import Navbar from '../global-components/navbar';
import Testimonial from '../section-components/testimonial';
import Team from '../section-components/team';
import Footer_v1 from '../global-components/footer';

const URL = `${API_URL}/certification/getall/website`;
const URL_BOTTOM = `${API_URL}/certification_bottom/website`;
const urlimg = `${API_URL}/Blog_image`;

const Certifications = () => {

	const history = useHistory()

	const [Blogdata, setBlogdata] = useState([]);
	const [Blogdata_bottom, setBlogdata_bottom] = useState([]);
	const [readMore, setReadMore] = useState(false);
	

	React.useEffect(() => {
		GetData();
		GetData_bottom();
		if (document.location.pathname[document.location.pathname.length - 1] !== '/') {
			history.replace(document.location.pathname + '/');
		  }
		 
	}, [])


	const GetData = async () => {
		// const res = await axios.get(URL)
		// setBlogdata(res.data)
		const { data } = await axios.get(URL)
		console.log("uuuu",data)
		let sort = data.sort((a, b) => {
			return (
				new Date(a.created) -
				new Date(b.created)
			)
		}).reverse()
		setBlogdata(sort.slice(0, 12))
		 
	}
	const GetData_bottom = async () => {
	 
		const { data } = await axios.get(URL_BOTTOM);
		console.log("gggggg",data)
		setBlogdata_bottom(data)
		 
	}


	
	const linkName = readMore ? 'View Less' : 'View All';
	const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

	return (
		<div>
   <Navbar />
			
			<section className='py-md-5 py-4 title_bg jumbotron jumbotron-fluid text-center m-0 border-bottom'>
				<div className="container-fluid " style={{ marginTop: '4%' }}>
					<h1 className="text-center f-28 fw-600" >HR & HR Tech Certifications</h1>
				</div>

			</section>
<section>
 	<nav>
		<ol className="cd-breadcrumb custom-separator">
			<li><a href="/">Home</a></li>
			 
			<li className="current"><em>HR & HR Tech Certifications</em></li>
		</ol>
	</nav>
</section>
			<section className='bg-white'>
				<div className='container-fluid pt-5 pb-md-3 px-0'>
					<div className='row slide-in-bottom tech_talk_data mx-0'>
						<div className='col-lg-12 col-xl-12 pr-md-3 pl-md-0 tech_talk_view'>
							<div className='row mx-0 mb-4 justify-content-center'>
								
								{Blogdata?.map((item, i) => {
									// let date = new Date(item.BlogDate).toDateString().slice(4)
									return (
										 <>
										
										<div className='col-lg-3 col-md-3 mt-4 px-md-3 px-0 pb-0 tech_talk_view tech_talkList-count' key={item._id}>
											<a href={`${item.certification_url}`} target="_blank">

												{/* <Link to={{
												pathname: `/blog/${item.BlogHeadline.replace(/ /g, '-')}/`
											     }}> */}
												{/* <Link to='/blogdetails'> */}

												<div className='card resource_card'>
													<div className=' w-100 m-0 '
													//  style={{ backgroundImage: `url(${urlimg}/${item.certification_Image})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}
													 >
														<img src={`${urlimg}/${item.certification_Image}`} className='img-fluid'></img>
													</div>
													<div className='card-body bg-lightgrey pb-0 p-3' style={{ overFlow: 'hodden' }}>
                                                    {/* <div className='media align-items-center'>
															<img src={`${urlimg}/${item.author_Logo}`} className="author_cert_image" />
															<div className='media-body blog_f14 '>
						
																<span class="text-grey ml-2 f-15">{item.author_Name}</span>
															</div>

														</div> */}
														<p className='card-title text-black f-15 fw-600 fixed_content_cert mt-3 mb-2'>
															{item.certification_Name}
														</p>
														<p className='card-text text-grey-shade-2 f-12 fixed_content' >
															{item.brief_Summary}
														</p>
														 
														 <div className='mt-4 ml-5 text-center'>
														<a href={`${item.certification_url}`} className='mt-5 ' target="_blank"><div className="carousel_text register_btn" style={{width:"70%"}}><p>View more </p></div></a>
													</div>
													</div>
													{/* <div className='card-footer bg-lightgrey border-0 pt-0 p-3'>
														<div className='media align-items-center'>
															<img src={`${urlimg}/${item.AuthorProfileImage}`} className="author_image" />
															<div className='media-body blog_f14 ml-2'>
						
																<span class="text-grey">{item.AuthorCompanyName}</span>
															</div>

														</div>
													</div> */}
												</div>
											</a>
										</div>
										</>
									)
								})
								}

 

 
							</div>
							<div className='row'>
								<div className='col-md-2'></div>
								<div className='col-md-3'></div>
							<div className='col-md-4 pt-2 pb-3'>
	{/* <div className='d-inline-flex justify-center view-all pt-1' >
		{readMore ? <>
			<button onClick={DataLess} className="btn btn-red-primary border-radius-none f-16 f-mb-14 mt-1 px-5 waves-effect waves-light"  >
              {linkName}
            </button>
		
		</>:<>
		<button  onClick={DataRead}className="btn btn-red-primary border-radius-none f-16 f-mb-14 mt-1 px-5 waves-effect waves-light"  >
              {linkName}
            </button>
	
		</>}
	</div> */}
</div>
							</div>
						</div>
						{Blogdata_bottom.length > 0 && Blogdata_bottom[0].certification_bottom_content ? <>
  <div className='card border-radius-none mb-3'>
                                            {/* <div className='card-header py-3 bg-white align-items-center d-flex'>
                                                <h2 className='f-18 fw-600 w-100'>
                                                    EVENT AGENDA
                                                </h2>
                                            </div> */}
                                            <div className='card-body row m-0 style_summernote'>
                                                <p>
                                                
                                                 {renderHTML(Blogdata_bottom[0].certification_bottom_content)}
                                                </p>



                                            </div>
                                        </div>
</>:null}
						
					</div>
				</div>
			</section >
            <Testimonial />
            <Team />
            <Footer_v1 />
		</div >
	)
}

export default Certifications
