import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import axios from "axios";

import { API_URL } from '../Api'

const Api = `${API_URL}/Categories/`;
const Api_View = `${API_URL}/Categories/`;

function CourseFilter(props) {

  // initializing state to store data
  const [data,setData]=useState([])

  //Function call on page mount
  useEffect(()=>{
    
  },[])
 
  let publicUrl = process.env.PUBLIC_URL + '/'

  return (
 <section className="marketplace_bg p-md-0 pt-3 pb-5 marketplace_image">
    <div className="container-fluid pl-mb-0 px-md_7rem pt-md-2rem px-md-2 pl-mob-0">
      <div className="row mx-auto">
        <div className="col-md-12">
          <div className="testimonial-head mb-md-5 mt-md-2 my-3">
            <h2 className="f-28 text-white f-mb-20 fw-600">Browse Marketplace</h2>
          </div>
        </div>
      </div>
      <div className="row m-mb-0 mob-row">
        {props.Categorydats.length > 0 ? <>
          {props.Categorydats.map(item => {
            return (
              <div className="col-lg-3 col-md-3 col-sm-4 col-6 index_margin blog_new_location mb-30 pr-mb-0 mb-18">
                <a href={ `/marketplace/${item.CategoryName.replace(/ /g, '-').toLowerCase()}/`}>
                  <div className="card border-radius-none bg-transparent heigh-mob">
                    <div className="card-body resource-card-body marketplace_body">
                      <div className="adjust_detail or_btm_clr index_blog_card w-100 testimonial-head">
                        <div className="p-3 h-6 mb-md-3 mb-0">
                          <img src={`${Api_View}/${item.CategoryIcon}`} className="w-100 h_100 black_icon none" alt={item.CategoryName} />
                          <img src={`${Api_View}/${item.CategoryIcon}`} className="w-100 h_100 white_icon d-none" alt={item.CategoryName} id="HRMS" />
                        </div>
                        <h4 className="text-black fw-normal f-18 f-mb-14 word_wrap">
                          {item.CategoryName}
                        </h4>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            )
          })}
        </> : null}
      </div>
    </div>
    <div className="container-fluid">
      <div className="row mx-auto">
        <div className="col-md-12">
          <div className="testimonial-head my-4">
          </div>
        </div>
        <div className="col-md-12 mb-md-5 mx-auto d-flex text-center justify-content-center">
          <Link to={"./marketplace/"} className="btn btn-red-primary fw-500 f-20 f-mb-16 border-radius-none px-5 py-3 waves-effect waves-light">Explore Marketplace</Link>
          {/* <a className="btn btn-red-primary fw-500 f-20 f-mb-16 border-radius-none px-5 py-3 waves-effect waves-light" href="#">Explore Marketplace</a> */}
        </div>
      </div>
    </div>
  </section>

  )
}

export default CourseFilter
