import React, { useEffect, useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { Link} from 'react-router-dom';
import { API_URL } from '../Api';
import axios from 'axios'; 


const URLBlog = `${API_URL}/Blog`;
const URLResource = `${API_URL}/Resources`;
const urlimg = `${API_URL}/Blog_image`;
const urlResourceimage = `${API_URL}/Resources_image`;

const URLEvent = `${API_URL}/Events`;

const Counter = () => {

  const [RecentBlog, SetRecentBlog] = useState([]);
  const [RecentResource, SetRecentResource] = useState([]);
  
  useEffect(() => {
    fetchDataBlog();
    fetchDataResource();
    
  }, []);
  
  const fetchDataBlog = async () => {
  
    axios.get(`${URLBlog}`).then((res) => {
      let sort = res.data.sort((a, b) => {
        
          return (
              new Date(a.BlogDate) -
              new Date(b.BlogDate)
          )
      }).reverse()
      var dataBlogs = sort.slice(0,3);
      SetRecentBlog(dataBlogs)
       
  })
    
 
  }
  const fetchDataResource = async () => {
    const { data } = await axios.get(URLResource);
    SetRecentResource(data);
     

    axios.get(`${URLResource}`).then((res) => {
      let sort = res.data.sort((a, b) => {
          return (
              new Date(a.ResourceDate) -
              new Date(b.ResourceDate)
          )
      }).reverse()
      var dataResource = sort.slice(0,3);
      SetRecentResource(dataResource)
      
  })
    
 
  }

  return null;

}


export default Counter
