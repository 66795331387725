import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from 'react-router-dom'

import Navbar from './global-components/navbar';
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';
import Footer from './global-components/footer';
import { API_URL } from './Api'
import Model from './Model';
import Carousel from './Carousel';
import axios from "axios";
import { Helmet } from "react-helmet";
import moment from 'moment';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon
} from "react-share";
import DialogBox from "./global-components/enquiry-modal";
import DialogBoxDownload from "./global-components/download-modal";

const API_Key_Product = `${API_URL}/MarketPlace`;
const Product_images = `${API_URL}/MarketPlace_image`;
const Api = `${API_URL}/EnquireForm`;
const Api_review = `${API_URL}/ProductReview`;
const Api_Subscribe = `${API_URL}/Subscribe`;



export default function View() {

  const history = useHistory()
  const params = useParams();
  const location = useLocation();
  const ProductId = location.state

  const [showing, Setshowing] = useState(false)
  const [dialog, setDialog] = useState(false)
  const [dialogDownloads, setdialogDownloads] = useState(false)
const [hiddenmarket, sethiddenmarket] = useState(false)
  const [Product, SetProduct] = useState([])
  const [Reviewget, SetReviewget] = useState([])
  const [ReviewgetID, SetReviewgetID] = useState([])
  const [enquiry, setEnquiry] = useState({
    Name: "",
    Company: "",
    Email: "",
    ContactNumber: "",
    Country: "",
    Message: "",
    EnquiryFor: Product.ProductName
  })
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [starrating, setstarrating] = useState();
  const [subscriberlist, setsubscriberlist] = useState();
  const [Review, setReview] = useState({
    reviewmsg: '',
    reviewname: '',
    reviewemail: ''

  });
  const [Download, setDownload] = useState({
    Name: '',
    Email: '',
  });
  const [Subscribe, setSubscribe] = useState({
    Name: '',
    Email: '',
  });
  const [Reviewerror, setReviewerror] = useState('');
  const [Product_id, SetProduct_id] = useState('');
  const [ProductName, SetProductName] = useState('');
  const [Successtitle, setSuccesstitle] = useState('We have sent the resource file to your email. ')
  const [loading, Setloading] = useState(false)
  const [scrolling, setscrolling] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      Setloading(true)
    }, 1000)

    GetProduct();
    if (document.location.pathname[document.location.pathname.length - 1] !== '/') {
      history.replace(document.location.pathname + '/');
    }
    // scroll
    window.addEventListener('scroll', isSticky);
    return () => {
        window.removeEventListener('scroll', isSticky);
    };
  }, [params.product])


  const isSticky = (e) => {
   
    const scrollTop = window.scrollY;
    if(scrollTop >= 1){
      setscrolling(true)
    }else{
      setscrolling(false)
    }
     
};


  const GetProduct = async () => {
    const product = params.product

    const Prod = await axios.get(`${API_Key_Product}`)
    const filter = Prod.data.filter((person) => person.MarketURL==product)
    const Result = await axios.get(`${API_Key_Product}/${filter[0]._id}`)
    SetProduct(Result.data)
    SetProduct_id(Result.data._id)

    const Review = await axios.get(`${Api_review}/Approval/${Result.data._id}`)
    SetReviewget(Review.data)
    var sum = 0;
    Review.data.map(items => {
      sum += parseFloat(items.rating)
    })
    var toalstar = sum / Review.data.length;

    setstarrating(toalstar.toFixed(1))
    setsubscriberlist(Review.data.length)

    // }
  }


  const handleChange = ({ target: { name, value } }) => {
    setEnquiry({ ...enquiry, [name]: value });
  }

  const sentEnquiry = (event) => {
    event.preventDefault();

    let data =
    {
      Name: enquiry.Name,
      Companyname: enquiry.Companyname,
      Email: enquiry.Email,
      Contactnumber: enquiry.ContactNumber,
      Country: enquiry.Country,
      Message: enquiry.Message,
      Productid: Product.ProductName,
	MarketURL: Product.MarketURL,
    }
    axios.post(`${Api}`, data).then(res => {
      // this.props.history.push("/Training")
      setEnquiry({
        ...enquiry,
        Name: "",
        Companyname: "",
        Email: "",
        Contactnumber: "",
        Country: "",
        Message: "",
        ProductName: ""
      })
      window.location.reload(true);
    })
  }

  const ReviewSubmit = (event) => {
    event.preventDefault();

    let data =
    {
      reviewmsg: Review.reviewmsg,
      reviewname: Review.reviewname,
      reviewemail: Review.reviewemail,
      rating: rating,
      Productid: Product_id,
    }
    axios.post(`${Api_review}`, data).then(res => {

      setReview({
        ...enquiry,
        reviewmsg: "",
        reviewname: "",
        reviewemail: "",

      })
      setRating('')
      window.location.reload(true);
    })
  }

  const DownloadSubmit = (event) => {
    event.preventDefault();

    let data =
    {
      Name: Download.Name,
      Email: Download.Email,

    }


    axios.post(`${API_Key_Product}/download/${Product_id}`, data).then(res => {

      setDownload({
        ...Download,
        Name: "",
        Email: "",


      })
      setRating('')
      history.push({ pathname: '/enquire/success', state: Successtitle })
    })
  }

  const SubscribeSubmit = (event) => {
    event.preventDefault();

    let data =
    {
      Name: Subscribe.Name,
      Email: Subscribe.Email,
      Productid: Product_id

    }


    axios.post(`${Api_Subscribe}`, data).then(res => {

      setSubscribe({
        ...Download,
        Name: "",
        Email: "",


      })
      setRating('')
      window.location.reload(true);
    })
  }

  const onchangereview = ({ target: { name, value } }) => {
    setReview({ ...Review, [name]: value });
  }

  const onchangeDownload = ({ target: { name, value } }) => {
    setDownload({ ...Download, [name]: value });

  }

  const onchangeSubcribe = ({ target: { name, value } }) => {
    setSubscribe({ ...Subscribe, [name]: value });

  }

  const ReviewClick = () => {
    if (Review.reviewmsg) {

      setReviewerror(null)

    } else {
      setReviewerror("* This field is required")
    }

  }
  const Getcompanyname = data => {
    SetProductName(data)
     if(document.location.pathname =="/deel/"){
      window.open('https://www.deel.com/partners/hrtech-sg', '_blank');
      
    }else{
      setDialog(true)

    }
  }
  const Getcompanynamedownload = data => {
    SetProductName(data)
    setdialogDownloads(true)
  }



  const fullheight = {
    height: 'auto'
  }

  const sliceheight = {
    height: 220,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }

  const dialogClose = () => {
    setDialog(false)
  }
  const dialogdownloadClose = () => {
    setdialogDownloads(false)
  }
  const sliderhide =()=>{
    sethiddenmarket(true)
     
  }

console.log("bbb",Product)

  const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
 
  return (<>
    <div>
   
      <Navbar />


      <DialogBox show={dialog} title={ProductName} dialogClose={dialogClose} />
      <DialogBoxDownload show={dialogDownloads} title={ProductName} ProductIDs={Product_id} dialogClose={dialogdownloadClose} />

      {Product ?

        <>
        {/* without star */}
        {scrolling ? 
        <section className="position-fixed w-100 bg-white stick-head">
          <div className="px-1 filter_fixed w-100  d-mb-none stick-style" id="header_fix">
  <div className="container-fluid">
    <p id="this_slug" className="d-none">grabjobs</p>
    <div className="d-block border-0 p-0">
      <div className="media">
        <div className="media-body">
          <div className="row m-0">
            <div className="w-50 w-mb-100 mobile-wid">
              <h2 className="media-heading f-28 f-mb-20 text-black mb-md-2 mb-1 d-flex fw-600">{Product.ProductName}
                <span className="ml-md-2 ml-1 d-flex">
                </span>
              </h2>
              <p className="f-14 f-mb-12">{Product.ProductTagLine} </p>
            </div>
            <div className="w-50 align-self-end d-md-block d-none">
              <div className="text-md-right mt-md-0 mt-3 px-0 pl-md-3">
                <button className="btn ml-2 btn-red-primary border-radius-none px-4 f-18 rel_enquiry" style={{height: 50}} data-toggle="modal" data-target="#feedbackform"
                onClick={() => {
                  Getcompanyname(Product.ProductName)
                  SetProductName(Product.ProductName)
                }}
                >Enquire
                  {/* <p className="d-none rel_slug">grabjobs</p> */}
                  {/* <p className="d-none rel_name">GrabJobs</p> */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 d-md-none px-0 d-flex mt-3 px-0">
        <div className="row m-0 w-100">
          <div className="col-6 pr-0 pl-0">
            <button className="btn btn-red-primary w-100 border-radius-none m-0 f-16 rel_enquiry" data-toggle="modal" data-target="#feedbackform">Enquiry
              {/* <p className="d-none rel_slug">grabjobs</p>
              <p className="d-none rel_name">GrabJobs</p> */}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
          </section>:
           
          <section className=" w-100 stick-head" >
          

            <div className="border-bottom   margintop pb-4 jumbotron jumbotron-fluid m-0 bg-white detail_header_mobile w-mb-100" id="mobile_fixed">
              <div className="px-1 filter_fixed w-100 d-none d-mb-none" id="header_fix" datasqstyle="{&quot;top&quot;:null}" datasquuid="99faad15-4105-4de6-8660-14c0e930afc1" style={{ top: 46 }} datasqtop={46}>
                <div className="container-fluid pt-5">
                  <p id="this_slug" className="d-none">{Product.ProductName}</p>
                  <div className="d-block border-0 p-0">
                    <div className="media">
                      <div className="media-body">
                        <div className="row m-0">
                          <div className='col-12 col-md-12 col-sm-12'>
                            <div className="w-50 w-head w-mb-100 mobile-wid">
                              <h2 className="media-heading f-28 f-mb-20 text-black mb-md-2 mb-1 d-flex fw-600">{Product.ProductName}
                                <span className="ml-md-2 ml-1 d-flex">
                                </span>
                              </h2>
                              <p className="f-14 f-mb-12 ">{Product.ProductTagLine}</p>

                            </div>


                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 d-md-none px-0 d-flex mt-3 px-0">
                      <div className="row m-0 w-100">
                        <div className="col-6 pr-0 pl-0">
                          <button className="btn btn-red-primary w-100 border-radius-none m-0 f-16 rel_enquiry waves-effect waves-light"
                            // data-toggle="modal" data-target="#feedbackform"
                            onClick={() => {
                              Getcompanyname(Product.ProductName)
                              SetProductName(Product.ProductName)
                            }}
                          >Enquiry
                            <p className="d-none rel_slug">{Product.ProductName}</p>
                            <p className="d-none rel_name">{Product.ProductName}</p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid px-md-0">
                <p id="this_slug" className="d-none">{Product.ProductName}</p>
                <div className="d-block border-0 p-0">
                  <div className="media">
                    <div id="mp_image" className="resource_list_image mr-3 mt-1 border" style={{ backgroundImage: `url(${Product_images}/${Product.ProductLogo})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center' }}>
                    </div>
                    <div className="media-body">
                      <div className="row m-0">
                        <div className="w-50 w-mb-100 mobile-wid">
                          <h1 className="media-heading f-28 f-mb-20 text-black mb-md-2 mb-1 d-flex fw-600">{Product.ProductName}
                            <span className="ml-md-2 ml-1 d-flex">
                            </span>
                          </h1>
                          <p className="f-14 f-mb-12 mobile-font-size">{Product.ProductTagLine}</p>
                          {starrating && starrating !== "NaN" ? 
                          <div className="d-flex align-items-baseline" >
                          <div className="star-rating-count ">
                            {[...Array(5)].map((star, index) => {
                              index += 1;
                              return (
                                <button
                                  key={index}
                                  className={index <= (starrating) ? "on" : "off"}

                                >
                                  <span className="star" >&#9733;</span>

                                </button>
                              );
                            })}
                          </div>
                            <p className="Starcount"> {starrating} ({subscriberlist})</p>
                            </div>:null}
                        </div>

                        <div className="w-50 align-self-end d-md-block d-none">
                          <div className="text-md-right mt-md-0 mt-3 px-0 pl-md-3">
                            <button className="btn ml-2 btn-red-primary border-radius-none px-4 f-18 rel_enquiry waves-effect waves-light" style={{ height: 50 }}
                              // data-toggle="modal" data-target="#exampleModalLong"
                              onClick={() => {
                                Getcompanyname(Product.ProductName)
                                SetProductName(Product.ProductName)
                              }}
                            >Enquire
                              <p className="d-none rel_slug">{Product.ProductName}</p>
                              <p className="d-none rel_name">{Product.ProductName}</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 d-md-none px-0 d-flex mt-3 px-0">
                    <div className="row m-0 w-100">
                      <div className="col-6 pr-0 pl-0">
                        <button className="btn btn-red-primary w-100 border-radius-none m-0 f-16 rel_enquiry waves-effect waves-light"
                          // data-toggle="modal" data-target="#feedbackform"
                          onClick={() => {
                            Getcompanyname(Product.ProductName)
                            SetProductName(Product.ProductName)
                          }}
                        >Enquiry
                          <p className="d-none rel_slug">{Product.ProductName}</p>
                          <p className="d-none rel_name">{Product.ProductName}</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          }

          
          {loading ? <>
            <section className="bg-lightgrey  pb-5 detail_content_mobile" id="mobile_view_content" style={{paddingTop:scrolling?"15.5rem":"1.5rem"}}>
              <div className='container-fluid px-0'>
                <div className='row'>
                  <div className='col-md-4 mb-3 pl-md-2 mob-cont'>
                    <div className="card">
                      <div className="card-body">
                        <h2 className="border-bottom pb-2 f-18 fw-600">{Product.ProductHighlightsTitle}</h2>
                        <ol className="custom-counter">
                          <li><span className="py-2 f-14">{Product.ProductHighlights1}</span></li>
                          <li><span className="py-2 f-14">{Product.ProductHighlights2}</span></li>
                          <li><span className="py-2 f-14">{Product.ProductHighlights3}</span></li>
                        </ol>
                        <div className="list-wrap">



                        </div>
                        <p className="card-text" />
                      </div>
                      <div className="card-body">
                        <h2 className="border-bottom pb-2 f-18 fw-600">{Product.KeyInfoTitle}</h2>
                        {/* <div className="row pt-3 m-0">
                          <div className="col-md-8 col-12 px-0">
                            <p className="data_point f-14 fw-400">Eligible for Singapore Govt. Grants</p>
                          </div>
                          <div className="col-md-4 col-12 px-0">
                            <p className="data_point_content text-grey f-14"> {Product.EligibleforSingapore}</p>
                          </div>
                        </div> */}
                        <div className="row pt-3 m-0">
                          <div className="col-md-8 col-12 px-0">
                            <p className="data_point f-14 fw-400">Free Trial Period</p>
                          </div>
                          <div className="col-md-4 col-12 px-0">
                            <p className="data_point_content text-grey f-14"> {Product.FreeTrialPeriod}</p>
                          </div>
                        </div>
                        <div className="row pt-3 m-0">
                          <div className="col-md-8 col-12 px-0">
                            <p className="data_point f-14 fw-400">Free Version</p>
                          </div>
                          <div className="col-md-4 col-12 px-0">
                            <p className="data_point_content text-grey f-14"> {Product.FreeVersion}</p>
                          </div>
                        </div>

                        {/* <div className="row pt-3 m-0">
                          <div className="col-md-8 col-12 px-0">
                            <p className="data_point f-14 fw-400">Min. Price/User Per Month ( S$ )</p>
                          </div>
                          <div className="col-md-4 col-12 px-0">
                            <p className="data_point_content text-grey f-14"> {Product.MinPriceUserPerMonth}</p>
                          </div>
                        </div> */}

                        <div className="row pt-3 m-0">
                          <div className="col-md-8 col-12 px-0">
                            <p className="data_point f-14 fw-400">Mobile App</p>
                          </div>
                          <div className="col-md-4 col-12 px-0">
                            <p className="data_point_content text-grey f-14">{Product.MobileApp}</p>
                          </div>
                        </div>

                        <div className="row pt-3 m-0">
                          <div className="col-md-8 col-12 px-0">
                            <p className="data_point f-14 fw-400">Product Website</p>
                          </div>
                           <div className="col-md-4 col-12 px-0">
                           <p className="data_point f-14 fw-400">
                           <a target="_blank" href={Product.ProductWebsiteLink} style={{color:"#004AAD"}}>{Product.ProductName}</a>
                           </p>
                          </div>
                          
                        </div>
                        <p className="card-text" />
                      </div>
                      <div className="card-body">
                        <div className="p-0 d-flex align-items-center">
                          <button className="btn btn-block btn-red-primary border-radius-none px-4 f-18 rel_enquiry waves-effect waves-light" style={{ height: 50 }} type='button'
                            // data-toggle="modal" data-target="#exampleModalLong"
                            onClick={() => {
                              Getcompanyname(Product.ProductName)
                              SetProductName(Product.ProductName)
                            }}
                          >Enquire
                            <p className="d-none rel_slug">{Product.ProductName}</p>
                            <p className="d-none rel_name">{Product.ProductName}</p>
                          </button>
                        </div>
                      </div>
                      <div className="card-body py-2 border-top d-flex align-items-center share_event">
                        <p className="f-14 fw-600 w-50 pt-1">Share</p>
                        <div>
                          <WhatsappShareButton url={window.location.href} title={Product.ProductName} className="share-btn m-1">
                            <WhatsappIcon size={30} round={true} />
                          </WhatsappShareButton>
                          <FacebookShareButton url={window.location.href} title={Product.ProductName} className="share-btn m-1">
                            <FacebookIcon size={30} round={true} />
                          </FacebookShareButton>
                          <LinkedinShareButton url={window.location.href} className="share-btn m-1">
                            <LinkedinIcon size={30} round={true} />
                          </LinkedinShareButton>
                          <EmailShareButton url={window.location.href} className="share-btn m-1">
                            <EmailIcon size={30} round={true} />
                          </EmailShareButton>

                        </div>

                      </div>
                    </div>

                    {Product.ProductBrochure !== "undefined" && Product.ProductBrochure ? <div className="card mt-3 d-md-block ">
                      <div className="container-fluid px-md-0 w-100">
                        <div className="row m-0 py-3 pl-3">
                          <div className="col-lg-4 col-md-8 col-sm-12 col-2 d-flex px-0 text-right">
                            <div className="border_lightgrey m-auto brochure_img CompanyBrochure" >
                              {/* <img className="" src={`${Product_images}/${Product.ProductBrochure}`} width="100px" height='150px' alt /> */}
                              <iframe
                                style={{ width: "140px", height: "176px" }}

                                src={`${Product_images}/${Product.ProductBrochure}#toolbar=0&scrollbar=0`}
                                type='application/pdf'

                                title='title'
                              />
                            </div>
                          </div>
                          <div className=" col-lg-8 col-md-8 col-sm-12 col-6 align-self-end CompanyBrochureone">
                            <div className="card-body p-0" style={{ position: 'relative' }}>
                              <h4 className="media-heading f-16 text-black mb-md-3 mb-2 fw-600">Company Brochure</h4>
                              <button className="btn btn-red-primary-outline mb-md-3 mb-2 border-radius-none   download_brochure waves-effect waves-light" id="download_brochure" style={{ marginTop: '1rem!important' }} type='button' target="_blank"
                                onClick={() => {
                                  Getcompanynamedownload(Product.ProductName)

                                }} >Download</button>
                              <div className="modal fade" id="downloadpdf" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-toggle="modal" data-target="#downloadpdf">
                                <div className="modal-dialog" role="document">
                                  <div className="modal-content bg-lightgrey">
                                    <div className='row'>
                                      <div className="col-md-12">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true" style={{ float: 'left', position: 'absolute', right: '3%', top: '-5px' }}>×</span>
                                        </button>
                                      </div>
                                    </div>
                                    <div className="modal-body " style={{ padding: '0 25px', alignIitems: 'center', height: '100%', margin: '80px' }}>


                                      <div className="row">
                                        <div className="col-12 pb-4">
                                          <h3 className="f-26 m-0" style={{ color: '#004AAD', marginBottom: 0 }}>LOGIN WITH</h3>
                                          <p className="f-18 text-grey">To Download PDF need to login</p>
                                        </div>
                                      </div>

                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> : null}


                  </div>
                  <div className='col-md-8 pl-md-1 pr-md-2'>


                    {((Product.ProductVideoLinks !== "")&&(typeof Product.ProductVideoLinks !== "undefined") || (typeof Product.ProductImages !== "undefined")&&(Product.ProductImages.length != 0))?
                      <div className="card">
                        <div className="row card-body">
                          <div className="col-md-12">
                            <div id="custCarousel" className="row d-flex carousel slide" align="center">
                              <div className="col-md-10 col-sm-9 px-0 col-12 mb-md-2 mb-3">
                                <div className=" carousel-inner">
                                  {Product.ProductVideoLinks ?
                                    <div className="carousel-item active"> <iframe width='100%' height='420' src={`${Product.ProductVideoLinks}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> </div>
                                    : <>
                                     <div className="carousel-item active"  > <img src={`${Product_images}/${Product.ProductImages[0]}`} alt="Hills" /> </div>
                                      
                                    </>}
                                  {Product.ProductImages ? <>
                                    {Product.ProductImages.map((item, i) => {
                                      console.log("start111")
                                      return (<div className="carousel-item" key={i}> <img src={`${Product_images}/${item}`} alt="Hills" /> </div>
                                      )
                                    })}
                                  </> : null}
                                </div>
                              </div>

                              <div className="col-md-2 d-block side_img_div px-0">
                                <ol className=" carousel-indicators list-inline">
                                  {Product.ProductVideoLinks  ?
                                    <li className="list-inline-item active"> <a id="carousel-selector-0" href="#" className="selected" data-slide-to="0" data-target="#custCarousel"> <img src="/assets/img/videoicon.png" className="img-fluid" alt="video" /> </a> </li>
                                    :                                     
                                    <li className="list-inline-item active" key={1} style={{display:hiddenmarket ? "none" : "block"}}> <a id={`carousel-selector-${ + 1}`} data-slide-to={ + 1} data-target="#custCarousel" href="#"> <img src={`${Product_images}/${Product.ProductImages[0]}`} className="img-fluid"   /> </a> </li>
                                    }
                                  {Product.ProductImages.length > 0 ? <>
                                    {Product.ProductImages.map((item, i) => {
                                      console.log("start333")
                                      return (<li className="list-inline-item " key={i}> <a id={`carousel-selector-${i + 1}`} data-slide-to={i + 1} data-target="#custCarousel" href="#"> <img src={`${Product_images}/${item}`} className="img-fluid" alt={`${item}`} onClick={sliderhide}/> </a> </li>
                                      )
                                    })}
                                  </> :
//                                    <>
//                                   <li className="list-inline-item active"> <a id="carousel-selector-0" href="#" className="selected" data-slide-to="0" data-target="#custCarousel"> <img src="/assets/img/videoicon.png" className="img-fluid" alt="video" /> </a> </li>
// </>
null
                                  }
                                </ol></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      : null}


                    <div className="card mb-3 mt-2">
                      <div className="card-body">
                        <h2 className="border-bottom pb-3 f-18 fw-600">{Product.ProductSummaryTitle}</h2>
                        <div className="row pt-3 m-0">
                          <div className="col-md-12 col-12 px-0 style_summernote">
                            <p className="text-grey">{Product.ProductSummary}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-3 card-prod">
                      <div className="card-body">
                        <h2 className="border-bottom pb-3 f-18 fw-600">{Product.ProductDetailsTitle}</h2>
                        <div className="row pt-3 m-0">
                          <div className="col-md-12 col-12 px-0">
                            <div className="text_editor_content more style_summernote" data-readmore aria-expanded="false" id="rmjs-1" style={showing ? fullheight : sliceheight}>
                              <span itemProp="description">

                                {renderHTML(Product.ProductDetails)}
                              </span>
                            </div><button className="btn btn-link mb-0 mt-2 text-left f-14 text-primary-red pl-0" data-readmore-toggle="rmjs-1" aria-controls="rmjs-1" onClick={() => Setshowing(!showing)}>{showing ? 'Read Less' : 'Read More'}</button>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-3 mob-card">
                      <div className="border-bottom card-body p-3 position-relative">
                        {/* <div className="loader_div">
      <img src="/site_media/img/loader_gif.gif" className="loader_gif" width="45px" />
    </div> */}
                        <h5 className="pb-2 f-14 fw-600 text-grey">Rate this product</h5>

                        <div className="star-rating">
                          {[...Array(5)].map((star, index) => {
                            index += 1;
                            return (
                              <button

                                key={index}
                                className={index <= (hover || rating) ? "on" : "off"}
                                onClick={() => { setRating(index) }}
                                onMouseEnter={() => setHover(index)}
                                onMouseLeave={() => setHover(rating)}
                              >
                                <span className="star">&#9733;</span>
                              </button>
                            );
                          })}
                        </div>



                        <form id="ratingForm">
                          <input type="hidden" name="csrfmiddlewaretoken" defaultValue="INWn7wDxlGq0JEOtUP0bK5v83DSzBjxZQzNCkALV7e0ROP6qSYfusgbWtQD3nHlT" />
                          <div className="row m-0">

                            <p className="review_error_msg error_msg_rating text-red" />
                            <div className="col-md-12 px-0 card-body pt-4 h-100 pb-2 border-top mt-3">
                              <h5 className="pb-2 f-14 fw-600 text-grey">Review</h5>
                              <div className="login_alert">
                                <textarea rows={4} id="review" className=" w-100 border p-2 mb-3" placeholder="Write a review" name="reviewmsg" onChange={onchangereview} value={Review.reviewmsg} />
                                <p className="error_msg_comments text-red">{Reviewerror}</p>
                              </div>
                              <button className="submit clearfix btn-red-primary-outline border-radius-none float-right mb-0" onClick={ReviewClick} data-toggle="modal" data-target={Review.reviewmsg.length > 0 ? "#exampleModalReview" : ""} id="reviewsubmit" type="button">Submit</button>
                            </div>
                            <p className="review_error_msg error_msg_comments text-red" />
                          </div>
                        </form>

                      </div>
                    </div>
                          {starrating && starrating !== "NaN"  ?
                    <div className="card mb-3 mob-card">
                      <div className="border-bottom card-body p-3 position-relative">
                        {/* <div className="loader_div">
      <img src="/site_media/img/loader_gif.gif" className="loader_gif" width="45px" />
    </div> */}
                        <h5 className="pb-2 f-14 fw-600 text-grey">Reviews</h5>



                        <div>
                          {Reviewget.map((getreview) => {
                            return (
                              <div>
                                <div className="container-fluid">
                                  <div className="row mt-5 d-flex g-3">
                                    <div className="col-md-1 col-sm-1">
                                      <div className="text-center avator" ><p>{getreview.reviewname.charAt(0).toUpperCase()}</p></div>
                                    </div>

                                    <div className="col-md-10 col-sm-6 mt-2">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <p><b>{getreview.reviewname}</b>&nbsp;<small className="text-muted">{moment(getreview.created).format('MMMM Do YYYY')}</small></p>
                                          <div className="star-rating1">
                                            {[...Array(5)].map((star, index) => {
                                              index += 1;
                                              return (
                                                <button
                                                  key={index}
                                                  className={index <= (getreview.rating) ? "on" : "off"}

                                                >
                                                  <span className="star" >&#9733;</span>
                                                </button>
                                              );
                                            })}
                                          </div>
                                        </div>


                                        <div className="col-md-12">
                                       <p className="mt-1" style={{textAlign:"justify",lineHeight:"24px"}}>{getreview.reviewmsg}</p>

                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            )

                          })}

                        </div>



                      </div>
                    </div>:null }


                  </div>
                </div>
              </div>
            </section>
          <Carousel Category={Product} />
          </> : null}
        </>
        : <></>
      }
      {loading ? <>
        <Testimonial />
        <Team />
        <Footer />
      </> : null}
      <Model Product={Product.ProductName} />

      <div className="modal fade" id="exampleModalLong" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-toggle="modal" data-target="#exampleModal">
        <div className="modal-dialog" role="document">
          <div className="modal-content bg-lightgrey">
            <div className="modal-header">

              <div className="col-md-12">
                <h3 className="no_margin fw-600 text-center">Enquire</h3>
                <p className="mb-30 text-grey text-center" id="brand_name">{Product.ProductName}</p>

              </div>
              <div className='row'>
                <div className="col-md-12">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" style={{ float: 'right', position: 'absolute', right: '50%', top: '-5px' }}>×</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-body ">


              <form className="form-field" name="sample" onSubmit={sentEnquiry}>
                <input type="text" name="Name" required placeholder="Your Name" className="form-control" id="id_name" onChange={handleChange} value={enquiry.Name} />
                <p><span className="has_error" style={{ color: 'red', paddingLeft: '-58px', fontSize: 14 }} /></p>

                <input type="text" name="Companyname" required placeholder="Your Company Name" onChange={handleChange} className="form-control" id="id_company_name" value={enquiry.Companyname} />
                <p><span className="has_error" style={{ color: 'red', paddingLeft: '-58px', fontSize: 14 }} /></p>

                <input type="email" name="Email" required placeholder="Your Email Id" className="form-control" id="id_email" onChange={handleChange} value={enquiry.Email} />
                <p><span className="has_error" style={{ color: 'red', paddingLeft: '-58px', fontSize: 14 }} /></p>

                <input type="text" name="Country" placeholder="Your Country" className="form-control" id="id_country" onChange={handleChange} value={enquiry.Country} />

                <input type="text" name="Contactnumber" maxLength={15} minLength={6} placeholder="Your Contact Number (with country code)" className="form-control" id="id_contact_number" value={enquiry.Contactnumber} onChange={handleChange} />

                <textarea name="Message" width="100%" rows={5} id="id_message" required placeholder="Your Message" cols={40} className="form-control h-100" value={enquiry.Message} onChange={handleChange} />
                <p><span className="has_error" style={{ color: 'red', paddingLeft: '-58px', fontSize: 14 }} /></p>

                <div className="row">
                  <div className="col-12">
                    <button type="submit" className="btn btn-red-primary border-radius-none btn-head px-5 m-0 mt-3 waves-effect waves-light" id="send_btn">Submit</button>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>
      <div className="modal fade" id="exampleModalReview" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-toggle="modal" data-target="#exampleModal">
        <div className="modal-dialog" role="document">
          <div className="modal-content bg-lightgrey">
            <div className="modal-header">

              <div className="col-md-12">
                <h3 className="no_margin fw-600 text-center">Review</h3>
                {/* <p className="mb-30 text-grey text-center" id="brand_name">{Product.ProductName}</p> */}

              </div>
              <div className='row'>
                <div className="col-md-12">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" style={{ float: 'right', position: 'absolute', right: '50%', top: '-5px' }}>×</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-body ">


              <form className="form-field" name="sample" onSubmit={ReviewSubmit}>
                <input type="text" name="reviewname" required placeholder="Your Name" className="form-control" id="id_name" onChange={onchangereview} value={Review.reviewname} />
                <p><span className="has_error" style={{ color: 'red', paddingLeft: '-58px', fontSize: 14 }} /></p>



                <input type="email" name="reviewemail" required placeholder="Your Email Id" className="form-control" id="id_email" onChange={onchangereview} value={Review.reviewemail} />
                <p><span className="has_error" style={{ color: 'red', paddingLeft: '-58px', fontSize: 14 }} /></p>

                <div className="row">
                  <div className="col-12">
                    <button type="submit" className="btn btn-red-primary border-radius-none btn-head px-5 m-0 mt-3 waves-effect waves-light" id="send_btn">Submit</button>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>
      {/* download modal */}
      <div className="modal fade" id="exampleModalDownload" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-toggle="modal" data-target="#exampleModal">
        <div className="modal-dialog" role="document">
          <div className="modal-content bg-lightgrey">
            <div className="modal-header">

              <div className="col-md-12">
                <h3 className="no_margin fw-600 text-center">Details</h3>
                {/* <p className="mb-30 text-grey text-center" id="brand_name">{Product.ProductName}</p> */}

              </div>
              <div className='row'>
                <div className="col-md-12">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" style={{ float: 'right', position: 'absolute', right: '50%', top: '-5px' }}>×</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-body ">


              <form className="form-field" name="sample" onSubmit={DownloadSubmit}>
                <input type="text" name="Name" required placeholder="Your Name" className="form-control" id="id_name" onChange={onchangeDownload} value={Download.Name} />
                <p><span className="has_error" style={{ color: 'red', paddingLeft: '-58px', fontSize: 14 }} /></p>



                <input type="email" name="Email" required placeholder="Your Email Id" className="form-control" id="id_email" onChange={onchangeDownload} value={Download.Email} />
                <p><span className="has_error" style={{ color: 'red', paddingLeft: '-58px', fontSize: 14 }} /></p>

                <div className="row">
                  <div className="col-12">
                    <button type="submit" className="btn btn-red-primary border-radius-none btn-head px-5 m-0 mt-3 waves-effect waves-light" id="send_btn">Submit</button>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>
      {/*  Subscribe */}
      <div className="modal fade" id="exampleModalSubscribe" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-toggle="modal" data-target="#exampleModal">
        <div className="modal-dialog" role="document">
          <div className="modal-content bg-lightgrey">
            <div className="modal-header">

              <div className="col-md-12">
                <h3 className="no_margin fw-600 text-center">Subscribe</h3>


              </div>
              <div className='row'>
                <div className="col-md-12">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" style={{ float: 'right', position: 'absolute', right: '50%', top: '-5px' }}>×</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-body ">


              <form className="form-field" name="sample" onSubmit={SubscribeSubmit}>
                <input type="text" name="Name" required placeholder="Your Name" className="form-control" id="id_name" onChange={onchangeSubcribe} value={Subscribe.Name} />
                <p><span className="has_error" style={{ color: 'red', paddingLeft: '-58px', fontSize: 14 }} /></p>



                <input type="email" name="Email" required placeholder="Your Email Id" className="form-control" id="id_email" onChange={onchangeSubcribe} value={Subscribe.Email} />
                <p><span className="has_error" style={{ color: 'red', paddingLeft: '-58px', fontSize: 14 }} /></p>

                <div className="row">
                  <div className="col-12">
                    <button type="submit" className="btn btn-red-primary border-radius-none btn-head px-5 m-0 mt-3 waves-effect waves-light" id="send_btn">Submit</button>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>

    </div>
  </>)

}
