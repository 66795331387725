import React, { Component } from "react";
import Model from '../Model';
import HomeFeature from "../homefeature";

class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      ProductName:''
    };
  }

  Getcompanyname =data=>{
    console.log("function",data)
    this.setState({
      ProductName:data
    })
  }
 
  render() {
console.log("propsprops",this.props.Productdata)
    return (
      <section
        class="testimonial-area pt-0 pb-md-5 pb-5 mb-md-0 mb-2"
        id="marketplace"
      >
        <div class="container px-0">
          <div class="row mx-auto">
            <div class="col-md-12">
              <div class="testimonial-head my-md-5 my-4">
                <h2 class="wow flipInY f-mb-20 f-28 fw-600 testimonial-st">
                  Featured Products
                </h2>
              </div>
            </div>
          </div>
          <HomeFeature Productsdatas={this.props.Productdata}/>
        </div>
        <Model Product={this.state.ProductName} />
      </section>
    );
  }
}

export default About;
