/*--------------------Package--------------------*/
import React, { useEffect, useState } from "react";
import axios from "axios";
import {Link } from "react-router-dom";
import Navbar from "./global-components/navbar";
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';
import Footer from './global-components/footer';
/*--------------------APIURL--------------------*/
import { API_URL } from "./Api";
import "../components/Glossary.css"
const API_Glossary = `${API_URL}/hrglossary/website`;
// import { API_Glossary, CUURENT_URL, SITENAME } from "@/Config/api";

/*--------------------Css--------------------*/
// import Style from "/src/styles/glossary.module.css";

/*--------------------Components--------------------*/
 

// export async function getServerSideProps({ req, res }) {
//   res.setHeader(
//     "Cache-Control",
//     "public, s-maxage=10, stale-while-revalidate=59"
//   );

//   const glossaryData = await axios.get(`${API_Glossary}`);

//   return {
//     props: {
//       glossaryDetails: glossaryData.data,
//     },
//   };
// }

function Glossary() {
  const [glossaryDetails, setglossaryDetails] = useState();
  const [show, setShow] = useState(false);
  const [Offset, setOffset] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [clickdata ,setclickdata]=useState('')

 const [datas,setDatas]=useState("");
  

  const [active, setActive] = useState(null);
 



  const handleActive = (data) => {
    console.log("hhhhhh",data)
    setActive(data);
    setShow(data);
    setDatas(data)
    setclickdata(data)
    setHidden(true);
  };

  useEffect(() => {
    GetAll();
    const mq = window.matchMedia("(min-width: 650px)");

    if (mq.matches) {
      setOffset(true);
    } else {
      setOffset(false);
    }
  }, []);

  const GetAll = async()=>{
    const Result =  await axios.get(`${API_Glossary}`);

    setglossaryDetails(Result.data)
  
  }


  const albhabet = [
    {
      tittle: "#",
    //  boolean: glossaryDetails.Numberdata.length > 0 ? true : false,
    },
    {
      tittle: "A",
    //  boolean: glossaryDetails.Adata.length > 0 ? true : false,
    },
    {
      tittle: "B",
    //  boolean: glossaryDetails.Bdata.length > 0 ? true : false,
    },
    {
      tittle: "C",
    //  boolean: glossaryDetails.Cdata.length > 0 ? true : false,
    },
    {
      tittle: "D",
    //  boolean: glossaryDetails.Ddata.length > 0 ? true : false,
    },
    {
      tittle: "E",
    //  boolean: glossaryDetails.Edata.length > 0 ? true : false,
    },
    {
      tittle: "F",
    //  boolean: glossaryDetails.Fdata.length > 0 ? true : false,
    },
    {
      tittle: "G",
    //  boolean: glossaryDetails.Gdata.length > 0 ? true : false,
    },
    {
      tittle: "H",
    //  boolean: glossaryDetails.Hdata.length > 0 ? true : false,
    },
    {
      tittle: "I",
    //  boolean: glossaryDetails.Idata.length > 0 ? true : false,
    },
    {
      tittle: "J",
    //  boolean: glossaryDetails.Jdata.length > 0 ? true : false,
    },
    {
      tittle: "K",
    //  boolean: glossaryDetails.Kdata.length > 0 ? true : false,
    },
    {
      tittle: "L",
    //  boolean: glossaryDetails.Ldata.length > 0 ? true : false,
    },
    {
      tittle: "M",
    //  boolean: glossaryDetails.Mdata.length > 0 ? true : false,
    },
    {
      tittle: "N",
    //  boolean: glossaryDetails.Ndata.length > 0 ? true : false,
    },
    {
      tittle: "O",
    //  boolean: glossaryDetails.Odata.length > 0 ? true : false,
    },
    {
      tittle: "P",
    //  boolean: glossaryDetails.Pdata.length > 0 ? true : false,
    },
    {
      tittle: "Q",
    //  boolean: glossaryDetails.Qdata.length > 0 ? true : false,
    },
    {
      tittle: "R",
    //  boolean: glossaryDetails.Rdata.length > 0 ? true : false,
    },
    {
      tittle: "S",
    //  boolean: glossaryDetails.Sdata.length > 0 ? true : false,
    },
    {
      tittle: "T",
    //  boolean: glossaryDetails.Tdata.length > 0 ? true : false,
    },
    {
      tittle: "U",
    //  boolean: glossaryDetails.Udata.length > 0 ? true : false,
    },
    {
      tittle: "V",
    //  boolean: glossaryDetails.Vdata.length > 0 ? true : false,
    },
    {
      tittle: "W",
    //  boolean: glossaryDetails.Wdata.length > 0 ? true : false,
    },
    {
      tittle: "X",
      boolean: typeof glossaryDetails !== "undefined" && glossaryDetails.Xdata.length > 0 ? true : false,
    },
    {
      tittle: "Y",
    //  boolean: glossaryDetails.Ydata.length > 0 ? true : false,
    },
    {
      tittle: "Z",
    //  boolean: glossaryDetails.Zdata.length > 0 ? true : false,
    },
  ];

  return (
    <>
      {/* <Head
        pageTitle="HR Glossary - hrtech.sg"
        description="We help Singapore enterprises in identifying the right-fit HRTech solutions for solving their business challenges, leveraging our partnership with best-in-class HRTech product providers."
        currentURL={`${CUURENT_URL}${router.asPath}`}
        seoimage="https://www.hrtech.sg/assets/img/logo.png"
        siteName={SITENAME}
      /> */}

    
<div>
<Navbar/>
      <div className="position-relative">
        <section className="title_bg jumbotron jumbotron-fluid text-center m-0 border-bottom ">
          <div className="container-fluid">
            <h1
              className="glossary_h1 text-center portal fw-700 p-4"
            >
              HR Glossary
            </h1>
          </div>
        </section>
        <section>
          <nav>
            <ol className="cd-breadcrumb custom-separator">
              <li>
                <a to="/">Home</a>
              </li>

              <li className="current">
                <em>HR Glossary</em>
              </li>
            </ol>
          </nav>
        </section>
        <section>
          <div className="container-fluid">
            <div className="glossary_p_text">
              This comprehensive glossary of human resource (HR) terms defines
              words and phrases frequently used by HR professional. We would
              like to encourage you to share and make use of this library. If
              you have any suggestions of terms we have missed or neglected
              please contact us & we will include it in our next update.
            </div>
          </div>
        </section>
        <section className="sticky">
          <div className="">
            <div className="row" id="navbar-example2">
              {albhabet.map((item, id) => {
                return (
                  <div  key={id}>
                    <div
                  
                    // aScrool
                      className={
                        active == item.tittle && item.boolean == true
                          ? "albhabetactive col-lg-1 issuhrglo"
                          : "albhabet issuhrglo"
                      }
                      onClick={() => handleActive(item.tittle)}
                     
                      to={`${item.tittle}`}
                      // spy={false}
                      // smooth={true}
                      offset={Offset ? -150 : -250}
                      // duration={500}
                    >
                      <span
                        className={
                          item.boolean
                            ? "opacity-100"
                            : "opacity-50 text-muted"
                        }
                        
                      >
                        {item.tittle}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <div>
         
         {hidden ? <>
            <>
           {/* click data show */}
             {typeof glossaryDetails !== "undefined" && clickdata == "#" &&  glossaryDetails.Numberdata.length > 0 ? (
            <>
              {" "}
              <div
                className={
                  show == "#"
                    ? `div_alp_top container-fluid`
                    : `div_alp container-fluid`
                }
                id="#"
              >
                <h5 className=" alphatext_bg p-2">#</h5>
                <div className="row my-2">
                  { glossaryDetails.Numberdata.map((item, id) => {
                    return (
                      <div
                        className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
                        key={id}
                      >
                        {" "}
                        <a
                          href={`/hr-glossary/${item.url
                            .replace(/ /g, "-")
                            .toLowerCase()}`}
                        >
                         
                          <span className="glossary-test">{item.tittle}</span>
                        </a>
                      </div>
                    );
                  })}{" "}
                </div>
              </div>
            </>
          ) : null}

{typeof glossaryDetails !== "undefined" &&   clickdata == "A" &&  glossaryDetails.Adata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "A"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="A"
    >
      <h5 className=" alphatext_bg p-2">A</h5>
      <div className="row my-2">
        { glossaryDetails.Adata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}
{typeof glossaryDetails !== "undefined" && clickdata == "B" &&   glossaryDetails.Bdata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "B"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="B"
    >
      <h5 className=" alphatext_bg p-2">B</h5>
      <div className="row my-2">
        { glossaryDetails.Bdata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}

{typeof glossaryDetails !== "undefined" && clickdata == "C" &&  glossaryDetails.Cdata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "C"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="C"
    >
      <h5 className=" alphatext_bg p-2">C</h5>
      <div className="row my-2">
        { glossaryDetails.Cdata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}
 {typeof glossaryDetails !== "undefined" && clickdata == "D" &&  glossaryDetails.Ddata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "D"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="D"
    >
      <h5 className=" alphatext_bg p-2">D</h5>
      <div className="row my-2">
        { glossaryDetails.Ddata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}
  

  {typeof glossaryDetails !== "undefined" && clickdata == "E" &&  glossaryDetails.Edata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "E"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="E"
    >
      <h5 className=" alphatext_bg p-2">E</h5>
      <div className="row my-2">
        { glossaryDetails.Edata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}

{typeof glossaryDetails !== "undefined" && clickdata == "F" &&   glossaryDetails.Fdata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "F"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="F"
    >
      <h5 className=" alphatext_bg p-2">F</h5>
      <div className="row my-2">
        { glossaryDetails.Fdata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}

{typeof glossaryDetails !== "undefined" && clickdata == "G" &&  glossaryDetails.Gdata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "G"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="G"
    >
      <h5 className=" alphatext_bg p-2">G</h5>
      <div className="row my-2">
        { glossaryDetails.Gdata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}


{typeof glossaryDetails !== "undefined" && clickdata == "H" &&  glossaryDetails.Hdata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "H"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="H"
    >
      <h5 className=" alphatext_bg p-2">H</h5>
      <div className="row my-2">
        { glossaryDetails.Hdata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}

{typeof glossaryDetails !== "undefined" && clickdata == "I" &&  glossaryDetails.Idata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "I"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="I"
    >
      <h5 className=" alphatext_bg p-2">I</h5>
      <div className="row my-2">
        { glossaryDetails.Idata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}



{typeof glossaryDetails !== "undefined" && clickdata == "J" &&  glossaryDetails.Jdata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "J"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="J"
    >
      <h5 className=" alphatext_bg p-2">J</h5>
      <div className="row my-2">
        { glossaryDetails.Jdata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}




{typeof glossaryDetails !== "undefined" && clickdata == "K" &&  glossaryDetails.Kdata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "K"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="K"
    >
      <h5 className=" alphatext_bg p-2">K</h5>
      <div className="row my-2">
        { glossaryDetails.Kdata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}



{typeof glossaryDetails !== "undefined" && clickdata == "L" &&  glossaryDetails.Ldata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "L"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="L"
    >
      <h5 className=" alphatext_bg p-2">L</h5>
      <div className="row my-2">
        { glossaryDetails.Ldata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className="glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}



{typeof glossaryDetails !== "undefined" && clickdata == "M" &&  glossaryDetails.Mdata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "M"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="M"
    >
      <h5 className=" alphatext_bg p-2">M</h5>
      <div className="row my-2">
        { glossaryDetails.Mdata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}



{typeof glossaryDetails !== "undefined" && clickdata == "N" &&  glossaryDetails.Ndata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "N"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="N"
    >
      <h5 className=" alphatext_bg p-2">N</h5>
      <div className="row my-2">
        { glossaryDetails.Ndata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}



{typeof glossaryDetails !== "undefined" && clickdata == "O" &&   glossaryDetails.Odata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "O"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="O"
    >
      <h5 className=" alphatext_bg p-2">O</h5>
      <div className="row my-2">
        { glossaryDetails.Odata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}


{typeof glossaryDetails !== "undefined" && clickdata == "P" &&   glossaryDetails.Pdata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "P"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="P"
    >
      <h5 className=" alphatext_bg p-2">P</h5>
      <div className="row my-2">
        { glossaryDetails.Pdata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}



{typeof glossaryDetails !== "undefined" && clickdata == "Q" &&  glossaryDetails.Qdata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "Q"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="Q"
    >
      <h5 className=" alphatext_bg p-2">Q</h5>
      <div className="row my-2">
        { glossaryDetails.Qdata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}


{typeof glossaryDetails !== "undefined" && clickdata == "R" &&   glossaryDetails.Rdata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "R"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="R"
    >
      <h5 className=" alphatext_bg p-2">R</h5>
      <div className="row my-2">
        { glossaryDetails.Rdata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}



{typeof glossaryDetails !== "undefined" && clickdata == "S" &&   glossaryDetails.Sdata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "S"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="S"
    >
      <h5 className=" alphatext_bg p-2">S</h5>
      <div className="row my-2">
        { glossaryDetails.Sdata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}



{typeof glossaryDetails !== "undefined" && clickdata == "T" &&  glossaryDetails.Tdata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "T"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="T"
    >
      <h5 className=" alphatext_bg p-2">T</h5>
      <div className="row my-2">
        { glossaryDetails.Tdata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}



{typeof glossaryDetails !== "undefined" && clickdata == "U" &&  glossaryDetails.Udata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "U"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="U"
    >
      <h5 className=" alphatext_bg p-2">U</h5>
      <div className="row my-2">
        { glossaryDetails.Udata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}


{typeof glossaryDetails !== "undefined" && clickdata == "V" &&  glossaryDetails.Vdata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "V"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="V"
    >
      <h5 className=" alphatext_bg p-2">V</h5>
      <div className="row my-2">
        { glossaryDetails.Vdata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}


{typeof glossaryDetails !== "undefined" && clickdata == "W" &&   glossaryDetails.Wdata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "W"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="W"
    >
      <h5 className=" alphatext_bg p-2">W</h5>
      <div className="row my-2">
        { glossaryDetails.Wdata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}


{typeof glossaryDetails !== "undefined" && clickdata == "X" &&  glossaryDetails.Xdata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "X"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="X"
    >
      <h5 className=" alphatext_bg p-2">X</h5>
      <div className="row my-2">
        { glossaryDetails.Xdata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}



{typeof glossaryDetails !== "undefined" && clickdata == "Y" &&  glossaryDetails.Ydata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "Y"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="Y"
    >
      <h5 className=" alphatext_bg p-2">Y</h5>
      <div className="row my-2">
        { glossaryDetails.Ydata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}



{typeof glossaryDetails !== "undefined" && clickdata == "Z" &&  glossaryDetails.Zdata.length > 0 ? (
  <>
    {" "}
    <div
      className={
        show == "Z"
          ? `div_alp_top container-fluid`
          : `div_alp container-fluid`
      }
      id="Z"
    >
      <h5 className=" alphatext_bg p-2">Z</h5>
      <div className="row my-2">
        { glossaryDetails.Zdata.map((item, id) => {
          return (
            <div
              className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
              key={id}
            >
              {" "}
              <a
                href={`/hr-glossary/${item.url
                  .replace(/ /g, "-")
                  .toLowerCase()}`}
              >
               
                <span className=" glossary-test">{item.tittle}</span>
              </a>
            </div>
          );
        })}{" "}
      </div>
    </div>
  </>
) : null}

          
     
            </> 

            </>
         
            :
            
            <>
            <>
            {/* GetAll Data Show start */}
{console.log("nnnnnn",glossaryDetails)}
 {typeof glossaryDetails !== "undefined" &&  glossaryDetails.Numberdata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "#"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="#"
  >
    <h5 className=" alphatext_bg p-2">#</h5>
    <div className="row my-2">
      { glossaryDetails.Numberdata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}

{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Adata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "A"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="A"
  >
    <h5 className=" alphatext_bg p-2">A</h5>
    <div className="row my-2">
      { glossaryDetails.Adata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}
{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Bdata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "B"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="B"
  >
    <h5 className=" alphatext_bg p-2">B</h5>
    <div className="row my-2">
      { glossaryDetails.Bdata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}

{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Cdata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "C"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="C"
  >
    <h5 className=" alphatext_bg p-2">C</h5>
    <div className="row my-2">
      { glossaryDetails.Cdata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}
{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Ddata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "D"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="D"
  >
    <h5 className=" alphatext_bg p-2">D</h5>
    <div className="row my-2">
      { glossaryDetails.Ddata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}


{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Edata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "E"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="E"
  >
    <h5 className=" alphatext_bg p-2">E</h5>
    <div className="row my-2">
      { glossaryDetails.Edata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}

{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Fdata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "F"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="F"
  >
    <h5 className=" alphatext_bg p-2">F</h5>
    <div className="row my-2">
      { glossaryDetails.Fdata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}

{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Gdata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "G"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="G"
  >
    <h5 className=" alphatext_bg p-2">G</h5>
    <div className="row my-2">
      { glossaryDetails.Gdata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}


{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Hdata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "H"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="H"
  >
    <h5 className=" alphatext_bg p-2">H</h5>
    <div className="row my-2">
      { glossaryDetails.Hdata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}

{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Idata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "I"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="I"
  >
    <h5 className=" alphatext_bg p-2">I</h5>
    <div className="row my-2">
      { glossaryDetails.Idata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}



{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Jdata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "J"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="J"
  >
    <h5 className=" alphatext_bg p-2">J</h5>
    <div className="row my-2">
      { glossaryDetails.Jdata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}




{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Kdata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "K"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="K"
  >
    <h5 className=" alphatext_bg p-2">K</h5>
    <div className="row my-2">
      { glossaryDetails.Kdata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}



{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Ldata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "L"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="L"
  >
    <h5 className=" alphatext_bg p-2">L</h5>
    <div className="row my-2">
      { glossaryDetails.Ldata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}



{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Mdata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "M"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="M"
  >
    <h5 className=" alphatext_bg p-2">M</h5>
    <div className="row my-2">
      { glossaryDetails.Mdata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}



{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Ndata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "N"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="N"
  >
    <h5 className=" alphatext_bg p-2">N</h5>
    <div className="row my-2">
      { glossaryDetails.Ndata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}



{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Odata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "O"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="O"
  >
    <h5 className=" alphatext_bg p-2">O</h5>
    <div className="row my-2">
      { glossaryDetails.Odata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}


{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Pdata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "P"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="P"
  >
    <h5 className=" alphatext_bg p-2">P</h5>
    <div className="row my-2">
      { glossaryDetails.Pdata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}



{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Qdata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "Q"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="Q"
  >
    <h5 className=" alphatext_bg p-2">Q</h5>
    <div className="row my-2">
      { glossaryDetails.Qdata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}


{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Rdata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "R"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="R"
  >
    <h5 className=" alphatext_bg p-2">R</h5>
    <div className="row my-2">
      { glossaryDetails.Rdata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}



{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Sdata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "S"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="S"
  >
    <h5 className=" alphatext_bg p-2">S</h5>
    <div className="row my-2">
      { glossaryDetails.Sdata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}



{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Tdata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "T"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="T"
  >
    <h5 className=" alphatext_bg p-2">T</h5>
    <div className="row my-2">
      { glossaryDetails.Tdata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}



{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Udata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "U"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="U"
  >
    <h5 className=" alphatext_bg p-2">U</h5>
    <div className="row my-2">
      { glossaryDetails.Udata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}


{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Vdata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "V"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="V"
  >
    <h5 className=" alphatext_bg p-2">V</h5>
    <div className="row my-2">
      { glossaryDetails.Vdata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}


{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Wdata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "W"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="W"
  >
    <h5 className=" alphatext_bg p-2">W</h5>
    <div className="row my-2">
      { glossaryDetails.Wdata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}


{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Xdata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "X"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="X"
  >
    <h5 className=" alphatext_bg p-2">X</h5>
    <div className="row my-2">
      { glossaryDetails.Xdata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}



{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Ydata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "Y"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="Y"
  >
    <h5 className=" alphatext_bg p-2">Y</h5>
    <div className="row my-2">
      { glossaryDetails.Ydata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}



{typeof glossaryDetails !== "undefined" &&  glossaryDetails.Zdata.length > 0 ? (
<>
  {" "}
  <div
    className={
      show == "Z"
        ? `div_alp_top container-fluid`
        : `div_alp container-fluid`
    }
    id="Z"
  >
    <h5 className=" alphatext_bg p-2">Z</h5>
    <div className="row my-2">
      { glossaryDetails.Zdata.map((item, id) => {
        return (
          <div
            className="col-lg-3 col-sm-6 col-6 pt-3 pb-3 d-flex glossary-test"
            key={id}
          >
            {" "}
            <a
              href={`/hr-glossary/${item.url
                .replace(/ /g, "-")
                .toLowerCase()}`}
            >
             
              <span className=" glossary-test">{item.tittle}</span>
            </a>
          </div>
        );
      })}{" "}
    </div>
  </div>
</>
) : null}
</> 
            </>}
         
        </div>
      </div>
      <Testimonial />
        <Team />
        <Footer />
        </div>
    </>
  );
}

export default Glossary;
