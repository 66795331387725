import React, { Component } from 'react';

class Page_header extends Component {

	render() {

		let HeaderTitle = this.props.headertitle;
		let publicUrl = process.env.PUBLIC_URL + '/'

		return (


			<div className="breadcrumb-area bg-overlay" style={{ backgroundImage: 'url("' + publicUrl + 'assets/img/course/9.jpg")', width: 'auto', height: '30rem', }}>
				{/* <div className="container-fluid">
					<div className="breadcrumb-inner">
						<div className="section-title mb-0 ">
							
							<ul className="page-list Coursebanner-text">
								<li><Link to="/">Category</Link></li>
								<li>Sub Category Name</li>
							</ul>
							<h3 className="page-list-1 ">Fox Nymphs Grab Quick-jived Waltz. Brick Quiz Whangs</h3>
							<div className="total-avarage-rating">
								<h2>5.0</h2>
								<div className="rating-inner">
									<i className="fa fa-star" />
									<i className="fa fa-star" />
									<i className="fa fa-star" />
									<i className="fa fa-star" />
									<i className="fa fa-star" />
								</div>
								<p>Rated 5 out of 4 Ratings</p>
							</div>
						</div>
					</div>
				</div> */}
			</div>



		)
	}
}


export default Page_header