import React from "react";
import "./index.css";
import { API_URL } from "../../Api";
const API_Client_image = `${API_URL}/Client_image`;

export default function Customer_Section(props) {
  return (
    <div className="main-content-customer-sec">
      <div className="">
        <div className="container">
          <div className="row contents">
            <div className="col-md-12">
              <h1>Best-in-class </h1>
              <h1>Enterprise & Start-up Customers</h1>
            </div>
          </div>
          <div className="row card-sec mt-5">
            {props.Clientdata.map((item,i)=>{
              return(
                <div className="col colum-5" key={i}>
                <div className="card-box">
                  <img
                    src={`${API_Client_image}/${item.Profilephoto}`}
                    width="100%"
                    alt=""
                  />
                </div>
              </div>
              )
            })}
           
            
          </div>
        </div>
      </div>
    </div>
  );
}
