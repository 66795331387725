import React, { useState, useEffect,useReducer } from 'react';
import { Link ,useParams} from 'react-router-dom';
import { API_URL } from "./Api";
import axios from "axios";
import Navbar from "./global-components/navbar";
import Testimonial from './section-components/testimonial';
import Footer from './global-components/footer';
import "../components/Glossary.css"
const API_Glossary_Get =  `${API_URL}/hrglossary/website/particulardata`;
 
function Hrglossarydetails() {
  // console.log(url,"url")
  const [GlossarysData,setGlossarysData]=useState()
  const params = useParams();
  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });
   
    useEffect( () => {
 const hrglossid = params.id
        
        partculardata(hrglossid)
     

    }, []);

    const partculardata = async(id)=>{
        const Resultdata = await axios.post(`${API_Glossary_Get}`, {
            url: id,
          });
          if(Resultdata.data){
            setGlossarysData(Resultdata.data)
          }
          console.log("ccccc",Resultdata)
    }


  return (
    <div>
    <Navbar/>
    {GlossarysData ? <>


        <section className="title_bg jumbotron jumbotron-fluid text-center m-0 border-bottom ">
          <div className="container-fluid">
            <h1
              className="glossary_h1 text-center portal fw-700 p-4"
            >
              HR Glossary
            </h1>
          </div>
        </section>
        <section>
          <nav>
            <ol className="cd-breadcrumb custom-separator">
              <li>
                <a to="/">Home</a>
              </li>
              <li>
                <Link to="/hr-glossary"> HR Glossary</Link>
              </li>
              <li className="current">
                <em> {GlossarysData?.tittle}</em>
              </li>
            

              {/* {GlossarysData.map((headline, id) => {
              return ( */}
              {/* <Link
                key={id}
                to={`/hr-glossary/${GlossarysData.tittle.replace(/ /g, "-").toLowerCase()}/`}
              > */}
                {/* <li>
                  <Link href="/hr-glossary">
                    {" "}
                    {GlossarysData?.tittle.slice(0, 1)}
                  </Link>
                </li> */}
                {/* {console.log(GlossarysData,"GlossarysData")} */}
                {/* <li className="current breadcrumb-italic">
                  {GlossarysData?.tittle}
                </li>
              </Link> */}
            </ol>
          </nav>
        </section>

    
     
    

      <section className="container-fluid py-3">
        {/* {
          GlossarysData.map((curr,id)=>{
            return( */}
        <div
        // key={id}
        >
          <h1 className="heading_name py-3">
            {GlossarysData.tittle}
          </h1>
          <div className= "indi_content pt-3 pb-5 f-27">
            {" "}
            {renderHTML(GlossarysData.hrglossaryContent)}
          </div>
        </div>
        {/* )
          })
        }
         */}
        <Link to="/hr-glossary">
          <button
            className={
               "glossary_partcular_btn btn btn-success pt-5 pb-3"
            }
            type="button"
          >
            <span className="px-2">{"<"}</span>Back to HR Glossary
          </button>
        </Link>
      </section>
      </> :null}
      <Testimonial />
      <Footer />
    </div>
  );
}

export default Hrglossarydetails;
