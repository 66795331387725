import React, { Component, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Navbar from "./global-components/navbar";
import Team from "./section-components/team";
import Footer from "./global-components/footer";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import styled, { css } from "styled-components";
import { API_URL } from "./Api";
import axios from "axios";
import { Helmet } from "react-helmet";
import moment from "moment";

const API_Key = `${API_URL}/SubCategory/`;
const API_Key_Division = `${API_URL}/Division/`;
const API_Key_cate = `${API_URL}/Categories/`;
const API_Key_Product = `${API_URL}/MarketPlace/`;
const API_Key_updatedate = `${API_URL}/MarketPlaces/`;
const Product_images = `${API_URL}/MarketPlace_image/`;

export default function MarketMap() {
  const [Subcategory, SetSubcategory] = useState([]);
  const [Category, SetCategory] = useState([]);
  const [Products, SetProducts] = useState([]);
  const [Division, SetDivision] = useState([]);
  const [subCat, setSubCat] = useState([]);
  const [ProductsCate, SetProductsCate] = useState([]);
  const [productupdate, Setproductupdate] = useState("");
  const [Footerload, SetFooterload] = useState(false);
  const [pdfloading, Setpdfloading] = useState(true);

  const history = useHistory();

  const ImageStyle = {
    height: "70px",
    width: "100%",
    objectFit: "contain",
    "@media (max-width: 768px)": {
      height: "auto !important",
    },
  };

  useEffect(() => {
    GetAll();
    Getcategory();
    Product();
    ProductCate();
    DivisionGetAll();
    Updateddate();

    setTimeout(() => {
      SetFooterload(true);
    }, 500);
  }, []);

  // All Data Get
  const GetAll = () => {
    SetSubcategory([]);
    axios.get(`${API_Key}`).then((res) => {
      SetSubcategory(res.data);
    });
    if (
      document.location.pathname[document.location.pathname.length - 1] !== "/"
    ) {
      history.replace(document.location.pathname + "/");
    }
  };

  const Getcategory = () => {
    axios.get(`${API_Key_cate}`).then((res) => {
      SetCategory(res.data);
    });
  };
  const Product = () => {
    axios.get(`${API_Key_Product}`).then((res) => {
      SetProducts(res.data);
      console.log("SubCats", res.data);
    });
  };

  const DivisionGetAll = () => {
    axios.get(`${API_Key_Division}`).then((res) => {
      SetDivision(res.data);
    });
  };

  const Updateddate = () => {
    axios.get(`${API_Key_updatedate}`).then((res) => {
      const Result = moment(new Date(res.data[0].created)).format(
        "MMM Do YYYY"
      );
      Setproductupdate(Result);
    });
  };

  const ProductCate = () => {
    axios.get(`${API_Key_Product}`).then((res) => {
      const Datas = res.data;
      const SubCats = Datas.filter((item) => item.SubCategory === "");
      SetProductsCate(SubCats);
    });
  };

  const generatePDF = () => {
    Setpdfloading(false);
    var width = document.getElementById("container1").clientWidth;
    var height = document.getElementById("container1").clientHeight;
    console.log("www", width);
    console.log("hh", height);
    var element = document.getElementById("container1");
    var opt = {
      // margin: [30, 10, 10, 10],
      filename: "Marketmap-singapore.pdf",
      image: { type: "jpg", quality: 0.2 },
      jsPDF: { unit: "in" },
      // html2canvas: { scale: 2, scrollY: 0 },
      html2canvas: { dpi: 192, letterRendering: true, useCORS: true },
      jsPDF: { unit: "pt", format: [width, height], orientation: "portrait" },
      pagebreak: { mode: "avoid-all", after: ".avoidThisRow" },
    };
    html2pdf(element, opt).then(function (response) {
      document.getElementById("generatePDFButton");
      Setpdfloading(true);
    });
  };

  function SubCategoryDisp(ids) {
    return ids.Cate.map((Category_data, idx) => {
      const cateProd = Products.filter(({ SubCategory }) =>
        SubCategory.some(({ value }) => value === Category_data._id)
      );
      console.log("hhhh", cateProd.length);
      const SubCats = Division.filter(
        (item) => item.SubCategory === Category_data._id
      );
      return (
        <div className="row">
          <div
            key={idx}
            className="col-md-12"
            style={{ backgroundColor: "#fff", paddingLeft: "0px" }}
          >
            {cateProd.length > 0 ? (
              <>
                {" "}
                <p
                  style={{
                    color: "#000",
                    fontSize: 18,
                    fontWeight: 500,
                    textTransform: "uppercase",
                    padding: "6px 12px",
                  }}
                >
                  {Category_data.SubCategoryName}
                </p>
              </>
            ) : (
              ""
            )}
            {cateProd.length > 0 ? (
              <>
                <div className="row market_Subcat_head_Row">
                  {cateProd.map((prod, pi) => {
                    if (SubCats.length > 0) {
                      return (
                        <>
                          {prod.Division.length > 0 ? (
                            <></>
                          ) : (
                            <>
                              <div
                                key={pi}
                                className="col-md-2 col-4 mb-2 p-0 subcat_p_0"
                                style={{ display: "flex", marginBottom: 8 }}
                              >
                                <Link
                                  to={`/${prod.MarketURL}/`}
                                  className="hrms test"
                                >
                                  <img
                                    className="marketimages"
                                    src={`${Product_images}/${prod.ProductLogo}`}
                                  />
                                </Link>
                              </div>
                              {/* <DivisionDisp Cate={SubCats} /> */}
                            </>
                          )}
                        </>
                      );
                    } else {
                      return (
                        <div
                          key={pi}
                          className="col-md-2 col-4 mb-2 p-0 subcat_p_0"
                          style={{ display: "flex", marginBottom: 8 }}
                        >
                          <Link
                            to={`/${prod.MarketURL}/`}
                            className="hrms test"
                          >
                            <img
                              className="marketimages"
                              src={`${Product_images}/${prod.ProductLogo}`}
                            />
                          </Link>
                        </div>
                      );
                    }
                  })}
                </div>
              </>
            ) : (
              ""
            )}
            <DivisionDisp Cate={SubCats} />
          </div>
        </div>
      );
    });
  }

  function DivisionDisp(ids) {
    return ids.Cate.map((Category_data, idx) => {
      const cateProd = Products.filter(({ Division }) =>
        Division.some(({ value }) => value === Category_data._id)
      );
      return (
        <div key={idx} className="row market_Division_head">
          <p
            style={{
              color: "#004AAD",
              fontSize: 18,
              fontWeight: 500,
              textTransform: "uppercase",
              marginLeft: "-6px",
            }}
          >
            {Category_data.DivisionName}
          </p>
          <div className="col-md-12 ">
            <div className="row market_Division_head_Row">
              {cateProd.length > 0 ? (
                <>
                  {cateProd.map((prod, pi) => {
                    return (
                      <>
                        <div
                          key={pi}
                          className="marketImagessubHead col-md-2 col-4 p-0 subcat_p_0"
                        >
                          <Link
                            to={`/${prod.MarketURL}/`}
                            className="hrms test"
                          >
                            <img
                              className="marketimages"
                              src={`${Product_images}/${prod.ProductLogo}`}
                            />
                          </Link>
                        </div>
                      </>
                    );
                  })}
                </>
              ) : null}
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <div>
      <div>
        <Navbar />
      </div>

      <div
        id="container1"
        className="container"
        style={{
          boxSizing: "border-box",
          position: "relative",
          top: "85px",
          marginBottom: "100px",
        }}
      >
        <div
          className="page"
          size="A4"
          style={{
            background: "#ebeaea",
            display: "block",
            margin: "0 auto",
            marginBottom: "0.5cm",
            padding: "0.3cm",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-right-downloadbtn">
                {pdfloading ? (
                  <>
                    <button
                      className="m-2 btn "
                      id="generatePDFButton"
                      onClick={generatePDF}
                      style={{ backgroundColor: "#004AAD", color: "white" }}
                    >
                      Download PDF
                    </button>
                  </>
                ) : (
                  <>
                    <p>Downloading ...</p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className="d-flex align-items-center"
            style={{
              display: "flex",
              alignItems: "center!important",
              justifyContent: "center",
              padding: "0px 0px",
            }}
          >
            <a href="/" style={{ textAlign: "center" }} target="_blank">
              <img
                src="https://www.hrtech.sg/assets/img/marks.png"
                className=" d-lg-inline-block logo_two"
                style={{ height: "40px !important", width: "43%" }}
              />
            </a>
          </div>

          <div
            style={{
              backgroundColor: "#fff",
              padding: "12px",
              marginBottom: 15,
              marginTop: "16px",
            }}
          >
            <h4
              style={{
                color: "#000",
                fontWeight: 700,
                fontSize: 20,
                marginBottom: 10,
                marginLeft: "10px",
                textAlign: "center",
              }}
            >
              Supported by
            </h4>

            <div className="row d-flex align-items-center marketmap_support justify-content-center mt-3">
              <div className="d-flex first_child">
                <a
                  href=" https://hrme.economictimes.indiatimes.com/"
                  target="_blank"
                  className="hrms test m-1"
                >
                  <img
                    className="marketimages"
                    src="https://www.hrtech.sg/assets/img/ethr.svg"
                  />
                </a>

                <a
                  href="https://www.ihrp.sg/"
                  target="_blank"
                  className="hrms test m-1"
                >
                  <img
                    className="marketimages"
                    src="https://www.hrtech.sg/assets/img/ihrp-logo.jpg"
                  />
                </a>

                <a
                  href="https://shri.org.sg/"
                  target="_blank"
                  className="hrms test m-1"
                >
                  <img
                    className="marketimages"
                    src="https://www.hrtech.sg/assets/img/shrlogo.png"
                  />
                </a>

                <a
                  href="https://www.shrm.org/pages/default.aspx?loc=india"
                  target="_blank"
                  className="hrms test m-1"
                >
                  <img
                    className="marketimages"
                    src="https://www.hrtech.sg/assets/img/shrm-sharing-logo-square.png"
                  />
                </a>

                <a
                  href="https://www.sp.edu.sg/"
                  target="_blank"
                  className="hrms test m-1"
                >
                  <img
                    className="marketimages"
                    src="https://www.hrtech.sg/assets/img/sp-logo.png"
                  />
                </a>

                {/* <a href="https://www.shrm.org/pages/default.aspx?loc=india" target="_blank" className="text-center">
                <img className="" style={{display:'block',marginLeft:"auto",marginRight:'auto',width:'17%', marginTop:"25px"}}
                  src="https://d11p36kvaeudqt.cloudfront.net/logos/SHRM_BlocksTag_KO.svg"

                />
              </a>           */}
              </div>
            </div>
          </div>

          <p
            className="text-center"
            style={{ paddingBottom: "25px", fontSize: "14px" }}
          >
            Updated as of {productupdate}
          </p>

          {Category.length > 0
            ? Category.map((Category_data, idx) => {
                const cateProd = Products.filter(({ Category }) =>
                  Category.some(({ value }) => value === Category_data._id)
                );
                const SubCats = Subcategory.filter(
                  (item) => item.Category === Category_data._id
                );

                return (
                  <div className="container">
                    <div
                      key={idx}
                      className="row "
                      style={{
                        backgroundColor: "#fff",
                        padding: "12px",
                        marginBottom: 15,
                        marginTop: "16px",
                      }}
                    >
                      <h4
                        style={{
                          color: "#000",
                          fontWeight: 700,
                          fontSize: 20,
                          marginBottom: 10,
                          marginLeft: "10px",
                        }}
                      >
                        {Category_data.CategoryName}
                      </h4>
                      <div className="col-md-12 marketmap_main_cat_12">
                        {cateProd.length > 0 ? (
                          <>
                            <div className="row Cat_rows">
                              {cateProd.map((prod, pi) => {
                                if (Category_data.CategoryName=="Talent Analytics")  {
                                  if (prod.MarketURL=="compensly"){
                                    //FIXBYME console.log("INSIDEE ANALYTICS : Found compensly at",Category_data.CategoryName,"prod.SubCategory : ",prod.SubCategory)
                                    prod.SubCategory = []
                                    // console.log("INSIDEE ANALYTICS after changing subcat: Found compensly at",Category_data.CategoryName,"prod : ",prod)
                                  } 
                                  else if (prod.MarketURL=='entomo'){
                                    // console.log("INSIDE ANALYTICS , emyt obj : ",prod)
                                    prod.SubCategory = []
                                  }
                                }
				else if(Category_data.CategoryName=="Total Rewards"){
                                  if (prod.MarketURL=="compensly"){
                                    prod.SubCategory = [{
                                      "value": "62cc025b3b1708dc161f4f87",
                                      "label": "COMPENSATION MANAGEMENT"
                                      }]
                                    // console.log("INSIDE total REWARDS : ",Category_data.CategoryName,"prod.SubCategory : ",prod.SubCategory)
                                  }
                                }

				if (SubCats.length > 0) {
                                  return (
                                    <>
                                      {prod.SubCategory.length > 0 ? (
                                        <></>
                                      ) : (
                                        <>
                                          <div className="col-md-2 col-4 ">
                                            <Link
                                              to={`/${prod.MarketURL}/`}
                                              className="hrms test m-1"
                                            >
                                              <img
                                                className="marketimages"
                                                src={`${Product_images}/${prod.ProductLogo}`}
                                              />
                                            </Link>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  );
                                } else {
                                  return (
                                    <div className="col-md-2 col-4 ">
                                      <Link
                                        to={`/${prod.MarketURL}/`}
                                        className="hrms test m-1"
                                      >
                                        <img
                                          className="marketimages"
                                          src={`${Product_images}/${prod.ProductLogo}`}
                                        />
                                      </Link>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                            <div className="m-2">
                              <SubCategoryDisp Cate={SubCats} />
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            : null}

          {Footerload ? (
            <div style={{ padding: "0px 10px 0px 10px" }}>
              <div>
                <p
                  style={{
                    fontSize: 10,
                    fontWeight: "normal !important",
                    color: "#000",
                  }}
                >
                  <b>Disclaimer:</b> The HRTech Marketmap 2022 list includes
                  service providers that are servicing the Singapore
                  marketplace. This is not an exhaustive list and will be
                  updated on a continuous basis. For queries and for inclusions
                  in the Marketmap, reach out to maya@hrtech.sg.
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {/* <button onClick={()=>print('a4', 'jsx-template')}>
    Download PDF
</button> */}

      {Footerload ? (
        <>
          <Team />
          <Footer />
        </>
      ) : null}
    </div>
  );
}
