import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory} from 'react-router-dom'
import Navbar from '../global-components/navbar';
import Testimonial from '../section-components/testimonial';
import Team from '../section-components/team';
import Footer from '../global-components/footer';
// import Counter from '../section-components/counter';
import FeaturedCarosel from '../FeaturedCarosel';
import { FaFilter } from "react-icons/fa";
import axios from "axios";
import HrmsSsidetab from '../hrmsSidetab';
// import Model from '../Model';
import DialogBox from '../global-components/enquiry-modal';

import { API_URL } from '../Api'

const API_Market = `${API_URL}/MarketPlace/`;
const API_View = `${API_URL}/MarketPlace_image/`;
const API_Sub = `${API_URL}/SubCategory/`;
const Api_review = `${API_URL}/ProductReview/`;

function SubCategory() {


  const params = useParams();
  // const location = useLocation();
  const history = useHistory()

  // initializing state to store data
  const [data, setData] = useState([])
const [subdata, setsubdata] = useState([])
  const [Product, SetProduct] = useState("")
  const [dialog, setDialog] = useState(false)
  const [Footerload, SetFooterload] = useState(false)
  // const [SubCategoryId, setSubCategoryId] = useState("")

  //Function call on SubCategoryId Change
  useEffect(() => {
    setTimeout(() => {
      SetFooterload(true)
    }, 500)
    GetData()

    if(document.location.pathname[document.location.pathname.length-1] !== '/') {
      history.replace(document.location.pathname+'/');
    }
  }, [params.subCat])

   //Function call on page mount


  //Get Category from Backend
  const GetData = async () => {
    setData([]);
      const subCate = params.subCat
      const Scat = await axios.get(`${API_Sub}`)
      const filter = Scat.data.filter((person) =>person.SubCategoryURL.includes(subCate))
      const Result = await axios.get(`${API_Market}/SubCategory/${filter[0]._id}`)
      var ReviewData = Result.data;
      ReviewData.map(async(Stars)=>{
        console.log("tetss",Stars)
        const Review = await axios.get(`${Api_review}/Approval/${Stars._id}`)
      
        var sum = 0;
        Review.data.map(items => {
          sum += parseFloat(items.rating)
        })
        var toalstar = sum / Review.data.length;
       var fixednumber = toalstar.toFixed(1)
      const dataf ={
        MarketURL:Stars.MarketURL,
        ProductSummary:Stars.ProductSummary,
        ProductLogo:Stars.ProductLogo,            
        ProductName:Stars.ProductName,
        ProductTagLine:Stars.ProductTagLine,
        StarRating:fixednumber
      }
      setData((indexs)=>[...indexs,dataf])
      //  console.log("nnnnn",dataf)
       
      })
setsubdata(filter)
      // setData(Result.data)
      console.log("params.subCat",params.subCat)
  }

  const Getcompanyname = data => {
    SetProduct(data)
     if(data =="Deel"){
      window.open('https://www.deel.com/partners/hrtech-sg', '_blank');
  }else{
      
      setDialog(true)
  }
  }

  const dialogClose = () => {
    setDialog(false)
  }

const openNav = () => {
    var element = document.getElementById("mySidenavAdd_market");
    element.classList.add("Addding");
    document.getElementById("mySidenav_market").style.width = "250px";
    document.getElementById("mySidenav_market").style.left = "0px";
  }

  const closeNav = () => {
    var element = document.getElementById("mySidenavAdd_market");
    element.classList.remove("Addding");
    document.getElementById("mySidenav_market").style.width = "0";
  }

  const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

  return (
    <div>
      <Navbar />
      <DialogBox show={dialog} title={Product} dialogClose={dialogClose} />
 <div id="mySidenav_market" className="sidenav hidden-md hidden-lg side-nav-style ">
        <a className="closebtn text-light" onClick={() => closeNav()}>×</a>

        <div className="side-allingmnet">
        <HrmsSsidetab Linktit={params.name} />
        </div>
        
      </div>
      <div id="mySidenavAdd_market" ></div>
      <section className=' py-4 title_bg  jumbotron-fluid text-center m-0 border-bottom'>
        <div className="container-fluid market pt-5" >
        <h1 style={{textTransform:'capitalize'}} className="text-center  f-28 fw-600" >{params.subCat == "smes" ? "HRMS for Small Business Software Tools":params.subCat.replace(/-/g, ' ')+" "+"Software Tools"}</h1>
        </div>

      </section>
 <section>
 	<nav>
		<ol className="cd-breadcrumb custom-separator">
			<li><a href="/">Home</a></li>
			<li><a href="/marketplace/" >Marketplace</a></li>
			 
			<li className="current" style={  {textTransform:'capitalize'}}><em>{params.subCat == "smes" ? "HRMS for Small Business Software Tools":params.subCat.replace(/-/g, ' ')+" "+"Software Tools"}</em></li>
		</ol>
	</nav>
</section>
      
      {/* <section className='py-md-5 py-4 bg-lightgrey' style={{ marginBottom: "30px" }}>
        <div className='container-fluid w-90 pl-md-0 w-mb-100 pr-md-3 px-0'>
          <div className="row mx-auto">
            <div className="col-md-12">
              <div className="text-center mb-3">
                <h2 className="wow flipInY f-mb-18 f-20 text-red">Featured Products</h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <FeaturedCarosel />
            </div>
          </div>
        </div>
      </section> */}

      <section>
        <div className='py-md-5 pt-4 bg-white'>
          <div className='container-fluid px-0 d-flex'>
            <div className='row m-0 w-100'>
              <div className='col-md-12 px-0'>
                <div className='row m-0'>
                  <div className='col-12 align-items-center justify-content-center pt-2 pb-4 px-3' id='apply_filter_section'>
                    <button className='btn btn-red-primary filter_border w-90 w-mb-90 py-2 m-0 f-16 waves-effect waves-light' data-toggle="modal" data-target="#myModal" onClick={() => openNav()}>
                      <FaFilter />Apply Filter
                    </button>
                  </div>

                  <div className='col-md-3 pl-2 side_menu_mp ec-sidebarWrap mb-5' >
                    <HrmsSsidetab />
                  </div>

                  <div className='col-md-9 col-12 mp_cards'>
                    {data.length > 0 ?
                      <div className='row mx-0'>
                        {data.map((item, i) => {
                          return (
                            <div key={i} className='col-12 col-sm-6 col-md-4 col-lg-4 slide-in-bottom feature_card px-2 mb-3'>
                              <div className='content mp_card'>
                              <a href={ `/${item.MarketURL}/` }>
                                  <div className='content-overlay'></div>
                                  <div className='new_card content-image bg-transparent border-lightgrey'>
                                    <div className='h-50 d-flex' style={{ justifyContent: 'center', position: 'relative' }}>
                                      <div className="card-img-top" style={{ width: '100%', height: 'auto', backgroundImage: ` url(${API_View}/${item.ProductLogo})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center' }}></div>
                                    </div>
                                    <div className='h-50 d-flex align-items-center bg_grey justify-content-center' style={{ padding: '0px 10px 0px 10px' }}>
                                      <div className='d-block'>
                                        <p className='f-12 w-100 text-grey fixed_content_3'>
                                          {item.ProductSummary}
                                        </p>
                                        {item.StarRating && item.StarRating !== "NaN" ? <>
                      <div className="" >
                          <div className="star-rating-count ">
                            {[...Array(5)].map((star, index) => {
                              index += 1;
                              return (
                                <button
                                  key={index}
                                  className={index <= (`${item.StarRating}`) ? "on" : "off"}

                                >
                                  <span className="star" >&#9733;</span>

                                </button>
                              );
                            })}
                          </div>
                            {/* <p className="Starcount"> {4} ({1})</p> */}
                            </div>
                            </> : null}
                                        {/* <div className='stars w-100 pt-3 rating' style={{rating: 4.0 }}></div> */}
                                      </div>
                                    </div>

                                  </div>
                                </a>
                                <div className='content-details px-0'>
                                <a href={ `/${item.MarketURL}/` }>
                                    <div className='h-50 d-block text-center pt-5 px-3'>
                                      <h3 className='text-white content-title f-20 fw-600 mt-0 mb-2 word_wrap'>{item.ProductName}</h3>
                                      <p className='content-text f-12 fixed_content pr-0 word_wrap'>
                                        {item.ProductTagLine}
                                      </p>
                                    </div>
                                  </a>

                                  <div className='container row m-0 h-50 pt-md-4 pt-2 d-flex' style={{ justifyContent: 'center', position: 'relative',alignItems:'center' }}>
                                    <div className='col-md-6 col-6 pr-1 mb-0' style={{ backgroudColor: '#fff' }}>
                                    <a href={ `/${item.MarketURL}/` }>
                                        <button className='card_view btn btn-block f-16 border-radius-none enquiry_mobile waves-effect waves-light' >
                                          View
                                        </button>
                                      </a>
                                    </div>
                                    <div className='col-md-6 col-6 pr-1 mb-0' style={{ backgroudColor: '#fff' }}>
                                      <button type="button" className="card_view btn btn-block f-16 border-radius-none enquiry_mobile waves-effect waves-light"
                                        // data-toggle="modal"
                                        // data-target="#exampleModalLong"
                                        onClick={() => {
                                          Getcompanyname(item.ProductName)
                                          SetProduct(item.ProductName)
                                        }}>
                                        Enquire
                                      </button>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      : <></>}

{subdata.length > 0 && subdata[0].SubCategorybottomcontent && subdata[0].SubCategorybottomcontent !== "undefined" ? <>
  <div className='card border-radius-none mb-3'>
                                            {/* <div className='card-header py-3 bg-white align-items-center d-flex'>
                                                <h2 className='f-18 fw-600 w-100'>
                                                    EVENT AGENDA
                                                </h2>
                                            </div> */}
                                            <div className='card-body row m-0 style_summernote'>
                                                <p>
                                                  {console.log("sususus",subdata)}
                                                 {renderHTML(subdata[0].SubCategorybottomcontent)}
                                                </p>



                                            </div>
                                        </div>
</>:null}

                      {params.subCat === "earned-wage-access" ? <>
                        <div className="card mb-3 mt-2">
                      <div className="card-body">
                        {/* <h2 className="border-bottom pb-3 f-18 fw-600">Why are HRM Systems in High Demand?</h2> */}
                        <div className="row pt-3 m-0">
                          <div className="col-md-12 col-12 px-0 style_summernote">
                          <p>Employee well-being has become the priority for every employer. The pandemic has only further enhanced its need and urgency. Employees’ financial well-being is equally important as their mental and physical health. Many organisations in Singapore are taking conscious measures to ensure their employees’ financial well-being. Earned Wage Access (EWA) is a prominent step in that direction. </p>

                          <p><strong><i>Employee well-being is more than just their physical health. It is about prioritising various elements that impact them - mentally, emotionally and financially.</i></strong> </p>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-3 mt-2">
                      <div className="card-body">
                      <h2 className="border-bottom pb-3 f-18 fw-600">What is Earned Wage Access?</h2>

                        <div className="row pt-3 m-0">
                          <div className="col-md-12 col-12 px-0 style_summernote">
                          <p>EWA is the ability for employees to access wages that they have earned but not yet received. It is often referred to as on-demand pay or instant pay. With EWA, employees will have 24/7 access to the money that they have earned. The exponential rise of HR technology and increasing number of employees in the gig economy have surged the demand for instant payment practices like Earned Wage Access. </p>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-3 mt-2">
                      <div className="card-body">
                      <h2 className="border-bottom pb-3 f-18 fw-600">What Earned Wage Access is NOT?</h2>

                        <div className="row pt-3 m-0">
                          <div className="col-md-12 col-12 px-0 style_summernote">
                          <p>Whenever employees face an emergency, they cannot afford to wait until payday to receive their paycheck. These situations are precisely when they are forced to draw an overdraft and opt for a payday loan. This makes them liable to interest charges. </p>


                          <p>This is where Earned Wage Access proves beneficial. Having said that, it is important to realise what exactly EWA is and what it is not. </p>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-3 mt-2">
                      <div className="card-body">
                        <h2 className="border-bottom pb-3 f-18 fw-600">EWA is definitely -</h2>
                        <div className="row pt-3 m-0">
                          <div className="col-md-12 col-12 px-0 style_summernote">
                          <ul>
<li>NOT an advance on future earnings</li>
<li>NOT charged with interest rates</li>
<li>NOT a payday loan</li>
<li>NOT daily payroll or replacing payroll </li>
</ul>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-3 mt-2">
                      <div className="card-body">
                        <h2 className="border-bottom pb-3 f-18 fw-600">How does Earned Wage Access Work?</h2>
                        <div className="row pt-3 m-0">
                          <div className="col-md-12 col-12 px-0 style_summernote">
                          <p>The EWA platforms provide money to employees on-demand and this amount is reimbursed by employers at the end of every pay cycle. Employers can provide their employees with immediate access to their earned money through a payroll service provider or a third-party EWA vendor that partners with the payroll provider. The EWA provider will integrate with the employer’s payroll management system to enable the payout process. They also connect with the employee time and attendance system. This integration helps employees track their pay daily with the ability to access their earned wages anytime. </p>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-3 mt-2">
                      <div className="card-body">
                        <h2 className="border-bottom pb-3 f-18 fw-600">Types of EWA</h2>
                        <div className="row pt-3 m-0">
                          <div className="col-md-12 col-12 px-0 style_summernote">
                          <p>There are essentially two types of EWA - </p>

                          <ul>
<li>
<p><strong>Employee-Sponsored EWA:</strong> The employer collaborates with an EWA provider whose technology is integrated with the employer’s payroll system.</p>
</li>
<li>
<p><strong>Direct to Consumer EWA:</strong> Employees are offered the on-demand pay services directly by the EWA provider. The funds are reimbursed from employees’ accounts after the payday. </p>
</li>
</ul>

                           
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-3 mt-2">
                      <div className="card-body">
                        <h2 className="border-bottom pb-3 f-18 fw-600">Employee Benefits of Earned Wage Access</h2>
                        <div className="row pt-3 m-0">
                          <div className="col-md-12 col-12 px-0 style_summernote">
                          <ul>
<li>
  <p><strong>Self-finance Unexpected Expenses:</strong> As per the Financial well-being survey by OCBC, 53% of the Singapore workforce admitted that they would be unable to handle a sudden expense in times of emergencies and 11% indicated that they have borrowed money from friends and family. That’s why offering EWA safeguards the employees from cyclical debts and money-related stress. Instant access to earned wages enables employees to cover any unexpected expenses or money-related emergencies. EWA is beneficial the most for those who are unable to secure affordable credit or loans and need the money right away. </p>
</li>
<li>
<p><strong>Circumvent Monthly Budgets:</strong> Monthly payroll forces employees to plan their budgets from paycheck to paycheck. The financial shortfall before the month-end can add undue financial stress. EWA helps prevent this with instant access to money. </p>
</li>
<li>
<p><strong>Downsize Debts and Loans:</strong> Often, when faced with financial crises, employees have no option but to opt for high-interest loans to deal with cash flow problems. EWA helps them downsize debts and loans and save them from high interest rates. </p>
</li>
<li>
<p><strong>Flexible Financial Planning:</strong> With Earned Wage Access, employees can set long-term financial goals while staying afloat in the near term. EWA offers them the flexibility in financial planning and utilize their funds whenever required. </p>
</li>
<li>
<p><strong>Avoid Financial Stress:</strong> Lack of financial stability will constantly add up to the stress levels. Employers can free their employees from financial hardships by offering EWA benefits to them. </p>
</li>
<li>
<p><strong>Mobile App Convenience:</strong> Employees can handle their entire payment process themselves on their mobile devices, without any intervention from the payroll team. This makes the payroll process fast, effective and efficient. </p>
</li>
</ul>

                           
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-3 mt-2">
                      <div className="card-body">
                        <h2 className="border-bottom pb-3 f-18 fw-600">Employer Benefits of Earned Wage Access</h2>
                        <div className="row pt-3 m-0">
                          <div className="col-md-12 col-12 px-0 style_summernote">
                          <ul>
<li>
<p><strong>Bolster Employer’s Brand:</strong> Implementing EWA and on-demand payroll is the best way to tell employees that you care about their financial well-being. This generates goodwill and positivity among the workforce, bolstering your brand image. </p>
</li>
<li>
<p><strong>Reduce Absenteeism and Attrition:</strong> EWA puts employees in control of their finances and frees them from the anxiety-related to money issues. This increases their peace of mind and satisfaction levels. A happy and satisfied employee is less likely to be distant from work. </p>
</li>
<li>
<p><strong>Enhance Employee Productivity:</strong> EWA can assist the employees to address their financial stress and make them much more productive. Once the financial burden is off their shoulders, they can contribute better. </p>
</li>
<li>
<p><strong>Create a Thriving Work Environment:</strong> With EWA, employees will no longer be worried about their finances. All the money-related stress will be taken care of by the assurance that they can access their earned wages whenever they want. This helps create a thriving and a stress-free workplace.</p>
</li>
<li>
<p><strong>Attract Top Talent:</strong> Earned Wage Access is a major attraction point in a competitive job market. Offering financial stability through EWA will help you attract top talent.This will eventually translate into improved business outcomes. </p>
</li>
</ul>

                           
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mb-3 mt-2">
                      <div className="card-body">
                        <h2 className="border-bottom pb-3 f-18 fw-600">How to Implement EWA for your Business?</h2>
                        <div className="row pt-3 m-0">
                          <div className="col-md-12 col-12 px-0 style_summernote">
                          <p>Implementing EWA policy requires deliberation on a few key factors. Covering these factors helps in planning an organization-wide EWA adoption policy. </p>
                          <p>Consider these factors while implementing EWA for your business - </p>

                          <ul>
<li>
<p><strong>What will benefit every employee?</strong> Customisation options of EWA services will let you implement the program to benefit your entire workforce.</p>
</li>
<li>
<p><strong>Differences among EWA service providers:</strong> Choosing the EWA provider that best suits your business-specific demands and employee needs is crucial. Ideally, choose EWA tools that can easily integrate with your existing payroll system.</p>
</li>
<li>
<p><strong>Promote healthy financial habits:</strong> Approach financial wellness as an entire support system rather than treating it as a one-time solution. Your EWA program should help employees become financially stable and inculcate healthy finance habits. </p>
</li>
<li>
<p><strong>Create EWA program awareness:</strong> Find quick and effective ways to inform your entire workforce about the EWA process and benefits. It is important for your workforce to understand and welcome the EWA technology. </p>
</li>
<li>
<p><strong>Leverage mobile-first technology:</strong> EWA providers should offer their services through a mobile app to make the on-demand pay process convenient for employees. They should be able to access their earned wages anytime, anywhere. </p>
</li>
</ul>

                           
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-3 mt-2">
                      <div className="card-body">
                        <h2 className="border-bottom pb-3 f-18 fw-600">EWA Solution Providers in Singapore</h2>
                        <div className="row pt-3 m-0">
                          <div className="col-md-12 col-12 px-0 style_summernote">
                          <p>Given the rising concerns around employee well-being, many organisations in Singapore are leveraging technology solutions to make employees’ life easy. Listed below are the top-most EWA solution providers in Singapore who are helping organisations provide  employees with financial stability and stress-free work-life,</p>
                          <ul>
<li><a href="https://www.hrtech.sg/getpaid/">Get Paid</a></li>
<li><a href="https://www.hrtech.sg/octomate/">Octomate</a></li>
</ul>

                           
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-3 mt-2">
                      <div className="card-body">
                        <h2 className="border-bottom pb-3 f-18 fw-600">Things to watch out while implementing EWA</h2>
                        <div className="row pt-3 m-0">
                          <div className="col-md-12 col-12 px-0 style_summernote">
                          <p>Earned Wage Access can prove to be transformative for your business. It offers a plethora of tangible and intangible benefits. However, EWA might not be suitable for every business. Before implementing EWA solutions, organisations should be aware of the following pitfalls - </p>

                          <ul>
<li>
<p><strong>Employee Attitude towards the System:</strong> Change that involves technology is not always welcomed by everyone. It becomes important to consider how your workforce will react to EWA implementation. Measures should also be taken to ensure that they don’t abuse the system and get into financial troubles.</p>
</li>
<li>
<p><strong>Limited Technology Solutions:</strong> EWA technology is not widely used due to lack of awareness and thus, the number of EWA solution providers is a little below the satisfactory number. Limited options might sometimes lead to dissatisfactory service.</p>
</li>
<li>
<p><strong>Not Suitable for Small Businesses:</strong> EWA solutions provide maximum benefits when the business is widespread across countries. </p>
</li>
<li>
<p><strong>Costs:</strong> Implementing and maintaining the EWA solutions will incur some certain cost requirements. EWA providers may also charge employees to access funds.</p>
</li>
<li>
<p><strong>Laws and Regulations:</strong> Country specific or region specific laws and regulations can have an impact and limit or change how employees can receive their wages early. </p>
</li>
</ul>

                           
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-3 mt-2">
                      <div className="card-body">
                        <h2 className="border-bottom pb-3 f-18 fw-600">Is EWA Right for your Business?</h2>
                        <div className="row pt-3 m-0">
                          <div className="col-md-12 col-12 px-0 style_summernote">
                          <p>Earned Wage Access brings a lot of transformational benefits. However, it is important to understand the need, purpose and the best way to implement it. Whether EWA is an optimal solution for your business can depend on a number of factors. </p>
                          <p>These factors include (but are not limited to) - </p>

                          <ul>
<li>The size of your company</li>
<li>The number of countries you operate in</li>
<li>Country-specific payroll laws and regulations </li>
<li>The pay scale of your employees</li>
<li>Contract types (if any) of your workforce</li>
<li>The industrial sector you operate in</li>
</ul>


<p>Industries where work tends to be more flexible are ideal for EWA transition. Employees can track their accessible pay on a daily basis and withdraw their earnings for the day when need be. If your business is spread over different geographies, it is important to understand the payroll laws and regulations that can impact your EWA implementation strategies. </p>

<p>Remember, the primary goal of EWA is to provide financial stability to your employees. It is  an innovative way to transform the payroll process for the better and support employees’ evolving work patterns and lifestyles.</p>

                          </div>
                        </div>
                      </div>
                    </div>

                      </> :null}
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>

      </section>
      <section>
        {/* <Counter /> */}
      </section>
      {Footerload ? <>
            <Testimonial />
            <Team />
            <Footer /></>:null}
      {/* <Model Product={Product} /> */}
    </div>



  )
}

export default SubCategory;
