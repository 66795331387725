import React, { useEffect, useState } from "react";
import { useHistory,Link } from "react-router-dom";
import Navbar from "../global-components/navbar";
import Testimonial from "../section-components/testimonial";
import Team from "../section-components/team";
import Footer from "../global-components/footer";
import { Helmet } from "react-helmet";
import { FaRegCircle } from 'react-icons/fa';
import axios from "axios";
import { API_URL } from "../Api";

const API_About = `${API_URL}/About`;
const API_Advisory = `${API_URL}/Advisory`;
const API_Investors = `${API_URL}/Investors`;
const API_About_image = `${API_URL}/About_image`;
const API_Advisory_image = `${API_URL}/Advisory_image`;
const API_Investors_image = `${API_URL}/Investors_image`;

function Academy() {
  const history = useHistory();
  const [title,settitle]=useState("");
  const [academy,setacademy]=useState(false);
  const [advisory,setadvisory]=useState(false);
  const [marketplace,setmarketplace]=useState(false);
  const [talent,settalent]=useState(false);

  useEffect(() => {
    console.log("mmm",window.location.pathname)
    console.log("mmm 11",history)
    if (
      document.location.pathname[document.location.pathname.length - 1] !== "/"
    ) {
      history.replace(document.location.pathname + "/");
    }

    if(window.location.pathname == "/about-us/academy/"){
      setacademy(true)
      setadvisory(false)
    setmarketplace(false)
    settalent(false)
    settitle("Academy")
    }
     if(window.location.pathname == "/about-us/advisory-consulting/"){
      setadvisory(true)
      setacademy(false)
      setmarketplace(false)
    settalent(false)
    settitle("Advisory consulting")
    }
     if(window.location.pathname =="/about-us/marketplace-solutions/"){
      setmarketplace(true)
      setacademy(false)
      setadvisory(false)
      settalent(false)
      settitle("Marketplace solutions")
    }
     if(window.location.pathname == "/about-us/talent-ondemand/"){
      settalent(true)
      setmarketplace(false)
      setacademy(false)
      setadvisory(false)
      settitle("Talent ondemand")
    }


  }, []);

  const getservices =(items)=>{
if(items == "academy"){
  setacademy(true)
  setadvisory(false)
setmarketplace(false)
settalent(false)
settitle("Academy")
}
 if(items == "advisory-consulting"){
  setadvisory(true)
  setacademy(false)
  setmarketplace(false)
settalent(false)
settitle("Advisory consulting")
}
 if(items =="marketplace-solutions"){
  setmarketplace(true)
  setacademy(false)
  setadvisory(false)
  settalent(false)
  settitle("Marketplace solutions")
}
 if(items == "talent-ondemand"){
  settalent(true)
  setmarketplace(false)
  setacademy(false)
  setadvisory(false)
  settitle("Talent ondemand")
}
  }

  return (
    <div>
      <Navbar />
      <section className="py-md-5 py-4 title_bg jumbotron jumbotron-fluid text-center m-0 border-bottom">
        <div className="container-fluid " style={{ marginTop: "4%" }}>
          <h1 className="text-center f-28 fw-600">{title}</h1>
        </div>
      </section>
      <section>
        <nav>
          <ol className="cd-breadcrumb custom-separator">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <Link to="/about-us/">About us</Link>
            </li>

            <li className="current">
              <em>{title}</em>
            </li>
          </ol>
        </nav>
      </section>
      <section className="bg-white pb-0">
              <div className="container-fluid pb-md-4 px-0">
               
                <div className="row" style={{ margin: "0" }}>
                  
                  <div className="col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                    <div className="card">
                      <div className={document.location.pathname == "/about-us/academy/" ?"card-body service_card service_ca2 service_card_active":"card-body service_card service_ca2"} >
                        <Link to="/about-us/academy/" onClick={()=>getservices("academy")}>
                          <div className="align-items-center h-100">
                            <div className="adjust_detail or_btm_clr index_blog_card w-100 text-center ">
                              <div
                                style={{ height: "6rem", marginBottom: "1rem" }}
                              >
                                <img
                                  src="/assets/img/Aboutus/Academy.svg"
                                  alt="4567898"
                                ></img>
                              </div>
                              <h4 className="fw-normal f-18">Academy</h4>
                          
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                    <div className="card">
                      <div className={document.location.pathname == "/about-us/advisory-consulting/" ?"card-body service_card service_ca2 service_card_active":"card-body service_card service_ca2"}>
                        <Link to="/about-us/advisory-consulting/" onClick={()=>getservices("advisory-consulting")}>
                          <div className="align-items-center h-100">
                            <div className="adjust_detail or_btm_clr index_blog_card w-100 text-center ">
                              <div
                                style={{ height: "6rem", marginBottom: "1rem" }}
                              >
                                <img
                                  src="/assets/img/Aboutus/Advisory.svg"
                                  alt="4567898"
                                ></img>
                              </div>
                              <h4 className="fw-normal f-18">
                                {" "}
                                Advisory & Consulting
                              </h4>
                            
                               
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                 
                  
                  <div className="col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                    <div className="card">
                      <div className={document.location.pathname == "/about-us/marketplace-solutions/" ?"card-body service_card service_ca2 service_card_active":"card-body service_card service_ca2"}>
                        <Link to="/about-us/marketplace-solutions/" onClick={()=>getservices("marketplace-solutions")}>
                          <div className="align-items-center h-100">
                            <div className="adjust_detail or_btm_clr index_blog_card w-100 text-center ">
                              <div
                                style={{ height: "6rem", marginBottom: "1rem" }}
                              >
                                <img
                                  src="/assets/img/Aboutus/Marketplace.svg"
                                  alt="12345"
                                ></img>
                              </div>
                              <h4 className="fw-normal f-18">
                                {" "}
                                Marketplace Solutions
                              </h4>
                            
                              
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                    <div className="card">
                      <div className={document.location.pathname == "/about-us/talent-ondemand/" ?"card-body service_card service_ca2 service_card_active":"card-body service_card service_ca2"}>
                        <Link to="/about-us/talent-ondemand/" onClick={()=>getservices("talent-ondemand")}>
                          <div className="align-items-center h-100">
                            <div className="adjust_detail or_btm_clr index_blog_card w-100 text-center ">
                              <div
                                style={{ height: "6rem", marginBottom: "1rem" }}
                              >
                                <img
                                  src="/assets/img/Aboutus/Talent.svg"
                                  alt="7654"
                                ></img>
                              </div>
                              <h4 className="fw-normal f-18">
                                Talent OnDemand
                              </h4>
                             
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  
                </div>
               
              </div>
            </section>
            {/* Academy start */}
            {academy ? <>
      <section className="blog-area bg-white mt-1">
        <div className="container-fluid px-md-0">
          <div className="row mx-auto">
            <div className="col-md-12">
              <div className="f-16">
                {/* <h6><b>Creating the HRTech Ecosystem for the Future of Work</b></h6> */}
                <p className="line-hei mt-2">
                  Immerse yourself in a culture of continuous learning and
                  development with hrtech Academy. Our Academy offers a diverse
                  range of courses and training programs that are specifically
                  designed to upskill HR professionals, foster leadership
                  capabilities, and cultivate a growth-oriented mindset within
                  your organization.
                </p>
                <br></br>
                <h6> Key Features of hrtech Academy: </h6>
                <div className="ml-4">
                <p className="line-hei mt-2">
                  <ul>
                    <li>
                    <b>Comprehensive Course Catalog:</b> Our Academy boasts a
                  rich catalog of courses and learning programs that cover a
                  wide range of topics. We place a strong emphasis on upskilling
                  HR teams in the areas of Data, Technology, and Design
                  Thinking, equipping them with the necessary skills to thrive
                  in the era of digital transformation.
                    </li>
                  </ul>
                
                </p>
                <p className="line-hei mt-2">
                  <ul>
                    <li>
                    <b>Collaboration with Leading Institutions:</b> We have
                  collaborated with esteemed institutions to deliver
                  high-quality education and training. For instance, we designed
                  and delivered the "HR Technology" module for Masters students
                  at NUS Business School, Singapore. This collaboration ensures
                  that our programs are academically rigorous and aligned with
                  industry needs.
                    </li>
                  </ul>
                 
                </p>
                <p className="line-hei mt-2">
                  <ul>
                    <li>
                    <b>Strategic Partnerships:</b> We have formed strategic
                  partnerships to enhance the value of our offerings. One such
                  partnership is with Korn Ferry Academy, where we jointly
                  design and deliver the Technology & Analytics module as part
                  of the SGUnited Program. These partnerships bring cutting-edge
                  expertise and industry insights to our Academy.
                    </li>
                  </ul>
                  
                </p>
                <p className="line-hei mt-2">
                  <ul>
                    <li>
                    <b>Expert-Led Masterclasses: </b> As a testament to our
                  commitment to excellence, we have partnered with renowned
                  experts such as Dr. Fermin Diez to deliver in-person and
                  virtual Masterclasses on People Analytics. These interactive
                  sessions provide valuable insights and practical knowledge to
                  further enhance your HR professionals' skill sets.{" "}
                    </li>
                  </ul>
                
                </p>
                <p className="line-hei mt-2">
                  <ul>
                    <li>
                    <b>Collaborations with Industry Leaders: </b> We are proud
                  to have clients and partners such as{" "}
                  <b>
                    {" "}
                    Institute for Human Resource Professionals (IHRP), Academy
                    to Innovate HR (AIHR), Aon, and Economic Times Human
                    Resources (ETHR) World The Middle East.
                  </b>{" "}
                  These collaborations validate the quality and relevance of our
                  Academy's offerings, ensuring that our programs align with
                  industry best practices and emerging trends.{" "}
                    </li>
                  </ul>
               
                </p>
                </div>
                <br></br>
                <p className="line-hei mt-2">
                 
Reach out to hrtech Academy and empower your HR professionals with the knowledge and skills they need to excel in the digital era. Foster a culture of continuous learning, drive digital transformation, and unlock the full potential of your organization's HR capabilities.

                </p>
              </div>
             
            </div>
          </div>
        </div>
      </section></>:null}
         {/* Academy end */}
         {/* advisory start */}
         {advisory ? <>
         <section className="blog-area bg-white mt-1">
        <div className="container-fluid px-md-0">
          <div className="row mx-auto">
            <div className="col-md-12">
              <div className="f-16">
                <h6><b>Advisory and Consulting: </b></h6>
                <p className="line-hei mt-2">
                hrtech’s Advisory & Consulting vertical helps address your business and people challenges. Our tailor-made advisory services focus on understanding the core problems, providing recommendations, and identifying the right-fit solutions for your organization.
                </p>
                <p className="line-hei mt-2">Our dedicated research teams continuously track HR & HR Technology industry trends, enabling us to provide valuable insights. We share our findings through various channels such as <a href="https://www.hrtech.sg/blog/">blogs</a>, <a href="https://www.hrtech.sg/resources/reports/">reports</a>,<a href="https://www.hrtech.sg/resources/infographics/"> infographics</a> , and <a href="https://www.hrtech.sg/resources/case_studies/">case studies</a>. As co-creators of Singapore's HRTech Marketmap and authors of the <a href="https://www.hrtech.sg/resources-details/2023-hr-tech-market-outlook-report/">HRTech Market Outlook report</a> , we stay at the forefront of industry developments.</p>
                <br></br>
                <h6> Our Approach: </h6>
                <div className="ml-4">
                <p className="line-hei mt-2">
                  <ul>
                    <li>
                    <b>Deep Domain Knowledge: </b> Our practitioner consultants bring extensive industry experience and real-world perspectives to the table. We understand the unique challenges faced by organizations and offer practical solutions based on our expertise.
                    </li>
                  </ul>
                  
                </p>
                <p className="line-hei mt-2">
                  <ul>
                    <li>
                    <b>Bespoke Solutions: </b> Every organization is unique, and we recognize that. Our approach is entirely customized to suit your specific needs. We take the time to understand your business and work closely with you to deliver tailored recommendations and solutions.
                    </li>
                  </ul>
                  
                </p>
                <p className="line-hei mt-2">
                  <ul>
                    <li>
                    <b>Agility, Precision, and Expertise: </b> Through our agile methodology, precise analysis, and deep expertise, we provide a custom-made focus to each consulting engagement. Our aim is to deliver results and help organizations of all shapes and sizes improve their talent strategies, optimize critical metrics, and enhance overall effectiveness.
                    </li>
                  </ul>
                 
                </p>
                <p className="line-hei mt-2">
                  <ul>
                    <li>
                    <b>Proprietary Multi-Disciplinary Approach: </b> We bring a comprehensive approach to talent processes, combining multiple disciplines to address your organization's challenges holistically. By considering various aspects such as technology, culture, and strategy, we create a roadmap of recommendations that are driven by metrics and aligned with your goals.{" "}
                    </li>
                  </ul>
                 
                </p>
                <p className="line-hei mt-2">
                  <ul>
                    <li>
                    <b>Customized-High-Touch Engagement:  </b> We believe in building strong partnerships with our clients. Our engagement model ensures a high-touch experience, where we collaborate closely with your team throughout the consulting process. We are committed to your success and strive to exceed your expectations.
                    </li>
                  </ul>
                 
                </p>
                </div>
                <br></br>
                <p className="line-hei mt-2">
                 
                Choose hrtech as your trusted advisory and consulting partner, and let us help you optimize your talent strategies, overcome challenges, and achieve your business objectives.

                </p>
              </div>
            </div>
          </div>
        </div>
      </section></>:null}
         {/* advisory end */}
         {/* marketplce start */}
         {marketplace ? <>
         <section className="blog-area bg-white mt-1">
        <div className="container-fluid px-md-0">
          <div className="row mx-auto">
            <div className="col-md-12">
              <div className="f-16">
                
                <p className="line-hei mt-2">
                Explore the largest curated marketplace of HR technology solutions. Our HR Technology marketplace acts as a hub for cutting-edge technologies, tools, and resources, allowing you to optimize HR functions, enhance productivity, and drive business growth. We bring together a diverse ecosystem of trusted providers, offering innovative tools and services to optimize your HR functions, streamline processes, and drive organizational success. 
                </p>
                <br></br>
                <h6> Key Features of hrtech Marketplace Solutions: </h6>
                <div className="ml-4">
                <p className="line-hei mt-2">
                  <ul>
                  <li>
                   <b>Extensive Provider Network:  </b>Our marketplace hosts hundreds of product providers across eight HR categories, ensuring a wide selection of solutions to meet your specific requirements. From talent acquisition and onboarding to performance management and employee engagement, you'll find a comprehensive range of HR tech solutions in one place.

                  </li>
                  </ul>
                </p>
                <p className="line-hei mt-2">
                <ul>
                  <li>
                  <b>Curated Quality Assurance: </b> We prioritize quality and reliability in our marketplace. Each solution undergoes a thorough evaluation process to ensure it meets our stringent criteria. We assess factors such as functionality, user experience, scalability, security, and customer support, ensuring that only top-notch solutions are featured in our marketplace.
                  </li>
                </ul>
                  
                </p>
                <p className="line-hei mt-2">
                  <ul>
                    <li>
                    <b>Endorsements and Collaborations: </b> We have garnered support and collaboration from reputable organizations such as <b> Adrian Tan, Economic Times Human Resources (ETHR) World The Middle East, Institute for Human Resource Professionals (IHRP), NUEST, Singapore Human Resources Institute (SHRI), Society for Human Resource Management (SHRM), Singapore Polytechnic (SP) </b> for our Marketmap. These partnerships validate the quality and relevance of the solutions offered in our marketplace, providing you with confidence in your selection.
                    </li>
                  </ul>
                 
                </p>
                <p className="line-hei mt-2">
                  <ul>
                    <li>
                    <b>Marketmap and Thought Leadership: </b>As creators of the hrtech Marketmap, we provide a comprehensive overview of the HR tech landscape, helping you navigate the diverse options available. Additionally, our commitment to thought leadership ensures that you stay informed about the latest trends, best practices, and emerging technologies in the HR tech space.
                    </li>
                  </ul>
                 
                </p>
                
                </div>
                <br></br>
                <p className="line-hei mt-2">
                Discover the power of hrtech Marketplace Solutions and unleash the potential of your HR operations. Simplify your vendor selection process, access cutting-edge solutions, and drive efficiency and effectiveness in your organization's HR practices.

                </p>
              </div>
            </div>
          </div>
        </div>
      </section></>:null}
         {/* marketplce end */}
         {/* Talent section start */}
         {talent ? <>
         <section className="blog-area bg-white mt-1">
        <div className="container-fluid px-md-0">
          <div className="row mx-auto">
            <div className="col-md-12">
              <div className="f-16">
                <h6><b>Get access to best-in-class Technology & HR Talent</b></h6>
                <p className="line-hei mt-2">
                At hrtech, our Talent On-Demand Vertical specializes in delivering exceptional talent sourcing solutions customized for your specific industry. With an extensive network of skilled professionals, we are dedicated to helping our clients thrive in a highly competitive market.
                </p>
                <p className="line-hei mt-2">
                Whether you need Developers, Engineers, or Technical Specialists with niche expertise, we have the resources to connect you with the right talent to drive your business forward.
                </p>
                <br></br>
                <h6> Our Core service offerings include: </h6>
                <div className="ml-4">
                <p className="line-hei mt-2">
                  <ul>
                    <li>
                    <b>Contingent Staffing: </b>We understand the unique requirements of data and tech projects that demand specialized skills. Through our contingent staffing services, we enable you to quickly scale your team with highly qualified professionals. Our key strengths include:
                   <ul className="ml-5 mt-3 list-unstyled">
                    <li ><span className="small "><small><FaRegCircle/></small></span><span className="ml-2">Seamless integration of talent into your existing workforce.</span></li>
                    <li><span className="small "><small><FaRegCircle/></small></span><span className="ml-2">Mobilization of contingent talent across diverse technical skill sets.</span></li>
                    <li><span className="small "><small><FaRegCircle/></small></span><span className="ml-2">Fulfillment of both short-term and long-term roles.</span></li>
                   </ul>
                    </li>
                  </ul>
                  
                </p>
                <p className="line-hei mt-2">
                  <ul>
                    <li>
                    <b>Direct Hires: </b> Finding the perfect talent for long-term positions is vital for your organization's success and growth. Our full-time placement services take the recruitment burden off your shoulders. We meticulously assess candidates' technical abilities, experience, and cultural fit to ensure they align with your organization's goals and values. Our key strengths include:
                  <ul className="ml-5 mt-3 list-unstyled">
                    <li><span className="small "><small><FaRegCircle/></small></span><span className="ml-2">Access to a database of high-quality local talent across Business Consulting, Technology, and Sales roles.</span></li>
                    <li><span className="small "><small><FaRegCircle/></small></span><span className="ml-2">Utilization of multiple platforms to source top-tier talent in the industry.</span></li>
                  </ul>
                    </li>
                  </ul>
                
                </p>
                <h6><b>Get access to best-in-class Technology & HR Talent</b></h6>

                <p className="line-hei mt-2">
                  <ul>
                    <li>
                    <b>Extensive Domain Expertise:  </b>Our team of recruiters possesses in-depth knowledge and experience within the HR and Technology services industry. We stay abreast of the latest trends, technologies, and skill requirements to identify and attract the most qualified professionals.
                    </li>
                  </ul>
                 
                </p>
                <p className="line-hei mt-2">
                  <ul>
                    <li>
                    <b>Speed and Flexibility:  </b>We understand the time-sensitive nature of the IT services industry. With our streamlined processes and extensive candidate database, we can rapidly provide you with the right professionals to meet your project deadlines.
                    </li>
                  </ul>
                 
                </p>
                <p className="line-hei mt-2">
                  <ul>
                    <li>
                    <b>Customized Solutions:  </b>We take the time to thoroughly understand your business, culture, and unique staffing needs. This enables us to deliver tailored solutions that align with your specific requirements, ensuring a successful match between your organization and the professionals we recommend.
                    </li>
                  </ul>
                 
                </p>
                <p className="line-hei mt-2">
                  <ul>
                    <li>
                    <b>Exceptional Customer Service:  </b>Our dedicated team of account managers is committed to delivering personalized attention and support throughout the staffing process. We are always available to address your queries, provide regular updates, and ensure your utmost satisfaction with our services.
                    </li>
                  </ul>
                 
                </p>
                
                </div>
                <br></br>
                <p className="line-hei mt-2">
                Choose hrtech as your trusted partner for On-Demand Talent needs. With our industry expertise, speed, flexibility, customized solutions, and exceptional customer service, we are ready to help you acquire the talent that drives your business success.

                </p>
              </div>
            </div>
          </div>
        </div>
      </section></>:null}
         {/* Talent section end */}

         <div>
                <div className="row mt-3">
                  <div className="col-md-12 align-items-center justify-content-center">
                    <div className="text-center mt-4 mb-4">
                      <Link to="/contact-us/" className="btn btn-red-primary border-radius-none f-16 f-mb-14 mt-1 px-5 waves-effect waves-light" >CONTACT US</Link>
                      <a href=" https://calendly.com/iyersriram/30-min" className="btn btn-red-primary border-radius-none f-16 f-mb-14 mt-1 px-5 waves-effect waves-light ml-3" target="_blank">SCHEDULE A CALL</a> 

                    </div>

                  </div>

                </div>
              </div>
      <br></br>

      <Testimonial />
      <Team />
      <Footer />
    </div>
  );
}

export default Academy;
