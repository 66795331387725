import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import DialogBox from './event-register-modal';
import { Link } from 'react-router-dom';
import { FaRegCalendarAlt, FaRegBuilding, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { API_URL } from './Api';
import axios from 'axios';
import moment from 'moment';
import './news.css';
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    LinkedinIcon
} from "react-share";

const URL = `${API_URL}/Events/filter/Upcoming/`;
const urlimg = `${API_URL}/Events_image`;

const EventCarousel = () => {
    const [EventData, SetEventData] = useState([]);


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const { data } = await axios.get(URL);
        SetEventData(data);
        console.log("hhh",data)

    }

    
    const addtocalender =(items)=>{
      
        if(items.EventDate){
            var dates = items.EventDate.split('T')[0].replaceAll("-","")
            var startdate = dates+"T"+items.EventStartTime.replaceAll(":","")+"00";
            var enddate = dates+"T"+items.EventEndTime.replaceAll(":","")+"00";
            window.open (`https://www.google.com/calendar/render?action=TEMPLATE&text=${items.EventName}&dates=${startdate}/${enddate}&sf=true&output=xml&details=${items.EventShortDescription}`,'_blank')
        }
        
        // https://www.google.com/calendar/render?action=TEMPLATE&amp;text=Demo+Hour:+Hiring+&amp;+Onboarding+Talent&amp;dates=20220526T150000/20220526T163000&amp;sf=true&amp;output=xml&amp;details=Watch+the+exclusive+demos+of+HRTech+tools+that+can+be+leveraged+for+Talent+Acquisition:+HireQuotient,+Multiplier,+Otomeyt
    }

    return (
        <>
            {
                EventData.length > 0 ?
                    <>


                        <Carousel hrefuch={true} indicators={false} controls={true}>
                            {EventData.map(Events => {
	let date = new Date(Events.EventDate).toDateString().slice(4)
                                return (

                                    <Carousel.Item>
                                        <section>
                                            <div className='carousel_hole_container event-margin'>
                                                <div className='row carousel_row'>
                                                    <div className='col-md-6 carousel_container' style={{ padding: '4em' }}>
                                                        <div className='d-block text-black '>
                                                            <a href={`/events/${Events.Eventurlname.replace(/ /g, '-').toLowerCase()}/`}>

                                                                <p className='fw-600 text-primary-red carousel_text'>
                                                                    Event
                                                                </p>

                                                                <h1 className='f-50 fw-600 mb-1 text-black f-mb-34 event-headline carousel_text'>
                                                                    {Events.EventName}
                                                                </h1>
                                                                <p className='f-18 Caro-line-height mb-3 text-black f-mb-14  carousel_text'>
                                                                    {Events.EventShortDescription}
                                                                </p>
                                                            </a>
                                                        </div>
                                                        
                                                        <DialogBox buttonname={Events.CallToAction} Eventname={Events.EventName} lumalink={Events.EventLUMALink} lumaid={Events.EventLUMAId}  EventWebsiteLink={Events.EventWebsiteLink}/>
                                                        <div className='mt-5 pt-4 pb-2 d-flex align-items-center'>
                                                            <FaRegCalendarAlt />
                                                            <div className='d-md-flex d-block align-items-center'>
                                                                <p className='f-16 pl-md-2 pl-3 w-mb-100 f-mb-14 date_time_container'> {date}  &nbsp; {moment(Events.EventStartTime, "hh:mm").format('LT')}   -  {moment(Events.EventEndTime, "hh:mm").format('LT')} </p>
                                                                <a 
                                                                    onClick={()=>addtocalender(Events)}  sclassName='date_time_container' style={{ margin: '0 0 0 1em', color: '#f4504f' }}>Add to calendar</a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className='col-md-6 carousel_container carousel_image_container'>
                                                        <img className="d-block w-100" src={`${urlimg}/${Events.EventImage}`} alt="First slide" />
                                                    </div>
                                                </div>

                                                <div className='row carousel_row-mob'>
                                                    <div >
                                                        <img className="img_carousel_mob" src={`${urlimg}/${Events.EventImage}`} alt="First slide" />
                                                    </div>
                                                    <div className='text_carousel_mob'>
                                                        <div className='d-block text-black '>
                                                            <a href="/EventsView">
                                                                <p className='fw-600 text-primary-red carousel_text'>
                                                                    Event
                                                                </p>
                                                            </a>
                                                            <h1 className='fw-600 mb-1 text-black f-mb-34 carousel_text carousel_text_heading'>
                                                                {Events.EventAgendaTitle}
                                                            </h1>
                                                            <p className='f-18 fw-600 mb-3 text-black f-mb-14 fixed_content carousel_text'>
                                                                {Events.EventShortDescription}
                                                            </p>
                                                        </div>
                                                        <div className='register_div'>
                                                            <div className='carousel_text register_btn'><p>REGISTER HERE</p></div>
                                                            <div className='register_icon'><FaEnvelope /></div>
                                                            <div>
                                                                <WhatsappShareButton url={window.location.href} title={Events.EventName} className="share-btn m-1">
                                                                    <WhatsappIcon size={30} round={true} />
                                                                </WhatsappShareButton>
                                                                <FacebookShareButton url={window.location.href} title={Events.EventName} className="share-btn m-1">
                                                                    <FacebookIcon size={30} round={true} />
                                                                </FacebookShareButton>
                                                                <LinkedinShareButton url={window.location.href} title={Events.EventName} className="share-btn m-1">
                                                                    <LinkedinIcon size={30} round={true} />
                                                                </LinkedinShareButton>
                                                                <EmailShareButton url={window.location.href} title={Events.EventName} className="share-btn m-1">
                                                                    <EmailIcon size={30} round={true} />
                                                                </EmailShareButton>

                                                            </div>
                                                        </div>
                                                        <div className='mt-5 pt-4 pb-2 d-flex align-items-center date_mob'>
                                                            <FaRegCalendarAlt />
                                                            <div className='d-md-flex d-block align-items-center Eventdate'>
                                                                <p className=' Eventdate pl-md-2 pl-3 w-mb-100 f-mb-14'>{moment(Events.EventDate).format("MMM Do YY")}  &nbsp; {Events.EventStartTime}   - {Events.EventEndTime} </p>
                                                                <a className='mob_calander'
                                                                    href="https://www.google.com/calendar/render?action=TEMPLATE&amp;text=Demo+Hour:+Hiring+&amp;+Onboarding+Talent&amp;dates=20220526T150000/20220526T163000&amp;sf=true&amp;output=xml&amp;details=Watch+the+exclusive+demos+of+HRTech+tools+that+can+be+leveraged+for+Talent+Acquisition:+HireQuotient,+Multiplier,+Otomeyt"
                                                                    target="_blank"
                                                                    style={{ margin: '0 0 0 1em', color: '#f4504f' }}>Add to calander</a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </section>
                                    </Carousel.Item>

                                )
                            })}

                        </Carousel>


                    </>
                    : null
            }

        </>
    )
}

export default EventCarousel
