import React from "react";
import "./index.css";

export default function Second_Section() {
  return (
    <div className="main-content-second-sec">
    <div className="">
      <div className="container">
        <div className="row mb-5">
           <div className="col-md-12">
        <h1>What do we do?</h1>
           </div>
           </div>
           <div className="row what-do-sec">
           <div className="col-md-3">
             
            <div className="mb-4">
                <span className="img-span">
                <img src="/assets/img/icon/Vector.png" width={40} alt=""/>
                </span>
            </div>
            <h6>Academy</h6>
            <p>Foster a culture of continuous growth & development with our diverse catalogue of courses & training programs. Designed to upskill HR professionals & cultivate leadership capabilities, this catalogue will empower you to drive future-proof strategic initiatives.</p>
           
           </div>
           <div className="col-md-3">
           
            <div className="mb-4">
                <span className="img-span">
                <img src="/assets/img/icon/Vector2.png" width={40} alt=""/>
                </span>
            </div>
            <h6>Advisory</h6>
            <p>Design, re-design & elevate your HR strategies with a wealth of knowledge & industry insights from our seasoned HR rockstars. Leverage their experience, innovative solutions & proven methods to position yourself for sustainable success.</p>

           </div>
           <div className="col-md-3">
          
            <div className="mb-4">
                <span className="img-span">
                <img src="/assets/img/icon/Vector3.png" width={40} alt=""/>
                </span>
            </div>
            <h6>Marketplace</h6>
            <p>A comprehensive hub of cutting-edge HRTech solutions, tools & resources catering to your business growth needs. Enhance your HR operations and stay ahead of the curve with our curated marketplace and drive business success.</p>
           
           </div>
           <div className="col-md-3">
           
            <div className="mb-4">
                <span className="img-span">
                <img src="/assets/img/icon/Vector4.png" width={40} alt=""/>
                </span>
            </div>
            <h6>Talent-on-Demand</h6>
            <p>Get your hands on highly skilled HR professionals suited for your dynamic business needs. Whether you need specialised expertise or additional resources for projects, our talent pool offers the flexibility and agility you need to thrive in today's competitive landscape.</p>
          
           </div>
           </div>
           <div className="row mb-4">
            <div className="col-md-12">
                
                <div className="view-more mt-5">
                    <a href="/about-us/" className="btn1" >VIEW MORE</a>
                </div>

            </div>

           </div>
        </div>
      </div>
    </div>
   
  );
}
