import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar';
import Banner from './section-components/banner';
import Intro from './section-components/intro';
import About from './section-components/about';
import Resource from './ResourceHomepage';
import Blog from './BlogHomepage';
import Event from './EventHomepage';
import NewsHome from './NewsHomepage';
import Techtalk from './TechTalksHome';
import CourseFilter from './section-components/course-filter';
import FunFact from './section-components/fun-fact';
import HowToWork from './section-components/how-to-work';
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';
import Footer from './global-components/footer';
import Counter from './section-components/counter';
import {Helmet} from "react-helmet";
import axios from "axios";
import FeaturedCarosel from './FeaturedCarosel';
import { API_URL } from './Api'
import Banner_Home from './Home/Banner';
import Second_Section from './Home/Second_section';
import Customer_Section from './Home/CustomerSection';
import Marketplace_Home_Section from './Home/Marketplace_home';
import Feature_Product from './Home/Feature_Product';
import Resource_Home_pages from './Home/Resource_section';
import Event_Home_pages from './Home/Event_Home';
import Blog_Home_pages from './Home/Blog_home';


const api = `${API_URL}/News/alldata/website`;
const Homepageapi = `${API_URL}/homepagewebsite`;
const HomePage = () => {
  // const [NewsData, SetNewsData] = useState([]);
  const [Homedata, SetHomedata] = useState([]);
  useEffect(() => {
    // fetch();
    fetchHome();
  }, [])

  
// const fetch = async () => {
//   const { data } = await axios.get(api);
  
//   SetNewsData(data);
   
 
// }
const fetchHome = async () => {
  const { data } = await axios.get(Homepageapi);
  SetHomedata(data);
   
 
}
    return <div>
           
        <Navbar />
       
      <Banner_Home />
      <Second_Section />
      {typeof Homedata.Clientdata !== "undefined" && Homedata.Clientdata.length > 0 ? <>
      <Customer_Section Clientdata={Homedata.Clientdata}/>
      </>:null}
      {typeof Homedata.Category !== "undefined" && Homedata.Category.length > 0 ? <>
      <Marketplace_Home_Section Categorydats={Homedata.Category}/></>:null}
      {typeof Homedata.FeaturedProd !== "undefined" && Homedata.FeaturedProd.length > 0 ? <>
      <Feature_Product Productdata={Homedata.FeaturedProd}/>
      </>:null}
      {typeof Homedata.Resourcesdata !== "undefined" && Homedata.Resourcesdata.length > 0 ? <>
      <Resource_Home_pages Resourcesdatas={Homedata.Resourcesdata}/>
      </>:null}
      {typeof Homedata.Event !== "undefined" && Homedata.Event.length > 0 ? <>
      <Event_Home_pages Eventdatas={Homedata.Event}/>
      </>:null}
      {/* <Blog_Home_pages /> */}
        
        {/* <Counter /> */}
        <Testimonial />
        <Team />
        <Footer />
    </div>
}

export default HomePage;

