import React, {memo,useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronRight } from "react-icons/fa";
import { API_URL } from './Api';


const urlimg = `${API_URL}/News_image`;
const NewsEditors = ({ NewsData ,view }) => {

    const [Editor, setEditor] = useState();

     useEffect(() => {
        //  console.log(TechTalkData,'TechTalkData');
      let fltdata  = NewsData.filter(item=>item.EditorChoice == "true")
      let sort = fltdata.sort((a, b) => {
        return (
            new Date(a.NewsDate) -
            new Date(b.NewsDate)
        )
    }).reverse()
      setEditor(sort)
   

     }, [NewsData]);




   
    return (
        <div className='sticky-filter-course'>

            <div className='ec-sidebar'>
                <div className='ec-box-width' >
                    <header className='f-16 font-weight-bold'>
                        <div className="clearfix display_flex direction_column">
                            <div className='float-left font-weight-bold'>
                            Latest News
                            </div>
                           { view ?  <div className='float-right d-inline-flex'>
                                <a href={"/news/"} className='f-16 text-red'>
                                    View All
                                </a>
                                <FaChevronRight className='text-red f-14 ml-2 font-weight-bold' />
                            </div> :<></>}

                        </div>
                    </header>
                    {NewsData && NewsData?.map((item, id) => {
			let date = new Date(item.NewsDate).toDateString().slice(4);
                        return (
                            <div style={{ overflow: 'hidden' }} key={id}>
                                <a href={`/news/${item?.Newsurlname.replace(/ /g, '-').toLowerCase()}/`} className='d-block list-group-item list-group-item-action border-0 p-0 '>
                                    <div className='media py-3 border_top_table resource_list_media direction_column d-flex align-items-center'>
                                        <div className='mr-2'  >
                                            <img src={`${urlimg}/${item.NewsImage}`} style={{width:"143px"}}></img>   </div>
                                        <div className='media-body'  >
                                            <p className='text-black f-16 fw-600'>
                                               {item?.NewsHeadline}
                                            </p>
                                            <span className='text-grey-shade-2 f-12'> {date}</span>
                                            <span className='text-grey-shade-2 f-12'></span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )
                    })}

                 



                </div>
            </div>

        </div>
    )
}


export default memo(NewsEditors);
