import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { FaEnvelope } from 'react-icons/fa';
import { AiOutlineCalendar } from 'react-icons/ai';
import { BiInfoCircle } from 'react-icons/bi';
import { FiCheckCircle } from 'react-icons/fi';
import { HiVideoCamera } from 'react-icons/hi';
import './news.css';
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    LinkedinIcon
} from "react-share";


const Registerbutton = (props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div >
            <div className='register_div'>
               {
                    props.lumalink ? <>
                <div className='carousel_text register_btn' style={{width:"100%"}} onClick={handleShow}><p >{props.buttonname ? props.buttonname : "REGISTER HERE"}</p></div>
                    
                    </>:<>
                <a href={props.EventWebsiteLink} target="_blank"><div className='carousel_text register_btn' style={{width:"100%"}} ><p >{props.buttonname ? props.buttonname : "REGISTER HERE"}</p></div>
                    </a>
                    </>
                }  
            </div>
            <div className="col-sm-4">
                <Modal show={show} onHide={handleClose}>
                    <div> <span className="btn btn-text ml-auto float-right  mr-3" onClick={handleClose}><img src="/assets/img/close_icon.svg" /></span></div>
                    <Modal.Body>

                        <iframe
                            className='iframe_modal'

                            src={props.lumalink}

                            width="600"

                            height="450"

                            frameborder="0"

                            style={{ border:'1px solid #bfcbda88',borderRadius:'4px' }}

                            allowfullscreen=""

                            aria-hidden="false"

                            tabindex="0"

                        ></iframe>

                        {/* <div style={{ backgroundColor: 'white' }}>
                            <div>
                                <img style={{ height: '40vh', width: '100%', backgroundColor: 'white' }} src='/assets/img/widget/hrtech.jpg' />
                            </div>
                            <div style={{ width: '85%', margin: '0 auto', textAlign: 'start' }}>
                                <h1 style={{ fontSize: '1.8em', fontWeight: '600' }}>Embarking on a Tech driven Employee Mental Wellness journey</h1>
                                <div style={{ display: 'flex', color: '#e75e52', margin: '0.8em 0 0 0' }}>
                                    <div><AiOutlineCalendar /></div>
                                    <div><p>Thu, May 26, 12:30 PM - 2:00 PM GMT+5:30</p></div>
                                    <div><BiInfoCircle /></div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', color: '#2d8cff', }}>
                                    <div style={{ display: 'flex' }}><HiVideoCamera /></div>
                                    <div style={{ margin: '0 0 0 0.5em' }}>Zoom</div>
                                </div>
                                <p style={{ padding: '1.5em 0 1em 0' }}>Hello! To join the event, please register below.</p>
                                <div style={{ backgroundColor: '#f4f5f6' }}>
                                    <div style={{ width: '85%', margin: '0 auto' }}>
                                        <form>
                                            <label className='reg_label'>Name *</label>
                                            <div>  <input type="text" required name="Name" placeholder="Your Name" className='register_input' /> </div>
                                            <label className='reg_label'>Email *</label>
                                            <div>  <input type="text" required name="Email" placeholder="You@email.com" className='register_input' /> </div>
                                            <label className='reg_label'>Company Name  *</label>
                                            <div>  <input type="text" required name="Companyname" className='register_input' /> </div>
                                            <label className='reg_label'>Job Title *</label>
                                            <div style={{ margin: '0 0 1em 0' }}>  <input type="text" required name="Name" className='register_input' /> </div>
                                            <button className='modal_register_btn'>
                                                <span><FiCheckCircle style={{ display: 'flex', margin: '0 0.2em 0 0' }} /></span>
                                                <span>Register</span>
                                            </button>
                                            <div style={{ height: '7vh' }}></div>
                                        </form>
                                    </div>
                                </div>
                                <p style={{ padding: '1em 0 1em 0', color: '#c2b5b7' }}>View Full Event Page &rarr; </p>
                            </div>
                        </div> */}

                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}


export default Registerbutton
