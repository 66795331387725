import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import axios from "axios";
import { API_URL } from '../Api'

const Api = `${API_URL}/EnquireForm`;
const API_Key_Product = `${API_URL}/MarketPlace`;

const DialogDownload = ({ show,ProductIDs,  dialogClose }) => {

    const emailvali = /^([\w-\.]+@(?!gmail)(?!yahoo)(?!hotmail)(?!outlook)(?!icloud)([\w-]+\.)+[\w-]{2,4})?$/;

    const [inputs, setInputs] = useState({
        Name: '',
        Email: '',
        emailError: ''
    })
    const [load, setLoad] = useState('')
    const [Successtitle,setSuccesstitle]=useState('We have sent the resource file to your email.') 


    const history = useHistory()

    const Formsubmit = (event) => {
        event.preventDefault();
       
        let valuess =
        {
            Name: inputs.Name,
            Email: inputs.Email,
           
        }
console.log("valuess",ProductIDs)
        const validate = Validation();
        if (validate) {
            setLoad('Processing...')
            console.log("test")
            axios.post(`${API_Key_Product}/download/${ProductIDs}`,valuess).then(res => {
                console.log("test")
                setInputs({
                    Name: '',
                    Email: '',
                })
                setLoad('')
                history.push({pathname:'/enquire/success',state:Successtitle})
                dialogClose()
            })
        }
    }



    const handleChange = ({ target: { name, value } }) => {
        setInputs({ ...inputs, [name]: value });
    }
    
    const numberCheck =(event)=>{
        var ch = String.fromCharCode(event.which);
        if(!(/[0-9]/.test(ch))){
          event.preventDefault();
        }
    }

    const Validation = () => {
        let emailerrors = '';
        if (!emailvali.test(inputs.Email)) {
            emailerrors = "Please enter the business mail only"
        }
        if (emailerrors) {
            setInputs({
                ...inputs,
                emailError: emailerrors
            })
            return false;
        }
        else {
            setInputs({
                ...inputs,
                emailError: ''
            })
            return true;
        }
    }



    if (!show) {
        return <> </>
    }
    else {
        return (
            <div className="col-sm-4">
                <Modal show={show} onHide={dialogClose}>
                    <div> <span className="btn btn-text ml-auto float-right mt-3 mr-3" onClick={dialogClose}><img src="/assets/img/close_icon.svg" /></span></div>
                    <Modal.Body>
                        <div className='text-center'>
                            <h3>Brochure Download</h3>
                            <p className="mb-30 text-grey " id="brand_name"> </p>
                        </div>
                        <form onSubmit={Formsubmit} >
                            <input type="text" required placeholder="Your Name" name="Name" className="form-control mt-2" onChange={handleChange} value={inputs.Name} />
                            
                            <input type="email" required placeholder="Your Email Id" className="form-control mt-2" name="Email" onChange={handleChange} value={inputs.Email} />
                            <p style={{color:"red",marginTop:'20px'}}>{inputs.emailError}</p>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <br></br>
                                <p className='mr-3' style={{color:"red"}}>{load}</p>

                                </div>
                                <div className='col-sm-6'>
                             <button type="submit" value="Submit" className="btn btn-red-primary border-radius-none btn-head px-5 mt-3 waves-effect waves-light ml-auto float-right">Send</button>


                                </div>

                            </div>
                            {/* <button type="button" className="btn btn-outline-danger px-5 mt-3 waves-effect ml-3" onClick={dialogClose}>Close</button> */}
                        </form>

                    </Modal.Body>
                </Modal>
            </div>
        )
    }

}
export default DialogDownload
