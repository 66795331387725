import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import axios from "axios";
import { API_URL } from '../Api'

const Api = `${API_URL}/EnquireForm`;


const DialogBox = ({ show, title,links, dialogClose }) => {

    const emailvali = /^([\w-\.]+@(?!gmail)(?!yahoo)(?!hotmail)(?!outlook)(?!icloud)([\w-]+\.)+[\w-]{2,4})?$/;

    const [inputs, setInputs] = useState({
        Name: '',
        Email: '',
        Company: '',
        EnquiryFor: title,
        Country: '',
        ContactNumber: '',
        Message: '',
        emailError: ''
    })
    const [Successtitle,setSuccesstitle]=useState('we will get in touch shortly') 


    const history = useHistory()

    const Formsubmit = (event) => {
        event.preventDefault();
        let valuess =
        {
            Name: inputs.Name,
            Email: inputs.Email,
            Company: inputs.Company,
            EnquiryFor: title,
	        MarketURL: links,
            Country: inputs.Country,
            ContactNumber: inputs.countryCode + ' ' + inputs.ContactNumber,
            Message: inputs.Message
        }

        const validate = Validation();
        if (validate) {
            axios.post(`${Api}`, valuess).then(res => {
                setInputs({
                    Name: '',
                    Email: '',
                    Company: '',
                    EnquiryFor: title,
                    Country: '',
                    ContactNumber: '',
                    Message: ''
                })
                history.push({pathname:'/enquire/success',state:Successtitle})
                dialogClose()
            })
        }
    }

    const handleChange = ({ target: { name, value } }) => {
        setInputs({ ...inputs, [name]: value });
    }
    
    const numberCheck =(event)=>{
        var ch = String.fromCharCode(event.which);
        if(!(/[0-9]/.test(ch))){
          event.preventDefault();
        }
    }

    const Validation = () => {
        let emailerrors = '';
        if (!emailvali.test(inputs.Email)) {
            emailerrors = "Please enter the business mail only"
        }
        if (emailerrors) {
            setInputs({
                ...inputs,
                emailError: emailerrors
            })
            return false;
        }
        else {
            setInputs({
                ...inputs,
                emailError: ''
            })
            return true;
        }
    }

    const countryCodes = [
        { code: '+1', name: 'USA' },
        { code: '+44', name: 'UK' },
        { code: '+91', name: 'India' },
        { code: '+93', name: 'Afghanistan' },
        { code: '+355', name: 'Albania' },
        { code: '+213', name: 'Algeria' },
        { code: '+376', name: 'Andorra' },
        { code: '+244', name: 'Angola' },
        { code: '+54', name: 'Argentina' },
        { code: '+374', name: 'Armenia' },
        { code: '+61', name: 'Australia' },
        { code: '+43', name: 'Austria' },
        { code: '+994', name: 'Azerbaijan' },
        { code: '+973', name: 'Bahrain' },
        { code: '+880', name: 'Bangladesh' },
        { code: '+375', name: 'Belarus' },
        { code: '+32', name: 'Belgium' },
        { code: '+501', name: 'Belize' },
        { code: '+229', name: 'Benin' },
        { code: '+975', name: 'Bhutan' },
        { code: '+591', name: 'Bolivia' },
        { code: '+387', name: 'Bosnia and Herzegovina' },
        { code: '+267', name: 'Botswana' },
        { code: '+55', name: 'Brazil' },
        { code: '+673', name: 'Brunei' },
        { code: '+359', name: 'Bulgaria' },
        { code: '+226', name: 'Burkina Faso' },
        { code: '+257', name: 'Burundi' },
        { code: '+855', name: 'Cambodia' },
        { code: '+237', name: 'Cameroon' },
        { code: '+1', name: 'Canada' },
        { code: '+238', name: 'Cape Verde' },
        { code: '+236', name: 'Central African Republic' },
        { code: '+235', name: 'Chad' },
        { code: '+56', name: 'Chile' },
        { code: '+86', name: 'China' },
        { code: '+57', name: 'Colombia' },
        { code: '+269', name: 'Comoros' },
        { code: '+242', name: 'Congo' },
        { code: '+243', name: 'Congo, Democratic Republic of the' },
        { code: '+682', name: 'Cook Islands' },
        { code: '+506', name: 'Costa Rica' },
        { code: '+225', name: "Côte d'Ivoire" },
        { code: '+385', name: 'Croatia' },
        { code: '+53', name: 'Cuba' },
        { code: '+357', name: 'Cyprus' },
        { code: '+420', name: 'Czech Republic' },
        { code: '+45', name: 'Denmark' },
        { code: '+253', name: 'Djibouti' },
        { code: '+593', name: 'Ecuador' },
        { code: '+20', name: 'Egypt' },
        { code: '+503', name: 'El Salvador' },
        { code: '+240', name: 'Equatorial Guinea' },
        { code: '+291', name: 'Eritrea' },
        { code: '+372', name: 'Estonia' },
        { code: '+268', name: 'Eswatini' },
        { code: '+251', name: 'Ethiopia' },
        { code: '+679', name: 'Fiji' },
        { code: '+358', name: 'Finland' },
        { code: '+33', name: 'France' },
        { code: '+241', name: 'Gabon' },
        { code: '+220', name: 'Gambia' },
        { code: '+995', name: 'Georgia' },
        { code: '+49', name: 'Germany' },
        { code: '+233', name: 'Ghana' },
        { code: '+30', name: 'Greece' },
        { code: '+299', name: 'Greenland' },
        { code: '+502', name: 'Guatemala' },
        { code: '+224', name: 'Guinea' },
        { code: '+245', name: 'Guinea-Bissau' },
        { code: '+592', name: 'Guyana' },
        { code: '+509', name: 'Haiti' },
        { code: '+504', name: 'Honduras' },
        { code: '+36', name: 'Hungary' },
        { code: '+354', name: 'Iceland' },
        { code: '+62', name: 'Indonesia' },
        { code: '+98', name: 'Iran' },
        { code: '+964', name: 'Iraq' },
        { code: '+353', name: 'Ireland' },
        { code: '+972', name: 'Israel' },
        { code: '+39', name: 'Italy' },
        { code: '+81', name: 'Japan' },
        { code: '+962', name: 'Jordan' },
        { code: '+7', name: 'Kazakhstan' },
        { code: '+254', name: 'Kenya' },
        { code: '+686', name: 'Kiribati' },
        { code: '+383', name: 'Kosovo' },
        { code: '+965', name: 'Kuwait' },
        { code: '+996', name: 'Kyrgyzstan' },
        { code: '+856', name: 'Laos' },
        { code: '+371', name: 'Latvia' },
        { code: '+961', name: 'Lebanon' },
        { code: '+266', name: 'Lesotho' },
        { code: '+231', name: 'Liberia' },
        { code: '+218', name: 'Libya' },
        { code: '+423', name: 'Liechtenstein' },
        { code: '+370', name: 'Lithuania' },
        { code: '+352', name: 'Luxembourg' },
        { code: '+853', name: 'Macau' },
        { code: '+389', name: 'North Macedonia' },
        { code: '+261', name: 'Madagascar' },
        { code: '+265', name: 'Malawi' },
        { code: '+60', name: 'Malaysia' },
        { code: '+960', name: 'Maldives' },
        { code: '+223', name: 'Mali' },
        { code: '+356', name: 'Malta' },
        { code: '+692', name: 'Marshall Islands' },
        { code: '+222', name: 'Mauritania' },
        { code: '+230', name: 'Mauritius' },
        { code: '+52', name: 'Mexico' },
        { code: '+691', name: 'Micronesia' },
        { code: '+373', name: 'Moldova' },
        { code: '+377', name: 'Monaco' },
        { code: '+976', name: 'Mongolia' },
        { code: '+382', name: 'Montenegro' },
        { code: '+212', name: 'Morocco' },
        { code: '+258', name: 'Mozambique' },
        { code: '+95', name: 'Myanmar' },
        { code: '+264', name: 'Namibia' },
        { code: '+674', name: 'Nauru' },
        { code: '+977', name: 'Nepal' },
        { code: '+31', name: 'Netherlands' },
        { code: '+64', name: 'New Zealand' },
        { code: '+505', name: 'Nicaragua' },
        { code: '+227', name: 'Niger' },
        { code: '+234', name: 'Nigeria' },
        { code: '+47', name: 'Norway' },
        { code: '+968', name: 'Oman' },
        { code: '+92', name: 'Pakistan' },
        { code: '+680', name: 'Palau' },
        { code: '+970', name: 'Palestine' },
        { code: '+507', name: 'Panama' },
        { code: '+675', name: 'Papua New Guinea' },
        { code: '+595', name: 'Paraguay' },
        { code: '+51', name: 'Peru' },
        { code: '+63', name: 'Philippines' },
        { code: '+48', name: 'Poland' },
        { code: '+351', name: 'Portugal' },
        { code: '+974', name: 'Qatar' },
        { code: '+40', name: 'Romania' },
        { code: '+7', name: 'Russia' },
        { code: '+250', name: 'Rwanda' },
        { code: '+290', name: 'Saint Helena' },
        { code: '+1-869', name: 'Saint Kitts and Nevis' },
        { code: '+1-758', name: 'Saint Lucia' },
        { code: '+508', name: 'Saint Pierre and Miquelon' },
        { code: '+1-784', name: 'Saint Vincent and the Grenadines' },
        { code: '+685', name: 'Samoa' },
        { code: '+378', name: 'San Marino' },
        { code: '+239', name: 'Sao Tome and Principe' },
        { code: '+966', name: 'Saudi Arabia' },
        { code: '+221', name: 'Senegal' },
        { code: '+381', name: 'Serbia' },
        { code: '+248', name: 'Seychelles' },
        { code: '+232', name: 'Sierra Leone' },
        { code: '+65', name: 'Singapore' },
        { code: '+421', name: 'Slovakia' },
        { code: '+386', name: 'Slovenia' },
        { code: '+677', name: 'Solomon Islands' },
        { code: '+252', name: 'Somalia' },
        { code: '+27', name: 'South Africa' },
        { code: '+82', name: 'South Korea' },
        { code: '+211', name: 'South Sudan' },
        { code: '+34', name: 'Spain' },
        { code: '+94', name: 'Sri Lanka' },
        { code: '+249', name: 'Sudan' },
        { code: '+597', name: 'Suriname' },
        { code: '+268', name: 'Swaziland' },
        { code: '+46', name: 'Sweden' },
        { code: '+41', name: 'Switzerland' },
        { code: '+963', name: 'Syria' },
        { code: '+886', name: 'Taiwan' },
        { code: '+992', name: 'Tajikistan' },
        { code: '+255', name: 'Tanzania' },
        { code: '+66', name: 'Thailand' },
        { code: '+670', name: 'Timor-Leste' },
        { code: '+228', name: 'Togo' },
        { code: '+676', name: 'Tonga' },
        { code: '+1-868', name: 'Trinidad and Tobago' },
        { code: '+216', name: 'Tunisia' },
        { code: '+90', name: 'Turkey' },
        { code: '+993', name: 'Turkmenistan' },
        { code: '+688', name: 'Tuvalu' },
        { code: '+256', name: 'Uganda' },
        { code: '+380', name: 'Ukraine' },
        { code: '+971', name: 'United Arab Emirates' },
        { code: '+598', name: 'Uruguay' },
        { code: '+998', name: 'Uzbekistan' },
        { code: '+678', name: 'Vanuatu' },
        { code: '+58', name: 'Venezuela' },
        { code: '+84', name: 'Vietnam' },
        { code: '+681', name: 'Wallis and Futuna' },
        { code: '+967', name: 'Yemen' },
        { code: '+260', name: 'Zambia' },
        { code: '+263', name: 'Zimbabwe' },
      ];
      


    if (!show) {
        return <> </>
    }
    else {
        return (
            <div className="col-sm-4">
                <Modal show={show} onHide={dialogClose}>
                    <div> <span className="btn btn-text ml-auto float-right mt-3 mr-3" onClick={dialogClose}><img src="/assets/img/close_icon.svg" /></span></div>
                    <Modal.Body>
                        <div className='text-center'>
                            <h3>Enquire</h3>
                            <p className="mb-30 text-grey " id="brand_name">For {title}</p>
                        </div>
                        <form onSubmit={Formsubmit} >
                            <input type="text" required placeholder="Your Name" name="Name" className="form-control mt-2" onChange={handleChange} value={inputs.Name} />
                            <input type="text" required placeholder="Your Company Name" className="form-control mt-2" name="Company" onChange={handleChange} value={inputs.Company} />
                            <input type="email" required placeholder="Your Email Id" className="form-control mt-2" name="Email" onChange={handleChange} value={inputs.Email} />
                            <p style={{color:"red",marginTop:'20px'}}>{inputs.emailError}</p>
                            <input type="text" required placeholder="Your Country" className="form-control mt-2" name="Country" onChange={handleChange} value={inputs.Country} />
                            
                            <div className="my-3" style={{ textAlign: 'start' }}>
                                <label className="active">
                                    Contact Number <span className="text-danger">*</span>
                                </label>
                                <div className="d-flex">
                                    <select
                                        name="countryCode"
                                        className="form-control mt-2 me-2"
                                        onChange={handleChange}
                                        value={inputs.countryCode}
                                        required
                                    >
                                        <option value="" disabled>Select country code</option>
                                        {countryCodes.map((country, index) => (
                                            <option key={index} value={country.code}>
                                                {country.name} ({country.code})
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                        type="text"
                                        onKeyPress={numberCheck}
                                        required
                                        maxLength={15}
                                        minLength={6}
                                        placeholder="Contact Number"
                                        className="form-control mt-2"
                                        name="ContactNumber"
                                        onChange={handleChange}
                                        value={inputs.ContactNumber}
                                    />
                                </div>
                                <p className="text-danger"> </p>
                            </div>

                            <textarea width="100%" rows={5} id="id_message" required placeholder="Your Message" cols={40} className="form-control h-100 mt-2" name="Message" onChange={handleChange} value={inputs.Message} />

                            <button type="submit" value="Submit" className="btn btn-red-primary border-radius-none btn-head px-5 mt-3 waves-effect waves-light ml-auto float-right">Send</button>
                            {/* <button type="button" className="btn btn-outline-danger px-5 mt-3 waves-effect ml-3" onClick={dialogClose}>Close</button> */}
                        </form>

                    </Modal.Body>
                </Modal>
            </div>
        )
    }

}
export default DialogBox

