import React from 'react';
 

const Four_pages = () => {
    return   <div className="_center">
    <div className="">
        <img src="https://www.hrtech.sg/assets/img/logo.png" alt="" className="error_img" />
    </div>
    <div className="error">
        <h1 className="font-weight-bold">404. That’s an error.</h1>
        <p className="lead">The requested URL was not found on this website.<br/>
            That’s all we know. Please click on the logo and try accessing other links from our <a href="/" className="error_link"> home page.</a> </p>
    </div>

</div>
}

export default Four_pages

