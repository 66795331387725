import React, { useState,useEffect } from 'react';
import { Link, NavLink, useParams,useHistory } from 'react-router-dom';
import { FaSearch, FaChevronRight,FaChevronDown,FaRegWindowClose, FaLinkedin } from 'react-icons/fa';
import Dropdown from 'react-bootstrap/Dropdown';
import { API_URL } from '../Api'
import axios from "axios";
import Search from '../Search'
const APIGET = `${API_URL}/Searching`;

const Timer = (props)=>{
  const [eventtime,seteventtime]=useState({
    day:"",
    hour:"",
    minut:"",
    sec:"",
  })
  useEffect(() => {
    // fetch();
    //fetchHome();
    var countDownDate = new Date("mar 14, 2023 11:30:25").getTime();
    setInterval(()=>{
   
      var now = new Date().getTime();
      var distance = countDownDate - now;
      // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
 var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      seteventtime({
        day:days,
        hour:hours,
        minut:minutes,
        sec:seconds,
      })


    },1000);
    
  }, [])
  return (
    <>
    <div>
    <div className='row'>
      <div className='col-md-12'>
        <div className='m-1'>
      <p className='timezo' style={{color:"black"}}> 
        <span><b>{eventtime.day}</b> Days </span> 
        <span><b>{eventtime.hour}</b> Hours</span> 
        <span><b>{eventtime.minut}</b>  Mins </span> 
        <span><b>{eventtime.sec}</b>Sec </span> 
        </p>

        </div>
      {/* <p>Days : {eventtime.day} Hours:{eventtime.hour} minutes : {eventtime.minut} Seconds:{eventtime.sec}</p> */}

      </div>

    </div>
    </div>
    </>
  )
}



const Navbar = () => {
  let history = useHistory();
  const [showDropdown, setShowDropdown] = useState(false);
  const [mobilesearchpopup, setmobilesearchpopup] = useState(false);
  const [Searching, SetSearching] = useState({
    keywords:""
  })

  useEffect(() => {
    // getdata();
 }, [])

 const searchpopupopen =()=>{
  setmobilesearchpopup(true)
}
const searchpopupclose =()=>{
  setmobilesearchpopup(false)
}


 const getdata = async()=>{
  const Result = await axios.get(`${APIGET}`,{params:{keyword:Searching.keywords}})
  console.log("uuu", Result.data)

 }

  const adding = async({ target: { name, value } }) => {
    SetSearching({ ... Searching, [name]: value });
    // const Result = await axios.post(`${APIGET}`,{keyword:value})
    // console.log("uuu", Result.data)
  }

  
  const openNav = () => {
    var element = document.getElementById("mySidenavAdd");
    element.classList.add("Addding");
    document.getElementById("mySidenav").style.width = "250px";
    document.getElementById("mySidenav").style.left = "0px";
  }
  const closeNav = () => {
    var element = document.getElementById("mySidenavAdd");
    element.classList.remove("Addding");
    document.getElementById("mySidenav").style.width = "0";
  }

  const Showdropdownbar = () => setShowDropdown(!showDropdown);
  

  const URL = window.location.pathname;
  let paths = URL.split("/");
  let currentpath = paths[1];

  // console.log("url", currentpath)

  const onSubmited =async(e)=>{
    e.preventDefault();
    const Result = await axios.post(`${APIGET}`,{keyword:Searching.keywords})
   if(Result.data){
    history.push({
      pathname: `/search`,
      search: `?search=${Searching.keywords}/`,
      state: { detail: `${Searching.keywords}` }
  });
  // history.push(`/search?search=${Searching.keywords}`)
     
   }
  }

  return (
<>

  {/* <div className="alert alert-warning alert-dismissible fade show countbg fixeds_bars" role="alert">
<div className=' '>
        <div className='row'>
          <div className='col-md-12'>
            <div className='text-center timermain'>             
           <a href="https://www.hrtech.sg/events/people-analytics-masterclass-march-2023-/?utm_source=website&utm_medium=banner&utm_campaign=pa_events_countdown/"><span style={{color:"black"}}><span><img src='https://cdn-icons-png.flaticon.com/128/6713/6713794.png' width='27px'></img></span> Workshop: Embarking on your People Analytics Journey</span> <span className='uniconcta' style={{textDecoration: 'none',color:"white",background:"#f04645",padding:"5px 10px 5px 10px",borderRadius:"5px"}}>Register Now <i classname="fas fa-play" style={{fontSize: 10}} /></span></a>
           <Timer/>

            </div>
          </div>
        </div>
      
    
        </div>
  <button type="button" className="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true" style={{color:"red"}}>×</span>
  </button>
</div> */}
    <header className="position-fixed w-100" style={{ overfloeX: 'hidden' }}>

      <nav className="navbar navbar-expand-lg navbar-light h-65px p-0 position-relative nav_d" id="web_applicant_nav_bar">
        <div className="container-fluid collapse d-flex align-item-center h-100 pr-md-0" id="navbarTogglerDemo01">
          <div className="d-flex align-items-center" style={{ paddingRight: '50px' }}>
            <a className="navbar-brand p-0" href="/"><img src="https://www.hrtech.sg/assets/img/logo.svg" className="HR_logo" alt="HR_logo" /></a>
            <div className="nav-item text-black mx-4">
              <form onSubmit={onSubmited}>
          <div className="input-group global_search border">
            <input type="text" className="form-control mt-0 border-0 input_search" onChange={ adding} name="keywords" value={Searching.keywords} placeholder="Search" id="global_search" />
            <div className="input-group-append">
              <span className="input-group-text bg-transparent border-0 cursor-pointer" onClick={onSubmited}>
              <FaSearch type='submit'/>
              </span>
            </div>
          </div>
          </form>
        </div>
          </div>
<div>
  <div className='top_navi'>
<ul className='navbar-nav'>
<li className={currentpath == "hr-glossary"  ? " active" : " "}>
              <a href={"/hr-glossary/"} className=" ">HR GLOSSARY</a>
            </li>
<li className={currentpath == "about-us" ? " active" : ""}>
              <a href={"/about-us/"} className=" ">About Us</a>
            </li>
<li className={currentpath == "contact-us" ? " active" : " "}>
              <a href={"/contact-us/"} className=" ">Contact Us</a>
            </li>
<li >
              <a href={"https://docs.google.com/forms/d/1h8o6qFgxRnp9xHjNPDTHxjw8Q5_9988574bDOWj6JLE"} className=" ">List Your Product</a>
            </li>
</ul>
  </div>
<ul className="navbar-nav h-100">
            <li className={currentpath == "market_map_pdf" ? "nav-item text-black active" : "nav-item text-black "}>
              <a href={"/market_map_pdf/"} className="nav-link bor h-100 py_1_2rem f-16 waves-effect waves-light">MarketMap</a>
            </li>
            <li className={(currentpath == "marketplace" || (currentpath != "careers" &&  currentpath != "market_map_pdf" && currentpath != "jobs" && currentpath != "resources" && currentpath != "blog" && currentpath != "news" && currentpath != "resources-details" && currentpath != "events" && currentpath != "tech_talks" && currentpath != "contact-us" &&  currentpath != "hr-glossary" && currentpath != "about-us" && currentpath != "certifications" && currentpath != "")) ? "nav-item text-black active" : "nav-item text-black "}>
              <a href={"/marketplace/"} className="nav-link bor h-100 py_1_2rem f-16 waves-effect waves-light" >MarketPlace</a>
            </li>
             <li className={currentpath == "certifications" ? "nav-item text-black active" : "nav-item text-black "}>
              <a href={"/certifications/"}   className="nav-link bor h-100 py_1_2rem f-16 waves-effect waves-light" >Certifications</a>
            </li>
            {/* <li className={currentpath == "jobs" ? "nav-item text-black active" : "nav-item text-black "}>
              <a href={"/jobs/"}  className="nav-link bor h-100 py_1_2rem f-16 waves-effect waves-light" >Jobs</a>
            </li> */}
            <li className={(currentpath == "resources" || currentpath == "blog" || currentpath == "tech_talks")? "nav-item text-black dropdown active " : "nav-item text-black dropdown dropsdowns"}>
              <a  href="/resources/"  className="nav-link bor h-100 py_1_2rem f-16 waves-effect waves-light" >insights&nbsp;<span className="down-nav-floa "><FaChevronDown /></span></a>
              <div className="dropdown-content">
                <a href={"/resources/reports/"}>Reports</a>
                <a href={"/resources/ebooks/"}>eBooks</a>                 
                <a href={"/resources/infographics/"}>Infographics</a>                 
                {/*<a href={"/resources/know_your_hrtech_solution/"}>Know Your Solution</a>  */}               
                <a href={"/resources/case_studies/"}>Case Studies</a>                 
                <a href={"https://hrtech.sg/blog"}>Blogs</a>
		{/*<a href={"/articles/"}>Articles</a>                  */}
                <a href={"/tech_talks/"}>Tech Talks</a>                                 
                <hr className='custom_hr'></hr>
              </div>
            </li>         

             {/* <li className={currentpath == "resources" || currentpath == "resources-details" ? "nav-item text-black active" : "nav-item text-black "}>
              <a href="/resources/"  className="nav-link bor h-100 py_1_2rem f-16 waves-effect waves-light">Resources</a>

             </li> */}
            {/* <li className={currentpath == "blog" ? "nav-item text-black active" : "nav-item text-black "}>
              <a href="/blog/"  className="nav-link bor h-100 py_1_2rem f-16 waves-effect waves-light">blogs</a>

            </li> */}
            <li className={currentpath == "events" ? "nav-item text-black active" : "nav-item text-black "}>
              <a href={"/events/"}  className="nav-link bor h-100 py_1_2rem f-16 waves-effect waves-light">events</a>

            </li>
            {/* <li className={currentpath == "tech_talks" ? "nav-item text-black active" : "nav-item text-black "}>
              <a href="/tech_talks/"  className="nav-link bor h-100 py_1_2rem f-16 waves-effect waves-light">TechTalks</a>

            </li> */}
            <li className={currentpath == "news" ? "nav-item text-black active" : "nav-item text-black "}>
              <a href="/news/"  className="nav-link bor h-100 py_1_2rem f-16 waves-effect waves-light">News</a>

            </li>

            {/* <li className={currentpath == "about-us"? "nav-item text-black active" : "nav-item text-black "}>
                <a href={"/about-us"}  className="nav-link bor h-100 py_1_2rem f-16 waves-effect waves-light" >About Us</a>
              </li>
              <li className={currentpath == "contact-us"? "nav-item text-black active" : "nav-item text-black "}>
                <a href={"/contact-us/"}  className="nav-link bor h-100 py_1_2rem f-16 waves-effect waves-light" >Contact Us</a>
              </li> */}
           
            {/* <div className="dropdown">            
              <span className="dropbtn"><img src="/assets/img/menu_b.png" className="custom_img"></img></span>
              <div className="dropdown-content">
                <a href={"/about-us"}>About Us</a>
                <a href={"/contact-us/"}>Contact Us</a>                 
                <hr className='custom_hr'></hr>
              </div>
            </div> */}


          </ul>

</div>
         

        </div>
      </nav>
      <div id="mySidenav" className="sidenav hidden-md hidden-lg side-nav-style   ">
        <a className="closebtn" onClick={() => closeNav()}>×</a>
        <a href={'/'} className="white navbar-brand-mobile mobile-menu-logo" >
          <img src="https://www.hrtech.sg/assets/img/logo.svg" width={180} alt="LOGO" className="logo_img" /></a>
          <ul>
            <li>
        <a href={'/market_map_pdf/'} className="sidebarLink" target='_blank'><span className="side-nav-sty">MARKETMAP</span>
          </a>
          </li>
          <li>
        <a href={'/marketplace/'} className="sidebarLink" ><span className="side-nav-sty">MARKET PLACE</span>
          </a>
          </li>
          <li>
      
        <a href='/certifications/'  className="sidebarLink " ><span className="side-nav-sty ">CERTIFICATIONS</span>
         </a>
          </li>

        {/*  <li>
      
        <a href={'/jobs/'} className="sidebarLink " ><span className="side-nav-sty ">JOBS</span>
          </a>
          </li> */}


{/* test */}
 
<li>
      
      <Link to="/resources/" className="sidebarLink " ><span className="side-nav-sty " onClick={Showdropdownbar}>INSIGHTS</span>
   {showDropdown ? <>
    <span className="side-nav-floa"><FaChevronDown /></span>
    </>:<>
    <span className="side-nav-floa"><FaChevronRight /></span>
    </>}   
      
      
       </Link>
         {showDropdown ? <>
         <ul>
          <li>
          <a href={'/resources/reports/'} className="  " style={{padding:"0.5rem 2rem",display:"flex",fontSize:"12px"}} ><span className="side-nav-sty ">Reports</span>
          <span className="side-nav-floa"><FaChevronRight /></span></a>
          </li>
          <li>
          <a href={'/resources/ebooks/'} className="  " style={{padding:"0.5rem 2rem",display:"flex",fontSize:"12px"}}><span className="side-nav-sty ">eBooks</span>
          <span className="side-nav-floa"><FaChevronRight /></span></a>
          </li>
          <li>
          <a href={'/resources/infographics/'} className="  " style={{padding:"0.5rem 2rem",display:"flex",fontSize:"12px"}}><span className="side-nav-sty ">Infographics</span>
          <span className="side-nav-floa"><FaChevronRight /></span></a>
          </li>
          {/*<li>
          <a href={'/resources/know_your_hrtech_solution/'} className="  " style={{padding:"0.5rem 2rem",display:"flex",fontSize:"12px"}}><span className="side-nav-sty ">Know Your Solution</span>
          <span className="side-nav-floa"><FaChevronRight /></span></a>
          </li>*/}
          <li>
          <a href={'/resources/case_studies/'} className="  " style={{padding:"0.5rem 2rem",display:"flex",fontSize:"12px"}}><span className="side-nav-sty "> Case Studies</span>
          <span className="side-nav-floa"><FaChevronRight /></span></a>
          </li>
          <li>
          <a href={'/blog/'} className="  " style={{padding:"0.5rem 2rem",display:"flex",fontSize:"12px"}}><span className="side-nav-sty ">Blogs</span>
          <span className="side-nav-floa"><FaChevronRight /></span></a>
          </li>
 <li>
          <a href={'/articles/'} className="  " style={{padding:"0.5rem 2rem",display:"flex",fontSize:"12px"}}><span className="side-nav-sty ">Articles</span>
          <span className="side-nav-floa"><FaChevronRight /></span></a>
          </li>
          <li>
          <a href={'/tech_talks/'} className="  " style={{padding:"0.5rem 2rem",display:"flex",fontSize:"12px"}}><span className="side-nav-sty ">Tech Talks</span>
          <span className="side-nav-floa"><FaChevronRight /></span></a>
          </li>
         
         </ul>
         </> : null}
        </li>

{/* test end */}

          <li>
        <a href={'/events/'} className="sidebarLink"  ><span className="side-nav-sty">EVENTS</span>
         </a>
          </li>
          <li>
        <a href={'/news/'} className="sidebarLink"  ><span className="side-nav-sty">NEWS</span>
         </a>
          </li>
          {/* <li>
        <Link to={'/resources'} className="sidebarLink" ><span className="side-nav-sty">RESOURCES</span>
          <span className="side-nav-floa"><FaChevronRight /></span></Link>
          </li> */}
          {/* <li>
        <Link to={'https://blog.hrtech.sg/'} className="sidebarLink" href="/"><span className="side-nav-sty">BLOGS</span>
          <span className="side-nav-floa"><FaChevronRight /></span></Link>
          </li> */}
        
          {/* <li>
        <Link to={'/tech_talks/'} className="sidebarLink" href="/"><span className="side-nav-sty">TECHTALKS</span>
          <span className="side-nav-floa"><FaChevronRight /></span></Link>
          </li> */}
          <li>
        <a href={'/hr-glossary/'} className="sidebarLink" ><span className="side-nav-sty">HR GLOSSARY
</span>
          </a>
          </li>
          <li>
        <a href={'/about-us/'} className="sidebarLink" ><span className="side-nav-sty">ABOUT US</span>
          </a>
          </li>
          <li>
        <a href={'/contact-us/'} className="sidebarLink" ><span className="side-nav-sty">CONTACT US</span>
         </a>
          </li>
          <li>
        <a href="https://docs.google.com/forms/d/1h8o6qFgxRnp9xHjNPDTHxjw8Q5_9988574bDOWj6JLE" className="sidebarLink" ><span className="side-nav-sty">LIST YOUR PRODUCT</span>
         </a>
          </li>
          </ul>
        <div className="sidebarLink d-block border-top text-center" id="mobile_login">
          <div className="row mx-0 d-block">
            <div className="col-12 p-0 f-18 mb-3 text-white">LOGIN WITH</div>
          </div>
          <div className="row m-auto d-flex">
            <div className="p-0 col-4 d-flex justify-content-center align-items-center">
              {/* <a className="white navbar-brand py-0 fb_icon m-0 dis-con" href="/">
            <img src="/assets/img/facebook_logo.png" height="40px" width="40px" /></a> */}
            </div>

            <div className="col-4 p-0  justify-content-center align-items-center text-white">
              <a className="link_image" href="/">
                <FaLinkedin />
              </a>
            </div>
            <div className="col-4 px-2 d-flex justify-content-center align-items-center">
              {/* <a className="white navbar-brand py-0 fb_icon m-0 dis-con" href="/">
            <img src="/assets/img/google_logo.png" height="42px" width="42px" /></a> */}
            </div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 col-xs-12 text-center p-md-4 p-2 priv-sty">
          <a href="/" className="text-white f-14">© IKIGAI ENABLERS PTE. LTD.</a>
          <a className="f-14 text-white privacy_link" href="/">Privacy Policy</a>
        </div>
      </div>

      <div id="mySidenavAdd" ></div>
      
      <span className="side-bar-op" onClick={() => openNav()}>☰ open</span>
      <div id="mobile_applicant_nav_bar">
        <nav className="navbar fixed-top side-bar-z" id="my-nav">
          <span data-toggle="collapse" data-target="#mysidemenu" className="nav_icon navbar-brand mobile-menu-op" onClick={openNav}><img src="/assets/img/menu_b.png" alt="logos" /></span>
          <a className="white navbar-brand-mobile m-auto" href="/"><img src="/assets/img/logo.svg" alt="LOGO" className="logo_img d-flex m-auto" /></a>
          <span className='pr-3 pe-auto' onClick={searchpopupopen}><FaSearch /></span>
          {mobilesearchpopup ? <>
          <div class="searchbar">
          <div className="nav-item text-black">
              <form onSubmit={onSubmited}>
          <div className="input-group global_search border">
            <input type="text" className="form-control mt-0 border-0 input_search" onChange={ adding} name="keywords" value={Searching.keywords} placeholder="Search" id="global_search" />
            <div className="input-group-append">
              <span className="input-group-text bg-transparent brright">
              <FaSearch type='submit' onClick={onSubmited}/>
              </span>
              <span className="input-group-text bg-transparent border-0">
              <FaRegWindowClose onClick={searchpopupclose}/>
              </span>
            </div>
          </div>
          </form>
        </div>
            </div></>:null}
        </nav>
 


        <div className="w-100 search_bar_mobile d-none justify-content-center">
          <div className="input-group global_search border w-mb-90 my-3">
            <input type="text" className="form-control mt-0 border-0 input_search" placeholder="Search" id="mobile_global_search" name="mobile_global_search" />
          </div>
        </div>

      </div>
    </header>

</>

  )

}


export default Navbar
