import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaRegCalendarAlt, FaRegBuilding, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Navbar from './global-components/navbar';
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';
import Footer from './global-components/footer';
import Counter from './section-components/counter';
import { FaEnvelope } from 'react-icons/fa';
import './news.css';
import EventCarousel from './EventCarousel';
// import DialogBox from './event-register-modal';
import axios from 'axios';
import { API_URL } from './Api';
import EventsView from './EventsView';
import moment from 'moment';
import {Helmet} from "react-helmet";



const URL = `${API_URL}/Events`;
const UpcomingEvent_API = `${API_URL}/Events/filter/Upcoming`;
const PastEvent_API = `${API_URL}/Events/filter/Past`;
const urlimg = `${API_URL}/Events_image`;

const Event = () => {

  const [EventData, SetEventData] = useState([]);
  const [UpcomingEvent, SetUpcomingEvent] = useState([]);
  const [PastEvent, SetPastEvent] = useState([]);
  const [Footerload, SetFooterload] = useState(false)
 

  useEffect(() => {
    setTimeout(() => {
      SetFooterload(true)
    }, 500)
    fetchData();
    UpcomingEventdata();
    PastEventdata();
  }, []);

  const fetchData = async () => {
    const { data } = await axios.get(URL);
    SetEventData(data);
 
  }
  const UpcomingEventdata = async () => {
    const { data } = await axios.get(UpcomingEvent_API);
    let sort = data.sort((a, b) => {
			return (
				new Date(b.EventDate) -
				new Date(a.EventDate)
			)
		}).reverse()
    SetUpcomingEvent(sort);
    console.log("bbbbb",data)
 
  }
  const PastEventdata = async () => {
    const { data } = await axios.get(PastEvent_API);
    let sort = data.sort((a, b) => {
			return (
				new Date(a.EventDate) -
				new Date(b.EventDate)
			)
		}).reverse()
    SetPastEvent(sort);
 
  }

  return (
    <>
      <div>
 
        <Navbar />
         

        <section className='py-md-5 py-4 title_bg jumbotron jumbotron-fluid text-center m-0 '>
          <div className="container-fluid" >
            {EventData.length >0 ? 
            <EventCarousel />
            :null}
          </div>

        </section>

 <section>
 	<nav>
		<ol className="cd-breadcrumb custom-separator">
			<li><a href="/">Home</a></li>
			 
			<li className="current"><em>Events</em></li>
		</ol>
	</nav>
</section>
       
             
        {UpcomingEvent.length >0 ?
        <section>
           {Footerload ? <>
          <div className='row m-0' style={{ padding: '3em 0 0em 0', }}>
            <div className='col-md-12'>
              <h5 className='text-center f-28 fw-600 mb-4'>Upcoming Events</h5>
            </div>
          </div>
          <div className='col-md-12 col-sm-12 m-auto px-0 upocoming_event_container'>
            <div className='row m-0 d-flex justify-content-center'>
              {UpcomingEvent.map(UpcomingEvent =>{
                return(
                  <div className='col-lg-4 col-md-4 col-sm-6 col-12 index_margin blog_new_location ps-0 mb-30'>
                <div className='card event_card_height border-radius-none' id='past_event_card'>
                <a href={`/events/${UpcomingEvent.Eventurlname.replace(/ /g, '-').toLowerCase()}/`}>
                    <div className='card-header bg-transparent border-0 position-relative py-2'>
                      <div className='row aftr_rt_bor'>
                        <div className='col-lg-2 col-md-2 col-sm-3 col-2 px-0 align-items-center d-flex justify-content-center'>
                          <h2 className='m-0 text-black text-center f-32 f-mb-24 fw-600'> {moment( UpcomingEvent.EventDate).format("DD")}</h2>
                        </div>
                        <div className='col-lg-10 col-md-10 col-sm-9 col-10 d-flex align-self-center pl-md-1 pl-0'>
                          <div className='d-block h-100'>
                            <p className='m-0 link_blog text-black f-12'>{moment( UpcomingEvent.EventDate).format('dddd')}</p>
                            <p className='m-0 font-weight-400 f-12 text-black'>{moment( UpcomingEvent.EventDate).format("MMM  YYYY")}</p>
                          </div>
                        </div>
                      </div>
                      <div className='w-100 bg-white event--img border_top border_bottom mt-2' style={{ position: 'relative', textAlign: 'center' }}>
                        <img className='card-img-bottom' alt="sample" style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={`${urlimg}/${UpcomingEvent.EventImage}`} />
                      </div>
                      <div className='card-body event_body fixed_content_3 padding' id='past_event_body'>
                        <h4 className='mt-0 mb-2 text-black fixed_content f-mb-20 fw-600' style={{ height: 'auto', marginBottom: '8px' }}>
                         {UpcomingEvent.EventName}
                        </h4>
                        <div className='event--description f-14 fixed_content_3 text-grey line-3' >
                         {UpcomingEvent.EventShortDescription}
                        </div>
                      </div>
                    </div>
                  </a>
 <div className='row mx-0 mt-3'>
                        <div className='col-lg-12 col-md-9 col-sm-10 col-12 pb-3'>
                          <a href={`/events/${UpcomingEvent.Eventurlname.replace(/ /g, '-').toLowerCase()}/`} className='btn btn-red-primary-outline border-radius-none ml-1 f-14 waves-effect waves-light'>
                            REGISTER HERE
                          </a>
                        </div>
                      </div>
                </div>
              </div>
                )
              })}
              
            </div>
          </div>
         </> :null}
        </section>
        :null}


        {Footerload ? 
        <section>
          <div className="py-5 bg-lightgrey  text-center m-0">
            <div className="container-fluid">
              <div className="row mx-auto">
                <div className="col-md-12 mb-4">
                  <h2 className="f-40 f-mb-22 fw-600 d-md-block d-none">Looking to partner with us for EVENTS?</h2>
                  <h2 className="f-40 f-mb-22 fw-600 d-block d-md-none">Looking to partner with us <br />for EVENTS?</h2>
                </div>
                <div className="col-md-12">
                  <a href='/contact-us'>
                    <button className="m-auto d-flex f-20 f-mb-16 btn-red-primary px-4 py-2" onclick="location.href=/contact-us/" >Contact Us</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>:null}
        {PastEvent.length > 0 ? 
        <section>
           {Footerload ? <>
          <div className='container-fluid py-5 px-md-0'>
            <div className='row m-0'>
              <div className='col-md-12'>
                <h5 className='text-center f-28 fw-600 mb-4'>Past Events
                </h5>
              </div>
            </div>
            <div className='col-md-12 col-sm-12 m-auto px-0'>
              <div className='row m-0 d-flex justify-content-center'>
                {PastEvent.map(Pasteven =>{
                  return(
                    <div className='col-lg-4 col-md-4 col-sm-6 col-12 index_margin blog_new_location ps-0 mb-30'>
                    <div className='card event_card_height border-radius-none' id='past_event_card'>
                    <a href={`/events/${Pasteven.Eventurlname.replace(/ /g, '-').toLowerCase()}/`}>
                        <div className='card-header bg-transparent border-0 position-relative py-2'>
                          <div className='row aftr_rt_bor'>
                            <div className='col-lg-2 col-md-2 col-sm-3 col-2 px-0 align-items-center d-flex justify-content-center'>
                              <h2 className='m-0 text-black text-center f-32 f-mb-24 fw-600'>{moment( Pasteven.EventDate).format("DD")}</h2>
                            </div>
                            <div className='col-lg-10 col-md-10 col-sm-9 col-10 d-flex align-self-center pl-md-1 pl-0'>
                              <div className='d-block h-100'>
                                <p className='m-0 link_blog text-black f-12'>{moment( Pasteven.EventDate).format('dddd')}</p>
                                <p className='m-0 font-weight-400 f-12 text-black'>{moment( Pasteven.EventDate).format("MMM  YYYY")}</p>
                              </div>
                            </div>
                          </div>
                          <div className='w-100 bg-white event--img border_top border_bottom mt-2' style={{ position: 'relative', textAlign: 'center' }}>
                            <img className='card-img-bottom' alt="sample" style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={`${urlimg}/${Pasteven.PreEventImages}`} />
                          </div>
                          <div className='card-body event_body fixed_content_3 padding' id='past_event_body'>
                            <h4 className='mt-0 mb-2 text-black fixed_content f-mb-20 fw-600' style={{ height: 'auto', marginBottom: '8px' }}>
                            {Pasteven.EventName}
                            </h4>
                            <div className='event--description f-14 fixed_content_3 text-grey line-3'>
                            {Pasteven.EventShortDescription}
                            </div>
                          </div>
                        </div>
                      </a>
                      <div className='row mx-0 mt-3'>
                        <div className='col-lg-12 col-md-9 col-sm-10 col-12 pb-3'>
                          <a href={`/events/${Pasteven.Eventurlname.replace(/ /g, '-').toLowerCase()}/`} className='btn btn-red-primary-outline border-radius-none ml-1 f-14 waves-effect waves-light'>
                            View Details
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  )
                })}
               
              </div>
            </div>
          </div>
          </>:null}
        </section>
        :null}
      </div>
      
      {Footerload ? <>
        <Counter EventData={EventData} SetEventData={SetEventData} />
      <Testimonial />
      <Team />
      <Footer />
      </>:null}
    </>
  )
}


export default Event;