import React, { Component } from 'react';
import Navbar from './global-components/navbar';
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';
import Footer from './global-components/footer';
import Recentnews from './Recentnews'
class Newsetails extends Component {

    constructor() {
        super()

        this.state = {
            showing: false,
            activeSlideIndex: 0,

        }

    }

    setActiveSlideIndex = (newActiveSlideIndex) => {
        this.setState({
            activeSlideIndex: newActiveSlideIndex,
        });
    };

    componentDidMount() {

        const $ = window.$;

        $('body').removeClass('home-3');


    }

    openNav = () => {
        console.log("hii");
        document.getElementById("mySidenav").style.width = "250px";
        document.getElementById("mySidenav").style.left = "0px";
    }
    closeNav = () => {
        document.getElementById("mySidenav").style.width = "0";
    }

    render() {
        // let publicUrl = process.env.PUBLIC_URL+'/'
        // let imgattr = 'logo'
        // let anchor = '#'
        // const { showing } = this.state;
        return (
            <div >
                <Navbar /><br></br><br></br><br></br><br></br>
                <div className='container-fluid px-md-2' >
                    <div className='row'>
                        <div className='col-sm-12 col-md-6 col-lg-8 blog_detail'>
                            <div className='blog-detail'>
                                <p className='text-grey f-16 f-mb-16'>Wed, 09 Feb 2022 </p>
                                <h1 className='pb-2 text-black fw-600 resource_detail_page_title'>
                                WorkForce Software Chief Technology Officer, John Williams, Named One of the Top 25 Software CTOs of 2022 
                                </h1>
                                <div className="media align-items-center py-3 px-md-1">
                                    <div className="media-body f-12 ml-2">
                                        <span className="text-grey fw-600">by</span>&nbsp;&nbsp;<span className="text-grey fw-600">PR Newswire</span>
                                    </div>
                                    <div className="media-body f-12 ml-2" style={{ visibility: 'hidden' }}>
                                        <span className="text-grey fw-600">by</span>&nbsp;&nbsp;<span className="text-grey fw-600">PR Newswire</span>
                                    </div>
                                </div>
                                <div className="style_summernote pt-3" style={{ fontSize: '1rem' }}>
                                    <p>LIVONIA, Mich.: The Software Report Award Recognizes CTOs Across Cloud Technologies, AI, Data Collection and Management, who are Responsible for Delivering Some of the Most Successful and Effective Software Solutions on the Market LIVONIA, Mich., Feb. 9, 2022 /PRNewswire/ -- Today, WorkForce Software - the first global provider of integrated employee experience and workforce management solutions - announces its Chief Technology Officer, John Williams, was named to The Top 25 Software CTOs of 2022 list by The Software Report. These accomplished executives are recognized as being the driving force behind their companies' technical capabilities, ensuring software product functionality and effectiveness. They are leading high performing teams to deliver solutions that streamline operations, improve efficiency, interpret data, and much more with the end result of enhancing overall business operations for their global customers. Moreover, they have helped guide their own organizations through a trying and unpredictable period and have managed to not only sustain, but – as in the case of WorkForce Software - accelerate growth.</p>
                                </div>
                            </div>
                        </div>



                        <div className='col-sm-12 col-md-6 col-lg-4 side_bar ec-sidebarWrap recetleft'>
                        <Recentnews />

                        </div>



                    </div>
                </div><br></br>
                {/* <div style={{marginTop: '20%'}}>
                </div>
                */}
                
        <Testimonial />
        <Team />
        <Footer />
               

            </div>
        )
    }
}

export default Newsetails;
