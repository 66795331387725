import React, {memo,useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronRight } from "react-icons/fa";
import { API_URL } from './Api';


const urlimg = `${API_URL}/TechTalk_image`;
const TechtalkEditor = ({ TechTalkData ,view }) => {

    const [Editor, setEditor] = useState();

     useEffect(() => {
        //  console.log(TechTalkData,'TechTalkData');
      let fltdata  = TechTalkData.filter(item=>item.EditorChoice === true)
      let sort = fltdata.sort((a, b) => {
        return (
            new Date(a.TechTalkDate) -
            new Date(b.TechTalkDate)
        )
    }).reverse()
      setEditor(sort)
   

     }, [TechTalkData]);




   
    return (
        <div className='sticky-filter-course'>

            <div className='ec-sidebar'>
                <div className='ec-box-width' >
                    <header className='f-16 font-weight-bold'>
                        <div className="clearfix display_flex direction_column">
                            <div className='float-left font-weight-bold'>
                                Editor's Choice
                            </div>
                           { view ?  <div className='float-right d-inline-flex'>
                                <Link to={"/tech_talks/"} className='f-16 text-red'>
                                    View All
                                </Link>
                                <FaChevronRight className='text-red f-14 ml-2 font-weight-bold' />
                            </div> :<></>}

                        </div>
                    </header>
                    {Editor && Editor?.map((item, id) => {
			let date = new Date(item.TechTalkDate).toDateString().slice(4);
                        return (
                            <div style={{ overflow: 'hidden' }} key={id}>
                                <Link to={`/tech_talks/${item?.TechTalkurlname.replace(/ /g, '-').toLowerCase()}/`} className='d-block list-group-item list-group-item-action border-0 p-0 '>
                                    <div className='media py-3 border_top_table resource_list_media direction_column'>
                                        <div className='resource_list_image resource_list_image_mob mr-3 mt-1 border ' style={{ backgroundImage: `url(${urlimg}/${item.TechTalkImage})` , backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} >   </div>
                                        <div className='media-body' style={{ marginTop: '1em' }}>
                                            <p className='text-black f-16 fw-600'>
                                               {item?.TechTalkHeadline}
                                            </p>
                                            <span className='text-grey-shade-2 f-12'>by {item?.AuthorName} {date}</span>
                                            <span className='text-grey-shade-2 f-12'></span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )
                    })}

                 



                </div>
            </div>

        </div>
    )
}


export default memo(TechtalkEditor);
