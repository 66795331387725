import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Navbar from './global-components/navbar';
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';
import Footer from './global-components/footer';
import FeaturedCarosel from './FeaturedCarosel';
import { FaFilter } from "react-icons/fa";
import HrmsSsidetab from './hrmsSidetab';
import DialogBox from './global-components/enquiry-modal';
import axios from "axios";
import { Helmet } from "react-helmet";
import Counter from './section-components/counter';

import { API_URL } from './Api';

const API_MarketPlace = `${API_URL}/MarketPlace`;
const API_View = `${API_URL}/MarketPlace_image`;
const URL = `${API_URL}/Events`;
const Api_review = `${API_URL}/ProductReview`;

const LazyLoadCard = ({ item, API_View, Getcompanyname, SetProduct, SetProductlink }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const imgRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsLoaded(true);
                    observer.disconnect();
                }
            },
            {
                rootMargin: '0px',
                threshold: 0.1
            }
        );

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        return () => {
            if (imgRef.current) {
                observer.unobserve(imgRef.current);
            }
        };
    }, [imgRef]);

    return (
        <div className='content mp_card'>
            <a href={`/${item.MarketURL}/`}>
                <div className='content-overlay'></div>
                <div className='new_card content-image bg-transparent border-lightgrey'>
                    <div className='h-50 d-flex' style={{ justifyContent: 'center', position: 'relative' }}>
                        <div
                            className="card-img-top"
                            style={{
                                width: '100%',
                                height: 'auto',
                                backgroundImage: isLoaded ? `url(${API_View}/${item.ProductLogo})` : 'none',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center'
                            }}
                            ref={imgRef}
                        ></div>
                    </div>
                    <div className='h-50 d-flex align-items-center bg_grey justify-content-center' style={{ padding: '0px 10px 0px 10px' }}>
                        <div className='d-block'>
                            <p className='f-12 w-100 text-grey fixed_content_3'>
                                {item.ProductSummary}
                            </p>
                            {item.StarRating && item.StarRating !== "NaN" ? (
                                <div className="">
                                    <div className="star-rating-count ">
                                        {[...Array(5)].map((star, index) => (
                                            <button
                                                key={index}
                                                className={index < `${item.StarRating}` ? "on" : "off"}
                                            >
                                                <span className="star">&#9733;</span>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </a>
            <div className='content-details px-0'>
                <a href={`/${item.MarketURL}/`}>
                    <div className='h-50 d-block text-center pt-5 px-3'>
                        <h3 className='text-white content-title f-20 fw-600 mt-0 mb-2 word_wrap'>{item.ProductName}</h3>
                        <p className='content-text f-12 fixed_content pr-0 word_wrap'>
                            {item.ProductTagLine}
                        </p>
                    </div>
                </a>
                <div className='container row m-0 h-50 pt-md-4 pt-2 d-flex' style={{ justifyContent: 'center', position: 'relative', alignItems: 'center' }}>
                    <div className='col-md-6 col-6 pr-1 mb-0' style={{ backgroudColor: '#fff' }}>
                        <a href={`/${item.MarketURL}/`}>
                            <button className='card_view btn btn-block f-16 border-radius-none enquiry_mobile waves-effect waves-light'>
                                View
                            </button>
                        </a>
                    </div>
                    <div className='col-md-6 col-6 pr-1 mb-0' style={{ backgroudColor: '#fff' }}>
                        <button
                            type="button"
                            className="card_view btn btn-block f-16 border-radius-none enquiry_mobile waves-effect waves-light"
                            onClick={() => {
                                Getcompanyname(item.ProductName);
                                SetProduct(item.ProductName);
                                SetProductlink(item.MarketURL);
                            }}
                        >
                            Enquire
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

function Marketplace() {
    const history = useHistory();
    const [market, setMarket] = useState([]);
    const [Product, SetProduct] = useState("");
    const [Productlink, SetProductlink] = useState("");
    const [dialog, setDialog] = useState(false);
    const [Footerload, SetFooterload] = useState(false);
    const [Pageurl, SetPageurl] = useState(false);
    const [EventData, SetEventData] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            SetFooterload(true);
        }, 500);
        GetData();
        fetchData();
        if (document.location.pathname[document.location.pathname.length - 1] !== '/') {
            history.replace(document.location.pathname + '/');
        }
        console.log('document.location', window.location.pathname);
    }, []);

    const fetchData = async () => {
        const { data } = await axios.get(URL);
        SetEventData(data);
    };

    const GetData = async () => {
        setMarket([]);
        const cate = await axios.get(`${API_MarketPlace}`);
        var ReviewData = cate.data;

        ReviewData.map(async (Stars) => {
            console.log("tetss", Stars);
            const Review = await axios.get(`${Api_review}/Approval/${Stars._id}`);

            var sum = 0;
            Review.data.map(items => {
                sum += parseFloat(items.rating);
            });
            var toalstar = sum / Review.data.length;
            var fixednumber = toalstar.toFixed(1);
            const dataf = {
                MarketURL: Stars.MarketURL,
                ProductSummary: Stars.ProductSummary,
                ProductLogo: Stars.ProductLogo,
                ProductName: Stars.ProductName,
                ProductTagLine: Stars.ProductTagLine,
                StarRating: fixednumber
            };
            setMarket((indexs) => [...indexs, dataf]);
        });

        if (window.location.pathname === "/marketplace/") {
            SetPageurl(true);
        }
    };

    const Getcompanyname = data => {
        SetProduct(data);
        if (data === "Deel") {
            window.open('https://www.deel.com/partners/hrtech-sg', '_blank');
        } else {
            setDialog(true);
        }
    };

    const dialogClose = () => {
        setDialog(false);
    };

    const openNav = () => {
        var element = document.getElementById("mySidenavAdd_market");
        element.classList.add("Addding");
        document.getElementById("mySidenav_market").style.width = "250px";
        document.getElementById("mySidenav_market").style.left = "0px";
    };

    const closeNav = () => {
        var element = document.getElementById("mySidenavAdd_market");
        element.classList.remove("Addding");
        document.getElementById("mySidenav_market").style.width = "0";
    };

    // Ref to observe
    const observer = useRef(null);

    useEffect(() => {
        observer.current = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Load image or content here
                    entry.target.classList.add('slide-in-bottom'); // Add animation class
                    observer.current.unobserve(entry.target); // Stop observing once loaded
                }
            });
        }, { threshold: 0.1 }); // Adjust the threshold as needed

        return () => {
            if (observer.current) {
                observer.current.disconnect(); // Clean up on component unmount
            }
        };
    }, []);

    useEffect(() => {
        const elements = document.querySelectorAll('.lazy-load');
        elements.forEach(element => {
            observer.current.observe(element); // Start observing each element with class 'lazy-load'
        });
    }, [market]); // Re-observe when market data changes

    return (
        <div>
            <Navbar />
            <Helmet>
                <title>Marketplace</title>
                <meta name="description" content="Marketplace" />
            </Helmet>
            <section className="pt-5 pb-3">
                <div className="text-center">
                    <h1 className="text-center f-28 fw-600" style={{ marginTop: "50px" }} >Marketplace</h1>
                </div>
            </section>
            <section>
                <nav>
                    <ol className="cd-breadcrumb custom-separator">
                        <li><a href="/">Home</a></li>
                        <li className="current"><em>Marketplace</em></li>
                    </ol>
                </nav>
            </section>
            {Pageurl ?
                <section className='py-md-5 py-4 bg-lightgrey' style={{ marginBottom: "30px" }}>
                    <div className='container-fluid w-90 pl-md-0 w-mb-100 pr-md-3 px-0'>
                        <div className="row mx-auto">
                            <div className="col-md-12">
                                <div className="text-center mb-3">
                                    <h2 className="wow flipInY f-mb-18 f-20 text-red">Featured Products</h2>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <FeaturedCarosel />
                            </div>
                        </div>
                    </div>
                </section>
                : null}
            <section>
                <DialogBox show={dialog} title={Product} links={Productlink} dialogClose={dialogClose} />
                <div id="mySidenav_market" className="sidenav hidden-md hidden-lg side-nav-style">
                    <a className="closebtn text-light" onClick={() => closeNav()}>×</a>
                    <div className="side-allingmnet">
                        <HrmsSsidetab />
                    </div>
                </div>
                <div id="mySidenavAdd_market"></div>
                <div className='py-md-5 pt-4 bg-white'>
                    <div className='container-fluid px-0 d-flex'>
                        <div className='row m-0 w-100'>
                            <div className='col-md-12 px-0'>
                                <div className='row m-0'>
                                    <div className='col-12 align-items-center justify-content-center pt-2 pb-4 px-3' id='apply_filter_section'>
                                        <button className='btn btn-red-primary filter_border w-90 w-mb-90 py-2 m-0 f-16 waves-effect waves-light' data-toggle="modal" data-target="#myModal" onClick={() => openNav()}>
                                            <FaFilter />Apply Filter
                                        </button>
                                    </div>
                                    <div className='col-md-3 pl-2 side_menu_mp ec-sidebarWrap'>
                                        <HrmsSsidetab />
                                    </div>
                                    <div className='col-md-9 col-12 mp_cards'>
                                        <div className='row mx-0'>
                                            {market.map((item, i) => (
                                                <div key={i} className='col-12 col-sm-6 col-md-4 col-lg-4 lazy-load mb-3'>
                                                    <LazyLoadCard
                                                        item={item}
                                                        API_View={API_View}
                                                        Getcompanyname={Getcompanyname}
                                                        SetProduct={SetProduct}
                                                        SetProductlink={SetProductlink}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {Footerload ? (
                <>
                    <Counter EventData={EventData} SetEventData={SetEventData} />
                    <Testimonial />
                    <Team />
                    <Footer />
                </>
            ) : null}
        </div>
    );
}

export default Marketplace;

