import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronRight } from "react-icons/fa";

class Recentnews extends Component {

    constructor() {
        super()

        this.state = {
            showing: false,
            activeSlideIndex: 0,

        }

    }

    setActiveSlideIndex = (newActiveSlideIndex) => {
        this.setState({
            activeSlideIndex: newActiveSlideIndex,
        });
    };

    componentDidMount() {

        const $ = window.$;

        $('body').removeClass('home-3');


    }

    openNav = () => {
        console.log("hii");
        document.getElementById("mySidenav").style.width = "250px";
        document.getElementById("mySidenav").style.left = "0px";
    }
    closeNav = () => {
        document.getElementById("mySidenav").style.width = "0";
    }

    render() {
        // let publicUrl = process.env.PUBLIC_URL+'/'

        return (
            <div>
               
                            <div className='ec-sidebar'>
                                <div className='ec-box-width' >
                                    <header className='f-16 pb-3 pt-3 pt-md-0 border-bottom font-weight-bold'>
                                    <div class="clearfix">
                                        <div className='float-left font-weight-bold'>
                                            Related News
                                        </div>
                                        <div className='float-right'>
                                            <Link to={"/"} className='f-16 text-red'>
                                                View All 
                                            </Link>
                                            <FaChevronRight  className='text-red f-14 ml-2 font-weight-bold'/>
                                        </div>
                                      
                                    </div>
                                    </header>
                                    <div style={{overflow:'hidden'}}>
                                            <Link to={""} className='d-block list-group-item list-group-item-action border-0 p-0 '>
                                                <div className='media py-3 border_top_table resource_list_media'>
                                                    <div className='resource_list_image mr-3 mt-1 border news_img1' >   </div>
                                                    <div className='media-body'>
                                                        <p className='text-black f-16 fw-600'>
                                                        WorkForce Software Chief Technology Officer, John Williams, Named One of the Top 25 Software CTOs of 2022
                                                        </p>
                                                        <span className='text-grey-shade-2 f-12'>by PR Newswire </span>
                                                        <span className='text-grey-shade-2 f-12'>Wed, 09 Feb 2022  </span>
                                                    </div>
                                               </div>    
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to={""} className='d-block list-group-item list-group-item-action border-0 p-0 '>
                                                <div className='media py-3 border_top_table resource_list_media'>
                                                    <div className='resource_list_image mr-3 mt-1 border news_img2' >   </div>
                                                    <div className='media-body'>
                                                        <p className='text-black f-16 fw-600'>
                                                        WorkForce Software Defines the Future of Labor Forecasting and Employee Experience to Meet the Needs of a More Powerful Workforce
                                                        </p>
                                                        <span className='text-grey-shade-2 f-12'>by PR Newswire </span>
                                                        <span className='text-grey-shade-2 f-12'>Wed, 02 Feb 2022   </span>
                                                    </div>
                                               </div>    
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to={""} className='d-block list-group-item list-group-item-action border-0 p-0 '>
                                                <div className='media py-3 border_top_table resource_list_media'>
                                                    <div className='resource_list_image mr-3 mt-1 border news_img3' >   </div>
                                                    <div className='media-body'>
                                                        <p className='text-black f-16 fw-600'>
                                                        ARV partners with IBM to build a national digital corporate identity system for Thailand's standardized corporate onboarding process
                                                        </p>
                                                        <span className='text-grey-shade-2 f-12'>by PR Newswire </span>
                                                        <span className='text-grey-shade-2 f-12'>Mon, 31 Jan 2022  </span>
                                                    </div>
                                               </div>    
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to={""} className='d-block list-group-item list-group-item-action border-0 p-0 '>
                                                <div className='media py-3 border_top_table resource_list_media'>
                                                    <div className='resource_list_image mr-3 mt-1 border news_img4' >   </div>
                                                    <div className='media-body'>
                                                        <p className='text-black f-16 fw-600'>
                                                        Locus listed as a Representative Vendor in The 2021 Gartner® Market Guide for Vehicle Routing and Scheduling and Last-Mile Technologies
                                                        </p>
                                                        <span className='text-grey-shade-2 f-12'>by PR Newswire </span>
                                                        <span className='text-grey-shade-2 f-12'>Mon, 31 Jan 2022  </span>
                                                    </div>
                                               </div>    
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to={""} className='d-block list-group-item list-group-item-action border-0 p-0 '>
                                                <div className='media py-3 border_top_table resource_list_media'>
                                                    <div className='resource_list_image mr-3 mt-1 border news_img5' >   </div>
                                                    <div className='media-body'>
                                                        <p className='text-black f-16 fw-600'>
                                                        Asia's leading HR technology platform Darwinbox raises $72 Million funding led by Technology Crossover Ventures (TCV) at $1B+ valuation
                                                        </p>
                                                        <span className='text-grey-shade-2 f-12'>by PR Newswire </span>
                                                        <span className='text-grey-shade-2 f-12'>Tue, 25 Jan 2022 </span>
                                                    </div>
                                               </div>    
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to={""} className='d-block list-group-item list-group-item-action border-0 p-0 '>
                                                <div className='media py-3 border_top_table resource_list_media'>
                                                    <div className='resource_list_image mr-3 mt-1 border news_img6' >   </div>
                                                    <div className='media-body'>
                                                        <p className='text-black f-16 fw-600'>
                                                        X0PA AI RAISES $4.2 MILLION IN SERIES A FUNDING TO SCALE AI-POWERED OBJECTIVE B2B HIRING & SELECTIONS SOLUTION
                                                        </p>
                                                        <span className='text-grey-shade-2 f-12'>by PR Newswire </span>
                                                        <span className='text-grey-shade-2 f-12'>Tue, 04 Jan 2022 </span>
                                                    </div>
                                               </div>    
                                            </Link>
                                        </div>

                    </div>
                </div>
                
                  </div>
        )
    }
}

export default Recentnews;
