import React, { useEffect, useState } from 'react';
import Navbar from '../global-components/navbar';
import Blog from './blog';
import Footer from '../global-components/footer';
import Testimonial from '../blog-page/testimonial';
import Team from '../section-components/team';

const SingUpPage = () => {
    const [Footerload, SetFooterload] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            SetFooterload(true)
          }, 500)
         
         
    }, [])
    return (
        <div>
            <Navbar />
               <Blog /> 
 {/* 
            <div className='row '>
    <div className='col-md-12  '>
<div className='jobs_iframe'>
    <iframe className='mt-3' src="https://hrtechblogs.superblog.cloud/" height='900' width="100%"  title="Iframe Example"></iframe>
</div>

    </div>

 </div>
*/}
            {Footerload ? <>
            <Testimonial />
            <Team />
            <Footer />
            </>:null}
        </div>
    )
}

export default SingUpPage