import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FaChevronRight } from "react-icons/fa";
import '../news.css';
import { API_URL } from '../Api';

const urlimg = `${API_URL}/Blog_image`;

const TechtalkEditor = (props) => {

    const history = useHistory()
    const [EditorChoice, SetEditorChoice] = useState([]);

     
    useEffect(() => {
        if (props.Blogdata) {
            SetEditorChoice(props.Blogdata);
        }
        if (document.location.pathname[document.location.pathname.length - 1] !== '/') {
            history.replace(document.location.pathname + '/');
        }
    }, []);

    return (

        <div className='sticky-filter-course' >
            <div className='ec-box-width' >
            <header className='f-16  font-weight-bold'>
                        <div className="clearfix display_flex direction_column">
                            <div className='float-left font-weight-bold'>
                                Editor's Choice
                            </div>
                             <div className='float-right d-inline-flex'>
                                <a href={"/blog/"} className='f-16 text-red'>
                                    View All
                                </a>
                                <FaChevronRight className='text-red f-14 ml-2 font-weight-bold' />
                            </div>  

                        </div>
                    </header>
                {
                 props.Blogdata.length>0 &&   props.Blogdata.map((item, i) => {

                        let date = new Date(item.BlogDate).toDateString().split(' ').slice(1).join().replace(/,/g, " ")

                        return (

                            <div key={i} style={{ overflow: 'hidden' }}>
                                <a href={`/blog/${item.Blogurlname.replace(/ /g, '-')}/`} className='d-block list-group-item list-group-item-action border-0 p-0 '>
                                    <div className='media py-3 border_top_table resource_list_media'>
                                        <div className='resource_list_image mr-3 mt-1 border ' style={{ backgroundImage: `url(${urlimg}/${item.BlogImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} >   </div>
                                        <div className='media-body'>
                                            <p className='text-black f-16 fw-600 ed_text'>
                                                {item.BlogHeadline}
                                            </p>
                                            <span className='text-grey-shade-2 f-12'>by</span>&nbsp;
                                            <span className='text-grey-shade-2 f-12'>{item.AuthorName},</span>&nbsp;
                                            <span className='text-grey-shade-2 f-12'>{date}</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )
                    }
                    )
                }
            </div>
        </div>
    )
}


export default TechtalkEditor;
