import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { render} from 'react-dom';
import ReactDatatable from '@ashvin27/react-datatable';
import { API_URL } from './Api'
const Api = `${API_URL}/EnquireForm`;

class EnquireList extends Component {

    constructor() {
        super()

        this.state = {
            showing: false,
            activeSlideIndex: 0,
            records: '',
            data:[],
            records: []
            

        }

        this.columns = [
            {
                key: "Name",
                text: "Name",
                className: "Name",
                sortable: true
            },
            {
                key: "ProductName",
                text: "Product Name",
                sortable: true
            },
            {
                key: "Companyname",
                text: "Company Name",
                className: "postcode",
                sortable: true
            },
            {
                key: "Email",
                text: "Email",
                className: "rating",
                sortable: true
            },
            {
                key: "Contactnumber",
                text: "	Contact number",
                className: "type_of_food",
                sortable: true
            },
            {
                key: "Country",
                text: "	Country",
                className: "type_of_food",
                sortable: true
            },
            {
                key: "Message",
                text: "	Message",
                className: "type_of_food",
                sortable: true
            },
            {
                key: "created",
                text: "	Date",
                className: "type_of_food",
                sortable: true
            },
         
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            filename: "restaurents",
            button: {
                excel: true,
                print: true,
                csv: true
            }
        }
     

    }

   
   


    setActiveSlideIndex = (newActiveSlideIndex) => {
        this.setState({
            activeSlideIndex: newActiveSlideIndex,
        });
    };

    componentDidMount() {

        const $ = window.$;

        $('body').removeClass('home-3');
this.GetAll();

    }
    GetAll =()=>{
        axios.get(`${Api}`).then(res => {
            // this.props.history.push("/Training")
        
         this.setState({
            records:res.data
 
         })
         console.log("hh",res.data)
           
        })
    }

    openNav = () => {
        console.log("hii");
        document.getElementById("mySidenav").style.width = "250px";
        document.getElementById("mySidenav").style.left = "0px";
    }
    closeNav = () => {
        document.getElementById("mySidenav").style.width = "0";
    }

 
 

    render() {
        // let publicUrl = process.env.PUBLIC_URL+'/'
        // let imgattr = 'logo'
        // let anchor = '#'
        const { showing } = this.state;
        const { Product } = this.props;
       
        

        const toggledClass = showing ? 'showing' : 'collapsed';
        return (
            <div>
       <section class="container"  > 
       <br></br>
       <br></br>
  
<ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                extraButtons={this.extraButtons}/>
</section>

            </div>



        )
    }
}
export default EnquireList;