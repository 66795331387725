import React, { useEffect, useState } from 'react';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import axios from "axios";
import { FaFilter } from "react-icons/fa";

import Navbar from '../global-components/navbar';
import Testimonial from '../section-components/testimonial';
import Team from '../section-components/team';
import Footer from '../global-components/footer';
import FeaturedCarosel from '../FeaturedCarosel';
import HrmsSsidetab from '../hrmsSidetab';
// import Model from '../Model';
import DialogBox from '../global-components/enquiry-modal';
import { API_URL } from '../Api'

const API_Market = `${API_URL}/MarketPlace/`;
const API_View = `${API_URL}/MarketPlace_image/`;
const API_Category = `${API_URL}/Categories/`;
const Api_review = `${API_URL}/ProductReview/`;

function Categories() {

  // getting params from URL
  const params = useParams();
  const location = useLocation();
  const CategoryId = location.state
  const history = useHistory()

  // initializing state to store data
  const [data, setData] = useState([])
  const [catedata, setcatedata] = useState([])
  const [Product, SetProduct] = useState("")
  const [dialog, setDialog] = useState(false)
  const [Footerload, SetFooterload] = useState(false)
  const [Pageurl, SetPageurl] = useState(false)

  //Function call on page mount
  useEffect(() => {
    setTimeout(() => {
      SetFooterload(true)
    }, 500)
    GetData()
    if (document.location.pathname[document.location.pathname.length - 1] !== '/') {
      history.replace(document.location.pathname + '/');
    }
  }, [params.name])

  //Get Category from Backend
  const GetData = async () => {
    setData([]);
    const subCate = params.name.replace(/-/g, ' ')
    const Scat = await axios.get(`${API_Category}`)
    const filter = Scat.data.filter((person) => person.CategoryName.toLowerCase().includes(subCate.toLowerCase()))
    const Result = await axios.get(`${API_Market}/Category/${filter[0]._id}`)
    var ReviewData = Result.data;
    ReviewData.map(async(Stars)=>{
      console.log("tetss",Stars)
      const Review = await axios.get(`${Api_review}/Approval/${Stars._id}`)
    
      var sum = 0;
      Review.data.map(items => {
        sum += parseFloat(items.rating)
      })
      var toalstar = sum / Review.data.length;
     var fixednumber = toalstar.toFixed(1)
    const dataf ={
      MarketURL:Stars.MarketURL,
      ProductSummary:Stars.ProductSummary,
      ProductLogo:Stars.ProductLogo,            
      ProductName:Stars.ProductName,
      ProductTagLine:Stars.ProductTagLine,
      StarRating:fixednumber
    }
    setData((indexs)=>[...indexs,dataf])
    //  console.log("nnnnn",dataf)
     
    })
    setcatedata(filter)
    // setData(Result.data)

    if(window.location.pathname == "/marketplace/"){
     
      SetPageurl(true)
  }
  }

  const Getcompanyname = data => {
    SetProduct(data)
     if(data =="Deel"){
      window.open('https://www.deel.com/partners/hrtech-sg', '_blank');
  }else{
      
      setDialog(true)
  }
  }

  const dialogClose = () => {
    setDialog(false)
  }
 const openNav = () => {
    var element = document.getElementById("mySidenavAdd_market");
    element.classList.add("Addding");
    document.getElementById("mySidenav_market").style.width = "250px";
    document.getElementById("mySidenav_market").style.left = "0px";
  }

  const closeNav = () => {
    var element = document.getElementById("mySidenavAdd_market");
    element.classList.remove("Addding");
    document.getElementById("mySidenav_market").style.width = "0";
  }
  const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

  return (
    <div>
      <Navbar />
      <DialogBox show={dialog} title={Product} dialogClose={dialogClose} />
   <div id="mySidenav_market" className="sidenav hidden-md hidden-lg side-nav-style ">
        <a className="closebtn text-light" onClick={() => closeNav()}>×</a>

        <div className="side-allingmnet">
        <HrmsSsidetab Linktit={params.name} />
        </div>
        
      </div>
      <div id="mySidenavAdd_market" ></div>
      <section className=' title_bg jumbotron-fluid text-center m-0 border-bottom'>
        <div className="container-fluid market pt-5" >
          <h1 style={  {textTransform:'capitalize'}} className="text-center  f-28 fw-600" >
            {params.name === "hrms" ?"Human Resource Management System(HRMS) software tools":params.name.replace(/-/g, ' ')+" " + "software tools"}
            </h1>
        </div>

      </section>
  <section>
 	<nav>
		<ol className="cd-breadcrumb custom-separator">
			<li><a href="/">Home</a></li>
			<li><a href="/marketplace/" >Marketplace</a></li>
			 
			<li className="current" style={  {textTransform:'capitalize'}}><em>{params.name === "hrms" ?"Human Resource Management System(HRMS) software tools":params.name.replace(/-/g, ' ')+" " + "software tools"}</em></li>
		</ol>
	</nav>
</section>


{/* images banner */}
{params.name === "hrms" ? 
<>
<section >
        <div className=''>
          <div className="row  ">
            <div className="col-md-12">
	<a href="https://www.iqdynamics.com/#utm_source=website&utm_medium=website&utm_campaign=HRTech&utm_id=HRTech" target="_blank" >
              <img src='/assets/img/marketplace_hrms.jpg' width="100%"></img>
</a>
            </div>
          </div>
          
        </div>
      </section></> :null }

      {params.name === "total-rewards"  ? 
<>
<section >
        <div className=''>
          <div className="row  ">
            <div className="col-md-12">
	<a href="https://www.qapita.com/" target="_blank" >
              <img src='/assets/img/qapita.png' width="100%"></img>
</a>
            </div>
          </div>
          
        </div>
      </section></> :null }

    {params.name === "talent-acquisition"  ? 
<>
<section >
        <div className=''>
          <div className="row  ">
            <div className="col-md-12">
	<a href="https://berribot.com/" target="_blank" >
              <img src='/assets/img/berribot.png' width="100%"></img>
</a>
            </div>
          </div>
          
        </div>
      </section></> :null }
      {params.name === "talent-development"  ? 
<>
<section >
        <div className=''>
          <div className="row  ">
            <div className="col-md-12">
	<a href="https://www.enparadigm.com/" target="_blank" >
              <img src='/assets/img/development.png' width="100%"></img>
</a>
            </div>
          </div>
          
        </div>
      </section></> :null }


{/* end image banner */}

      {/* <section className='py-md-5 py-4 bg-lightgrey' style={{ marginBottom: "30px" }}>
        <div className='container-fluid w-90 pl-md-0 w-mb-100 pr-md-3 px-0'>
          <div className="row mx-auto">
            <div className="col-md-12">
              <div className="text-center mb-3">
                <h2 className="wow flipInY f-mb-18 f-20 text-red">Featured Products</h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <FeaturedCarosel />
            </div>
          </div>
        </div>
      </section> */}

      <section>
        <div className='py-md-5 pt-4 bg-white'>
          <div className='container-fluid px-0 d-flex'>
            <div className='row m-0 w-100'>
              <div className='col-md-12 px-0'>
                <div className='row m-0'>
                  <div className='col-12 align-items-center justify-content-center pt-2 pb-4 px-3' id='apply_filter_section'>
                    <button className='btn btn-red-primary filter_border w-90 w-mb-90 py-2 m-0 f-16 waves-effect waves-light' data-toggle="modal" data-target="#myModal" onClick={() => openNav()}>
                      <FaFilter />Apply Filter
                    </button>
                  </div>

                  <div className='col-md-3 pl-2 side_menu_mp ec-sidebarWrap mb-5' >
                    <HrmsSsidetab Linktit={params.name} />
                  </div>



                  <div className='col-md-9 col-12 mp_cards'>
                    {data.length > 0 ?
                      <div className='row mx-0'>
                        {data.map((item, i) => {
                          return (
                            <div key={i} className='col-12 col-sm-6 col-md-4 col-lg-4 slide-in-bottom feature_card px-2 mb-3'>
                              <div className='content mp_card'>
                              <a href={ `/${item.MarketURL}/` }>
                                
                                  <div className='content-overlay'></div>
                                  <div className='new_card content-image bg-transparent border-lightgrey'>
                                    <div className='h-50 d-flex' style={{ justifyContent: 'center', position: 'relative' }}>
                                      <div className="card-img-top" style={{ width: '100%', height: 'auto', backgroundImage: ` url(${API_View}/${item.ProductLogo})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center' }}></div>
                                    </div>
                                    <div className='h-50 d-flex align-items-center bg_grey justify-content-center' style={{ padding: '0px 10px 0px 10px' }}>
                                      <div className='d-block'>
                                        <p className='f-12 w-100 text-grey fixed_content_3'>
                                          {item.ProductSummary}
                                        </p>
                                        {item.StarRating && item.StarRating !== "NaN" ? <>
                      <div className="" >
                          <div className="star-rating-count ">
                            {[...Array(5)].map((star, index) => {
                              index += 1;
                              return (
                                <button
                                  key={index}
                                  className={index <= (`${item.StarRating}`) ? "on" : "off"}

                                >
                                  <span className="star" >&#9733;</span>

                                </button>
                              );
                            })}
                          </div>
                            {/* <p className="Starcount"> {4} ({1})</p> */}
                            </div>
                            </> : null}
                                        {/* <div className='stars w-100 pt-3 rating' style={{rating: 4.0 }}></div> */}
                                      </div>
                                    </div>

                                  </div>
                                </a>
                                <div className='content-details px-0'>
                                <a href={ `/${item.MarketURL}/` }>
                                    <div className='h-50 d-block text-center pt-5 px-3'>
                                      <h3 className='text-white content-title f-20 fw-600 mt-0 mb-2 word_wrap'>{item.ProductName}</h3>
                                      <p className='content-text f-12 fixed_content pr-0 word_wrap'>
                                        {item.ProductTagLine}
                                      </p>
                                    </div>
                                  </a>

                                  <div className='container row m-0 h-50 pt-md-4 pt-2 d-flex' style={{ justifyContent: 'center', position: 'relative' ,alignItems:'center'}}>
                                    <div className='col-md-6 col-6 pr-1 mb-0' style={{ backgroudColor: '#fff' }}>
                                    <a href={ `/${item.MarketURL}/` }>
                                        <button className='card_view btn btn-block f-16 border-radius-none enquiry_mobile waves-effect waves-light' >
                                          View
                                        </button>
                                      </a>
                                    </div>
                                    <div className='col-md-6 col-6 pr-1 mb-0' style={{ backgroudColor: '#fff' }}>
                                      <button type="button" className="card_view btn btn-block f-16 border-radius-none enquiry_mobile waves-effect waves-light"
                                        onClick={() => {
                                          Getcompanyname(item.ProductName)
                                          SetProduct(item.ProductName)
                                        }} 
                                        // data-toggle="modal" data-target="#exampleModalLong"
                                        >
                                        Enquire
                                      </button>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      : <></>}

{catedata.length > 0 && catedata[0].Categorybottomcontent && catedata[0].Categorybottomcontent !== "undefined" ? <>
  <div className='card border-radius-none mb-3'>
                                            {/* <div className='card-header py-3 bg-white align-items-center d-flex'>
                                                <h2 className='f-18 fw-600 w-100'>
                                                    EVENT AGENDA
                                                </h2>
                                            </div> */}
                                            <div className='card-body row m-0 style_summernote'>
                                                <p>
                                                
                                                 {renderHTML(catedata[0].Categorybottomcontent)}
                                                </p>



                                            </div>
                                        </div>
</>:null}
                    
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>

      </section>
      <section>

      </section>
      {Footerload ? <>
            <Testimonial />
            <Team />
            <Footer /></>:null}
      {/* <Model Product={Product} /> */}
    </div>



  )
}

export default Categories;
