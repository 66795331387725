import React, { useState, useEffect,useReducer } from 'react';
import { Link ,useParams} from 'react-router-dom';
import { MdMail } from 'react-icons/md';
import Navbar from './global-components/navbar';
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';
import Footer from './global-components/footer';
import NewsEditors from './NewsEditors';
import axios from 'axios';
import { API_URL } from './Api';
import { FaChevronLeft, FaChevronRight, FaLinkedinIn,FaWhatsapp,FaFacebookF,} from "react-icons/fa";
import './news.css';
import { Reducer } from './Reducer';
import {Helmet} from "react-helmet";
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    LinkedinIcon
  } from "react-share";



const api = `${API_URL}/News/alldata/website`;
const apiview = `${API_URL}/News/particular/website`;
const urlimg = `${API_URL}/News_image`;



const NewsViews = () => {

    // const Scrolltop =()=>{
    //     window.scrollTo({
    //         top:0,
    //         behavior:"smooth"
    //     })
    // }
    const inital=()=>{}

    const params = useParams();
    const [Scroll, dispatch] = useReducer(Reducer,inital )
    const [result, setresult] = useState([]);
    const [nextResult, setNextResult] = useState([]);
    const [prevResult, setPrevResult] = useState([]);
    const [Latest_News, setLatest_News] = useState([]);
    const [getdata, setgetdata] = useState(false);
    // const [Alldata, setAlldata] = useState([]);
    const [view, setview] = useState(false);
 
	 

    


    useEffect( () => {

        const product = params.id;

        const  fetch= async()=>{
       try{
           dispatch({type:"SCROLLTO"})
       

        const datas  = await axios.post(apiview,{Newsurlname:product})
        setgetdata(true)
        setresult(datas.data.data[0])
        setNextResult(datas.data.next)
        setPrevResult(datas.data.prev)
        setLatest_News(datas.data.Latest_News)

        // const { data } = await axios.get(api)
      
        // setAlldata(data)
        // let filter = data?.filter((headline) => headline?.Newsurlname?.toLowerCase().includes(product.toLowerCase()))

        
        // const next =data?.filter(dd => dd?.PublishDate > filter[0]?.PublishDate)
        
        // const prev =  data?.filter(dd => dd?.PublishDate < filter[0]?.PublishDate)
        setview(true)
         

       } catch(err){
           console.log(err," error in the fetch data");
       }
        }
        fetch()
        Scroll()

    }, [params.tech, params.product]);

   


    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    
    // const datefor=()=>{
    //     let date= ''
    //     if(getdata){
    //       date=  result?.NewsDate?.split('T')[0]
    //         date=new Date(date).toDateString().slice(4)
    //     }
    //     return date
    // }
    if(getdata){
        return (
            <div>
  
                <div className=' '>
                <Navbar/>
                </div>
                <br></br>
              
                <section className='blog-page-content mt-5 top_M'>
                <section>
					<nav>
		<ol className="cd-breadcrumb custom-separator" style={{backgroundColor:"#ffffff"}}>
            <br></br>
			<li><Link to="/">Home</Link></li>
			<li><Link to="/news/">News</Link></li>
			 
			<li className="current"><em>{result?.NewsHeadline}</em></li>
		</ol>
	</nav></section>
                    <div className='container-fluid px-md-2  mb-5'  >
                        <div className='row px-0 flexbox-wrapper'>
                            <div className='col-sm-8 col-md-8 co-lg-8'>
                                <div className='blog-detail pr-md-3'>
                                <div className='text-grey f-16 f-mb-16'>{result?.NewsDate}  </div>
                                    
                                    <h1 className='pb-2 text-black fw-600 resource_detail_page_title fr-2'>
                                       <a href={`/news/${result?.Newsurlname}/`} style={{color:"black"}} >{result?.NewsHeadline} </a>
                                    </h1>
                                    <p className='f-blog3-16 short_desc text-grey'>
                                       {/* {result?.TechTalkShortDescription} */}
                                    </p>
                                    <div className='media align-items-center py-3 '>
                                    <div className='text-grey f-16 f-mb-16'>   Source: <b><span className='text-uppercase'>{result.Sources}</span></b> </div>
                                        {/* <div className='author_image author_border border talkimg' style={{ backgroundImage: `url(${urlimg}/${result.AuthorProfileImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} > </div> */}
                                        {/* <div className="media-body f-12 ml-2">
                                            <span className="text-grey fw-600">by</span>&nbsp;&nbsp;<span className="text-grey fw-600">{result.AuthorName}</span>, <span className="text-grey fw-600">{result.AuthorDesignation}</span>,  <a href={result.AuthorCompanyLink} target={'_blank'} className="fw-600">{result.AuthorCompanyName}</a>
                                        </div> */}
    
                                    </div>
                                    <div className='style_summernote pt-3'>
                                        
                                          {renderHTML(result.NewsStory)}
                                       
                                      
                                    </div>
                                  
                                   
                                    <div className="row mx-0">
                                        <div className="col-md-12 px-0 px-md-1 text-md-right d-flex align-items-center">
                                            <p className="text-grey py-3 mr-0 ">Share: </p>
                                            &nbsp; &nbsp;
                                            <div>
                          <WhatsappShareButton url={window.location.href} title={result.TechTalkHeadline} className="share-btn m-1">
                            <WhatsappIcon size={30} round={true} />
                          </WhatsappShareButton>
                          <FacebookShareButton url={window.location.href} title={result.TechTalkHeadline} className="share-btn m-1">
                            <FacebookIcon size={30} round={true} />
                          </FacebookShareButton>
                          <LinkedinShareButton url={window.location.href} title={result.TechTalkHeadline} className="share-btn m-1">
                            <LinkedinIcon size={30} round={true} />
                          </LinkedinShareButton>
                          <EmailShareButton url={window.location.href} title={result.TechTalkHeadline} className="share-btn m-1">
                            <EmailIcon size={30} round={true} />
                          </EmailShareButton>

                        </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12 col-lg-12 col-12 px-0 border_top_table'>
                                            <div className='row m-0 py-4 resource_detail_footer_nav d-md-flex w-100 px-2 px-md-0 border_top_table mb-4 mb-md-0'>
                                        {
                                            prevResult.length>0 ?<div className='col-5 p-0 d-flex align-items-center'>
                                            <a href={`/news/${prevResult[prevResult.length - 1].Newsurlname.replace(/ /g, '-').toLowerCase()}/`} className="d-flex justify-content-start align-items-center w-100">
                                                <div className="media align-items-center">
                                                    <div>
                                                        <FaChevronLeft className='text-grey f-18' />
                                                    </div>
                                                    <div className="media-body ml-4">
                                                        <p className="text-grey f-14 f-mb-12">Previous Post</p>
                                                        <p className="text-black f-16 f-mb-14"> {prevResult[prevResult.length - 1]?.NewsHeadline} </p>
                                                    </div>
                                                </div>
                                            </a>

                                        </div>:<></>
                                        }
                                                <div className='col-2 d-flex m-auto justify-content-center'>
                                                    <a href={"/news/"}>
                                                        <img src='/assets/img/Event/Group1.svg' alt=''></img>
                                                    </a>
                                                </div>

                                                {
                                                    nextResult.length>0? <div className='col-5 p-0 float-right text-right d-flex align-items-center justify-content-end'>
                                                    <a href={`/news/${nextResult[0].Newsurlname.replace(/ /g, '-').toLowerCase()}/`} className="d-block">
                                                        <div className="media align-items-center">
                                                            <div >
                                                                <p className="text-grey f-14 f-mb-12 text-right">Next Post</p>
                                                                <p className="text-black f-16 f-mb-14 text-right">{nextResult[0]?.NewsHeadline}</p>
                                                            </div>
                                                            <div className="media-body ml-4">
                                                                <FaChevronRight className='text-grey f-18' />
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>:<></>
                                                }
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-4 col-md-4 col-lg-4 side_bar ec-sidebarWrap '>
                              {
                                  Latest_News.length>0?  <NewsEditors  NewsData={Latest_News} view={view} />:<>nodata</>
                              }
                            </div>
                        </div>
                    </div>
                </section>
    
    
                <Testimonial />
                <Team />
                <Footer />
            </div>
        )
        
    }
    else{
        return <></>
    }



    
}


export default NewsViews