import React from "react";
import "./index.css";

export default function Banner_Home() {
  return (
    <div className="main-content">
    <div className="contain_navs banner-sec">
      <span className="double-dots"><img src="/assets/img/banner/double-dot.png" width={50} className="img-fluid" alt="the" /></span>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="bannar-main">
             <span className=""></span>
             <span className="fill-round-line"><img src="/assets/img/banner/lines.png" width={100} className="img-fluid" alt="the" /></span>
            <div className="banner-left">
                <h1 className="transforming-HR">
                  Transforming HR through Agile Workforce Solutions.
                </h1>
                <p className="para">We enable your success by identifying the right fit strategies, systems, processes, and technologies that facilitate workplace and workforce transformation.</p>
              <ul>
                <li><a href="/about-us/academy/">ACADEMY</a> </li>
                <li><a href="/about-us/advisory-consulting/">ADVISORY</a></li>
                <li><a href="/marketplace/">MARKETPLACE</a> </li>
                <li><a href="/about-us/talent-ondemand/">TALENT ON-DEMAND</a></li>
              </ul>
              </div>
              <div className="button-sec">
                <a href="/contact-us/" className="btn-1">CONTACT US</a>
                <a href="https://calendly.com/iyersriram/30-min" target="_blank" className="btn-2">SCHEDULE A CALL</a>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="right-banner">
              <span className="single-dot"><img src="/assets/img/banner/dot.png" width={60} height={100} className="img-fluid" alt="the" /></span>
            <img src="/assets/img/banner/banner_img.jpg" className="img-fluid banner-images" alt="the" />

            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
