import React, { useEffect, useState } from 'react';
import { Link ,useParams} from 'react-router-dom';
import { FaChevronRight } from "react-icons/fa";
import axios from 'axios';
import { API_URL } from './Api';

const api = `${API_URL}/Resources`
const apiimage = `${API_URL}/Resources_image`

function Resourceeditorchoice() {
    // initializing state to store data
    const [editor, seteditor] = useState([]);
   
 
const params = useParams();


    useEffect(() => {
        geteditor()
    }, []);

    const geteditor = async () => {
        const { data } = await axios.get(api)
        
 const formats = data.filter(item=>item.EditorsChoice === true);
 let sort = formats.sort((a, b) => {
    return (
        new Date(a.ResourceDate) -
        new Date(b.ResourceDate)
    )
}).reverse()
        let Editss = sort.slice(0,6);
  seteditor(Editss)

    }
   

    return (
        <div className='sticky-filter-course'>

            <div className='ec-sidebar'>
                <div className='ec-box-width' >
                    <header className='f-16   font-weight-bold'>
                        <div className="clearfix">
                            <div className='float-left font-weight-bold'>
                                Editor's Choice
                            </div>
                            <div className='float-right d-inline-flex '>
                                <Link to={"/resources/"} className='f-16 text-red '>
                                    View All
                                </Link>
                                <FaChevronRight className='text-red f-14 ml-2 font-weight-bold' />
                            </div>

                        </div>
                    </header>
                    <div style={{ overflow: 'hidden' }}>

                        {
                            editor?.map((item, i) => {
                                let datesp = item.ResourceDate.split('T')
                                let datefor = ""
                                datefor = item.ResourceDate?.split('T')[0]
                                datefor = new Date(datefor).toDateString().slice(4)
                                // datefor = datefor.toDateString()
                                // datefor = datefor.replace(" ", ",")
                                return (



                                    <Link to={`/resources-details/${item.Resourceurlname.replace(/ /g, '-').toLowerCase()
                                }`} className='d-block list-group-item list-group-item-action border-0 p-0  editorview' key={i}>
                                        <div className='media py-3 border_top_table resource_list_media editorview'>
                                            <div className='resource_list_image mr-3 mt-1 border  ' style={{ backgroundImage: `url(${apiimage}/${item.ResourceImage} )`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} >   </div>
                                            <div className='media-body'>
                                                <p className='text-black f-16 fw-600'>
                                                    {item.ResourceHeadline}
                                                </p>

                                                <span className='text-grey-shade-2 f-12'>{datefor }</span>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })
                        }


                    </div>


                </div>
            </div>

        </div>
    )
}

export default Resourceeditorchoice;
