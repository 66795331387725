import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Navbar from './global-components/navbar';
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';
import Footer from './global-components/footer';
import {Helmet} from "react-helmet";
import axios from "axios";
import { API_URL } from './Api'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const API_About = `${API_URL}/About/`;
const API_Advisory = `${API_URL}/Advisory`;
const API_Investors = `${API_URL}/Investors`;
const API_Client = `${API_URL}/Client`;
const API_About_image = `${API_URL}/About_image`;
const API_Advisory_image = `${API_URL}/Advisory_image`;
const API_Investors_image = `${API_URL}/Investors_image`;
const API_Client_image = `${API_URL}/Client_image`;

function Aboutus () {
    const [data, setData] = useState([])
    const [DataAdvisory, setDataAdvisory] = useState([])
    const [DataInvestors, setDataInvestors] = useState([])
    const [DataClient, setDataClient] = useState([])

    const history = useHistory()

    useEffect(()=> {
       
        GetData();
        GetData_Advisory();
        GetData_Investors();
        GetData_Client();
        if (document.location.pathname[document.location.pathname.length - 1] !== '/') {
            history.replace(document.location.pathname + '/');
        }
    },[])

    const GetData = async () => {
          const Result = await axios.get(`${API_About}`)
          setData(Result.data)
           
      }
    const GetData_Advisory = async () => {
          const Result = await axios.get(`${API_Advisory}`)
          setDataAdvisory(Result.data)
           
      }
    const GetData_Investors = async () => {
          const Result = await axios.get(`${API_Investors}`)
          setDataInvestors(Result.data)
           
      }
    const GetData_Client = async () => {
          const Result = await axios.get(`${API_Client}`)
          setDataClient(Result.data)
           
      }

    

        return (
          <div>
            <Navbar />
            <section className="py-md-5 py-4 title_bg jumbotron jumbotron-fluid text-center m-0 border-bottom">
              <div className="container-fluid " style={{ marginTop: "4%" }}>
                <h1 className="text-center f-28 fw-600">ABOUT US</h1>
              </div>
            </section>
            <section>
              <nav>
                <ol className="cd-breadcrumb custom-separator">
                  <li>
                    <a href="/">Home</a>
                  </li>

                  <li className="current">
                    <em>About us</em>
                  </li>
                </ol>
              </nav>
            </section>
            <section className="blog-area bg-white mt-5">
              <div className="container-fluid px-md-0">
                <div className="row mx-auto">
                  <div className="col-md-12">
                    <div className="f-16">
                      <h6>
                        <b>
                          Creating the HRTech Ecosystem for the Future of Work
                        </b>
                      </h6>
                      <p className="line-hei mt-2">
                        Founded in 2018, we are a Singapore-based HRTech Analyst
                        firm dedicated to facilitating Workplace and Workforce
                        Transformations that drive impactful Business and HR
                        outcomes. Our ultimate vision is to empower HR
                        professionals to transform their organizations by
                        seamlessly integrating technology and human-centric
                        approaches.
                      </p>
                      <p className="line-hei mt-2">
                        From our very beginning, we have been committed to
                        building a TECH-DRIVEN and DATA-CENTRIC HR ECOSYSTEM
                        that empowers organizations to achieve successful
                        Workplace and Talent Transformations, while enabling HR
                        teams to evolve into Strategic Business Partners.
                      </p>
                      <p className="line-hei mt-2">
                        At hrtech, we recognize the paramount importance of
                        adapting to the ever-changing demands of the modern
                        workplace. Our unique value proposition lies in
                        automating and digitizing HR processes to initiate a
                        transformative mindset shift. By embracing our
                        ecosystem, organizations can leverage the power of
                        technology to streamline operations, enhance Business
                        and HR productivity and efficiency, elevate employee
                        experiences, and unlock their full potential.
                      </p>
                      <p className="line-hei mt-2">
                       Our comprehensive ecosystem serves as a catalyst for the digital transformation of HR functions, enabling you to optimize efficiency, foster innovation, and cultivate a thriving workforce amidst constant change. To facilitate this transformation and drive organizational success, we operate through the following four distinct verticals:
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="bg-white pt-4 pb-0">
              <div className="container-fluid pb-md-4 px-0">
                <div className="row mx-auto">
                  <div className="col-md-12">
                    <div className="testimonial-head">
                      <h2 className="mb-md-5 fw-600 mt-md-3 mt-3 mb-4">
                        Our Services
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ margin: "0" }}>
                  
                  <div className="col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                    <div className="card">
                      <div className="card-body service_card service_ca2">
                        <Link to="/about-us/academy/">
                          <div className="align-items-center h-100">
                            <div className="adjust_detail or_btm_clr index_blog_card w-100 text-center ">
                              <div
                                style={{ height: "6rem", marginBottom: "1rem" }}
                              >
                                <img
                                  src="/assets/img/Aboutus/Academy.svg"
                                  alt="4567898"
                                ></img>
                              </div>
                              <h4 className="fw-normal f-18">Academy</h4>
                          
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                    <div className="card">
                      <div className="card-body service_card service_ca2 seri">
                        <Link to="/about-us/advisory-consulting/">
                          <div className="align-items-center h-100">
                            <div className="adjust_detail or_btm_clr index_blog_card w-100 text-center ">
                              <div
                                style={{ height: "6rem", marginBottom: "1rem" }}
                              >
                                <img
                                  src="/assets/img/Aboutus/Advisory.svg"
                                  alt="4567898"
                                ></img>
                              </div>
                              <h4 className="fw-normal f-18">
                                {" "}
                                Advisory & Consulting
                              </h4>
                            
                               
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                 
                  
                  <div className="col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                    <div className="card">
                      <div className="card-body service_card service_ca2">
                        <Link to="/about-us/marketplace-solutions/">
                          <div className="align-items-center h-100">
                            <div className="adjust_detail or_btm_clr index_blog_card w-100 text-center ">
                              <div
                                style={{ height: "6rem", marginBottom: "1rem" }}
                              >
                                <img
                                  src="/assets/img/Aboutus/Marketplace.svg"
                                  alt="12345"
                                ></img>
                              </div>
                              <h4 className="fw-normal f-18">
                                {" "}
                                Marketplace Solutions
                              </h4>
                            
                              
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                    <div className="card">
                      <div className="card-body service_card service_ca2">
                        <Link to="/about-us/talent-ondemand/">
                          <div className="align-items-center h-100">
                            <div className="adjust_detail or_btm_clr index_blog_card w-100 text-center ">
                              <div
                                style={{ height: "6rem", marginBottom: "1rem" }}
                              >
                                <img
                                  src="/assets/img/Aboutus/Talent.svg"
                                  alt="7654"
                                ></img>
                              </div>
                              <h4 className="fw-normal f-18">
                                Talent OnDemand
                              </h4>
                             
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div className=' '>
             </div>
               
              </div>
            </section>
            <br></br>
                 {/* Our CLient start */}
                 {DataClient.length > 0 ?(
                 <section className="bg-white pb-4 pt-0">
              <div className="container-fluid pb-md-4 px-0">
                <div className="row mx-auto">
                  <div className="col-md-12">
                    <div className="testimonial-head">
                      <h2 className="mb-md-5 fw-600 mt-md-3 mt-3 mb-4">
                        Our clients
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="our_team">
                  <div className="wrapper-large align-center">
                    <div>
                      <div className="">
                        <div className="four-col-adjustss">
                          <span className="row   ">
                            {DataClient && DataClient.map((client,index) =>
                                (
                                    <div className="col-md-2  " key={client._id}>
                                    <div
                                      className="container our_team_position"
                                      style={{
                                        justifyContent: "center",
                                        display: "grid",
                                      }}
                                    >
                                      <div className="client-logo">
                                        <img
                                          src={`${API_Client_image}/${client.Profilephoto}`}
                                          className="img-fluid"
                                          alt
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))}
                           
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            ):null}
            {/* Our CLient end */}
            {/* Our Team start */}

            <section className="bg-white pb-4 pt-0">
              <div className="container-fluid pb-md-4 px-0">
                <div className="row mx-auto">
                  <div className="col-md-12">
                    <div className="testimonial-head">
                      <h2 className="mb-md-5 fw-600 mt-md-3 mt-3 mb-4">
                        Our Team
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="our_team">
                  <div className="wrapper-large align-center">
                    <div>
                      <div
                        className="row mx-auto"
                        style={{ justifyContent: "center" }}
                      >
                        <div className="four-col-adjust">
                          <span className="d-flex align-items-center">
                            {data?.map((items) => {
                              return (
                                <>
                                  <div className="col-md-3 mb-5">
                                    <div
                                      className="container our_team_position"
                                      style={{
                                        justifyContent: "center",
                                        display: "grid",
                                      }}
                                    >
                                      <a
                                        href={items.Linkedinlink}
                                        target="_blank"
                                        className="hover_effect "
                                      >
                                        <div
                                          className="image-here align-center-word membersphoto"
                                          style={{ position: "relative" }}
                                        >
                                          <img
                                            src={`${API_About_image}/${items.Profilephoto}`}
                                            className="rounded-circle"
                                          ></img>
                                        </div>
                                      </a>
                                      <div className="align-center-word mt-4">
                                        <h5 className="p-0">{items.Name}</h5>
                                        <p>{items.designation}</p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <hr></hr>
            {/* Our Team end */}
            {/* advisory section Start */}
            {DataAdvisory.length > 0 ? (
              <>
                <section className="bg-white pb-4 pt-0">
                  <div className="container-fluid pb-md-4 px-0">
                    <div className="row mx-auto">
                      <div className="col-md-12">
                        <div className="testimonial-head">
                          <h2 className="mb-md-5 fw-600 mt-md-3 mt-3 mb-4">
                            Advisory Board
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="our_team">
                      <div className="wrapper-large align-center">
                        <div>
                          <div className="">
                            <div className="four-col-adjustss">
                              <span className="row d-flex justify-content-center">
                                {DataAdvisory?.map((items) => {
                                  return (
                                    <>
                                      <div className="col-md-3 mb-5">
                                        <div
                                          className="container our_team_position"
                                          style={{
                                            justifyContent: "center",
                                            display: "grid",
                                          }}
                                        >
                                          <a
                                            href={items.Linkedinlink}
                                            target="_blank"
                                            className="hover_effect "
                                          >
                                            <div
                                              className="image-here align-center-word membersphoto"
                                              style={{ position: "relative" }}
                                            >
                                              <img
                                                src={`${API_Advisory_image}/${items.Profilephoto}`}
                                                className="rounded-circle"
                                              ></img>
                                            </div>
                                          </a>
                                          <div className="align-center-word mt-4">
                                            <h5 className="p-0">
                                              {items.Name}
                                            </h5>
                                            <p>{items.designation}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <hr></hr>
              </>
            ) : null}
            {/* advisory section end */}

       

            {/* Investor section Start */}
            {DataInvestors.length > 0 ? (
              <>
                <section className="bg-white pb-4 pt-0">
                  <div className="container-fluid pb-md-4 px-0">
                    <div className="row mx-auto">
                      <div className="col-md-12">
                        <div className="testimonial-head">
                          <h2 className="mb-md-5 fw-600 mt-md-3 mt-3 mb-4">
                            Investors
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="our_team">
                      <div className="wrapper-large align-center">
                        <div>
                          <div className="">
                            <div className="four-col-adjustss">
                              <span className="row d-flex justify-content-center">
                                {DataInvestors?.map((items) => {
                                  return (
                                    <>
                                      <div className="col-md-3 mb-5">
                                        <div
                                          className="container our_team_position"
                                          style={{
                                            justifyContent: "center",
                                            display: "grid",
                                          }}
                                        >
                                          <a
                                            href={items.Linkedinlink}
                                            target="_blank"
                                            className="hover_effect "
                                          >
                                            <div
                                              className="image-here align-center-word membersphoto"
                                              style={{ position: "relative" }}
                                            >
                                              <img
                                                src={`${API_Investors_image}/${items.Profilephoto}`}
                                                className="rounded-circle"
                                              ></img>
                                            </div>
                                          </a>
                                          <div className="align-center-word mt-4">
                                            <h5 className="p-0">
                                              {items.Name}
                                            </h5>
                                            <p>{items.designation}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            ) : null}
            {/* Investor section end */}

            <Testimonial />
            <Team />
            <Footer />
          </div>
        );
}

export default Aboutus;
