import React, { useEffect, useState } from "react";
import { Link,useParams } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import axios from "axios";

import { API_URL } from './Api'

const API_Category = `${API_URL}/Categories/`;
const API_Sub = `${API_URL}/SubCategory/`;

function SideTab(props) {
  const { Linktit } = props
  // initializing state to store data
  const [category, setCategory] = useState([])
  const [subCate, setSunCate] = useState([])
  const params = useParams();


  //Function call on page mount
  useEffect(() => {
    GetData()
    console.log("yyy", Linktit)
  }, [])

  //Get Category from Backend
  const GetData = async () => {
    const cate = await axios.get(`${API_Category}`)
    setCategory(cate.data)
    const subCate = await axios.get(`${API_Sub}`)
    const Result =subCate.data.sort((a, b) => (a.SubCategoryName > b.SubCategoryName) ? 1 : -1)
    setSunCate(Result)
    
  }

  return (
    <div>
      <div className="ec-sidebar" style={{ position: 'relative' }}>
        <div className="card border-radius-none ec-box-width-" id="side_bar1">
          <div className="card-body p-0">
            <ul
              className="list-group list-group-flush border-0"
              style={{ display: "none" }}
            >
              <li className="list-group-item pl-3 py-3 border-y bg_color_add">
                <div className="custom-control custom-radio">
                  <input
                    type="checkbox"
                    className="bg-lightgrey custom-control-input show_only"
                    id="recommended"
                    defaultValue="recommended"
                  />
                  <label
                    className="custom-control-label fw-normal filter_innertext f-14"
                    htmlFor="recommended"
                  >
                    Show only recommended
                  </label>
                  <i
                    className="fas fa-info-circle fa-lg text-primary-red"
                    data-toggle="tooltip"
                  />
                </div>
              </li>
            </ul>
            <div id="s_all" className="menu_accordion border-y ">
              <div
                className="position-relative  card-header border-0 collapsed pl-3 py-3 pr-2 bg_color_add"
                id="headingOne"
              >
                <div className="media">
                  {/* <img class="mr-2 my-1 align-self-center d-md-block d-none" src="/site_media/img/circle.svg" alt="Generic placeholder image"> */}
                  <div className="media-body pl-md-0 pr-3 pr-md-0">
                    <div className="custom-control custom-radio">
                    {params.name?
                    <a
                    className="nav-link1 text-bold py_1_2rem1 fw-normal filter_innertext f-14 active"
                    href="/marketplace/"
                  >
                    All
                  </a>:
                  <a
                  className="nav-link1 text-bold py_1_2rem1 fw-normal filter_innertext greennav f-14 active"
                  href="/marketplace/"
                >
                  All
                </a>}
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {category.length > 0 ? <>

              {category.map((item, i) => {
                const suncat = subCate.filter(ii => ii.Category === item._id)
                
                return (

                  <div id="s_total-rewards" className="accordion menu_accordion border-y" key={i} >
                    <div
                      className="position-relative  card-header border-0 collapsed pl-3 py-3 pr-2 bg_color_add"
                      id="headingOne"
                    >
                      <div className="media">
                        <div className="media-body pl-md-0 pr-3 pr-md-0">
                          <div className="custom-control custom-radio">
                            <div id="headingSeven">
                              {params.name?.replace(/-/g, ' ').toLowerCase() === item.CategoryName.toLowerCase() ?
                                <a
                                  href={ `/marketplace/${item.CategoryName.replace(/ /g, '-').toLowerCase()}/`}
                                  className="nav-link1   py_1_2rem1 text-bold  fw-normal filter_innertext  greennav f-14 "
                                >
                                  {item.CategoryName}
                                </a> :
                                <a
                                href={ `/marketplace/${item.CategoryName.replace(/ /g, '-').toLowerCase()}/`}
                                  className="nav-link1   py_1_2rem1 text-bold  fw-normal filter_innertext  f-14 "
                                >
                                  {item.CategoryName}
                                </a>
                              }
                              {suncat.length > 0 ?
                                <div
                                  className="btn float-right bg-transparent mp_list_arrow text-grey waves-effect waves-light"
                                  style={{ paddingTop: 3 }}
                                  data-toggle="collapse"
                                  data-target={`#${item.CategoryName}`}
                                  aria-expanded="true"
                                  aria-controls={`${item.CategoryName}`}>
                                  <FaChevronDown />
                                  <i className="fa fa-chevron-up d-none" id="list_open" aria-hidden="true" />
                                </div> : null}
                            </div>
                            {suncat.map(ee => {
                              return (<div key={ee._id}
                                id={`${item.CategoryName}`}
                                className={params.name?.replace(/-/g, ' ').toLowerCase() === item.CategoryName.toLowerCase()  ? "collapse show " : "collapse"}
                                aria-labelledby="headingSeven"
                                data-parent="#accordion">
                                <div className="custom-control custom-radio py-2 subcategory">
                                {params.subCat === ee.SubCategoryURL ?
                                  <a 
                                  href={`/marketplace/${item.CategoryName.replace(/ /g, '-').toLowerCase()}/${ee.SubCategoryURL}/`}
                                    className="nav-link1   py_1_2rem1   fw-normal filter_innertext greennav f-14"
                                  >
                                    {ee.SubCategoryName}
                                  </a>:
                                  <a href={`/marketplace/${item.CategoryName.replace(/ /g, '-').toLowerCase()}/${ee.SubCategoryURL}/`}
                                    className="nav-link1   py_1_2rem1   fw-normal filter_innertext f-14"
                                  >
                                    {ee.SubCategoryName}
                                  </a>}
                                  {/*                                                        <input type="radio" class="bg-lightgrey custom-control-input marketplaces_filter filter_subcatagory" id="compensation-management" name="sub_category_list" value="compensation-management" >*/}
                                  {/*                                                        <label class="custom-control-label fw-normal filter_innertext f-14" for="compensation-management">COMPENSATION MANAGEMENT</label>*/}
                                </div>
                              </div>)
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  //   <div key={i}>
                  //   <ul>
                  //     {item.CategoryName}
                  //     {suncat.map(ee=>{
                  //       return(
                  //         <li>{ee.SubCategoryName}</li>
                  //       )
                  //     })}
                  //   </ul>
                  // </div>
                )

              })}

            </> : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideTab;
