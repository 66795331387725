import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Model from './Model';
import axios from "axios";
import DialogBox from './global-components/enquiry-modal';
import moment from 'moment';

import { API_URL } from './Api'


const PastEvent_API = `${API_URL}/Events/`;
const urlimg = `${API_URL}/Events_image/`;

export default function ResourceHome(props) {

  // initializing state to store data
  const [Product, SetProduct] = useState("")
  const [Productlink, SetProductlink] = useState("")
  const [PastEvent, SetPastEvent] = useState([]);
  const [dialog, setDialog] = useState(false)


  //Function call on page mount
  // useEffect(() => {
  //   PastEventdata()
  // }, [])

  

// const PastEventdata = async () => {
//     const { data } = await axios.get(PastEvent_API);
//     let sort = data.sort((a, b) => {
// 			return (
// 				new Date(a.EventDate) -
// 				new Date(b.EventDate)
// 			)
// 		}).reverse()
//         let Editss = sort.slice(0,6);
//         console.log("dadadadadada",Editss)
//     SetPastEvent(Editss);
 
//   }


  return (
    <div className="container-flex m-auto">
       
     
       
        
            <div className="row mx-0">
              {console.log("lllllllll",props.Eventdatas.length)}
            {props.Eventdatas.length > 0 ? 
        <section>
           <>
           
             
            <div className='col-md-12 col-sm-12 m-auto px-0'>
              <div className='row m-0 d-flex justify-content-center'>
                {props.Eventdatas.map(Pasteven =>{
                  return(
                    <div className='col-lg-4 col-md-4 col-sm-6 col-12 index_margin blog_new_location ps-0 mb-30'>
                    <div className='card event_card_height border-radius-none' id='past_event_card'>
                    <a href={`/events/${Pasteven.Eventurlname.replace(/ /g, '-').toLowerCase()}/`}>
                        <div className='card-header bg-transparent border-0 position-relative py-2'>
                          <div className='row aftr_rt_bor'>
                            <div className='col-lg-2 col-md-2 col-sm-3 col-2 px-0 align-items-center d-flex justify-content-center'>
                              <h2 className='m-0 text-black text-center f-32 f-mb-24 fw-600'>{moment( Pasteven.EventDate).format("DD")}</h2>
                            </div>
                            <div className='col-lg-10 col-md-10 col-sm-9 col-10 d-flex align-self-center pl-md-1 pl-0'>
                              <div className='d-block h-100'>
                                <p className='m-0 link_blog text-black f-12'>{moment( Pasteven.EventDate).format('dddd')}</p>
                                <p className='m-0 font-weight-400 f-12 text-black'>{moment( Pasteven.EventDate).format("MMM  YYYY")}</p>
                              </div>
                            </div>
                          </div>
                          <div className='w-100 bg-white event--img_home border_top border_bottom mt-2' style={{ position: 'relative', textAlign: 'center' }}>
                            <img className='card-img-bottom' alt="sample" style={{ width: '100%', objectFit: 'contain' }} src={`${urlimg}/${Pasteven.PreEventImages}`} />
                          </div>
                          <div className='card-body  event_body_home fixed_content_3 padding' id='past_event_body'>
                            <p className='card-title text-black text-center f-14 fw-600 fixed_content mb-2' style={{ height: 'auto', marginBottom: '8px' }}>
                            {Pasteven.EventName}
                            </p>
                           
                          </div>
                        </div>
                      </a>
                      <div className='row mx-0 mt-3'>
                        <div className='col-lg-12 col-md-9 col-sm-10 col-12 pb-3'>
                            <div className='d-flex justify-content-center'>
                          <a href={`/events/${Pasteven.Eventurlname.replace(/ /g, '-').toLowerCase()}/`} className='btn btn-red-primary-outline border-radius-none ml-1 f-14 waves-effect waves-light'>
                            View Details
                          </a></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )
                })}
               
              </div>
            </div>
          
          </> 
        </section>
        :null}
</div>
<div className="row mx-0">
<div className="col-md-12 mb-md-5 mx-auto d-flex text-center justify-content-center">
          <a href={"/events/"} className="btn btn-red-primary fw-500 f-20 f-mb-16 border-radius-none px-5 py-3 waves-effect waves-light">View More</a>
        </div>
</div>
         
     
    </div>
  );
}
