import React, { memo, useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import axios from "axios";
import { API_URL } from './Api'
import Navbar from './global-components/navbar';
import './resoursedownload.css'



const api = `${API_URL}/Resources`



function Resourcedownload({ show, hide, sucess, productid }) {

    const emailvali = /^([\w-\.]+@(?!gmail)(?!yahoo)(?!hotmail)(?!outlook)(?!icloud)([\w-]+\.)+[\w-]{2,4})?$/;
    const [inputs, setInputs] = useState({
        Name: '',
        Email: '',
        ContactNumber : '',
        Company: '',
        Country: '',
        error: '',
    })

 


    // 'This field is required.'
    const handleChange = ({ target: { name, value } }) => {
        setInputs({ ...inputs, [name]: value });
    }


    const submit = (event) => {
        event.preventDefault();
        let valitade = validtion();
        let valuess = {
            Resource:productid,
            Name: inputs.Name,
            ContactNumber : inputs.countryCode + ' ' + inputs.ContactNumber,
            CompanyName: inputs.Company,
            CompanyEmail: inputs.Email,
            Country: inputs.Country

        }

        try {
           
            if (valitade) {
                axios.post(`${api}/${productid}`, valuess).catch((err) => console.log(err, 'error in the sender'))

            }
        }
        finally {

            if (valitade) {
                setInputs({
                    Name: "",
                    Email: '',
                    Company: '',
                    Country: '',
                })

                hide()
                sucess()
            }

        }
    }


    const numberCheck =(event)=>{
        var ch = String.fromCharCode(event.which);
        if(!(/[0-9]/.test(ch))){
           event.preventDefault();
         }
    }


    const countryCodes = [
        { code: '+1', name: 'USA' },
        { code: '+44', name: 'UK' },
        { code: '+91', name: 'India' },
        { code: '+93', name: 'Afghanistan' },
        { code: '+355', name: 'Albania' },
        { code: '+213', name: 'Algeria' },
        { code: '+376', name: 'Andorra' },
        { code: '+244', name: 'Angola' },
        { code: '+54', name: 'Argentina' },
        { code: '+374', name: 'Armenia' },
        { code: '+61', name: 'Australia' },
        { code: '+43', name: 'Austria' },
        { code: '+994', name: 'Azerbaijan' },
        { code: '+973', name: 'Bahrain' },
        { code: '+880', name: 'Bangladesh' },
        { code: '+375', name: 'Belarus' },
        { code: '+32', name: 'Belgium' },
        { code: '+501', name: 'Belize' },
        { code: '+229', name: 'Benin' },
        { code: '+975', name: 'Bhutan' },
        { code: '+591', name: 'Bolivia' },
        { code: '+387', name: 'Bosnia and Herzegovina' },
        { code: '+267', name: 'Botswana' },
        { code: '+55', name: 'Brazil' },
        { code: '+673', name: 'Brunei' },
        { code: '+359', name: 'Bulgaria' },
        { code: '+226', name: 'Burkina Faso' },
        { code: '+257', name: 'Burundi' },
        { code: '+855', name: 'Cambodia' },
        { code: '+237', name: 'Cameroon' },
        { code: '+1', name: 'Canada' },
        { code: '+238', name: 'Cape Verde' },
        { code: '+236', name: 'Central African Republic' },
        { code: '+235', name: 'Chad' },
        { code: '+56', name: 'Chile' },
        { code: '+86', name: 'China' },
        { code: '+57', name: 'Colombia' },
        { code: '+269', name: 'Comoros' },
        { code: '+242', name: 'Congo' },
        { code: '+243', name: 'Congo, Democratic Republic of the' },
        { code: '+682', name: 'Cook Islands' },
        { code: '+506', name: 'Costa Rica' },
        { code: '+225', name: "Côte d'Ivoire" },
        { code: '+385', name: 'Croatia' },
        { code: '+53', name: 'Cuba' },
        { code: '+357', name: 'Cyprus' },
        { code: '+420', name: 'Czech Republic' },
        { code: '+45', name: 'Denmark' },
        { code: '+253', name: 'Djibouti' },
        { code: '+593', name: 'Ecuador' },
        { code: '+20', name: 'Egypt' },
        { code: '+503', name: 'El Salvador' },
        { code: '+240', name: 'Equatorial Guinea' },
        { code: '+291', name: 'Eritrea' },
        { code: '+372', name: 'Estonia' },
        { code: '+268', name: 'Eswatini' },
        { code: '+251', name: 'Ethiopia' },
        { code: '+679', name: 'Fiji' },
        { code: '+358', name: 'Finland' },
        { code: '+33', name: 'France' },
        { code: '+241', name: 'Gabon' },
        { code: '+220', name: 'Gambia' },
        { code: '+995', name: 'Georgia' },
        { code: '+49', name: 'Germany' },
        { code: '+233', name: 'Ghana' },
        { code: '+30', name: 'Greece' },
        { code: '+299', name: 'Greenland' },
        { code: '+502', name: 'Guatemala' },
        { code: '+224', name: 'Guinea' },
        { code: '+245', name: 'Guinea-Bissau' },
        { code: '+592', name: 'Guyana' },
        { code: '+509', name: 'Haiti' },
        { code: '+504', name: 'Honduras' },
        { code: '+36', name: 'Hungary' },
        { code: '+354', name: 'Iceland' },
        { code: '+62', name: 'Indonesia' },
        { code: '+98', name: 'Iran' },
        { code: '+964', name: 'Iraq' },
        { code: '+353', name: 'Ireland' },
        { code: '+972', name: 'Israel' },
        { code: '+39', name: 'Italy' },
        { code: '+81', name: 'Japan' },
        { code: '+962', name: 'Jordan' },
        { code: '+7', name: 'Kazakhstan' },
        { code: '+254', name: 'Kenya' },
        { code: '+686', name: 'Kiribati' },
        { code: '+383', name: 'Kosovo' },
        { code: '+965', name: 'Kuwait' },
        { code: '+996', name: 'Kyrgyzstan' },
        { code: '+856', name: 'Laos' },
        { code: '+371', name: 'Latvia' },
        { code: '+961', name: 'Lebanon' },
        { code: '+266', name: 'Lesotho' },
        { code: '+231', name: 'Liberia' },
        { code: '+218', name: 'Libya' },
        { code: '+423', name: 'Liechtenstein' },
        { code: '+370', name: 'Lithuania' },
        { code: '+352', name: 'Luxembourg' },
        { code: '+853', name: 'Macau' },
        { code: '+389', name: 'North Macedonia' },
        { code: '+261', name: 'Madagascar' },
        { code: '+265', name: 'Malawi' },
        { code: '+60', name: 'Malaysia' },
        { code: '+960', name: 'Maldives' },
        { code: '+223', name: 'Mali' },
        { code: '+356', name: 'Malta' },
        { code: '+692', name: 'Marshall Islands' },
        { code: '+222', name: 'Mauritania' },
        { code: '+230', name: 'Mauritius' },
        { code: '+52', name: 'Mexico' },
        { code: '+691', name: 'Micronesia' },
        { code: '+373', name: 'Moldova' },
        { code: '+377', name: 'Monaco' },
        { code: '+976', name: 'Mongolia' },
        { code: '+382', name: 'Montenegro' },
        { code: '+212', name: 'Morocco' },
        { code: '+258', name: 'Mozambique' },
        { code: '+95', name: 'Myanmar' },
        { code: '+264', name: 'Namibia' },
        { code: '+674', name: 'Nauru' },
        { code: '+977', name: 'Nepal' },
        { code: '+31', name: 'Netherlands' },
        { code: '+64', name: 'New Zealand' },
        { code: '+505', name: 'Nicaragua' },
        { code: '+227', name: 'Niger' },
        { code: '+234', name: 'Nigeria' },
        { code: '+47', name: 'Norway' },
        { code: '+968', name: 'Oman' },
        { code: '+92', name: 'Pakistan' },
        { code: '+680', name: 'Palau' },
        { code: '+970', name: 'Palestine' },
        { code: '+507', name: 'Panama' },
        { code: '+675', name: 'Papua New Guinea' },
        { code: '+595', name: 'Paraguay' },
        { code: '+51', name: 'Peru' },
        { code: '+63', name: 'Philippines' },
        { code: '+48', name: 'Poland' },
        { code: '+351', name: 'Portugal' },
        { code: '+974', name: 'Qatar' },
        { code: '+40', name: 'Romania' },
        { code: '+7', name: 'Russia' },
        { code: '+250', name: 'Rwanda' },
        { code: '+290', name: 'Saint Helena' },
        { code: '+1-869', name: 'Saint Kitts and Nevis' },
        { code: '+1-758', name: 'Saint Lucia' },
        { code: '+508', name: 'Saint Pierre and Miquelon' },
        { code: '+1-784', name: 'Saint Vincent and the Grenadines' },
        { code: '+685', name: 'Samoa' },
        { code: '+378', name: 'San Marino' },
        { code: '+239', name: 'Sao Tome and Principe' },
        { code: '+966', name: 'Saudi Arabia' },
        { code: '+221', name: 'Senegal' },
        { code: '+381', name: 'Serbia' },
        { code: '+248', name: 'Seychelles' },
        { code: '+232', name: 'Sierra Leone' },
        { code: '+65', name: 'Singapore' },
        { code: '+421', name: 'Slovakia' },
        { code: '+386', name: 'Slovenia' },
        { code: '+677', name: 'Solomon Islands' },
        { code: '+252', name: 'Somalia' },
        { code: '+27', name: 'South Africa' },
        { code: '+82', name: 'South Korea' },
        { code: '+211', name: 'South Sudan' },
        { code: '+34', name: 'Spain' },
        { code: '+94', name: 'Sri Lanka' },
        { code: '+249', name: 'Sudan' },
        { code: '+597', name: 'Suriname' },
        { code: '+268', name: 'Swaziland' },
        { code: '+46', name: 'Sweden' },
        { code: '+41', name: 'Switzerland' },
        { code: '+963', name: 'Syria' },
        { code: '+886', name: 'Taiwan' },
        { code: '+992', name: 'Tajikistan' },
        { code: '+255', name: 'Tanzania' },
        { code: '+66', name: 'Thailand' },
        { code: '+670', name: 'Timor-Leste' },
        { code: '+228', name: 'Togo' },
        { code: '+676', name: 'Tonga' },
        { code: '+1-868', name: 'Trinidad and Tobago' },
        { code: '+216', name: 'Tunisia' },
        { code: '+90', name: 'Turkey' },
        { code: '+993', name: 'Turkmenistan' },
        { code: '+688', name: 'Tuvalu' },
        { code: '+256', name: 'Uganda' },
        { code: '+380', name: 'Ukraine' },
        { code: '+971', name: 'United Arab Emirates' },
        { code: '+598', name: 'Uruguay' },
        { code: '+998', name: 'Uzbekistan' },
        { code: '+678', name: 'Vanuatu' },
        { code: '+58', name: 'Venezuela' },
        { code: '+84', name: 'Vietnam' },
        { code: '+681', name: 'Wallis and Futuna' },
        { code: '+967', name: 'Yemen' },
        { code: '+260', name: 'Zambia' },
        { code: '+263', name: 'Zimbabwe' },
      ];


    const validtion = () => {
         
        let emailerrors = '';
        if (!emailvali.test(inputs.Email)) {
           
            emailerrors = "Please enter the business mail only";
        }
        if (emailerrors) {
            setInputs({
                ...inputs,
                error: emailerrors
            })
            return false
        }
        else {
            setInputs({
                ...inputs,
                error: ''
            })
            return true
        }


    }

    // swi@mail.com


    if (!show) {
        return <> </>
    }


    // return   <Sucess  onshow={onshow}  onHide={setonShow} />

    else {

        return (
            <>

                <Modal show={show} onHide={hide} fullscreen={true} size={'sm'} >

                    <Modal.Body>
                        <div className='text-center'>
                            <p className="f-18 text-grey">Please fill these details to download the resource</p>

                            <p className="mb-30 text-grey " id="brand_name"></p>
                        </div>
                        <form onSubmit={submit} >

                            <div className="my-3" style={{ textAlign: 'start' }}>
                                <label className="active">Name <span className="text-danger">*</span></label>
                                <input type="text" required name="Name" onChange={handleChange} value={inputs.Name} id="name" className="form-control mt-0" placeholder="Name" />
                                <p className="text-danger "></p>
                            </div>
                            <div className="my-3" style={{ textAlign: 'start' }}>
                                <label className="active">Company Name <span className="text-danger">*</span></label>
                                <input type="text" required onChange={handleChange} name="Company" value={inputs.Company} id="company_name" className="form-control mt-0" placeholder="Company Name" />
                                <p className="text-danger"> </p>
                            </div>
                            <div className="my-3" style={{ textAlign: 'start' }}>
                                <label className="active">Company Email <span className="text-danger">*</span></label>
                                <input type="email" required onChange={handleChange} name="Email" value={inputs.Email} id="email" className="form-control mt-0" placeholder="Email" />
                                <p className="text-danger mt-3"> {inputs.error} </p>
                            </div>



                            <div className="my-3" style={{ textAlign: 'start' }}>
                                <label className="active">
                                    Contact Number <span className="text-danger">*</span>
                                </label>
                                <div className="d-flex">
                                    <select
                                        name="countryCode"
                                        className="form-control mt-0 me-2"
                                        onChange={handleChange}
                                        value={inputs.countryCode}
                                        required
                                    >
                                        <option value="" disabled>Select country code</option>
                                        {countryCodes.map((country, index) => (
                                            <option key={index} value={country.code}>
                                                {country.name} ({country.code})
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                        type="text"
                                        onKeyPress={numberCheck}
                                        required
                                        maxLength={15}
                                        minLength={6}
                                        placeholder="Contact Number"
                                        className="form-control mt-0"
                                        name="ContactNumber"
                                        onChange={handleChange}
                                        value={inputs.ContactNumber}
                                    />
                                </div>
                                <p className="text-danger"> </p>
                            </div>



                            <div className="my-3" style={{ textAlign: 'start' }}>
                                <label className="active">Country <span className="text-danger">*</span></label>
                                <input type="text" required onChange={handleChange} name="Country" value={inputs.Country} id="country" className="form-control mt-0" placeholder="Country" />
                                <p className="text-danger"> </p>
                            </div>
                            <div className='modelbtn text-center waves-effect mt-5'>
                                <button type='submit' className="btn" >submit</button>


                            </div>
                        </form>


                    </Modal.Body>
                </Modal>
            </>
        )
    }

}

export default memo(Resourcedownload)

export const Sucess = function Sucess({ onshow, onHide }) {
    if (!onshow) {

        return <></>
    }

    else {

        return (
            <>

                <Modal
                    show={onshow}
                    onHide={onHide}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    fullscreen={true} size={'sm'}
                >


                    <Modal.Body >
                        <div style={{ textAlign: 'center' }}>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="47.156" height="47.156" viewBox="0 0 47.156 47.156"><defs><style dangerouslySetInnerHTML={{ __html: ".a{fill:#004AAD;}" }} /></defs><path className="a" d="M23.578,0A23.578,23.578,0,1,0,47.156,23.578,23.578,23.578,0,0,0,23.578,0Zm0,43.788a20.21,20.21,0,1,1,20.21-20.21A20.21,20.21,0,0,1,23.578,43.788Z" /><g transform="translate(10.084 13.494)"><path className="a" d="M128.622,137.216a1.684,1.684,0,0,0-2.34,0h0l-15.651,15.651-5.546-5.546a1.684,1.684,0,1,0-2.423,2.34l.041.041,6.737,6.737a1.684,1.684,0,0,0,2.381,0L128.664,139.6A1.684,1.684,0,0,0,128.622,137.216Z" transform="translate(-102.19 -136.743)" /></g></svg>
                            </div>

                            <h3 className="f-30 mb-2 text-model text-capitalize">Thank you !</h3>
                            <p className="mb-2">We have sent the resource file to your email.</p>
                            <button className="btn btn-model rounded-pill mb-2 waves-effect waves-light" onClick={onHide} >ok</button>


                        </div>



                    </Modal.Body>
                </Modal>
            </>
        )
    }


}

