import React, { useState, useEffect } from "react";
import Navbar from './global-components/navbar';
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';
import Footer from './global-components/footer';
import { useLocation} from 'react-router-dom'

export default function Success (){
  const loc = useLocation();
  const [successtitle , setsuccesstitle]= useState()
  useEffect(()=>{
    
    const IdFromURL = loc.state
    setsuccesstitle(IdFromURL)
    console.log("suuu",IdFromURL)
  },[])
    return( <div>
        <Navbar />
        <section className="w-100 d-block overflow-auto bg-white">
  <div className="container-fluid" style={{paddingTop: '4rem'}}>
    <div className="py-5 d-flex flex-column justify-content-center" style={{height: '400px!important'}}>
      <img src="/assets/img/Icon.svg" style={{height:"100px",width:"100px",margin:'auto'}} alt="uu" />
      <h3 className="f-30 text-center" style={{color: '#004AAD', marginBottom: 0}}>Thank you !</h3>
      <p className="f-18 text-grey text-center">{successtitle}</p>
      <div className="text-center my-3">
        <a className="btn btn-red-primary waves-effect waves-light" href='/' >BACK</a>
      </div> 
    </div>
  </div>
  <div className="modal fade text-center" id="contact_success_modal" tabIndex={-1} role="dialog" aria-labelledby="contact_success_modal_Title" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered contact_success_width" role="document">
      <div className="modal-content success_content" style={{borderRadius: 0, paddingBottom: 20, paddingTop: 10}}>
        <div className="modal-close">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{float: 'right', position: 'absolute', right: '4%'}}>
            <img className src="/site_media/img/close_icon.svg" alt="ii" />
          </button>
        </div>
        <div className="modal-body d-md-grid d-inline" style={{padding: '0 25px', marginTop: 20, alignItems: 'center', height: '100%'}}>
          <div className="row">
            <div className="col-12">
              <img src="/site_media/img/success.svg" alt="yy" />
              <h3 className="f-30" style={{color: '#FF5453', marginBottom: 0}}>Thank you !</h3>
              <p className="f-18 text-grey">we will get in touch shortly</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

        <Testimonial />
        <Team />
        <Footer />
    </div>)
}

 

