import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Model from './Model';
import axios from "axios";
import DialogBox from './global-components/enquiry-modal';

import { API_URL } from './Api'

const API_Market = `${API_URL}/MarketPlace`;
const API_View = `${API_URL}/MarketPlace_image`;
const Api_review = `${API_URL}/ProductReview`;

export default function FeaturedCarosel() {

  // initializing state to store data
  const [Product, SetProduct] = useState("");
const [Productlink, SetProductlink] = useState("")
  const [data, setData] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [starrating, setstarrating] = useState();
  const [subscriberlist, setsubscriberlist] = useState();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 769 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet1: {
      breakpoint: { max: 768, min: 520 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 520, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  //Function call on page mount
  useEffect(() => {
    GetData()
  }, [])

  //Get Category from Backend
  const GetData = async () => {
    const Result = await axios.get(`${API_Market}`)
    const filtered = Result.data.filter(ss => ss.IsFeatured === 'true')
    var ReviewData = filtered
    setData([])
ReviewData.map(async(Stars)=>{
  console.log("tetss",Stars)
  const Review = await axios.get(`${Api_review}/Approval/${Stars._id}`)

  var sum = 0;
  Review.data.map(items => {
    sum += parseFloat(items.rating)
  })
  var toalstar = sum / Review.data.length;
 var fixednumber = toalstar.toFixed(1)
const dataf ={
  MarketURL:Stars.MarketURL,
  ProductSummary:Stars.ProductSummary,
  ProductLogo:Stars.ProductLogo,
  ProductSummary:Stars.ProductSummary,
  ProductName:Stars.ProductName,
  ProductTagLine:Stars.ProductTagLine,
  StarRating:fixednumber
}
setData((indexs)=>[...indexs,dataf])
//  console.log("nnnnn",dataf)
 
})
  }

  const Getcompanyname = data => {
    SetProduct(data)
    setDialog(true)
  }

  const dialogClose = () => {
    setDialog(false)
  }

  return (
    <div className="container-flex m-auto">
      <DialogBox show={dialog} title={Product} links={Productlink} dialogClose={dialogClose} />
      <Carousel
        removeArrowOnDeviceType={["tablet", "mobile"]}
        responsive={responsive}
      >
        {data.map((item, i) => {
          return (
            <div className=" slide-in-bottom feature_card px-2 mb-3" key={i}>
              <div className="content mp_card mb-3 testimonial-bord ">
                <Link to={`/${item.MarketURL}/`} className="link_details">
                  <div className="content-overlay"></div>
                  <div className="new_card content-image border-lightgrey">
                    <div className='h-50 d-flex' style={{ justifyContent: 'center', position: 'relative' }}>
                      <div className="card-img-top" style={{ width: '100%', height: 'auto', backgroundImage: ` url(${API_View}/${item.ProductLogo})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center' }}></div>
                    </div>
                    <div className="h-50 d-flex align-items-center bg_grey justify-content-center testimonial-padding">
                      <div className="d-block">
                        <p className="Products f-12 w-100 text-grey fixed_content_3 ">
                          {item.ProductSummary}
                        </p>
                        
                      {item.StarRating && item.StarRating !== "NaN" ? <>
                      <div className="" >
                          <div className="star-rating-count ">
                            {[...Array(5)].map((star, index) => {
                              index += 1;
                              return (
                                <button
                                  key={index}
                                  className={index <= (`${item.StarRating}`) ? "on" : "off"}

                                >
                                  <span className="star" >&#9733;</span>

                                </button>
                              );
                            })}
                          </div>
                            {/* <p className="Starcount"> {4} ({1})</p> */}
                            </div>
                            </> : null}
                      </div>
                    </div>
                  </div>
                </Link>
                <div className="content-details px-0 ">
                <Link to={`/${item.MarketURL}/`} className="link_details">
                    <div className="h-50 d-block text-center pt-5 px-3">
                      <h3 className="content-title f-20 fw-600 mt-0 mb-2">
                        {item.ProductName}
                      </h3>

                      <p className="content-text f-12 fixed_content pr-0">
                        {item.ProductTagLine}
                      </p>

                    </div>
                  </Link>
                  <div className="row m-0 h-50 pt-md-4 pt-2 d-flex testimonial-justify" style={{ alignItems: 'center' }}>
                    <Link to="#" className="link_details">
                      {" "}
                    </Link>
                    <div className="col-md-6 col-6 pr-1 mb-0">
                      <Link to="#" className="link_details">
                        {" "}
                      </Link>
                      <Link to={`/${item.MarketURL}/`} className="link_details">
                        <button className="card_view btn btn-block f-16 border-radius-none waves-effect waves-light">
                          View
                        </button>
                      </Link>
                    </div>
                    <div className="col-md-6 pl-1 col-6">
                      <button
                        className="card_view btn btn-block f-16 modal_enquiry border-radius-none waves-effect waves-light"
                        // data-toggle="modal"
                        // data-target="#exampleModalLong"
                        onClick={() => {
                          Getcompanyname(item.ProductName)
                          SetProduct(item.ProductName)
			SetProductlink(item.MarketURL)
                        }}>
                        Enquire
                        <p className="d-none enq_slug">{item.ProductName}</p>
                        <p className="d-none name_slug">{item.ProductName}</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </Carousel>
      {/* <Model Product={Product} /> */}
    </div>
  );
}
