import React, { Component,useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { API_URL } from '../Api';
import axios from 'axios';
const api = `${API_URL}/Banner`;
const urlimg = `${API_URL}/Banner_image`;
const Banner =(props)=> {

 
 const [data,setdata]=useState([])

       useEffect(()=>{
      
       },[])

  

    return (
      <div>
        {console.log("yyyyyyyyyyccccc",props.Bannerdatas)}
      <section className="p-md-0 pt-3 pb-5 carousel-dot">
  <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
  <ol className="carousel-indicators">
    {props.Bannerdatas?.map((banner,index) =>{
      
      
return(
    
  
      <li data-target="#carouselExampleIndicators" data-slide-to={index} className={banner.BannerActive =="Active" ?"active":""} />
      
   )
    })}
     </ol>
    <div className="carousel-inner">
      {/* first banner */}
      {props.Bannerdatas?.map(banner =>{
         
        return(

     
      <div className={banner.BannerActive =="Active"? "carousel-item set_height banner_style active" :"carousel-item set_height banner_style"} onclick="window.location.href = 'https://www.google.com';">
      <img src={`${urlimg}/${banner.BannerImage}`} className='bannerbtns' ></img>
       
      <div className="col-md-12 h-100">
          <div className="row m-0 h-100">
            <div className="col-md-6" />
            <div className="col-md-6 d-flex align-items-center">
              <div className="text-black px-4 px-lg-0">
                <h5 className="f-30 fw-600 mb-1 text-white f-mb-32 word_wrap shadow_text bannercenter">
                {banner.BannerTitle}
                </h5>
                <p className="f-20 fw-400 text-white f-mb-14 shadow_text subheading_overlay bannercenter">
                {banner.BannerSubtext}
                </p>
                <div className="d-md-block align-items-center mt-3 text-left1">
                  <a href={ banner.ButtonLink } className="btn fw-400 btn-red-primary-outline1 border-radius-none m-0 f-20 f-mb-16 waves-effect waves-light"  >{banner.ButtonText}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        
      </div> )})}
 {/* first banner end */}
   
    </div>
    <a className="carousel-control-prev carousel-control" href="#carouselExampleControls" role="button" data-slide="prev">
      <button type="button" className="btn btn-default border border-radius-none btn-arrow slide waves-effect waves-light carousel-button-control">
        <i className="fas fa-arrow-left fa-lg m-2 teal-color" />
      </button>
    </a>
    <a className="carousel-control-next carousel-control" href="#carouselExampleControls" role="button" data-slide="next">
      <button type="button" className="btn btn-default border border-radius-none btn-arrow slide waves-effect waves-light carousel-button-control">
        <i className="fas fa-arrow-right fa-lg m-2 teal-color" />
      </button>
    </a>
  </div>


  <a className="carousel-control-prev d-none" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true" />
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next d-none" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true" />
    <span className="sr-only">Next</span>
  </a>
</section></div>)


 
}

export default Banner