import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Navbar from './global-components/navbar';
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';
import Footer from './global-components/footer';
import { MdMail, MdLocationPin } from "react-icons/md";
import { FaLinkedinIn, FaYoutube } from "react-icons/fa";
import axios from "axios";
import { API_URL } from './Api'
import {Helmet} from "react-helmet";

const Api = `${API_URL}/ContactPage`;

function Contactus() {

    const [contactForm, setContactForm] = useState({
        Name: "",
        Company:"",
        Email: "",
        ContactNumber: "",
        Subject: "",
        Message: "",

    })
    const [Successtitle,setSuccesstitle]=useState('Your message has been successfully sent. We will contact you very soon!') 

    const[message,setMessage]=useState('')

    const history = useHistory()

    useEffect(() => {
        
        if (document.location.pathname[document.location.pathname.length - 1] !== '/') {
            history.replace(document.location.pathname + '/');
        }
    }, [])


    // onchanges
    const adding = ({ target: { name, value } }) => {
        setContactForm({...contactForm,[name]:value})
        setMessage("")
    }

    // submit
    const Formsubmit = async (event) => {
        event.preventDefault();
        setMessage("Sending")
        const res = await axios.post(`${Api}`, contactForm)
            // this.props.history.push("/Training")
            if(res.data){
                setMessage("")
                setContactForm({
                    Name: "",
                    Company:"",
                    Email: "",
                    ContactNumber: "",
                    Subject: "",
                    Message: "",
                })
                history.push({pathname:'/enquire/success',state:Successtitle})
     
            }
    }

    return (
        <div>
                 
            <Navbar />
            <section className='py-md-5 py-4 title_bg jumbotron jumbotron-fluid text-center m-0 border-bottom'>
                <div className="container-fluid market" style={{ marginTop: '2%' }}>
                    <h1 className="text-center f-28 fw-600" >CONTACT US</h1>
                </div>

            </section>

 <section>
 	<nav>
		<ol className="cd-breadcrumb custom-separator">
			<li><a href="/">Home</a></li>
			 
			<li className="current"><em>Contact us</em></li>
		</ol>
	</nav>
</section>
            <section className='w-100 d-block overflow-auto bg-white'>
                <div className='container-fluid my-md-5 my-4 py-md-3 contact_container'>
                    <div className='row m-0'>
                        <div className='col-md-8 bg-lightgrey pt-md-3 pb-md-4 px-md-5 px-4'>
                            <div className='contact-form contact_form'>
                                <p className='f-20 f-mb-16 fw-600'>
                                    GET IN TOUCH WITH US
                                </p>
                                <form className=" " onSubmit={Formsubmit} >
                                    <input type='text' placeholder='Name' className='w-100 border-0 border-radius-none f-16 f-mb-14 my-3' onChange={adding} name="Name" value={contactForm.Name} required></input>
                                    <input type='text' placeholder='Company Name' className='w-100 border-0 border-radius-none f-16 f-mb-14 my-3 ' onChange={adding} name="Company" value={contactForm.Company} required></input>
                                    <div className='row m-0 p-0'>
                                        <div className='col-md-6 col-sm-6 pl-0 pr-md-2 pr-0'>
                                            <input type='email' className='border-radius-none w-100 border-0 f-16 f-mb-14 mt-md-1 mb-md-1 mt-0' onChange={adding} name="Email" value={contactForm.Email} placeholder='Email'></input>
                                        </div>
                                        <div className='col-md-6 col-sm-6 pl-0 pr-md-2 pr-0'>
                                            <input type='number' className='border-radius-none w-100 border-0 f-16 f-mb-14 mt-md-1 mb-md-1 mt-0' onChange={adding} name="ContactNumber" value={contactForm.ContactNumber} placeholder='Contact Number'></input>
                                        </div>
                                    </div>
                                    <input type='text' placeholder='Subject' className='w-100 border-0 border-radius-none f-16 f-mb-14 my-3' name='Subject' onChange={adding} value={contactForm.Subject} required></input>
                                    <textarea rows={4} id="contact_message" className="border-radius-none w-100 border-0 f-16 f-mb-14 my-3" onChange={adding} name="Message" value={contactForm.Message} placeholder="Message" required/>
                                     <div className='row m-0 pb-3'>
                                     <p className="error_msg_comments text-red">{message}</p>
                                     </div>
                                    <div className='row float-right m-0 pb-3'>
                                        <div className='col-md-12'>
                                            <button type="submit" className="btn btn-red-primary border-radius-none f-16 f-mb-14 mt-1 px-5 waves-effect waves-light" id="contact_submit">SEND</button>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='col-md-4 my-md-0 mt-4 pl-md-4 pr-md-0 pb-0 px-0'>
                            <div className='card h-100'>
                                <div className='card-body d-flex'>
                                    <div className='contact-address contact-wraper'>
                                        <h5 className='card-title f-20 fw-600 mb-md-3 mb-2'>CONTACT INFORMATION</h5>
                                        <Link to={"/"} className='white contact_logo mb-md-4 mb-3 pt-3  '>
                                            <img src="/assets/img/logo.svg" className="HR_logo"  alt="ee"/>
                                        </Link>
                                        <div className='d-flex align-items-center mb-2'>
                                            <div className='add-icon'><MdMail className='text-red add-iconsize' /></div>
                                            <p className='mail '>
                                                <a className="ml-1 text-black" href="mailto:maya@hrtech.sg">maya@hrtech.sg</a>
                                         </p>
                                        </div>
                                        <div className='sin-add  mb-2'>
                                            <div className='add-icon'><MdLocationPin className='text-red add-iconsize' /></div>
                                             <p className='adress text-black'>
                                                <span className="ml-0 text-black">Singapore HQ</span> <br></br>
                                                {/* <span className="ml-0 text-black">IKIGAI ENABLERS PTE. LTD.</span> <br></br> */}
                                                #05-95, 18 Boon Lay Way, <br></br>
                                        Singapore - 609966.<br></br>
                                        
                                       
                                            </p>
                                        </div>
                                        <div className='sin-add mb-md-4 '>
                                            <div className='add-icon'><MdLocationPin className='text-red add-iconsize' /></div>
                                             <p className='adress text-black'>
                                                <span className="ml-0 text-black">Dubai Office</span> <br></br>
                                                {/* <span className="ml-0 text-black">IKIGAI ENABLERS PTE. LTD.</span> <br></br> */}
                                                
                                     
                                         DSO-THUB-G-D-FLEX-G116B, <br></br>THUB, Dubai Silicon Oasis (DSO),<br></br> Dubai .
                                            </p>
                                        </div>
                                        <div className='sin-add pb-3 pt-3'>
                                            <p className='text-black f-18 fw-600 mb-3 pl-1'>FOLLOW US</p>
                                            <a className="white navbar-brand py-0 contact_icons lin_icon mr-2 text-black" href="https://www.linkedin.com/company/hrtech-singapore/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in fa-lg" aria-hidden="true" />
                                                <FaLinkedinIn />
                                            </a>
                                            <a className="white navbar-brand py-0 contact_icons lin_icon mr-2 text-black" href="https://www.youtube.com/channel/UCxWn6IQ5MYYGwtb72xBcQ4w" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in fa-lg" aria-hidden="true" />
                                                <FaYoutube />
                                            </a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <Testimonial />
            <Team />
            <Footer />
        </div>



    )
}

export default Contactus;
