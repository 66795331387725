import React, { Component, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Navbar from '../../components/global-components/navbar';
import Testimonial from '../../components/section-components/testimonial';
import Team from '../../components/section-components/team';
import Footer from '../../components/global-components/footer';
import TechtalkEditor from './techtalkEditor';
import { FaChevronLeft, FaChevronRight, FaLinkedinIn,FaWhatsapp,FaFacebookF,} from "react-icons/fa";
import { MdMail,MdFacebook } from 'react-icons/md';
import '../../components/news.css';
import { API_URL } from '../Api';
import axios from 'axios';
import {Helmet} from "react-helmet";
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    LinkedinIcon
  } from "react-share";


const api = `${API_URL}/Blog/particular/website`;
const urlimg = `${API_URL}/Blog_image`;


const BlogDetails = () => {

	const params = useParams();

	const [Blogdata, setBlogdata] = useState([]);
	const [Result, SetResult] = useState([]);
	const [ResultEditor, SetResultEditor] = useState([]);
	const [nextResult, setNextResult] = useState([]);
    const [prevResult, setPrevResult] = useState([]);
	const [Blogmetadata, setBlogmetadata] = useState([])
	const [Blogmetaname, setBlogmetname] = useState([])
	const [Footerload, SetFooterload] = useState(false)
	const [Static, SetStatic] = useState(false)

	let date = new Date(Result.BlogDate).toDateString().slice(4)



	useEffect(() => {
		setTimeout(() => {
            SetFooterload(true)
          }, 1200)
		setTimeout(() => {
            SetStatic(true)
          }, 200)
		dataget();
		
	}, [params.id,params.product]);

	const dataget = async ()=>{
		console.log("params.id",params.id)
		const { data } = await axios.post(api,{Blogurlname:params.id})
		console.log("ff",data)
		console.log("ff 1111",data.next[0])
		setBlogdata(data.data[0]);
		let Heading = params.id?.replace(/-/g, ' ')
		// let filter = data.data[0].filter((headline) => headline.Blogurlname.toLowerCase().includes(Heading.toLowerCase()));
		
		SetResult(data.data[0]);
		SetResultEditor(data.filterEditor);

		//  const next = data.data.filter(dd => dd.PublishDate > filter[0].PublishDate)
        setNextResult(data.next)
        // setNextResult(next)
        // const prev = data.data.filter(dd => dd.PublishDate < filter[0].PublishDate)
        // setPrevResult(prev[prev.length - 1])
        setPrevResult(data.prev)
		// GetBlogdata()
	}

	const GetBlogdata = async () =>{
		let Heading = params.id?.replace(/-/g, ' ')
		const blog = await axios.get(api)
		console.log(blog,'blog')
		const filter = blog.data.find((person) => person.Blogurlname.toLowerCase().includes(Heading.toLowerCase()))
		console.log(filter,"filter")
		const Result = await axios.get(`${api}/${filter._id}`)
		setBlogmetadata(Result.data)
		setBlogmetname(Result.data._id)
		console.log(Result.data,'meta')
	}

	const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
	return (
<>


		<div>
	 
			<div className='row'>
				<Navbar />
			</div>
			<br></br>
			
				{Static ? 
			<section className='blog-page-content mt-5'>
<section>
					<nav>
		<ol className="cd-breadcrumb custom-separator" style={{backgroundColor:"#ffffff"}}>
			<li><a href="/">Home</a></li>
			<li><a href="/blog/">Blogs</a></li>
			 
			<li className="current"><em>{Result.BlogHeadline}</em></li>
		</ol>
	</nav></section>
	{Result.BlogHeadline ? <>
				<div className='container-fluid px-md-2 mb-5' >
					<div className='row px-0 flexbox-wrapper'>
						{

							<div className='col-sm-8 col-md-8 co-lg-8'>
								<div className='blog-detail pr-md-3'>
									<p className='text-grey '>{date}</p>
									<h1 className='pb-2 text-black fw-600 resource_detail_page_title'>{Result.BlogHeadline}</h1>
									<p className='f-blog3-16 short_desc text-grey'>
										{Result.BlogShortDescription}
									</p>
									<div className='media align-items-center py-3 px-md-1'>
										<div className='author_image author_border border' style={{ backgroundImage: `url(${urlimg}/${Result.AuthorProfileImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
										</div>
										<div className='media-body f-14 ml-2'>
											<span className="text-grey">by</span>&nbsp;
											<span className="text-black fw-400">{Result.AuthorName},</span>&nbsp;&nbsp;
											<span class="text-grey">{Result.AuthorDesignation},</span>
											&nbsp;
											<a href={Result.AnthorCompanyLink} className='fw-600'>{Result.AuthorCompanyName}</a>
											{console.log(Result.AnthorCompanyLink,"123456789")}
										</div>
									</div>
									<div className='style_summernote pt-3'>
									{renderHTML(Result.BlogStory)}
									
									</div>
									<div className='w-100 py-4 px-3 bg-lightgrey mt-md-m4 mt-3'>
										<div className='media py-0 px-0'>
											<div className='footer_author_image author_border border' style={{ backgroundImage: `url(${urlimg}/${Result.AuthorProfileImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
												{/* <img style={{ backgroundColor: 'black' }} src='' /> */}
											</div>
											<div className='media-body f-12 ml-2 ml-md-3'>
												<div className='owner_description style_summernote'>
													<p>
														{renderHTML(Result.AuthorDescription)}
													</p>
												</div>
											</div>
										</div>
									</div>
									 <br></br>
									<div class="row mx-0">
										<div class="col-md-12 px-0 px-md-1 text-md-right d-flex">
											<p class="text-grey py-3 mr-0 ">Share: </p>
											&nbsp; &nbsp;
                                            <div>
                          <WhatsappShareButton url={window.location.href} title={Result.BlogHeadline} className="share-btn m-1">
                            <WhatsappIcon size={30} round={true} />
                          </WhatsappShareButton>
                          <FacebookShareButton url={window.location.href} title={Result.BlogHeadline} className="share-btn m-1">
                            <FacebookIcon size={30} round={true} />
                          </FacebookShareButton>
                          <LinkedinShareButton url={window.location.href} title={Result.BlogHeadline} className="share-btn m-1">
                            <LinkedinIcon size={30} round={true} />
                          </LinkedinShareButton>
                          <EmailShareButton url={window.location.href} title={Result.BlogHeadline} className="share-btn m-1">
                            <EmailIcon size={30} round={true} />
                          </EmailShareButton>

                        </div>
										</div>
									</div>
									<div className='row'>
										<div className='col-md-12 col-lg-12 col-12 px-0 border_top_table'>
											<div className='row m-0 py-4 resource_detail_footer_nav d-md-flex w-100 px-2 px-md-0 border_top_table mb-4 mb-md-0'>
											{
                                                prevResult.length > 0 ? <div className='col-5 p-0 d-flex align-items-center'>
                                                    <Link  to={{
                                                        pathname:`/blog/${prevResult[prevResult.length - 1].Blogurlname.replace(/ /g, '-').toLowerCase()}`,
                                                        state:`${prevResult[prevResult.length - 1]._id}`
                                                    }}
                                                     className="d-flex justify-content-start align-items-center w-100">
                                                        <div className="media align-items-center">
                                                            <div>
                                                                <FaChevronLeft className='text-grey f-18' />
                                                            </div>
                                                            <div className="media-body ml-4">                                                                <p className="text-grey f-14 f-mb-12">Previous Post</p>
                                                                <p className="text-black f-16 f-mb-14">{prevResult[prevResult.length - 1]?.BlogHeadline}</p>
                                                            </div>
                                                        </div>
                                                    </Link>

                                                </div> :<div class="col-5"></div>
                                            }
												<div className='col-2 d-flex m-auto justify-content-center'>
													<a href={`/blog/`} >
														<img src='/assets/img/Event/Group1.svg' alt=''></img>
													</a>
												</div>
												{
                                                nextResult.length > 0 ? <div className='col-5 p-0 float-right text-right d-flex align-items-center justify-content-end'>
                                                    <a  href={`/blog/${nextResult[0].Blogurlname.replace(/ /g, '-').toLowerCase()}`}  className="d-block">
                                                        <div className="media align-items-center">
                                                            <div className>
                                                                <p className="text-grey f-14 f-mb-12 text-right">Next Post</p>
                                                                <p className="text-black f-16 f-mb-14 text-right">{nextResult[0].BlogHeadline}</p>
                                                            </div>
                                                            <div className="media-body ml-4">
                                                                <FaChevronRight className='text-grey f-18' />
                                                            </div>
                                                        </div>
                                                    </a>

                                                </div> :<div class="col-5"></div>
                                            }
											</div>
										</div>
									</div>
								</div>
							</div>
						}
						<div className='col-sm-4 col-md-4 col-lg-4 side_bar ec-sidebarWrap'>
							<TechtalkEditor Blogdata={ResultEditor} setBlogdata={setBlogdata} />
						</div>
					</div>
				</div>
				</> 
		:null}

			</section>:null}
			

			{Footerload ? <>
			<Testimonial />
			<Team />
			<Footer />
			</>:null}
		</div>
		
		</>
	)
}


export default BlogDetails;
