import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin,FaYoutube } from "react-icons/fa";
import ScrollButton from '../section-components/ScrollButton'

class Footer_v1 extends Component {

    componentDidMount() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/main.js";

        document.body.appendChild(minscript);
    }

    render() {

        // let publicUrl = process.env.PUBLIC_URL+'/'

        return (
			<footer className="footer-area bg-000 pt-0 pb-4 pb-md-0">
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-12 col-sm-12 col-xs-12 text-center d-block d-md-none margin-t2">
        <div className=" pb-3 pt-2 by_lightgrey">
          <p className="text-white f-18 fw-600 " style={{color: '#fff'}}>Follow hrtech.sg</p>
          <a className="white navbar-brand py-0 contact_icons lin_icon" href="https://www.linkedin.com/company/14469382" target="_blank"><FaLinkedin /></a>
          <a className="white navbar-brand py-0 contact_icons yt_icon" href=" https://www.youtube.com/channel/UCxWn6IQ5MYYGwtb72xBcQ4w" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
        </div>
      </div>
      <ScrollButton />
      
      <div className="col-md-6 col-sm-12 col-xs-12 text-center align-items-center justify-content-between d-md-flex p-0 p-md-3 footer-border-top-md">
         
        <div>
          <p className="f-16 txt_lightwhite pb-2 pt-2 d-md-block d-none position-rela">© IKIGAI ENABLERS PTE. LTD. All Rights Reserved 2023</p>
        </div>
        
        <p className="f-14 txt_lightwhite pb-2 pt-2 d-md-none d-block">
          © IKIGAI ENABLERS PTE. LTD.<br />
          All Rights Reserved 2023
        </p>
      </div>
      <div className='col-md-3'></div>
      <div className="col-md-3 col-sm-3 col-xs-12 text-center align-items-center justify-content-between d-md-flex p-0 p-md-3 footer-border-top-md">
      
        <div><Link to="/privacy" className="txt_lightwhite footer-st">Privacy Policy</Link></div>
      
      </div>
    </div>
  </div>
</footer>


        )
    }
}


export default Footer_v1