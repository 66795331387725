import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import axios from "axios";
import { API_URL } from './Api'
import Model from './Model';
import DialogBox from './global-components/enquiry-modal';

const API_Market = `${API_URL}/MarketPlace`;
const API_View = `${API_URL}/MarketPlace_image`;

export default function SimpleSlider(props) {

  const [data, setData] = useState([])
  const [Product, SetProduct] = useState("")
  const [dialog, setDialog] = useState(false)

  var settings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    speed: 500,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 320,
        settings: { slidesToShow: 1, slidesToScroll: 1, infinite: true, autoplay: true, arrows: true, }
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 2, slidesToScroll: 2, infinite: true }
      },

    ]


  };

  useEffect(() => {
    if (props.Category.Category) {
      GetData(props.Category.Category)
    }
  }, [props.Category])

  const GetData = async (CategoryId) => {
    setData([])
    const arr=[]
    CategoryId.map(async(item) => {
      const Res = await axios.get(`${API_Market}/Category/${item.value}`)
      arr.push(...Res.data)
      const ids = arr.map(o => o._id)
      const filtered = arr.filter(({_id}, index) => !ids.includes(_id, index + 1))
      setData(filtered.filter(ii => ii._id !== props.Category._id))
      // setData(old=>[...old,...Res.data])
    })
    // const Result = await axios.get(`${API_Market}/Category/${CategoryId}`)
    // setData(Result.data.filter(ii => ii._id !== props.Category._id))
  }

  

  const Getcompanyname = data => {
    SetProduct(data)
    setDialog(true)
  }

  const dialogClose = () => {
    setDialog(false)
  }


  return (
    <>
     {data.length > 0 ?
     <section className='py-5 bg-white rel-prod' >
    <div className='container-fluid w-90'>
      <div className="row mx-auto">
        <div className="col-md-12">
          <div className="text-center mb-4">
            <h2 className="wow flipInY f-28 fw-600">Related Products</h2>
          </div>
        </div>
      </div>
      <div className='row m-md-auto pr-mb-0'>
        <div className='col-md-12  mb-5 m-auto scroll_div px-md-0 scroll_event' style={{ marginBottom: '20px' }}>
          <div className="container-fluid">
            <DialogBox show={dialog} title={Product} dialogClose={dialogClose} />
               <Slider {...settings} style={{ overflow: 'hidden', position: 'relative' }}>
                {data.map((item, i) => {
                  return (
                    <div className=' slide-in-bottom feature_card p-2 mb-1' key={i}>
                      <div className='content mp_card p-1' >
                        <a href={ `/${item.MarketURL}/`}>
                          <div className='content-overlay'></div>
                          <div className='new_card content-image bg-transparent border-lightgrey'>
                            <div className='h-50 d-flex' style={{ justifyContent: 'center', position: 'relative' }}>
                              <div className="card-img-top" style={{ width: '100%', height: 'auto', backgroundImage: ` url(${API_View}/${item.ProductLogo})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center' }}></div>
                            </div>
                            <div className='h-50 d-flex align-items-center bg_grey justify-content-center' style={{ padding: '0px 10px 0px 10px' }}>
                              <div className='d-block'>
                                <p className='f-12 w-100 text-grey fixed_content_3' style={{ paddingBottom: 0, }}>
                                  {item.ProductSummary}
                                </p>
                             
                              </div>
                            </div>
                          </div>
                        </a>
                        <div className='content-details'>
                          <a href={ `/${item.MarketURL}/`} >
                            <div className='h-50 d-block text-center pt-5 px-3'>
                              <h3 className='text-white content-title f-20 fw-600 mt-0 mb-2 word_wrap'>{item.ProductName}</h3>
                              <p className='content-text f-12 fixed_content pr-0 word_wrap'>
                                {item.ProductTagLine}
                              </p>
                            </div>
                          </a>
                          <div className='row m-0 h-50 pt-md-4 pt-2 d-flex' style={{ justifyContent: 'center', paddingTop: '3em!important', height: 'auto!important', alignItems: 'center' }}>
                            <div className='col-md-6 col-6 mb-0' style={{ backgroudColor: '#fff' }}>
                              {/* <a href={ `/${item.MarketURL}/`}>test</a> */}
                              <a href={`/${item.ProductName.replace(/ /g, '-').toLowerCase()}`}>
                                <button className='card_view btn btn-block f-16 border-radius-none enquiry_mobile waves-effect waves-light' >
                                  View
                                </button>
                              </a>
                            </div>
                            <div className='col-md-6 col-6 mb-0' style={{ backgroudColor: '#fff' }}>

                              <button type='button' className='card_view btn btn-block f-16 border-radius-none enquiry_mobile waves-effect waves-light'
                                // data-toggle="modal"
                                // data-target="#exampleModalLong"
                                onClick={() => {
                                  Getcompanyname(item.ProductName)
                                  SetProduct(item.ProductName)
                                }}>
                                Enquire
                                <p className="d-none enq_slug">{item.ProductName}</p>
                                <p className="d-none name_slug">{item.ProductName}</p>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Slider> 
              
            <Model Product={Product} />
          </div>
        </div>
      </div>
    </div>
    </section>
    : <></>}</>
  );
}

