import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Model from './Model';
import axios from "axios";
import DialogBox from './global-components/enquiry-modal';

import { API_URL } from './Api'


const URL = `${API_URL}/Blog`;
const urlimg = `${API_URL}/Blog_image`;

export default function BlogHome(props) {

  // initializing state to store data
  const [Product, SetProduct] = useState("")
  const [Productlink, SetProductlink] = useState("")
  const [Blogdata, setBlogdata] = useState([]);
  const [dialog, setDialog] = useState(false)

  

  //Function call on page mount
  // useEffect(() => {
  //   GetData()
  // }, [])

  
//   const GetData = async () => {
    
//     const { data } = await axios.get(URL)
//     let sort = data.sort((a, b) => {
//         return (
//             new Date(a.BlogDate) -
//             new Date(b.BlogDate)
//         )
//     }).reverse()
//     setBlogdata(sort.slice(0, 6))
     
// }
 
 
  return (
    <div className="container-flex m-auto">
       
     
       
        
            <div className="row mx-0">
            {props.Blogdatas?.map((item, i) => {
									let date = new Date(item.BlogDate).toDateString().slice(4)
									return (
										<div className='col-lg-4 col-md-4 mt-2 px-md-3 px-0 pb-0 tech_talk_view tech_talkList-count'>
											<a href={`/blog/${item.Blogurlname.replace(/ /g, '-').toLowerCase()}/`}>
 

												<div className='card resource_card'>
													<div className='card-img-top w-100 m-0 ' style={{ backgroundImage: `url(${urlimg}/${item.BlogImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center' }}>
													</div>
													<div className='card-body bg-lightgrey pb-0 p-3' style={{ overFlow: 'hodden' }}>
														 
														<p className='card-title text-black text-center f-14 fw-600 fixed_content mb-2'>
															{item.BlogHeadline}
														</p>
														 
													</div>
													 
												</div>
											</a>
										</div>
									)
								})
								}
</div>
<br></br>
<div className="row mx-0">
<div className="col-md-12 mb-md-5 mx-auto d-flex text-center justify-content-center">
          <a href={"./blog/"} className="btn btn-red-primary fw-500 f-20 f-mb-16 border-radius-none px-5 py-3 waves-effect waves-light">View More</a>
        </div>
</div>
         
    
      {/* <Model Product={Product} /> */}
    </div>
  );
}
