import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from '../Api'
const Api_Subscribe = `${API_URL}/Subscribe`;

export default function Testimonial () {

  

  const [Subscribe, setSubscribe] = useState({
    Name: '',
    Email: '',
  });

  const onchangeSubcribe = ({ target: { name, value } }) => {
    setSubscribe({ ...Subscribe, [name]: value });

  }

  const SubscribeSubmit = (event) => {
    event.preventDefault();

    let data =
    {
      Name: Subscribe.Name,
      Email: Subscribe.Email,
      

    }


    axios.post(`${Api_Subscribe}`, data).then(res => {

      setSubscribe({
        ...Subscribe,
        Name: "",
        Email: "",


      })
      
      window.location.href="/enquire/success";
    })
  }

    return ( <section className="subscribe_div py-1">
  <div className="container-fluid px-0">
    <div className="row d-md-flex align-items-center subscri-sty">
      <div className="col-sm-6 col-md-6 mb-md-0 mb-3">
        <h2 className="text-white f-28 f-mb-20 fw-bold sub">
          Subscribe to the preferred newsletter of HR Technology
          enthusiasts.
        </h2>
      </div>
      <div className="col-sm-6 col-md-6 text-center">
         
          <button className="btn btn-tertairy fw-500 f-20 f-mb-16 border-radius-none px-5 py-3 waves-effect waves-light" data-toggle="modal" data-target="#exampleModalSubscribe" id="mc-embedded-subscribe">
            Subscribe Now
          </button>
        
      </div>
    </div>
  </div>
  <div className="modal fade" id="exampleModalSubscribe" tabIndex={-1} role="dialog" aria-labelledby="example1ModalLabel" aria-hidden="true" data-toggle="modal" data-target="#exampleModal">
        <div className="modal-dialog" role="document">
          <div className="modal-content bg-lightgrey">
            <div className="modal-header">

              <div className="col-md-12">
                <h3 className="no_margin fw-600 text-center">Subscribe</h3>


              </div>
              <div className='row'>
                <div className="col-md-12">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" style={{ float: 'right', position: 'absolute', right: '50%', top: '-5px' }}>×</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-body ">


              <form className="form-field" name="sample" onSubmit={SubscribeSubmit}>
                <input type="text" name="Name" required placeholder="Your Name" className="form-control" id="id_name" onChange={onchangeSubcribe} value={Subscribe.Name} />
                <p><span className="has_error" style={{ color: 'red', paddingLeft: '-58px', fontSize: 14 }} /></p>



                <input type="email" name="Email" required placeholder="Your Email Id" className="form-control" id="id_email" onChange={onchangeSubcribe} value={Subscribe.Email} />
                <p><span className="has_error" style={{ color: 'red', paddingLeft: '-58px', fontSize: 14 }} /></p>

                <div className="row">
                  <div className="col-12">
                    <button type="submit" className="btn btn-red-primary border-radius-none btn-head px-5 m-0 mt-3 waves-effect waves-light" id="send_btn">Submit</button>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>
</section>
    )

        
}

 