import React, { useState, useEffect, } from 'react';
import { Link, useHistory, useParams, useLocation, NavLink } from 'react-router-dom';
import Navbar from './global-components/navbar';
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';
import Footer from './global-components/footer';
import { API_URL } from './Api';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import './news.css'



const api = `${API_URL}/Resources`
const apiimage = `${API_URL}/Resources_image`
const apicato = `${API_URL}/Categories/`

function Resources() {


    const location = useLocation();

    const [data, setdata] = useState([]);
    const [report, setreport] = useState([]);
    const [getdata, setgetdata] = useState(false);
    const [Category, setCategory] = useState([]);
    const [subcato, setsubcato] = useState(false);
    const [Footerload, SetFooterload] = useState(false)

    const $ = window.$;
    const history = useHistory()
    const params = useParams()


    useEffect(() => {
        setTimeout(() => {
            SetFooterload(true)
        }, 500)
        GetAll()
        Categorydata()
        subCat()

    }, []);


var ResourURL = document.location.pathname.split("/");

    const GetAll = () => {
        axios.get(`${api}`).then((res) => {
            console.log('Inside get all resources')
            let sort = res.data.sort((a, b) => {
                return (
                    new Date(a.ResourceDate) -
                    new Date(b.ResourceDate)
                )
            }).reverse()
            setdata(sort)

            if (document.location.pathname === "/resources/") {

                setreport(sort)
            }
            else if (document.location.pathname === "/resources/reports/") {

                setsubcato(false)
                const respo = sort?.filter((items) => items.Category === "REPORT");

                setreport(respo)
            }
            else if (document.location.pathname === "/resources/ebooks/") {

                setsubcato(false)
                const respo = sort?.filter((items) => items.Category === "EBOOKS");

                setreport(respo)
            }
            else if (document.location.pathname === "/resources/infographics/") {

                setsubcato(false)
                const respo = sort?.filter((items) => items.Category === "INFOGRAPHICS");

                setreport(respo)
            }
            else if (document.location.pathname === "/resources/case_studies/") {

                setsubcato(true)
                const respo = sort?.filter((items) => items.Category === "CASE STUDIES");

                setreport(respo)
            }

            setgetdata(true)
        })
    }

    const Categorydata = () => {
        axios.get(apicato).then((res) => {
            setCategory(res.data)
        })

    }

    const getcategory = (Category) => {

        setsubcato(false)
        const respo = data?.filter((items) => items.Category === Category);
        setreport(respo)
        if (Category === "All") {
            setreport(data)
        }

        if (Category === "CASE STUDIES") {
            setsubcato(true)
        }
        toggle(Category)
    }

    const toggle = (Category) => {
        let tag = document.getElementById("toggle-cls")
        tag.classList.remove("active")
        if (Category === 'All') {
            tag.classList.add('active')
        }
    }
    const handleCateChange = (e) => {
        const cate = e.target.value
        const respo = data?.filter((items) => items?.subCategory === cate);
        setreport(respo)

        history.replace(document.location.pathname);


        if (cate === 'All') {
            const resu = data?.filter((items) => items?.Category === "CASE STUDIES");
            setreport(resu)
        }
    }

    const subCat = () => {
        if (document.location.pathname === "/resources/case_studies/") {
            setsubcato(true)
        }


        if (document.location.pathname !== '/resources/') {
            let tag = document.getElementById("toggle-cls")
            tag.classList.remove("active")
        }
    }


    // render() {


    return (<div>


        <Navbar />

        <section className='py-md-5 text-center m-0 border-bottom' style={{paddingBottom:"0rem !important"}}>
            <div className="container-fluid market title_bg resource-width" style={{ marginTop: " 1%" }}>
                <h1 className="text-center f-28 fw-600" style={  {textTransform:'capitalize'}}>{ResourURL[2] =="" ? ResourURL[1] : ResourURL[2].replace(/_/g, ' ')}</h1>
            </div>

        </section>
        <section>
 	<nav>
		<ol className="cd-breadcrumb custom-separator" style={{marginTop:"-49px"}}>
			<li><a href="/">Home</a></li>
            {ResourURL[2] =="" ? <>
			<li className="current" style={ {textTransform:'capitalize'}}><em>{ResourURL[1]}</em></li>
           
            </> :<>
            <li ><a href="/resources/">Resources</a></li>

            </>}
			
            {ResourURL[2] =="" ? null:
			<li className="current" style={ {textTransform:'capitalize'}}><em>{ResourURL[2].replace(/_/g, ' ')}</em></li>
 }
		</ol>
	</nav>
</section>
        <section>
            <div className='bg-white'>
                <div className='container-fluid pt-5 pb-md-3 px-0'>
                    <div >

                       {/* TABS for filter data  */}
                       <ul className="nav nav-pills justify-content-center mb-3 text-center text-grey toggle-tabs"  >
                            <li className="nav-item">
                                <a href='/resources/' className={document.location.pathname === "/resources/" ? "nav-link btn-resources-active rounded-pill list-active" : "nav-link btn-resources rounded-pill list-active"} id='toggle-cls' onClick={() => getcategory("All")}>All</a>
                            </li>
                            <li className="nav-item">
                                <a href={'/resources/reports/'} className={document.location.pathname === "/resources/reports/" ? "nav-link btn-resources-active rounded-pill list-active" : "nav-link btn-resources rounded-pill list-active"}
                                onClick={() => getcategory("REPORT")}  >Reports</a>
                            </li>
                            <li className="nav-item">
                                <a href={'/resources/ebooks/'} className={document.location.pathname === "/resources/ebooks/" ? "nav-link btn-resources-active rounded-pill list-active" : "nav-link btn-resources rounded-pill list-active"} onClick={() => getcategory("EBOOKS")}  >Ebooks</a>
                            </li>
                            <li className="nav-item">
                                <a href={'/resources/infographics/'} className={document.location.pathname === "/resources/infographics/" ? "nav-link btn-resources-active rounded-pill list-active" : "nav-link btn-resources rounded-pill list-active"}  onClick={() => getcategory("INFOGRAPHICS")}  >Infographics</a >
                            </li>
                            <li className="nav-item">
                                <a href={'/resources/case_studies/'} className={document.location.pathname === "/resources/case_studies/" ? "nav-link btn-resources-active rounded-pill list-active" : "nav-link btn-resources rounded-pill list-active"} onClick={() => getcategory("CASE STUDIES")} >Case Studies</a>
                            </li>

                        </ul>

                        {/* selete bar after maxwitdh of 990px   */}
                        <div className="px-3 d-block d-lg-none px-3 pb-3 ">
                            <div className="form-group position-relative">
                                <i className="fa fa-chevron-down" style={{ fontSize: 12, position: 'absolute', right: 11, top: 13, zIndex: 1 }} />
                                <select className="form-control select-bars" id="mySelect"
                                    onChange={(e) => getcategory(e.target.value)} defaultChecked
                                // onchange={}
                                >
                                    <option value="All" selected>All</option>
                                    <option value="REPORT" > Reports</option>
                                    <option value="EBOOKS" >eBooks</option>
                                    <option value="INFOGRAPHICS">Infographics</option>
                                    <option value="KNOW YOUR HRTECH SOLUTION">Know Your HRTech Solution</option>
                                    <option value="CASE STUDIES">Case Studies</option>
                                </select>
                            </div>
                        </div>
                        <div className='selete-w'>
                            <div className="px-3 d-block  px-3 pb-3 ">
                                <div className="form-group position-relative">
                                    <i className="fa fa-chevron-down" style={{ fontSize: 12, position: 'absolute', right: 11, top: 13, zIndex: 1 }} />
                                    {subcato ?
                                        <select id="selectMe" className=' form-control select-bars ' onChange={handleCateChange}>
                                            <option value='All' >All</option>
                                            {
                                                Category?.map((subcta, i) => {
                                                    return (
                                                        <>
                                                            <option key={i} value={subcta._id}>{subcta.CategoryName}</option>

                                                        </>
                                                    )
                                                })

                                            }
                                        </select> : <></>

                                    }
                                </div>
                            </div>
                        </div>
                        {report?.length > 0 ?

                            <div className=" active" id="" >

                                <div className='row mx-auto home_blog'>
                                    {report?.map((item, i) => {
                                        return (
                                            <div key={i} className='col-md-3 mb-4'>
                                                <a href={`/resources-details/${item.Resourceurlname.replace(/ /g, '-').toLowerCase()
                                                    }/`} >
                                                    <div className='card resource_card_height border border-radius-none'>
                                                        <div className='w-100 border_lightgrey' style={{
                                                            backgroundImage: `url(${apiimage}/${item.ResourceImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', backgroundColor: '#000', height: '200px'
                                                        }}>  </div>
                                                        <div className='card-body bg-lightgrey p-3'>
                                                            <p className='card-text f-16 fw-600 text-dark'>
                                                                {item.ResourceHeadline}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        )

                                    })}


                                </div>
                            </div> :
                            <p></p>
                        }


                    </div>

                </div>
            </div>
        </section>

        {Footerload ? <>
            <Testimonial />
            <Team />
            <Footer /></> : null}
    </div>
    )

    // }
}

export default Resources
