import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Model from './Model';
import axios from "axios";
import DialogBox from './global-components/enquiry-modal';

import { API_URL } from './Api'


const api = `${API_URL}/Resources/`
const apiimage = `${API_URL}/Resources_image`

export default function ResourceHome(props) {

  // initializing state to store data
  const [Product, SetProduct] = useState("")
  const [Productlink, SetProductlink] = useState("")
  const [data, setData] = useState([])
  const [dialog, setDialog] = useState(false)

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 769 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet1: {
      breakpoint: { max: 768, min: 520 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 520, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  //Function call on page mount
  // useEffect(() => {
  //   GetAll()
  // }, [])

  //Get Category from Backend
//   const GetAll = () => {
//     axios.get(`${api}`).then((res) => {
//         let sort = res.data.sort((a, b) => {
//             return (
//                 new Date(a.ResourceDate) -
//                 new Date(b.ResourceDate)
//             )
//         }).reverse()
//         let Editss = sort.slice(0,6);
//         setData(Editss)
        
//     })
// }

  

  return (
    <div className="container-flex m-auto">
       
     
       
        
            <div className="row mx-0">
            {props.Resourcesdatas?.map((item, i) => {
                                        return (
                                            <div key={i} className='col-md-4 mb-4'>
                                                <Link to={`/resources-details/${item.Resourceurlname.replace(/ /g, '-').toLowerCase()
                                                    }/`} >
                                                    <div className='card resource_card_height-home border border-radius-none'>
                                                        <div className='w-100 border_lightgrey' style={{
                                                            backgroundImage: `url(${apiimage}/${item.ResourceImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', backgroundColor: '#000', height: '200px'
                                                        }}>  </div>
                                                        <div className='card-body bg-lightgrey p-3'>
                                                            <p className='card-text f-16 fw-600 text-dark'>
                                                                {item.ResourceHeadline}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )

                                    })}
</div>
<div className="row mx-0">
<div className="col-md-12 mb-md-5 mx-auto d-flex text-center justify-content-center">
          <Link to={"./resources/"} className="btn btn-red-primary fw-500 f-20 f-mb-16 border-radius-none px-5 py-3 waves-effect waves-light">View More</Link>
        </div>
</div>
         
    
      {/* <Model Product={Product} /> */}
    </div>
  );
}
