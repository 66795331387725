import React from "react";
import {FaAngleRight} from "react-icons/fa";
import "./index.css";
import moment from 'moment';
import { API_URL } from '../../Api';
const urlimg = `${API_URL}/Events_image`;

export default function Event_Home_pages(props) {
  return (
    <div className="main-content-event-sec">
      <div className="">
        <div className="container">
          <div className="row contents ">
            <div className="col-md-12">
              <h1 className="dark-color">Events</h1>
            </div>
          </div>
          <div className="row mt-5 card-sec">
            {props.Eventdatas.map((item,i)=>{
              return(
                  <div className="col-md-4 mb-4" key={i}>
                    <a href={`/events/${item.Eventurlname.replace(/ /g, '-').toLowerCase()}/`}>
              <div className=" ">
                <div className="img-sec mb-3">
                  <div className="img-1">
                    <img
                      src={`${urlimg}/${item.PreEventImages}`}
                      className="img-fluid"
                    ></img>
                    <div className="card-date">
                      <h4>{moment(item.EventDate).format("DD")}</h4>
                      <span>{moment( item.EventDate).format("MMM ")}</span>
                    </div>
                  </div>
                </div>
                <h4 className="mb-2">{item.EventName}</h4>
                
                <a href={`/events/${item.Eventurlname.replace(/ /g, '-').toLowerCase()}/`} className="d-flex align-items-center">View Details <span className="d-flex"><FaAngleRight /></span></a>
              </div></a>
            </div>
              )
            })}
          
          </div>
          <div className="row mt-3 ">
            <div className="col-md-12 text-right">
              <div className="btn-sec">
                <a href="/events/" className="btn-1">
                  VIEW MORE  
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
