import React, { useEffect, useState } from 'react';
import Navbar from "./global-components/navbar";
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';
import Footer from './global-components/footer';
import { Link,useHistory } from 'react-router-dom';
import "./news.css";
import { API_URL } from './Api';
import axios from 'axios';



const api = `${API_URL}/News/alldata/website`
const apiimage = `${API_URL}/News_image`

function News() {

  const history = useHistory()

  const [data, setdata] = useState([]);
  const [date, setdate] = useState('');
  console.log(date);

  useEffect(() => {

   Getall()
  

  },[]);

   const Getall = async () => {
    await axios.get(api).then((res) => {
      if(res.data.length >0){
      setdata(res.data);

    }
    });
  };

  // if (document.location.pathname[document.location.pathname.length - 1] !== '/') {
  //   history.replace(document.location.pathname + '/');
  // }
  
  return (
    <div >
      <div  >
      <section className="scroll-n" >
        <Navbar />
        <div className="py-5 md-5 py-4 title_bg jumbotron jumbotron-fluid text-center m-0 border-bottom">
          <div className="container">
            <h1 className="text-center noteheading">News</h1>
          </div>
        </div>
        <section>
 	<nav>
		<ol className="cd-breadcrumb custom-separator">
			<li><a href="/">Home</a></li>
			 
			<li className="current"><em>News</em></li>
		</ol>
	</nav>
</section>
        <div className="container-fluid card-adjustment ">
          <div className="row">
            
        {
          data?.map((items,i)=>{

            let datesp = new Date(items.NewsDate).toDateString().slice(4);
            
            return(
              <div className="col-lg-3 col-md-4 col-sm-6 mb-4 news_bg ">
              <div className="card cardadj border shadow h-100 rounded">
                <a href={`/news/${items.Newsurlname.replace(/ /g, '-').toLowerCase()}/`} className="">
                  <div className="m_view">
                    <img  
                      src={`${apiimage}/${items.NewsImage}`}
                      className="card-img-top cardimg ml-3 "
                      alt
                    /></div>
                  <div className="card-body bg-lightgrey">
                    <p className="text-grey f-12">{datesp} </p>
                    <p className="card-title text-black f-16 fw-600  mb-2 line_clamp_2">
                    {items.NewsHeadline}
                    </p>
                  </div>
                  {/* <div className="card-footer bg-lightgrey border-0 pt-0 p-3 ">
                    <div className="media align-items-center">
                      <div className="media-body f-12 ml-2 ">
                        <span className="text-grey">by </span> &nbsp; &nbsp;
                        <span className="text-black">{items.ResourceAuthorName} </span>
                      </div>
                    </div>
                  </div> */}
                </a>
              </div>
            </div>
            )
          })
        }


            
           


            </div>
        </div>
      </section>
     
        <Testimonial />
        <Team />
        <Footer />
        </div>
     
      
</div>
   
  );
};
export default News;
