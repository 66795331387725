import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import TechtalkEditor from './techtalkEditor';
import '../../components/news.css';
import axios from 'axios';
import { API_URL } from '../Api';
import {Link} from "react-router-dom"
import {Helmet} from "react-helmet";

const URL = `${API_URL}/Blog/getall/website`;
const urlimg = `${API_URL}/Blog_image`;

const Blog = () => {

	const history = useHistory()

	const [Blogdata, setBlogdata] = useState([]);
	const [readMore, setReadMore] = useState(false);
	

	React.useEffect(() => {
		GetData()
		if (document.location.pathname[document.location.pathname.length - 1] !== '/') {
			history.replace(document.location.pathname + '/');
		  }
		 
	}, [])


	const GetData = async () => {
		// const res = await axios.get(URL)
		// setBlogdata(res.data)
		const { data } = await axios.get(URL)
		let sort = data.sort((a, b) => {
			return (
				new Date(a.BlogDate) -
				new Date(b.BlogDate)
			)
		}).reverse()
		setBlogdata(sort.slice(0, 12))
		 
	}

	const DataRead = async () => {

		const { data } = await axios.get(URL)
		let sort = data.sort((a, b) => {
			return (
				new Date(a.BlogDate) -
				new Date(b.BlogDate)
			)
		}).reverse()
		setBlogdata(sort)
		setReadMore(true)

		 

	}
	const DataLess = async () => {

		const { data } = await axios.get(URL)
		let sort = data.sort((a, b) => {
			return (
				new Date(a.BlogDate) -
				new Date(b.BlogDate)
			)
		}).reverse()
		setReadMore(false)
		setBlogdata(sort.slice(0, 12))
		

		 

	}
	const linkName = readMore ? 'View Less' : 'View All';

	return (
		<div>
 
			
			<section className='py-md-5 py-4 title_bg jumbotron jumbotron-fluid text-center m-0 border-bottom'>
				<div className="container-fluid " style={{ marginTop: '4%' }}>
					<h1 className="text-center f-28 fw-600" >Blogs</h1>
				</div>

			</section>
<section>
 	<nav>
		<ol className="cd-breadcrumb custom-separator">
			<li><a href="/">Home</a></li>
			 
			<li className="current"><em>Blogs</em></li>
		</ol>
	</nav>
</section>
			<section className='bg-white'>
				<div className='container-fluid pt-5 pb-md-3 px-0'>
					<div className='row slide-in-bottom tech_talk_data mx-0'>
						<div className='col-lg-8 col-xl-8 pr-md-3 pl-md-0 tech_talk_view'>
							<div className='row mx-0 mb-4'>
								{Blogdata?.map((item, i) => {
									let date = new Date(item.BlogDate).toDateString().slice(4)
									return (
										<div className='col-lg-6 col-md-6 mt-4 px-md-3 px-0 pb-0 tech_talk_view tech_talkList-count'>
											<a href={`/blog/${item.Blogurlname.replace(/ /g, '-').toLowerCase()}/`}>

												{/* <Link to={{
												pathname: `/blog/${item.BlogHeadline.replace(/ /g, '-')}/`
											     }}> */}
												{/* <Link to='/blogdetails'> */}

												<div className='card resource_card'>
													<div className='card-img-top w-100 m-0 ' style={{ backgroundImage: `url(${urlimg}/${item.BlogImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
													</div>
													<div className='card-body bg-lightgrey pb-0 p-3' style={{ overFlow: 'hodden' }}>
														<p className='text-grey f-14 blog_date'>{date}</p>
														<p className='card-title text-black f-18 fw-600 fixed_content mb-2'>
															{item.BlogHeadline}
														</p>
														<p className='card-text text-grey-shade-2 f-14 fixed_content'>
															{item.BlogShortDescription}
														</p>
													</div>
													<div className='card-footer bg-lightgrey border-0 pt-0 p-3'>
														<div className='media align-items-center'>
															<img src={`${urlimg}/${item.AuthorProfileImage}`} className="author_image" />
															<div className='media-body blog_f14 ml-2'>
																<span className="text-grey">by</span>&nbsp;
																<span className="text-black fw-400">{item.AuthorName},</span>&nbsp;&nbsp;
																<span class="text-grey">{item.AuthorDesignation},</span>
																&nbsp;
																<span class="text-grey">{item.AuthorCompanyName}</span>
															</div>

														</div>
													</div>
												</div>
											</a>
										</div>
									)
								})
								}

 

 
							</div>
							<div className='row'>
								<div className='col-md-2'></div>
								<div className='col-md-3'></div>
							<div className='col-md-4 pt-2 pb-3'>
	<div className='d-inline-flex justify-center view-all pt-1' >
		{readMore ? <>
			<button onClick={DataLess} className="btn btn-red-primary border-radius-none f-16 f-mb-14 mt-1 px-5 waves-effect waves-light"  >
              {linkName}
            </button>
			{/* <a className='f-16 text-center btn-btn-success' onClick={DataLess} >{linkName}</a> */}
		</>:<>
		<button  onClick={DataRead}className="btn btn-red-primary border-radius-none f-16 f-mb-14 mt-1 px-5 waves-effect waves-light"  >
              {linkName}
            </button>
		{/* <a className='f-16 text-center btn-btn-success' onClick={DataRead} >{linkName}</a> */}
		</>}
	</div>
</div>
							</div>
						</div>
						<div className='col-lg-4 col-xl-4'>
							<TechtalkEditor Blogdata={Blogdata} setBlogdata={setBlogdata} />
						</div>
					</div>
				</div>
			</section >
		</div >
	)
}

export default Blog
