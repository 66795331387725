import React, { useEffect, useState } from 'react';
import axios from "axios";
import { API_URL } from './Api'
const Api = `${API_URL}/EnquireForm`;
const emailvali = /^([\w-\.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!outlook.com)(?!icloud.com)([\w-]+\.)+[\w-]{2,4})?$/;

function Model(props){

  const [Enquire, AddEnquire] = useState({
   
    activeSlideIndex: 0,
    ProductName: '',
    Name: '',
    Companyname: '',
    Email: '',
    Contactnumber: '',
    Country: '',
    Message: '',
    emailError:'',
    // ProductNames:''

  })
  const [Productnames, SetProductnames] = useState('')
  
  const { Product } = props;
  
  useEffect(() => {
   if(Product){
    SetProductnames(Product)
   }
}, [])

  // onchanges
 
  const adding = ({ target: { name, value } }) => {
    AddEnquire({ ...Enquire, [name]: value ,ProductNames:Product
     });
  }


 const Validation = ()=>{
   
   let emailerrors = '';

if(!emailvali.test(Enquire.Email) ){
 
  emailerrors ="Please enter the business mail only"
  
} 
if(emailerrors){
  AddEnquire({...Enquire ,
    emailError:emailerrors
  })
  return false;
}
else {
  return true;
}

  }
   
  // submit
const Formsubmit = (event) => {
    event.preventDefault();
   const validate= Validation();
   
 
if(validate){
  let data =
    {
      Name: Enquire.Name,
      Company: Enquire.Companyname,
      Email: Enquire.Email,
      ContactNumber: Enquire.Contactnumber,
      Country: Enquire.Country,
      Message: Enquire.Message,
      EnquiryFor:Product,

    }

    axios.post(`${Api}`, data).then(res => {
      
      AddEnquire({...Enquire ,
        Name: "",
        Companyname: "",
        Email: "",
        Contactnumber: "",
        Country: "",
        Message: "",
        emailError: "",
        ProductName: '',
      })
      window.location.href="/enquire/success";


    })
} 
  


  }

 
   
 


 
    return (
      <div>

        <div className="modal fade" id="exampleModalLong" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">

              <div className="modal-body">
                <button type="button" className="close f-left" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
                <div className='text-center'>
                  <h3>Enquire</h3>
                  
                  <p  >{Product} {Productnames}</p>
                </div>
                <form className="form-field" name="sample" onSubmit={Formsubmit} >


                  <input type="text" autoComplete="nope" autoFocus required placeholder="Your Name" onChange={ adding} name="Name" value={Enquire.Name} className="form-control" />
                  <input type="text" autoComplete="nope" autoFocus required placeholder="Your Company Name" className="form-control" onChange={ adding} name="Companyname" value={Enquire.Companyname} />
                  <input type="email" autoComplete="nope"   placeholder="Your Email Id" className="form-control" onChange={ adding} name="Email" value={Enquire.Email} />
                  <p style={{color:"red"}}>{Enquire.emailError}</p>
                  <input type="text" autoComplete="nope" autoFocus placeholder="Your Country" className="form-control" onChange={ adding} name="Country" value={Enquire.Country} />
                  <input type="text" autoComplete="nope" maxLength={15} minLength={6} autoFocus placeholder="Your Contact Number (with country code)" className="form-control" onChange={ adding} name="Contactnumber" value={Enquire.Contactnumber} />
                  <textarea autoComplete="nope" width="100%" rows={5} id="id_message" autoFocus required placeholder="Your Message" cols={40} className="form-control h-100" onChange={ adding} name="Message" value={Enquire.Message} />

                  <div className="row">
                    <div className="col-12">
                      <button type="submit" className="btn btn-red-primary border-radius-none btn-head px-5 m-0 mt-3 waves-effect waves-light" id="send_btn">Submit</button>
                    </div>
                  </div>
                </form>

              </div>

            </div>
          </div>
        </div>

      </div>



    )
  }

export default Model;