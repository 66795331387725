import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeV1 from './components/home-v1';
import About from './components/about';
import EventDetails from './components/event-details';
import Instructor from './components/instructor';
import InstructorDetails from './components/instructor-details';
import Pricing from './components/pricing';
import Gallery from './components/gallery';
import SignIn from './components/sign-in';
import SignUp from './components/sign-up';
import Contact from './components/contact';
import CareerPage from './components/Careers'
import Blog from './components/blog-page/blog-home';
import BlogDetails from './components/blog-page/blog-details';
import Articles from './components/Articles/Articles-Home';
import ArticlesDetails from './components/Articles/Articlesdetails';
import Knowledge from './components/Knowledge';
import Discussion from './components/Discussion';
import View from './components/View';
import SimpleSlider from './components/Carousel';
import MarketMap from './components/MarketMap';
import Marketplace from './components/Marketplace'
import news from './components/news';
import Newsetails from './components/Newsetails';
import Hrglossary from './components/hr-glossary';
import Hrglossarydetail from './components/Hr-glossary-details';
import Recentnews from './components/Recentnews';
import Aboutus from './components/Aboutus';
import Contactus from './components/Contactus';
import Event from './components/Event';
import EventsView from './components/EventsView';
import TechTalks from './components/TechTalks';
import techtalkEditor from './components/techtalkEditor';
import TechtalkView from './components/TechtalkView';
import ViewNews from './components/NewsViews';
import Resources from './components/Resources';
import ResourceView from './components/ResourceView';
import Resourceeditorchoice from './components/Resourceeditorchoice';
import Jobs from './components/Jobs.js';
import hrmsSsidetab from './components/Marketplace-pages/Categories';
import Privacypolicy from './components/Privacypolicy'
//marketplace
import Categories from './components/Marketplace-pages/Categories';
//SubCategory
import SubCategory from './components/Marketplace-pages/SubCategory';
import Model from './components/Model';
import ContactpageList from './components/ContactpageList';
import FooterpageList from './components/FooterPageList';
import EnquireList from './components/EnquireList';
import Success from './components/success'
import News from './components/news';
import Page_404 from './components/404_page'
import Searchpages from './components/Search'
import SHRM from './components/SHRM';
import Academy from './components/Aboutus/Academy';
import Certifications from './components/certificate/Certification';
import HomePage from './components/HomePage';



class Root extends Component {
    componentDidMount(){
 if(window.location.href == "https://www.hrtech.sg/jobs/"){
           
            window.location.href ="https://hrtech.sg/";
        }
    if(window.location.pathname == "/resources-details/global-hrtech-investments-start-up-funding-top-deals-in-h1-2022/"){
           
            window.location.pathname ="/resources-details/global-hr-tech-investments-and-start-up-funding-top-deals-2022/";
        }
   if(window.location.pathname == "/resources-details/hr-tech-case-studies-singapore/"){
           
                window.location.pathname ="/resources/case_studies/";
            }
        if(window.location.pathname == "/resources-details/2020-singapore-hrtech-market-map/"){
           
                window.location.pathname ="/market_map_pdf/";
            }
        if(window.location.pathname == "/resources-details/2019-singapore-hrtech-market-map/"){
           
                window.location.pathname ="/market_map_pdf/";
            }

    if(window.location.href == "https://www.hrtech.sg/resources/2019-singapore-hrtech-market-map/"){
                window.location.href ="https://www.hrtech.sg/market_map_pdf/"
            }
            if(window.location.href == "https://www.hrtech.sg/resources/2020-singapore-hrtech-market-map/"){
                window.location.href ="https://www.hrtech.sg/market_map_pdf/"
            }
      
    }
    render() {
        return(
                <Router >
	                <div>
	                <Switch>
                    
                        <Route exact path="/" component={HomePage} />
                        <Route  path="/about" component={About} />
                        <Route path="/404_page" component={Page_404} />
                       
                        
                        <Route path="/events-details" component={EventDetails} />
                        <Route path="/instructor" component={Instructor} />
                        <Route path="/instructor-details" component={InstructorDetails} />
                        <Route path="/pricing" component={Pricing} />
                        <Route path="/gallery" component={Gallery} />
                        <Route path="/sign-in" component={SignIn} />
                        <Route path="/sign-up" component={SignUp} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/privacy" component={Privacypolicy} />
			<Route path="/news/:id" component={ViewNews} />		
      <Route path="/hr-glossary/:id" component={Hrglossarydetail} />		
                        <Route path="/events/:id" component={EventsView} />
 <Route path="/events" component={Event} />
			 <Route exact path="/articles/" component={Articles} />
                        <Route exact path="/articles/:id" component={ArticlesDetails} />
                        <Route path="/Knowledge" component={Knowledge} />
                        <Route path="/Discussion" component={Discussion} />
                        <Route path="/SimpleSlider" component={SimpleSlider} />
                        <Route path="/market_map_pdf" component={MarketMap} />
                        <Route exact path="/marketplace" component={Marketplace} />
                        <Route path="/news/" component={News} />
                        <Route path="/news_detail/" component={Newsetails} />
                        <Route path="/hr-glossary/" component={Hrglossary} />
                        <Route path="/Recentnews" component={Recentnews} />
			<Route path="/about-us/:details" component={Academy} />
                        <Route path="/about-us/" component={Aboutus} />
 			<Route path="/certifications/" component={Certifications} />
			<Route path="/search/" component={Searchpages} />
			<Route path="/shrm/" component={SHRM} />
                        <Route path="/contact-us/" component={Contactus} />
                        <Route exact path="/tech_talks" component={TechTalks} />
                        <Route exact path="/careers" component={CareerPage}/>
                        <Route exact path="/techtalkEditor/:id" component={techtalkEditor} />
                        <Route  path="/tech_talks/:tech" component={TechtalkView} />
                        <Route exact path="/resources/" component={Resources} />
                        <Route exact path='/resources/:cato' component={Resources} />
                        <Route  exact path="/resources-details/:head" component={ResourceView} />
                        <Route path="/Resourceeditorchoice" component={Resourceeditorchoice} />
                       {/* <Route path="/jobs" component={Jobs} /> */}
                        <Route path="/hrmsSsidetab" component={hrmsSsidetab} />
                       
                       
                        {/* marketpages */}
                        <Route path="/privacy" component={Privacypolicy} />
                        <Route exact path="/marketplace/:name" component={Categories} />
                        <Route path="/marketplace/:name/:subCat" component={SubCategory} />
                        <Route exact path="/:product" component={View} />

                        {/* SubCategory */}

                        <Route path="/Model" component={Model} />
                        <Route path="/ContactpageList" component={ContactpageList} />
                        <Route path="/FooterpageList" component={FooterpageList} />
                        <Route path="/EnquireList" component={EnquireList} />
                        <Route path="/enquire/success" component={Success} />
                        
                       
                        
                       
	                </Switch>
	                </div>
                </Router>
               
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('Hr-Tech'));
