const reducer=(state,action)=>{
    try{
    switch(action.type){
        case "SCROLLTO":
        state=()=>{
                window.scrollTo({
                    top:0,
                    behavior:"smooth"
                })
            }
            return state
            
        default : 
        return state
    
    }
} catch(err){
    console.log(err,"error in the reducer" )
    
}

}

export const Reducer = reducer