import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './global-components/navbar';
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';
import Footer from './global-components/footer';
import TechtalkEditor from './techtalkEditor';
import '../components/news.css';
import {Helmet} from "react-helmet";

import { API_URL } from './Api';
import axios from 'axios';

const api = `${API_URL}/TechTalk`;
const urlimg = `${API_URL}/TechTalk_image`;

const TechTalks = () => {

    const [TechTalkData, SetTechTalkData] = useState([]);
    const [view, setview] = useState(false);
    const [Footerload, SetFooterload] = useState(false)
    // console.log(TechTalkData, 'Techtalk');

    const fetch = async () => {
        const { data } = await axios.get(api);
        let sort = data.sort((a, b) => {
			return (
				new Date(a.TechTalkDate) -
				new Date(b.TechTalkDate)
			)
		}).reverse()
        SetTechTalkData(sort);
    }

   
    useEffect(() => {
        setTimeout(() => {
            SetFooterload(true)
          }, 500)
        fetch();
        setview(true)
    }, [])



    return (
        <div>
 
            <Navbar />
          

            <section className='py-md-5 py-4 title_bg jumbotron jumbotron-fluid text-center m-0 border-bottom' >
                <div className="container-fluid " style={{ marginTop: '4%' }}>
                    <h1 className="text-center f-28 fw-600" >Tech Talks</h1>
                </div>

            </section>
   <section>
 	<nav>
		<ol className="cd-breadcrumb custom-separator">
			<li><a href="/">Home</a></li>
			 
			<li className="current"><em>Tech Talks</em></li>
		</ol>
	</nav>
</section>
            <section className='bg-white'>
                <div className='container-fluid pt-5 pb-md-3 px-0'>
                    <div className='row slide-in-bottom tech_talk_data mx-0'>
                        <div className='col-lg-8 col-xl-8 pr-md-3 pl-md-0 tech_talk_view'>
                            <div className='row mx-0 mb-4' >
                                 {
                                    TechTalkData?.map((item, id) => {
                                    let date = new Date(item.TechTalkDate).toDateString().slice(4);
                                        // console.log(item, 'dtata');
                                        return (
                                            <div className='col-lg-6 col-md-6 mt-4 px-md-3 px-0 pb-0  tech_talkList-count' key={id}>
                                                <a href={`/tech_talks/${item?.TechTalkurlname.replace(/ /g, '-').toLowerCase()}/`}>
                                                    <div className='card resource_card'>
                                                        <div className='card-img-top w-100 m-0 ' style={{ backgroundImage: `url(${urlimg}/${item.TechTalkImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}> </div>
                                                        <div className='card-body bg-lightgrey pb-0 p-3' style={{ overFlow: 'hodden' }}>
                                                            <p className='text-grey f-14'>{date}</p>
                                                            <p className='card-title text-black f-18 fw-600 fixed_content mb-2 techtalk-lineheight'>
                                                                {item.TechTalkHeadline}
                                                            </p>
                                                            <p className='card-text text-grey-shade-2 f-14 fixed_content techtalk-lineheight'>
                                                                {item.TechTalkShortDescription}
                                                            </p>
                                                        </div>
                                                        <div className='card-footer bg-lightgrey border-0 pt-0 p-3'>
                                                            <div className='media align-items-center'>
                                                                <img src={`${urlimg}/${item.AuthorProfileImage}`} className="author_image" />
                                                                <div className='media-body f-14 ml-2'>
                                                                    <span className="text-grey">by</span>&nbsp;
                                                                    <span className="text-black fw-400">{item.AuthorName},</span>&nbsp;&nbsp;
                                                                    <span class="text-grey">{item.AuthorDesignation}</span>&nbsp;&nbsp;
                                                                    <span class="text-grey">{item.AuthorCompanyName}</span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className='col-lg-4 col-xl-4 ec-sidebarWrap ' >
                            <TechtalkEditor TechTalkData={TechTalkData} view={view} />
                        </div>
                    </div>
                </div>
            </section>
            {Footerload ? <>
            <Testimonial />
            <Team />
            <Footer />
            </>:null}
        </div>
    )
}


export default TechTalks