import React, { Component, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaRegCalendarAlt,FaRegBuilding,FaBuilding } from 'react-icons/fa';
import Navbar from './global-components/navbar';
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';
import Footer from './global-components/footer';
import Counter from './section-components/counter';
import { API_URL } from './Api';
import axios from 'axios';
import moment from 'moment';
import Registerbutton from './EventViewRegister';
import {Helmet} from "react-helmet";

const URL = `${API_URL}/Events`;
const urlimg = `${API_URL}/Events_image`;

const EventsView = () => {
    const params = useParams();
    const [Result, SetResult] = useState([]);
    const [EventData, setEventData] = useState([]);
    const [Footerload, SetFooterload] = useState(false)
    const [loading200, Setloading200] = useState(false)
    useEffect( () => {
        setTimeout(() => {
            SetFooterload(true)
          }, 500)
        setTimeout(() => {
            Setloading200(true)
          }, 200)
        getdata();


    }, [params.id]);
    const getdata = async ()=>{
        const { data } = await axios.get(URL)
        setEventData(data);

        let Heading = params.id.replace(/-/g, ' ')
        let filter = data.filter((headline) => headline.Eventurlname.toLowerCase().includes(Heading.toLowerCase()));
        SetResult(filter[0]);
        console.log("vvvvv",filter[0])

         
    }
    const imgstyle={
        objectFit:'cover !important'
    }

    if(Result.EventDate){
        var dates = Result.EventDate.split('T')[0].replaceAll("-","")
        var startdate = dates+"T"+Result.EventStartTime.replaceAll(":","")+"00";
        var enddate = dates+"T"+Result.EventEndTime.replaceAll(":","")+"00";
    
    }
    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    const Calenderlink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${Result.EventName}&dates=${startdate}/${enddate}&sf=true&output=xml&details=${Result.EventShortDescription}`
    return (<div>
        <div className='row'>
            <div className='col-md-12'>
 
                <Navbar />
            </div>
        </div>
        <br></br>
        <section >
            <div className='bg-lightgrey mt-5 p-0'>
   <section>
					<nav>
		<ol className="cd-breadcrumb custom-separator" style={{backgroundColor:"#ffffff"}}>
			<li><a href="/">Home</a></li>
			<li><a href="/events/">Events</a></li>
			 
			<li className="current"><em>{Result?.EventName}</em></li>
		</ol>
	</nav></section>
                <div className='container-fluid'>
                    {

                        <div className='row m-0 ' >
                            <div className="col-md-12 px-0 mb-3">
                                <div className="card border-0 border-radius-none">
                                <div className="card-body bg-white border px-0 px-md-3 eventview-padding" >
                                        
                                        {
                                                    Result.EventStatus == "Pending" ?
                                                    <>
                                        <div className="row m-0">
                                            <div className="col-md-12 col-sm-12 col-12" style={{padding: '0'}}>
                                              <div className="eventview-card">
                                              <img src={`${urlimg}/${Result.EventImage}`} className="eventview-img"/>
                                              </div>
                                            </div>
                                            
                                        
                                        </div> 
                                        <div className="row d-flex align-items-center" style={{padding: '17px'}}>
                                                                                    <div className="col-md-10 col-sm-12 col-12" >
                                                                                        <h1 className="f-28 f-mb-20 fw-600 mb-3">{Result.EventName}</h1>
                                                                                        <p className="f-16 f-mb-12 pb-3">{Result.EventShortDescription}</p>
                                                                                       
                                                                                    </div>
                                                                                    <div className="col-md-2 col-sm-12 col-12">
                                                                                    <Registerbutton style={{width: '100%'}} buttonname={Result.CallToAction} Eventname={Result.EventName} lumalink={Result.EventLUMALink} lumaid={Result.EventLUMAId} EventWebsiteLink={Result.EventWebsiteLink} />
                                                                                     </div>
                                                                                     </div></>
                                        
                                                 :   <div className="row m-0">
                                                 
                                                 <div className="col-md-12 col-sm-12 col-12 p-3">
                                                     <h1 className="f-28 f-mb-20 fw-600 mb-3">{Result.EventName}</h1>
                                                     <p className="f-16 f-mb-12">{Result.EventShortDescription}</p>
                                                 </div>
                                             </div>
                                                }
                                        
                                        
                                        </div>
                                   
                                </div>
                            </div>
                            {loading200 ? 
                            <div className='col-md-4 pl-md-0 pr-md-3 px-0'>
                                <div className='card border-radius-none mb-3'>
                                    <div className='card-body pt-3 pb-2'>
                                        <div className='d-flex border-bottom align-items-baseline'>
                                            <h2 className='pb-3 f-18 fw-600'>EVENT DETAILS</h2>
                                            {
                                                Result.EventStatus === "Pending" && Result.EventStatus != "undefined" ? <>
                                                    <a href={Calenderlink} target="_blank" style={{ color: '#004AAD', marginLeft: '80px' }}>+ Add to calendar</a>
                                                </> : null
                                            }

                                        </div>
                                        <div className='pt-3 pb-1 d-flex'>
                                            <FaRegCalendarAlt />
                                            <div className='d-md-flex d-block align-items-center pl-2'>
                                                <p className='f-16 pl-md-2 pl-3 w-mb-100 f-mb-14'>
                                                    {moment(Result.EventDate).format("MMM Do YYYY")}  &nbsp; {moment(Result.EventStartTime, "hh:mm").format('LT')}   -  {moment(Result.EventEndTime, "hh:mm").format('LT')}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='pt-3 pb-1 d-flex'>
                                            <FaRegBuilding />
                                            <div className='d-md-flex d-block align-items-center pl-2'>
                                                <p className=''>
                                                {Result.EventLocation}
                                                    {/* {moment(Result.EventDate).format("MMM Do YYYY")}  &nbsp; {moment(Result.EventStartTime, "hh:mm").format('LT')}   -  {moment(Result.EventEndTime, "hh:mm").format('LT')} */}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :null}
                         {loading200 ? 
                            <div className='col-md-8 pr-md-0 px-0'>
                                {Result.EventStatus == "Pending" ?
                                    <>
                                        <div className='card border-radius-none mb-3'>
                                            <div className='card-header py-3 bg-white align-items-center d-flex'>
                                                <h2 className='f-18 fw-600 w-100'>
                                                    EVENT AGENDA
                                                </h2>
                                            </div>
                                            <div className='card-body row m-0 style_summernote'>
                                                <p>
                                                    {renderHTML(Result.EventAgenda)}
                                                </p>



                                            </div>
                                        </div>
                                    </> :
                                    
                                    
                                    <>
                                      

                                        {((Result.PreEventVideoLink !== "")&&(typeof Result.PreEventVideoLink !== "undefined") || (typeof Result.Postimage !== "undefined")&&( Result.Postimage.length > 0))?
                    <div className="card">
                      <div className="row card-body">
                        <div className="col-md-12">
                          <div id="custCarousel" className="row d-flex carousel slide" align="center">
                            <div className="col-md-10 col-sm-9 px-0 col-12 mb-md-2 mb-3">
                              <div className=" carousel-inner">
                                {Result.PreEventVideoLink ?
                                  <div className="carousel-item active"> <iframe width='100%' height='420' src={`${Result.PreEventVideoLink}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> </div>
                                  : 
                                  <>
                                   <div className="carousel-item active"  > <img src={`${urlimg}/${Result.Postimage[0]}`} alt="Hills" /> </div>
                                  </>
                                  }
                                {(typeof Result.Postimage !== "undefined")&&( Result.Postimage.length > 0) ? <>
                                  {Result.Postimage.map((item, i) => {
                                
                                    return (<div className="carousel-item eventslide"  key={i}> <img  src={`${urlimg}/${item}`} alt="imagesss" style={imgstyle} /> </div>
                                    )
                                  })}
                                </> : null}
                              </div>
                            </div>
                           
                            <div className="col-md-2 d-block side_img_div px-0">
                              <ol className=" carousel-indicators list-inline">
                                {Result.PreEventVideoLink ?
                                  <li className="list-inline-item active"> <a id="carousel-selector-0" href="#" className="selected" data-slide-to="0" data-target="#custCarousel"> <img src="/assets/img/videoicon.png" className="img-fluid"  alt="video"/> </a> </li>
                                  :  null}
                                {(typeof Result.Postimage !== "undefined")&&( Result.Postimage.length > 0) ? <>
                                  {Result.Postimage.map((item, i) => {                                  
                                    return (<li className="list-inline-item " key={i}> <a id={`carousel-selector-${i + 1}`} data-slide-to={i + 1} data-target="#custCarousel" href="#"> <img src={`${urlimg}/${item}`} className="img-fluid" alt={`${item}`} /> </a> </li>
                                    )
                                  })}
                                </> : 
                               null}
                              </ol></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    : null}
                                         <div id="MainStreamEmbed"></div>
                                        <div className='card border-radius-none mb-3'>
                                            <div className='card-header py-3 bg-white align-items-center d-flex'>
                                                <h2 className='f-18 fw-600 w-100'>
                                                    EVENT SYNOPSIS
                                                </h2>
                                            </div>
                                            <div className='card-body row m-0 style_summernote'>
                                                <p>
                                                    {renderHTML(Result.EventSynopsis)}
                                                </p>



                                            </div>
                                        </div>
                                    </>
                                }

                            </div>
                             :null}
                        </div>
                    }
                </div>
            </div>
        </section>
        {Footerload ? <>
        <Counter />
        <Testimonial />
        <Team />
        <Footer /></>:null}
    </div>
    )
}

export default EventsView