import React, { useEffect, Fragment } from 'react';
import Navbar from './global-components/navbar';
import Footer from './global-components/footer';
import Testimonial from './section-components/testimonial';
import Team from './section-components/team';

const CareerPage = () => {
    useEffect(() => {
        // Inline script
        const scriptContent = `
            window.khConfig = {
                identifier: 'e0a22144-c7b7-4824-8830-1c08c9ca5ae2', 
                domain: 'https://hrtech.keka.com/careers/', 
                targetContainer: '#careers'
            };
        `;
        const script = document.createElement('script');
        script.innerHTML = scriptContent;
        document.body.appendChild(script);

        // External script
        const externalScript = document.createElement('script');
        externalScript.src = 'https://hrtech.keka.com/careers/api/embedjobs/js/e0a22144-c7b7-4824-8830-1c08c9ca5ae2';
        externalScript.defer = true;
        document.body.appendChild(externalScript);
        
    }, []);
    const style={
        'margin-bottom' : '60px'
    }
    return (
        <div>
            <Navbar />
            <div className="main-content">
                <div className="contain_navs banner-sec" style={style}>
                    <div className="container">
                        <div className="row" id="careers">

                        </div>
                    </div>
                </div>
            </div>
            <Testimonial />
            <Team />
            <Footer />
        </div>
    );
};

export default CareerPage;

